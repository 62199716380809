import cx from 'classnames';
import React, { ComponentType, FC } from 'react';

import { IconProps as RFProps } from 'react-feather';

interface OverlayButtonProps {
  icon: ComponentType<RFProps> | FC;

  className?: string;
  onClick?: () => void;
}

export const OverlayButton: FC<OverlayButtonProps> = (props) => {
  const { className, icon, onClick } = props;

  const Icon = icon;

  const classes = cx(
    'rounded p-2 bg-black-50 hover:bg-black-75 text-white hover:text-st-orange-normal flex items-center justify-center',
    className
  );

  return (
    <button
      className={classes}
      onClick={onClick}
      style={{ width: 36, height: 36 }}
    >
      <Icon size="20" />
    </button>
  );
};
