import { Map } from 'immutable';
import { AudioTrack, Participant, TrackPublication } from 'livekit-client';
import { atom, atomFamily } from 'recoil';

import firebase from 'api/firebase';

import { GameRoom } from 'api/modules/GameRoom';
import { ConnectionState, Game, Players, User, VideoResolution } from 'types';
import { Dice } from 'types/dice';
import { IDEAL_CAM_HEIGHT, IDEAL_CAM_WIDTH } from 'utils/media';

export const FIREBASE_USER = 'firebaseUserState';
export const firebaseUserState = atom({
  key: FIREBASE_USER,
  default: firebase.auth().currentUser,
  dangerouslyAllowMutability: true, // This is out of our control, as it comes from Firebase
});

export const AUTH_ATTEMPTED = 'authAttemptedState';
export const authAttemptedState = atom({
  key: AUTH_ATTEMPTED,
  default: false,
});

export const VIDEOS_MINIMIZED = 'videosMinimized';
export const videosMinimizedState = atom({
  key: VIDEOS_MINIMIZED,
  default: false,
});

export const GAME = 'game';
export const gameState = atom<Game | undefined>({
  key: GAME,
  default: undefined,
});

export const PLAYERS = 'players';
export const playersState = atom<Players>({
  key: PLAYERS,
  default: [],
});

export const INCLUDE_EXTRAS = 'includeExtras';
export const includeExtrasState = atom<boolean>({
  key: INCLUDE_EXTRAS,
  default: false,
});

export const INCLUDE_LANGUAGES = 'includeLanguages';
export const includeLanguagesState = atom<boolean>({
  key: INCLUDE_LANGUAGES,
  default: false,
});

export const DICE = 'dice';
export const diceState = atom<Dice>({
  key: DICE,
  default: {
    sided: undefined,
    result: undefined,
    showDiceTimer: undefined,
    showResultsTimer: undefined,
    isDiceShowing: true,
  },
});
export interface VideoPosition {
  x: number;
  y: number;
}
export const VIDEO_POSITIONS = 'videoPositionsState';
export const videoPositionsState = atom<VideoPosition>({
  key: VIDEO_POSITIONS,
  default: { x: 30, y: 100 },
});

export const LAST_VIDEO_POSITION = 'lastVideoPositionsState';
export const lastVideoPositionsState = atom<VideoPosition>({
  key: LAST_VIDEO_POSITION,
  default: { x: 30, y: 120 },
});

export const CURRENT_DRAG = 'currentDragState';
export const currentDragState = atom<
  { origin: { x: number; y: number } } | undefined
>({
  key: CURRENT_DRAG,
  default: undefined,
});

export const USER_PROFILE = 'userProfileState';
export const userProfileState = atomFamily<User | undefined, string>({
  key: USER_PROFILE,
  default: undefined,
});

export const PLAYER_VOLUME = 'playerVolumeState';
export const playerVolumeState = atom<Record<string, number | null>>({
  key: PLAYER_VOLUME,
  default: {},
});

export const USER_EMAIL = 'userEmail';
export const userEmailState = atom<string>({
  key: USER_EMAIL,
  default: '',
});

export const SPEAKERS = 'speakers';
export const speakersState = atom<string[]>({
  key: SPEAKERS,
  default: [],
});

export const MUTED = 'muted';
export const mutedState = atom<boolean>({
  key: MUTED,
  default: false,
});

export const MUTED_SPEAKERS = 'mutedSpeakers';
export const mutedSpeakersState = atom<string[]>({
  key: MUTED_SPEAKERS,
  default: [],
});

export const VIDEO_ENABLED = 'VIDEO_ENABLED';
export const videoEnabledState = atom<boolean>({
  key: VIDEO_ENABLED,
  default: false,
});

export const GAME_ROOM = 'GAME_ROOM';
export const gameRoomState = atom<GameRoom | undefined>({
  key: GAME_ROOM,
  default: undefined,
  dangerouslyAllowMutability: true, // gameroom gonna gammeroom
});

export const ROOM_CONNECTION_STATUS = 'ROOM_CONNECTION_STATUS';
export const roomConnectionStatusState = atom<ConnectionState>({
  key: ROOM_CONNECTION_STATUS,
  default: ConnectionState.CONNECTING,
});

export const VIDEO_TRACKS = 'videoTracks';
export const videoTracksState = atom<Map<string, TrackPublication>>({
  key: VIDEO_TRACKS,
  default: Map({}),
  dangerouslyAllowMutability: true, // LiveKit mutates these, but we store them in a map so we good
});

export const VIDEO_RESOLUTION = 'videoResolution';
export const videoResolutionState = atom<VideoResolution>({
  key: VIDEO_RESOLUTION,
  default: { height: IDEAL_CAM_HEIGHT, width: IDEAL_CAM_WIDTH },
});

export const CUSTOM_ASPECTRATIO = 'customAspectRatio';
export const customAspectRatioState = atom<number>({
  key: CUSTOM_ASPECTRATIO,
  default: -1,
});

export const AUDIO_TRACKS = 'audioTracks';
export const audioTracksState = atom<AudioTrack[]>({
  key: AUDIO_TRACKS,
  default: [],
  dangerouslyAllowMutability: true,
});

export const PARTICIPANTS = 'participants';
export const participantsState = atom<Participant[]>({
  key: PARTICIPANTS,
  default: [],
  dangerouslyAllowMutability: true,
});

export const ERROR_MESSAGE = 'errorMessage';
export const errorMessageState = atom<string | undefined>({
  key: ERROR_MESSAGE,
  default: undefined,
});
