import cx from 'classnames';
import React, { FC, useCallback } from 'react';
import { CheckSquare, Square } from 'react-feather';

interface CheckboxProps {
  isChecked: boolean;
  name: string;
  isDisabled?: boolean;
  onChange: (name: string, isChecked: boolean) => void;
}

export const Checkbox: FC<CheckboxProps> = ({
  children,
  isChecked,
  name,
  isDisabled,
  onChange,
}) => {
  const onChangeHandler = useCallback(() => {
    onChange(name, !isChecked);
  }, [name, isChecked, onChange]);

  const checkboxClasses = cx(
    'relative mr-3 flex items-center justify-center',
    isChecked ? 'text-st-orange-normal' : ''
  );

  return (
    <label className="flex items-center pointer-events-auto" htmlFor={name}>
      <span className={checkboxClasses} style={{ height: 18, width: 18 }}>
        {isChecked ? <CheckSquare /> : <Square />}
        <input
          checked={isChecked}
          className="absolute opacity-0"
          id={name}
          disabled={isDisabled}
          name={name}
          onChange={onChangeHandler}
          type="checkbox"
        />
      </span>
      {children}
    </label>
  );
};
