import { Track } from 'livekit-client';
import { useEffect, useRef } from 'react';

export interface AudioTrackProps {
  track: Track;
}

/**
 * This component is pulled/re-created from the livekit-react library
 */

export const AudioRenderer = ({ track }: AudioTrackProps) => {
  const audioEl = useRef<HTMLAudioElement>();
  useEffect(() => {
    audioEl.current = track.attach();
    if (track.sid) {
      audioEl.current.setAttribute('data-audio-track-id', track.sid);
    }
    return () => track.detach().forEach((el) => el.remove());
  }, [track]);

  return null;
};
