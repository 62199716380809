import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { LegalLinks } from 'components/LegalLinks';
import useLoginAction from 'hooks/useLoginAction';
import { CURRENT_FULL_YEAR } from 'constants/dates';
import { Link } from 'react-router-dom';

export const Footer: FunctionComponent = () => {
  const { t } = useTranslation();
  const login = useLoginAction();

  return (
    <>
      <div className="flex-1 w-full flex flex-col justify-center items center">
        <div className="flex flex-col sm:flex-row items-center justify-center text-gray-600 py-6">
          <Link
            to="/"
            className="px-4 py-2 sm:py-0 hover:text-st-orange-normal text-gray-600 hover:underline cursor-pointer justify-center transition-colors ease-in-out duration-200"
          >
            {t('label__home')}
          </Link>
          <button
            onClick={login}
            className="px-4 py-2 sm:py-0 hover:text-st-orange-normal text-gray-600 hover:underline cursor-pointer justify-center transition-colors ease-in-out duration-200"
          >
            {t('action__login')}
          </button>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://blog.spelltable.com"
            className="px-4 py-2 sm:py-0 hover:text-st-orange-normal text-gray-600 hover:underline cursor-pointer justify-centertransition-colors ease-in-out duration-200"
          >
            {t('label__update-blog')}
          </a>
          <Link
            target="_blank"
            to="/faq"
            className="px-4 py-2 sm:py-0 hover:text-st-orange-normal text-gray-600 hover:underline cursor-pointer transition-colors ease-in-out duration-200"
          >
            {t('label__faq')}
          </Link>
        </div>
      </div>

      <LegalLinks />
      <div
        className="flex justify-center -mt-4 pb-8"
        style={{
          fontWeight: 400,
          fontFamily: 'DIN Next Pro,Arial,sans-serif',
          color: '#66707D',
          fontSize: 11,
        }}
      >{`© 1995–${CURRENT_FULL_YEAR} Wizards of the Coast LLC, a subsidiary of Hasbro, Inc. All Rights Reserved.`}</div>
    </>
  );
};
