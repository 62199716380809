import {
  DEFAULT_COMMANDER_LETHAL,
  FORMAT_INFO,
  MagicFormat,
  PlayerProperties,
} from 'types';

export function getDefaultPlayerProps(
  format: MagicFormat = MagicFormat.Commander
): PlayerProperties {
  return {
    commanders: [],
    life: FORMAT_INFO[format].startingLife,
    damage: [],
    isDead: false,
    isMonarch: false,
  };
}

export function getResetablePlayerProps(
  format: MagicFormat = MagicFormat.Commander,
  resetCommander: boolean = true,
  resetGamestate: boolean = true
): Partial<PlayerProperties> {
  let reset: Partial<PlayerProperties> = {};

  if (resetCommander) {
    reset.commanders = [];
  }
  if (resetGamestate) {
    reset = {
      ...reset,
      life: FORMAT_INFO[format].startingLife,
      commanderLethal:
        FORMAT_INFO[format].commanderLethal ?? DEFAULT_COMMANDER_LETHAL,
      damage: [],
      isDead: false,
      isMonarch: false,
    };
  }

  return reset;
}
