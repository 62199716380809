export const CARD_SEARCH_SELECT = 'cardSearchSelect';
export const LOGIN = 'login';
export const LOGOUT = 'logout';
export const SIGNUP = 'signup';
export const SESSION_START = 'sessionStart';
export const SESSION_END = 'sessionEnd';
export const CARD_SPOTTED = 'cardSpotted';
export const SET_COMMANDER = 'setCommander';
export const CREATE_GAME = 'createGame';
export const CLOSE_GAME = 'closeGame';
export const BLOCK_USER = 'blockUser';
export const UNBLOCK_USER = 'unblockUser';
export const GAME_JOINED = 'gameJoined';
export const MARKETING_LINK_CLICKED = 'marketingLinkClicked';
export const IS_STREAMING = 'isStreaming';
export const GAME_LANGUAGE = 'gameLanguage';
export const USER_LANGUAGE = 'userLanguage';
export const CARD_DETECTED_CODEX = 'cardDetectedCodex';
export const CODEX_ENABLED = 'codexEnabled';
export const CODEX_SETTINGS = 'codexSettings';
export const CODEX_RETRIES = 'codexRetries';
export const DOOR_TO_PUBLIC = 'doorToPublic';
export const COIN_DICE = 'coinDice';
