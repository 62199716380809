import Api from 'api';
import { joinGame } from 'api/gateway/gateway';
import { GameRoom, RoomUpdate } from 'api/modules/GameRoom';
import LoadingVideo from 'components/game/LoadingVideo';
import useFirebaseUser from 'hooks/useFirebaseUser';
import { Track } from 'livekit-client';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { participantsState, videoResolutionState } from 'state/atoms';
import { localizationKeyFromError } from 'utils/getVideoStream';
import logger from 'utils/logger';

type Props = { gameId: string; deviceId: string };

export const MobileVideo: FunctionComponent<Props> = ({ gameId, deviceId }) => {
  const user = useFirebaseUser();
  const [participants, setParticipants] = useRecoilState(participantsState);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const mobileRef = useRef<HTMLVideoElement>(null);
  const [room, setRoom] = useState<GameRoom | null>(null);
  const videoResolution = useRecoilValue(videoResolutionState);
  const { t } = useTranslation();

  const mobileParticipant = participants.find((p) => {
    return (
      p.identity.split('-')[1] === 'mobile' &&
      p.identity.split('-')[0] === user?.uid
    );
  });

  // No op for mobile
  const onUpdate = (update: RoomUpdate) => {
    setParticipants(update.participants);
  };
  const startVideo = async () => {
    const wotcToken = await Api.auth.refreshAuth();
    const joinToken = await joinGame(
      wotcToken.access_token,
      mobile(Api.auth.wotcAccountId),
      gameId,
      true
    );
    try {
      const room = new GameRoom(
        mobile(user?.uid),
        joinToken,
        videoResolution,
        onUpdate,
        deviceId
      );
      await room.tryInit();
      setRoom(room);
      setLoading(false);
    } catch (e) {
      const message = localizationKeyFromError(e as Error);
      setErrorMessage(message);
      logger.error('Trouble initializing mobile room', e as Error);
    }
  };

  useEffect(() => {
    const participantTrack = mobileParticipant?.getTrackPublication(
      Track.Source.Camera
    )?.track;
    const track: Track | undefined = participantTrack
      ? participantTrack
      : undefined;

    if (track && mobileRef.current) {
      track?.attach(mobileRef.current);
    }
  }, [mobileParticipant, loading]);

  useEffect(() => {
    if (Api.getUserPreferences()?.useMobileCamera === false) {
      room?.leave();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Api.getUserPreferences()?.useMobileCamera]);

  useEffect(() => {
    const reset = () => {
      room?.leave();
    };
    document.addEventListener('beforeunload', reset);
    startVideo();
    return () => {
      reset();
      document.removeEventListener('beforeunload', reset);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex justify-center w-full h-full items-center">
      {errorMessage ? (
        <>{t(errorMessage)}</>
      ) : loading ? (
        <LoadingVideo message="message__connecting-server" />
      ) : (
        <video
          className="absolute left-0 top-0 w-full h-full rounded-md untransform"
          playsInline
          autoPlay
          muted
          ref={mobileRef}
        />
      )}
    </div>
  );
};

const mobile = (str?: string) => {
  return str + '-mobile';
};
