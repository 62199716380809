import { Header, Modal } from 'components/ui/Modal';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { isMac } from 'utils/isMac';

interface ShortcutsModalProps {
  onModalClose: () => void;
}

const SHORTCUTS = [
  {
    title: 'shortcut__pass-the-turn',
    shortcut: 'shortcut-key__pass-the-turn',
  },
  {
    title: 'shortcut__change-life-by-one',
    shortcut: 'shortcut-key__change-life-by-one',
  },
  {
    title: 'shortcut__change-life-by-ten',
    shortcut: 'shortcut-key__change-life-by-ten',
  },
  {
    title: 'shortcut__search-for-card',
    shortcut: `${isMac() ? 'Cmd' : 'Ctrl'}+F`,
  },
  {
    title: 'shortcut__toggle-drawer-visibility',
    shortcut: `${isMac() ? 'Cmd' : 'Ctrl'}+D`,
  },
  {
    title: 'shortcut__toggle-game-panel',
    shortcut: `${isMac() ? 'Cmd' : 'Ctrl'}+G`,
  },
  {
    title: 'shortcut__toggle-audio',
    shortcut: `${isMac() ? 'Cmd' : 'Ctrl'}+M`,
  },
];

export const ShortcutsModal: FunctionComponent<ShortcutsModalProps> = (
  props
) => {
  const { onModalClose } = props;
  const { t } = useTranslation();

  return (
    <Modal className="phone:w-auto" onClose={onModalClose}>
      <Header>{t('label__keyboard-shortcuts')}</Header>
      {SHORTCUTS.map(({ title, shortcut }) => {
        return (
          <div
            key={title}
            className="flex justify-between items-center py-4 phone:w-600 border-b border-surface-faded last:border-b-0"
          >
            <span>{t(title)}</span>
            <span className="bg-st-purple-light text-white font-bold text-sm font-mono rounded py-1 px-2">
              {t(shortcut)}
            </span>
          </div>
        );
      })}
    </Modal>
  );
};
