import React, { FunctionComponent, createContext, useContext } from 'react';

interface VideoContextProps {
  rotatedVideos: string[];
  toggleVideoRotate: Function;
}

export const VideoContext = createContext<Partial<VideoContextProps>>({});

export function useVideoContext() {
  const context = useContext(VideoContext);
  return context;
}

interface VideoContextProviderProps {
  value: Partial<VideoContextProps>;
}

export const VideoContextProvider: FunctionComponent<
  VideoContextProviderProps
> = (props) => {
  const { children, value } = props;
  return (
    <VideoContext.Provider value={value}>{children}</VideoContext.Provider>
  );
};
