import { useEffect } from 'react';

export default function usePopOverEvents(
  isOpen: boolean,
  openToggleFn: (open: boolean) => void,
  popOverRef: React.RefObject<HTMLElement>
) {
  useEffect(() => {
    let isMounted = true;

    function onKeyDown(e: KeyboardEvent) {
      if (!isMounted) {
        return;
      }

      if (e.keyCode === 27) {
        openToggleFn(false);
      }
    }

    function onWindowClick(e: MouseEvent) {
      if (!isMounted) {
        return;
      }

      if (
        popOverRef.current &&
        !popOverRef.current.contains(e.target as Node)
      ) {
        openToggleFn(false);
      }
    }

    if (isOpen) {
      window.addEventListener('keydown', onKeyDown);
      window.addEventListener('mousedown', onWindowClick);
    }

    return () => {
      isMounted = false;
      window.removeEventListener('keydown', onKeyDown);
      window.removeEventListener('mousedown', onWindowClick);
    };
  }, [isOpen, popOverRef, openToggleFn]);
}
