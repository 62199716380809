import React, { FunctionComponent, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import useFirebaseUser from 'hooks/useFirebaseUser';
import useGame from 'hooks/useGame';

import { AlertCircle } from 'react-feather';

export const PopupMessage: FunctionComponent = () => {
  const user = useFirebaseUser();
  const { host } = useGame()!;

  const { t } = useTranslation();

  const lastHost = useRef(host);

  const [show, setShow] = useState(false);

  const [msg, setMsg] = useState(t('label__you-are-now-host'));

  useEffect(() => {
    if (user && host !== lastHost.current && host === user.uid) {
      setMsg(t('label__you-are-now-host'));
      setShow(true);
    } else if (user && host !== lastHost.current && host !== user.uid) {
      setMsg(t('label__game-owner-rejoined'));
      setShow(true);
    }

    lastHost.current = host;
  }, [host, user, t]);

  useEffect(() => {
    let timeout: number;

    if (show) {
      timeout = window.setTimeout(() => {
        setShow(false);
      }, 8000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [show]);

  const classes = cx(
    'flex absolute inset-x-0 top-0 justify-center py-6 pointer-events-none transition-all ease-in-out duration-200',
    {
      'display-none opacity-0': !show,
      'opacity-100': show,
    }
  );

  return (
    <div className={classes}>
      <div className="flex items-center justify-center bg-surface-medium shadow-md inline-block text-white rounded py-3 px-4 text-sm">
        <AlertCircle className="mr-2 text-st-orange-normal" size="18" />
        <div>{msg}</div>
      </div>
    </div>
  );
};
