import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-modal-hook';
import { useHistory, useLocation, useParams } from 'react-router';

import firebase from 'api/firebase';
import { Game } from 'types';

import {
  Mic,
  MicOff,
  Settings,
  Smartphone,
  Video,
  VideoOff,
} from 'react-feather';

import Api from 'api';
import {
  DevicesModal,
  Settings as DeviceModalSettings,
} from 'components/DevicesModal';
import { Button } from 'components/ui/Button';
import { OverlayButton } from 'components/ui/OverlayButton';
import usePreviewVideo from 'hooks/usePreviewVideo';
import { useRecoilState } from 'recoil';
import {
  customAspectRatioState,
  mutedState,
  videoEnabledState,
} from 'state/atoms';
import isSpectator from 'utils/isSpectator';
import logger from 'utils/logger';

interface GameParams {
  id: string;
}

interface JoinScreenProps {
  onJoin: () => any;
}

export const JoinScreen: FC<JoinScreenProps> = (props) => {
  const { onJoin } = props;
  const { t } = useTranslation();
  const { id } = useParams<GameParams>();
  const history = useHistory();
  const [gameLoading, setGameLoading] = useState<boolean>(false);
  const [gameName, setGameName] = useState<string | null>(null);
  const [useMobileCamera, setUseMobileCamera] = useState(false);
  const [isVideoEnabled, setIsVideoEnabled] =
    useRecoilState<boolean>(videoEnabledState);
  const [isMuted, setIsMuted] = useRecoilState<boolean>(mutedState);
  const [customAspectRatio, setCustomAspectRatio] = useRecoilState(
    customAspectRatioState
  );
  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search?.toLowerCase());
  const [previewVideoNode, setPreviewVideoNode] = useState<HTMLVideoElement>();

  const {
    stream,
    error: streamError,
    reload,
  } = usePreviewVideo(isVideoEnabled, useMobileCamera, false);

  const [showDevicesModal, hideDevicesModal] = useModal((): JSX.Element => {
    return (
      <DevicesModal
        inGame={false}
        isMobile={false}
        onModalClose={onDeviceModalClose}
      />
    );
  }, [previewVideoNode, isVideoEnabled, customAspectRatio]);

  const onDeviceModalClose = useCallback(
    async (
      mobileDidChange,
      {
        customAspectRatio: aspectRatio,
        videoDeviceID,
        useMobileCamera,
      }: DeviceModalSettings
    ) => {
      if (mobileDidChange) {
        setUseMobileCamera(useMobileCamera);
        if (useMobileCamera && previewVideoNode && isVideoEnabled) {
          setIsVideoEnabled(false);
        } else if (!useMobileCamera) {
          setIsVideoEnabled(true);
        }
      }
      // this enables DevicesModal to update it's state
      setCustomAspectRatio(aspectRatio);
      reload(aspectRatio, videoDeviceID, stream);
      hideDevicesModal();
    },
    [
      hideDevicesModal,
      isVideoEnabled,
      previewVideoNode,
      setIsVideoEnabled,
      setCustomAspectRatio,
      reload,
      stream,
    ]
  );

  const leave = async () => {
    const user = Api.user.getUser();
    await Api.players.update(
      user?.accountId!,
      { inactive: true },
      { batch: false }
    );
  };

  // TODO: this logic should be moved out, but RecoilJS is bad in hooks so we need to figure
  // out how to move it out (note: this is copy/pasted in mobile join screen more or less)
  useEffect(() => {
    const init = async () => {
      try {
        const doc = await firebase
          .firestore()
          .collection('games')
          .doc(id)
          .get();
        if (!doc.exists) {
          setGameName(t('label__no-game-found'));
          return;
        }
        const preferences = await Api.getUserPreferences();

        const isUsingMobileCamera = preferences?.useMobileCamera || false;
        const isVideoEnabledBackend =
          preferences?.gameAudioVideo?.isVideoEnabled !== undefined
            ? preferences.gameAudioVideo.isVideoEnabled
            : true;
        const isMutedBackend =
          preferences?.gameAudioVideo?.isMuted !== undefined
            ? preferences.gameAudioVideo.isMuted
            : false;

        setUseMobileCamera(isUsingMobileCamera);
        const { name } = doc.data() as Game;
        setIsMuted(isMutedBackend);
        setGameName(name);
        setIsVideoEnabled(
          !isUsingMobileCamera && !isSpectator() && isVideoEnabledBackend
        );
      } catch (e) {
        logger.error('Join screen init failed', e as Error);
        setGameName(t('label__error'));
      }
      setGameLoading(false);
    };
    init();
    return () => {
      const leaveGame = async () => {
        if (window.location.pathname === '/lobby') {
          await leave();
        }
      };
      leaveGame();
    };
  }, [id, setIsVideoEnabled, t, gameLoading, setIsMuted]);

  const setVideoPreviewRef = useCallback((node) => {
    setPreviewVideoNode(node);
  }, []);

  useEffect(() => {
    if (previewVideoNode && isVideoEnabled) {
      previewVideoNode.srcObject = stream || null;
    }
  }, [previewVideoNode, isVideoEnabled, stream]);

  const handleJoin = useCallback(async () => {
    if (stream) {
      stream.getTracks().forEach((t) => t.stop());
    }
    onJoin();
  }, [onJoin, stream]);

  const backToLobby = async (): Promise<void> => {
    await leave();
    history.push('/lobby');
  };

  const toggleMute = useCallback(() => {
    Api.setAVPreferences({ isMuted: !isMuted });
    setIsMuted(!isMuted);
  }, [isMuted, setIsMuted]);

  const toggleVideo = useCallback(() => {
    Api.setAVPreferences({ isVideoEnabled: !isVideoEnabled });
    setIsVideoEnabled(!isVideoEnabled);
  }, [isVideoEnabled, setIsVideoEnabled]);

  const gameInfo = !gameLoading ? (
    <div className="flex-1 flex md:px-6 flex-col justify-center items-center w-full">
      <div className="text-gray-600 py-2 text-sm sm:text-normal">
        {t('label__joining')}
      </div>
      <div className="py-2 text-white font-hairline text-lg sm:text-3xl leading-none text-center truncate w-full">
        {gameName}
      </div>
      <div className="flex py-6">
        <Button onClick={handleJoin} size="large">
          {isSpectator() ? t('action__spectate') : t('action__join-now')}
        </Button>
        <Button
          className="ml-3"
          onClick={backToLobby}
          size="large"
          variant="secondary"
        >
          {t('action__back-to-lobby')}
        </Button>
      </div>
    </div>
  ) : // Loading icon does not work simply show nothing
  null;

  let video;

  if (searchParams.has('spectate')) {
    video = null;
  } else if (useMobileCamera) {
    video = (
      <div className="absolute left-0 top-0 w-full h-full rounded-md flex flex-col justify-center items-center text-gray-600">
        <Smartphone size="56" className="text-gray-900 py-2" />
        <div className="text-st-orange-normal py-2">
          {t('label__using-mobile-camera')}
        </div>
        <div className="py-2">
          {t('label__mobile-camera-further-instructions')}
        </div>
      </div>
    );
  } else if (isVideoEnabled) {
    video = (
      <video
        ref={setVideoPreviewRef}
        className="absolute left-0 top-0 w-full h-full rounded-md untransform"
        autoPlay
        muted
        playsInline
      />
    );
  }

  return (
    <div className="p-4 flex justify-center items-center w-full h-full">
      <div
        className="w-full sm:w-5/6 flex flex-col justify-center bg-surface-medium p-6 rounded-lg shadow-xl"
        style={{ maxWidth: '1400px' }}
      >
        <div className="flex flex-col xs:flex-row items-center h-full">
          {!searchParams.has('spectate') && (
            <div className="w-2/3 xs:pr-6 h-full">
              <div className="flex bg-black w-full h-full text-center rounded-md">
                <div
                  className="flex w-full h-full relative items-center"
                  style={{ minHeight: 320 }}
                >
                  {streamError ? (
                    <div className="flex w-full h-full justify-center">
                      {t(streamError)}
                    </div>
                  ) : (
                    video
                  )}
                  <div className="absolute bottom-0 inset-x-0 p-4 flex justify-between items-center">
                    <div className="flex items-center justify-center">
                      <OverlayButton
                        className="mr-2"
                        icon={isMuted ? MicOff : Mic}
                        onClick={toggleMute}
                      />
                      {!useMobileCamera && !gameLoading && (
                        <OverlayButton
                          icon={isVideoEnabled ? Video : VideoOff}
                          onClick={toggleVideo}
                        />
                      )}
                    </div>
                    <OverlayButton icon={Settings} onClick={showDevicesModal} />
                  </div>
                </div>
              </div>
              <div className="mt-6 text-gray-700 text-xs text-center mt-2 px-4">
                {t('label__video-stays-black')}
              </div>
            </div>
          )}
          <div className="w-full xs:w-1/3 py-8 xs:py-0 xs:pl-6 flex flex-col justify-center items-center">
            <div className="flex justify-center items-center py-2">
              <img
                alt="SpellTable"
                src={process.env.PUBLIC_URL + '/images/logo-horizontal.svg'}
                className="w-175 sm:w-200"
              />
            </div>
            {gameInfo}
          </div>
        </div>
      </div>
    </div>
  );
};
