import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import useCopyIcon from 'hooks/useCopyIcon';

import { Modal, Header, Buttons } from 'components/ui/Modal';
import { Button } from './ui/Button';

interface InvitePlayersModalProps {
  url: string;
  onModalClose: () => any;
}

export const InvitePlayersModal: FunctionComponent<InvitePlayersModalProps> = (
  props
) => {
  const { onModalClose, url } = props;
  const { t } = useTranslation();

  const copyIcon = useCopyIcon(url);

  return (
    <Modal onClose={onModalClose} style={{ width: 'auto' }}>
      <Header>{t('label__invite-players')}</Header>

      <div className="py-2 px-4 bg-surface-medium rounded select-text relative flex justify-center">
        {url}
        <div className="inset-y-0 right-0 flex flex-col justify-center pr-2">
          {copyIcon}
        </div>
      </div>
      <p className="mt-3 text-sm text-center">
        {t('label__invite-players-description')}
      </p>

      <Buttons>
        <Button onClick={onModalClose} size="large" variant="secondary">
          {t('label__close')}
        </Button>
      </Buttons>
    </Modal>
  );
};
