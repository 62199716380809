import React, { FunctionComponent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import cx from 'classnames';

import { Eye } from 'react-feather';

import { isMultiplayer } from 'types';

import useFocusedPlayerId from 'hooks/useFocusedPlayerId';
import useGame from 'hooks/useGame';
import useGameApi from 'hooks/useGameApi';
import usePlayer from 'hooks/usePlayer';
import usePlayers from 'hooks/usePlayers';
import { useHover } from 'use-events';

import { getActiveAndAlivePlayers } from 'utils/getActiveAndAlivePlayers';

import { SlimPlayerBar } from 'components/player/SlimPlayerBar';
import { ShopMagicOverlay } from 'components/videos/ShopMagicOverlay';
import { Stream } from 'components/videos/Stream';

import { PlayerContextProvider } from 'contexts/PlayerContext';
import { useVideoContext } from 'contexts/VideoContext';
import useVideoLayout from 'hooks/useVideoLayout';

interface VideoProps {
  playerId?: string;
  barPosition: string;
  isThumbnail?: boolean;
  isFocused?: boolean;
}

export const Video: FunctionComponent<VideoProps> = ({
  playerId,
  barPosition = 'left',
  isThumbnail,
  isFocused,
}) => {
  const { t } = useTranslation();

  const { rotatedVideos, toggleVideoRotate } = useVideoContext();

  const [hovered, hoverBindings] = useHover();

  const { isFocusedLayout } = useVideoLayout();
  const gameApi = useGameApi();
  const players = usePlayers();
  const player = usePlayer(playerId);
  const focusedPlayerId = useFocusedPlayerId();
  const { activePlayer, format, players: gamePlayers } = useGame() || {};

  const rotate = playerId ? rotatedVideos?.includes(playerId) : false;

  const containerClasses = cx(
    'flex flex-row w-full h-full border border-surface-border transition-all ease-in-out duration-200',
    {
      'flex-row-reverse': barPosition === 'right',
      'border-st-orange-normal':
        activePlayer && activePlayer === playerId && !player?.isDead,
      'border-red-600': player?.isDead,
    }
  );

  const onRotate = useCallback(() => {
    if (toggleVideoRotate) {
      toggleVideoRotate(playerId);
    }
  }, [playerId, toggleVideoRotate]);

  const onVideoFocus = useCallback(() => {
    if (playerId && gameApi) {
      gameApi.setFocusedPlayerId(playerId);
    }
  }, [gameApi, playerId]);

  const context = {
    playerId,
    onRotate,
    player,
  };

  const styles = isFocusedLayout
    ? {
        paddingTop: '56.25%',
      }
    : {
        maxHeight: '50%',
      };

  // Is this video your own
  const isOwnVideo = playerId
    ? gameApi?.getLocalPlayer()?.uid === playerId
    : false;
  // Are player dead
  const isPlayerDead = player?.isDead;
  // Was this a multiplayer game and there are at least 2 other players remaining
  const isMultiplayerWithLivePlayers =
    isMultiplayer(format) &&
    gamePlayers &&
    players &&
    getActiveAndAlivePlayers(gamePlayers, players).length >= 1;

  const isSelfEliminatedFirst =
    isOwnVideo && isPlayerDead && isMultiplayerWithLivePlayers;
  return (
    <PlayerContextProvider value={context}>
      <div
        className={containerClasses}
        style={{ padding: '1px' }}
        {...hoverBindings}
      >
        <div className="bg-black overflow-hidden flex-1 flex w-full h-full relative">
          <div style={styles}>
            <div
              className="absolute inset-0 flex flex-col justify-center items-center"
              onClick={onVideoFocus}
              aria-label={t('label__player-video-stream')}
            >
              {isSelfEliminatedFirst ? <ShopMagicOverlay /> : null}
              {playerId ? (
                <Stream
                  searchPosition={barPosition}
                  playerId={playerId}
                  playerUserId={player?.uid}
                  rotate={rotate}
                  disableIdentification={isThumbnail}
                  isThumbnail={isThumbnail}
                  isFocused={isFocused}
                />
              ) : null}
            </div>

            {/* Player Bar */}
            {player && playerId ? (
              <div className="absolute inset-x-0 top-0 block z-30">
                <SlimPlayerBar
                  player={player}
                  size={isThumbnail ? 'small' : 'large'}
                  showMenu={hovered}
                  orientation={barPosition === 'right' ? 'rtl' : 'ltr'}
                />
              </div>
            ) : null}

            {/* Focused Icon for Thumbnail Videos */}
            {focusedPlayerId === playerId && isThumbnail ? (
              <div className="absolute right-0 bottom-0 text-white m-1 p-1 bg-st-purple-light opacity-75 rounded">
                <Eye size="16" aria-label={t('label__focused-stream')} />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </PlayerContextProvider>
  );
};
