import React, {
  FunctionComponent,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { AnyCard } from 'types';

import useCards from 'hooks/useCards';

import { isFullCard } from 'api/utils/isFullCard';

import { Search } from 'react-feather';

import { CustomScrollbars } from 'components/CustomScrollbars';
import { DrawerCardSearch } from 'components/card-drawer/DrawerCardSearch';
import { DrawerCardNoData } from './DrawerCardNoData';
import { DrawerCardFullData } from './DrawerCardFullData';
import { Checkbox } from 'components/ui/Checkbox';
import { CustomTooltip } from 'components/CustomTooltip';
import { useRecoilState } from 'recoil';
import { includeExtrasState, includeLanguagesState } from 'state/atoms';

export const CardDrawer: FunctionComponent<{
  isOpen: boolean;
  setOpen: any;
}> = ({ isOpen, setOpen }) => {
  const { t } = useTranslation();
  let cards = useCards();
  const [hoversDisabled, setHoversDisabled] = useState(false);
  const [includeOtherLanguages, setOtherLanguages] = useRecoilState(
    includeLanguagesState
  );
  const [includeExtras, setIncludeExtras] = useRecoilState(includeExtrasState);

  useEffect(() => {
    let timeout: number;
    if (isOpen) {
      timeout = window.setTimeout(() => {
        setHoversDisabled(false);
      }, 200);

      setHoversDisabled(true);
    }

    return (): void => {
      clearTimeout(timeout);
    };
  }, [isOpen]);

  const onSearchFocus = useCallback(() => {
    if (!isOpen) {
      setOpen(true);
    }
  }, [isOpen, setOpen]);

  const lastScannedCard = useMemo(() => {
    if (!cards?.length) {
      return null;
    }
    const card = cards[0];
    return (
      <AnyDrawerCard
        key={`${card.set}-${card.id}`}
        card={card}
        hoverDisabled={hoversDisabled}
      />
    );
  }, [cards, hoversDisabled]);

  const cardHistory = useMemo((): JSX.Element[] => {
    if (!cards) {
      return [];
    }

    return cards.slice(1).map((card, index) => {
      const classes = cx({
        'mt-card-history-md md:mt-card-history-lg': index !== 0,
      });
      return (
        <div className={classes} key={`${card.set}-${card.id}`}>
          <AnyDrawerCard card={card} hoverDisabled={hoversDisabled} />
        </div>
      );
    });
  }, [cards, hoversDisabled]);

  const specialCardChange = useCallback(() => {
    setIncludeExtras(!includeExtras);
  }, [includeExtras, setIncludeExtras]);

  const languageChange = useCallback(() => {
    setOtherLanguages(!includeOtherLanguages);
  }, [includeOtherLanguages, setOtherLanguages]);

  const searchIcon = (
    <Search
      aria-label={t('label__search')}
      className="text-gray-850 md:w-1/6 w-1/4"
      width="100%"
      height="100%"
    />
  );

  return (
    <>
      <div className="w-full pr-4">
        <div className="w-full border-b flex flex-row items-center border-surface-border pb-4">
          <DrawerCardSearch onSearchFocus={onSearchFocus} />
        </div>
        <CustomTooltip placement="top" tooltip={t('label__include-extras')}>
          <div className="ml-4 flex flex-row justify-center items-center mt-2">
            <div style={{ fontSize: 10 }} className=" mr-3">
              {t('label__include-special')}
            </div>
            <div>
              <Checkbox
                name={'Include Special Cards'}
                isChecked={includeExtras}
                onChange={specialCardChange}
              />
            </div>
          </div>
        </CustomTooltip>
        <div className="ml-4 flex flex-row justify-center items-center mt-2">
          <div style={{ fontSize: 10 }} className=" mr-3">
            {t('label__include-other-languages')}
          </div>
          <div style={{ marginRight: 28 }}>
            <Checkbox
              name={'Include Multiple Languages'}
              isChecked={includeOtherLanguages}
              onChange={languageChange}
            />
          </div>
        </div>
      </div>
      <div className="pt-2 pr-4 w-full">
        <div className="border-b border-surface-border pb-4 flex flex-col items-center">
          <div className="text-gray-600 text-xs italic text-center mb-2">
            {t('label__last-card')}
          </div>
          <div className="w-card-md h-card-md md:w-card-lg md:h-card-lg bg-black rounded-5px md:rounded-12px flex justify-center items-center">
            {lastScannedCard ? lastScannedCard : searchIcon}
          </div>
        </div>
      </div>
      <div className="py-2 flex flex-col flex-1 w-full">
        <div className="pr-4 text-gray-600 text-xs italic text-center mb-2">
          {t('label__history')}
        </div>
        <div className="flex-1 h-12">
          <CustomScrollbars viewClasses="pr-4">{cardHistory}</CustomScrollbars>
        </div>
      </div>
    </>
  );
};

interface AnyDrawerCard {
  card: AnyCard;
  hoverDisabled: boolean;
}

const AnyDrawerCard: FunctionComponent<AnyDrawerCard> = (props) => {
  const { card, hoverDisabled } = props;

  if (isFullCard(card)) {
    return <DrawerCardFullData card={card} hoverDisabled={hoverDisabled} />;
  } else {
    return <DrawerCardNoData card={card} hoverDisabled={hoverDisabled} />;
  }
};
