import React, { FC, useCallback } from 'react';
import { useHistory } from 'react-router';
import { useModal } from 'react-modal-hook';
import { Link } from 'react-router-dom';
import { Plus, HelpCircle } from 'react-feather';
import { useTranslation } from 'react-i18next';

import usePlayerGames, { MAX_USER_GAMES } from 'hooks/usePlayerGames';

import { LobbyHeader } from 'components/lobby/LobbyHeader';
import { CreateGameModal } from 'components/CreateGameModal';
import { Footer } from 'components/homepage/Footer';
import { Button } from 'components/ui/Button';

import { MyGamesList } from 'components/lobby/MyGamesList';
import { LobbyGamesList } from 'components/lobby/LobbyGamesList';
import { Marketing } from 'components/Marketing';

export const Lobby: FC = () => {
  const history = useHistory();

  const playerGames = usePlayerGames();

  const { t } = useTranslation();

  const onCreateGameModalClose = (): void => {
    hideCreateGameModal();
  };

  const [showCreateGameModal, hideCreateGameModal] = useModal(
    (): JSX.Element => (
      <CreateGameModal
        history={history}
        onModalClose={onCreateGameModalClose}
      />
    )
  );

  const createGame = useCallback(async (): Promise<void> => {
    showCreateGameModal();
  }, [showCreateGameModal]);

  return (
    <>
      <div className="flex w-full justify-center">
        <div
          className="sm:w-3/4 w-full flex flex-col "
          style={{ maxWidth: 1440 }}
        >
          <LobbyHeader />

          <div className="flex px-12 flex-col w-full">
            <div className="w-full mt-2 xs:mt-4 sm:mr-2 flex flex-col">
              <div className="bg-surface-medium rounded-lg relative">
                <div className="flex flex-col tablet:flex-row justify-between items-center p-6">
                  <div className="uppercase mb-4 tablet:mb-0 font-bold text-xl text-white">
                    {t('title__your-games')}
                  </div>

                  <span className="ml-auto flex flex-col tablet:flex-row w-full tablet:w-auto">
                    <Button onClick={createGame} size="large">
                      <Plus size="18" className="mr-2" />
                      {t('action__create-game')}
                    </Button>
                  </span>
                </div>
                <MyGamesList games={playerGames} />
              </div>
              {Boolean(playerGames.length) && (
                <div className="w-full text-xs text-center mt-6">
                  {t('hint__lobby-games-shown', { max: MAX_USER_GAMES })}
                </div>
              )}
            </div>
            <div className="w-full mt-4 xs:mt-8 sm:mr-4 flex flex-col">
              <LobbyGamesList />
            </div>
            <div className="flex-1 mt-4 xs:mt-8 flex flex-col h-full">
              <Marketing />
            </div>
            <div className="flex-1 mt-4 xs:mt-8 flex flex-col h-full">
              <Box>
                <HelpCircle className="mr-4 hidden phone:block" />
                <span>
                  {t('label__have-any-questions')}{' '}
                  <Link
                    to="/faq"
                    target="_blank"
                    className="hover:underline text-st-orange-normal hover:text-st-orange-light cursor-pointer transition-all ease-in-out duration-200"
                  >
                    {t('label__check-our-faq')}
                  </Link>
                </span>
              </Box>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

const Box: FC = ({ children }) => {
  return (
    <div className="flex py-4 px-6 mb-4 bg-surface-medium text-lg rounded-lg items-center">
      {children}
    </div>
  );
};
