import { useContext } from 'react';

import { Game } from 'types';

import { GameContext, GameContextProps } from 'contexts/GameContext';

export default function useGame(): Game | undefined {
  const { game } = useContext<Partial<GameContextProps>>(GameContext);
  return game;
}
