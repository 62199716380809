import { useMemo } from 'react';

import { Player } from 'types';

import usePlayers from 'hooks/usePlayers';

interface PlayerSummary {
  uid: string;
  name: string;
}

interface CommanderSummary {
  id: string;
  name: string;
}

export interface PlayerCommander {
  player: PlayerSummary;
  commander: CommanderSummary;
}

export type PlayerCommanders = PlayerCommander[];

export default function useCommanders(): PlayerCommanders {
  const players = usePlayers();

  /**
   * TODO: Rework this. Is sucks to have to do all this data consolidation client side.
   * It's also not very efficient.
   * Either have the backend do it or update our data structures
   */
  return useMemo(() => {
    if (!players) {
      return [];
    }

    return players.reduce(
      (acc: PlayerCommanders, player: Player): PlayerCommanders => {
        const playerCommanders = player.commanders?.map((commander) => {
          return {
            player: {
              uid: player.uid,
              name: player.name.split('#')[0],
            },
            commander: {
              id: commander.id,
              name: commander.name,
            },
          };
        });
        return [...acc, ...(playerCommanders || [])];
      },
      []
    );
  }, [players]);
}
