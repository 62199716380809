import React, { FC } from 'react';
import cx from 'classnames';

type ButtonSize = 'default' | 'large';
type ButtonVariant = 'primary' | 'secondary';

interface ButtonProps {
  className?: string;
  href?: string;
  onClick?: () => void;
  rel?: string;
  size?: ButtonSize;
  target?: string;
  variant?: ButtonVariant;
}

const SIZES: Record<ButtonSize, string> = {
  default: 'text-sm h-8',
  large: 'h-10',
};

const VARIANTS: Record<ButtonVariant, string> = {
  primary: 'bg-st-purple-light hover:bg-st-purple-normal text-white',
  secondary: 'border border-content-low hover:text-content-high',
};

export const Button: FC<ButtonProps> = (props) => {
  const {
    children,
    className,
    href,
    onClick,
    rel,
    size = 'default',
    target,
    variant = 'primary',
  } = props;

  const classes = cx(
    'flex justify-center px-4 rounded items-center hover:no-underline',
    VARIANTS[variant],
    SIZES[size],
    className
  );

  // If an href is passed, use a link
  // instead of the button tag
  const Tag = href ? 'a' : 'button';

  return (
    <Tag
      className={classes}
      href={href}
      onClick={onClick}
      rel={rel}
      role="button"
      style={{
        transition: 'background 200ms ease',
        lineHeight: '100%',
      }}
      target={target}
    >
      {children}
    </Tag>
  );
};
