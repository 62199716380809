import React, {
  FunctionComponent,
  useState,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import KEY_CODES from 'constants/keycodes';
import { Plus, Minus } from 'react-feather';

interface CounterHorizontalProps {
  canEdit?: boolean;
  value: number;
  onChange?: (value: number) => void;
  size?: string;
  textColor?: string;
  max?: number;
  min?: number;
}

export const CounterHorizontal: FunctionComponent<CounterHorizontalProps> = (
  props
) => {
  const {
    // canGoInfinite = false,
    canEdit = false,
    value,
    onChange,
    size = 'large',
    textColor = 'text-white',
    max,
    min,
  } = props;
  const { t } = useTranslation();

  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState(value ? value.toString() : '');

  const updateValues = useCallback(
    (newValue: number): void => {
      if (
        inputRef.current &&
        newValue !== Infinity &&
        (newValue >= 1000 ||
          (max !== undefined && newValue > max) ||
          (min !== undefined && newValue < min))
      ) {
        inputRef.current.value = inputValue;
        return;
      }

      setInputValue(newValue === Infinity ? '∞' : newValue.toString());

      if (onChange) {
        onChange(newValue);
      }
      // Api.players.updatePlayer(user.uid, { life: newValue });
    },
    [onChange, inputValue, min, max]
  );

  // on prop change update the input value
  useEffect((): void => {
    setInputValue(value === Infinity ? '∞' : value.toString());
  }, [value]);

  const increment = (incrementValue: number = 1): void => {
    const newValue = value + incrementValue;
    updateValues(newValue);
  };

  const decrement = (decrementValue: number = 1): void => {
    const newValue = value - decrementValue >= 0 ? value - decrementValue : 0;
    updateValues(newValue);
  };

  const parseValue = (value: string): void => {
    const sanitized = parseInt(value.trim().replace(/[^\-.0-9]+/g, ''));

    if (isNaN(sanitized)) {
      setInputValue(value.toString());
      return;
    }

    updateValues(sanitized);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value.trim();

    if (inputRef.current && value.length > 3) {
      inputRef.current.value = inputValue;
      return;
    }

    setInputValue(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    const { keyCode, shiftKey } = e;

    switch (keyCode) {
      case KEY_CODES.up_arrow:
        increment(shiftKey ? 10 : 1);
        e.preventDefault();
        break;
      case KEY_CODES.down_arrow:
        decrement(shiftKey ? 10 : 1);
        e.preventDefault();
        break;
      case KEY_CODES.enter:
        e.stopPropagation();
        inputRef.current?.blur();
        break;
      default:
        break;
    }
  };

  const handleBlur = (): void => {
    if (inputValue.trim() === '') {
      setInputValue(value.toString());
      return;
    }

    parseValue(inputValue);
  };

  const handlePlusBtn = (): void => {
    increment();
  };

  const handleMinusBtn = (): void => {
    decrement();
  };

  const btnSize = size === 'large' ? '12' : '10';

  const plusBtn = canEdit ? (
    <div
      aria-label={t('label__increment-life')}
      role="button"
      className="flex justify-center cursor-pointer w-full text-white transition-all ease-in-out duration-200"
      onClick={handlePlusBtn}
    >
      <Plus size={btnSize} aria-hidden="true" />
    </div>
  ) : null;

  const minusBtn = canEdit ? (
    <div
      aria-label={t('label__decrement-life')}
      role="button"
      className="flex justify-center cursor-pointer w-full text-white transition-all ease-in-out duration-200"
      onClick={handleMinusBtn}
    >
      <Minus size={btnSize} aria-hidden="true" />
    </div>
  ) : null;

  const classes = cx(
    'focus:outline-none bg-transparent font-bold text-center select-auto',
    textColor,
    {
      'text-3xl': size === 'large',
      'text-xl': size === 'small',
    }
  );

  const style = size === 'large' ? { width: '59px' } : { width: '39px' };

  return (
    <div className="flex items-center relative">
      {minusBtn}
      <input
        className={classes}
        style={style}
        disabled={!canEdit}
        value={inputValue}
        onBlur={handleBlur}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        ref={inputRef}
        type="number"
        aria-label={t('label__life-total')}
      />
      {plusBtn}
    </div>
  );
};
