export const loginRedirect = (goToLobby: boolean = false) => {
  const location = window.location.href;
  const paramOperator = location.includes(`?`) ? '&' : '?';
  const redirect = `${location}${
    goToLobby ? 'lobby' : ''
  }${paramOperator}login=true`;
  const url = encodeURIComponent(redirect);
  const h = 'https://myaccounts.wizards.com';
  window.location.href = `${h}/login?redirectTo=${url}`;
};
