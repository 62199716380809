import React, { ChangeEvent, FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { Modal, Header, Label, Buttons } from 'components/ui/Modal';
import { Button } from 'components/ui/Button';
import { Player } from 'types';
import { useRecoilValue } from 'recoil';
import { firebaseUserState, gameState } from 'state/atoms';
import { playerProfileState } from 'state/selectors';
import Api from 'api';

interface ReportPlayerProps {
  onModalClose: () => void;
  player?: Player; // TODO type these
}

export const ReportPlayerModal: FC<ReportPlayerProps> = (props) => {
  const { onModalClose, player } = props;
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const game = useRecoilValue(gameState);
  const [reportContent, setReportContent] = useState('');
  const playerProfiles = useRecoilValue(playerProfileState);
  const user = useRecoilValue(firebaseUserState);

  // TODO move this stuff into a textarea component
  const inputClasses =
    'resize-none h-32 focus:outline-none select-auto bg-surface-medium placeholder-gray-700 appearance-none w-full focus:border-st-orange-normal block text-white border border-surface-low rounded py-3 px-4 leading-tight focus:outline-none tranision-all ease-in-out duration-200';
  const descriptionClasses = cx(inputClasses, {
    'border-red-600': error,
  });

  const report = useCallback(() => {
    setLoading(true);
    (async () => {
      setError('');
      const profiles = playerProfiles?.reduce((acc: any, player) => {
        acc.push({
          displayName: player.name,
          uid: player.uid,
          accountId: player.profile.accountId,
          personaId: player.profile.personaId,
        });
        return acc;
      }, []);
      const wotcToken = await Api.auth.refreshAuth();
      const token = wotcToken.access_token;
      const profileRequest = await fetch(
        `https://api.platform.wizards.com/profile`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const profile = await profileRequest.json();
      let request: any;
      try {
        request = await fetch(
          `${process.env.REACT_APP_API_GATEWAY_URL}/reportPlayer` || '',
          {
            method: 'POST',
            cache: 'no-cache',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'x-api-key': process.env.REACT_APP_LAMBDA_KEY || '',
            },
            body: JSON.stringify({
              token,
              submitter: user?.displayName,
              requester: profile.email,
              subject: player?.name,
              gameId: game?.id || '',
              content: reportContent || '',
              players: profiles,
            }),
          }
        );
      } catch (e) {
        setError(t('error__problem-submitting-report'));
        setLoading(false);
        return;
      }
      setLoading(false);
      if (!request?.ok) {
        setError(t('error__problem-submitting-report'));
        return;
      }
      setError('');
      onModalClose();
    })();
  }, [playerProfiles, game, onModalClose, player, reportContent, user, t]);

  const onChangeReportContent = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      setReportContent(e.target.value);
    },
    []
  );

  return (
    <Modal onClose={onModalClose}>
      <Header>{t('label__report-player')}</Header>
      <Label>{t('label__description-of-conduct')}</Label>
      {loading && <>{t('label__loading')}</>}
      {error && <span className="text-red-600">{error}</span>}
      {!loading && (
        <>
          <Row>
            <textarea
              className={descriptionClasses}
              value={reportContent}
              onChange={onChangeReportContent}
            ></textarea>
          </Row>
          <Buttons>
            <Button onClick={report} size="large" variant="primary">
              {t('action__submit')}
            </Button>
            <Button
              onClick={onModalClose}
              size="large"
              variant="secondary"
              className="ml-4"
            >
              {t('label__close')}
            </Button>
          </Buttons>
        </>
      )}
    </Modal>
  );
};

const Row: FC = ({ children }) => {
  return (
    <div className="flex justify-between text-gray-300 items-center py-2 mb-4 last:mb-0 text-sm">
      {children}
    </div>
  );
};
