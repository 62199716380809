import React, { FunctionComponent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import ToolTip from 'rc-tooltip';

import { usePlayerContext } from 'contexts/PlayerContext';

import { ColorIdentity } from 'components/player/ColorIdentity';
import { Commanders } from 'components/player/Commanders';
import { DamageBars } from 'components/player/DamageBars';
import useGame from 'hooks/useGame';
import { FORMAT_INFO, MagicFormat } from 'types';
import { DecklistModal } from 'components/modals/DecklistModal';
import { useModal } from 'react-modal-hook';
import Api from 'api';

interface PlayerPopoverProps {
  children: any;
  position: 'left' | 'right';
}

export const PlayerPopover: FunctionComponent<PlayerPopoverProps> = ({
  children,
  position = 'left',
}) => {
  const { player } = usePlayerContext();
  const { t } = useTranslation();
  const { format } = useGame() || {};
  const name = (player?.name || t('label__unknown-player').toString()).split(
    '#'
  )[0];

  const { hasCommander } = FORMAT_INFO[format || MagicFormat.Commander];

  const placement = position === 'right' ? 'bottomRight' : 'bottomLeft';

  const userId = Api.user.getUser()?.accountId;
  const isCurrentUser = player?.uid === userId;

  const [showDecklistModal, hideDecklistModal] = useModal(
    (): JSX.Element => <DecklistModal onModalClose={hideDecklistModal} />,
    []
  );

  const openDecklistModal = useCallback(() => {
    document.dispatchEvent(new MouseEvent('mousedown'));
    showDecklistModal();
  }, [showDecklistModal]);

  const popover = (
    <div className="bg-surface-high rounded text-white shadow-md w-400 rounded border border-surface-border">
      <div className="flex flex-row justify-between items-center bg-surface-low p-4 rounded">
        <div className="flex-1 text-white font-thin truncate">{name}</div>
        <div>
          <ColorIdentity />
        </div>
        {isCurrentUser && (
          <div
            className="hover:text-st-orange-normal font-normal hover:underline cursor-pointer ml-5"
            onClick={openDecklistModal}
          >
            {t('label__edit-decklist')}
          </div>
        )}
      </div>
      {hasCommander && (
        <div className="flex flex-col">
          <div className="p-4 italic text-gray-600 text-xs leading-snug">
            {t('label__commanders')}
          </div>
          <div className="pb-4 px-2">
            <Commanders />
          </div>
        </div>
      )}
      <div className="bg-surface-low py-4 rounded">
        <div className="italic text-gray-600 text-xs px-4 mb-4 leading-snug">
          {t('label__damage-totals')}
        </div>
        <div className="px-3">
          <DamageBars />
        </div>
      </div>
    </div>
  );

  return (
    <ToolTip
      overlayClassName="z-50 inline-block"
      placement={placement}
      trigger={['click']}
      overlay={popover}
      destroyTooltipOnHide
      id="playerpopover"
    >
      {children}
    </ToolTip>
  );
};
