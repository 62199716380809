import { useContext } from 'react';

import { VideoLayout } from 'types';

import { GameContext, GameContextProps } from 'contexts/GameContext';

export interface useVideoLayoutReturn {
  videoLayout: VideoLayout | undefined;
  isDefaultLayout: boolean;
  isFocusedLayout: boolean;
  isTheaterLayout: boolean;
}

export default function useVideoLayout(): useVideoLayoutReturn {
  const { videoLayout } = useContext<Partial<GameContextProps>>(GameContext);
  return {
    videoLayout,
    isDefaultLayout: videoLayout === VideoLayout.DEFAULT,
    isFocusedLayout: videoLayout === VideoLayout.FOCUSED,
    isTheaterLayout: videoLayout === VideoLayout.THEATER,
  };
}
