import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const CookieBanner: React.FC = () => {
  const { t } = useTranslation();

  useEffect(() => {
    if (!t) {
      return;
    }
    // Cookie management
    const deleteAllCookies = () => {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf('=');
        const cookiename = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie =
          cookiename + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
      }
    };

    const dropCookies = () => {
      window.setTimeout(deleteAllCookies, 2000);
    };
    const cb = new window.CookieBanner({
      cookieName: 'cookie-agree',
      cookieValue: 1,
      cookieExpiration: 60,
      acceptButtonLabel: t('cookie-button--agree'),
      nonConsentButtonLabel: t('cookie-button--no-thanks'),
      copy: t('cookie-banner_copy'),
      targetMountElement: document.body,
    });
    cb.consent
      .then(() => {})
      .catch(() => {
        if (cb.getConsentStatus === 'rejected') {
          dropCookies();
        }
      });
  }, [t]);
  return <></>;
};
