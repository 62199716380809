import * as THREE from 'three';
import { baseDice } from './baseDice';

export default function D6(
  scene: THREE.Scene,
  length: number,
  materialColor: string
) {
  const geometry = new THREE.BoxGeometry(length, length, length);
  const { update, group } = baseDice(geometry, scene, materialColor);
  return { update, group };
}
