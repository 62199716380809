// eslint-disable-next-line import/no-webpack-loader-syntax
import CardSpotterWorker from 'worker-loader!../cardspotter/cardspotter.worker.js';

// We only have one event for now

export enum Actions {
  FIND_CARD = 'FIND_CARD',
  IMAGE_DIFF = 'IMAGE_DIFF',
}

interface CSWorkerEvent {
  data: {
    type?: string;
    result?: {};
    status?: string;
  };
}

const NUM_WORKERS = 2;
// const DEBUG = true;

class CardSpotterInstance {
  events: Map<string, Function[]> = new Map();
  workers: CardSpotterWorker[];
  workerJob: number = 0;

  constructor() {
    let workers: CardSpotterWorker[] = [];
    for (let i = 0; i < NUM_WORKERS; i++) {
      const worker = new CardSpotterWorker();
      worker.onmessage = this.onMessage;
      workers.push(worker);
    }
    this.workers = workers;
    /* this.worker = new CardSpotterWorker();
      this.worker.onmessage = (event) => {
        if (event.data.status) {
          console.log(`CardIdentifier-Worker : ${event.data.status}`);
        }
        if (event.data.result) {
          this.events.has(ON_FIND_CARD) && this.events.get(ON_FIND_CARD).map(fn => fn(event.data.result));
        }
      }*/
  }

  onMessage = (event: CSWorkerEvent) => {
    if (event.data.status) {
      console.log(`CardIdentifier-Worker : ${event.data.status}`);
    }
    if (
      event.data.result &&
      event.data.type &&
      this.events.has(event.data.type)
    ) {
      this.events.get(event.data.type)?.map((fn) => fn(event.data.result));
    }
  };

  imageDiff = (
    video: HTMLVideoElement,
    actionId: string
  ): undefined | Uint8ClampedArray => {
    // Size is how big of an area of the screen to capture
    if (!video.videoWidth || !this.workers.length) {
      return;
    }
    // For diffing, we don't need to see "ALL" of the screen, so we trim around the edges to increase perf
    const INSIDE_BUFFER_Y = 60;
    const INSIDE_BUFFER_X = 100;
    const canvas = document.createElement('canvas');
    const width = video.videoWidth - INSIDE_BUFFER_X;
    const height = video.videoHeight - INSIDE_BUFFER_Y;
    canvas.width = width;
    canvas.height = height;
    const context = canvas.getContext('2d');
    if (!context) {
      return;
    }
    context.drawImage(
      video,
      -1 * (INSIDE_BUFFER_X / 2),
      -1 * (INSIDE_BUFFER_Y / 2),
      width,
      height,
      0,
      0,
      width,
      height
    );
    const imageData = context.getImageData(
      0,
      0,
      video.videoWidth,
      video.videoHeight
    );
    const uintArray = imageData.data;
    /*if (DEBUG === true) {
        var image = new Image();
        image.src = canvas.toDataURL();
        var w = window.open("");
        w.document.write(image.outerHTML)
      }*/
    // we need both images in the same memory, so they both have to go to the same worker
    this.workers[0].postMessage(
      {
        action: Actions.IMAGE_DIFF,
        width: video.videoWidth,
        height: video.videoHeight,
        imageData: uintArray,
        actionId,
      },
      [uintArray.buffer]
    );
    return uintArray;
  };

  getWorker = () => {
    const numWorkers = this.workers.length;
    if (!numWorkers) {
      return;
    }
    this.workerJob += 1;
    const worker = this.workerJob % numWorkers;
    return this.workers[worker];
  };

  findCardInSquare = (
    video: HTMLVideoElement,
    x: number,
    y: number,
    width: number,
    height: number,
    size = 300,
    rotate = false,
    playerId: string
  ) => {
    // Size is how big of an area of the screen to capture
    const canvas = document.createElement('canvas');
    const scale = width / video.videoWidth;
    const inverseScale = video.videoWidth / width;
    canvas.width = size;
    canvas.height = size;
    const context = canvas.getContext('2d');
    if (rotate) {
      x = width - x;
      y = height - y;
    }
    const sourceX = (x - (size * scale) / 2) * inverseScale;
    const sourceY = (y - (size * scale) / 2) * inverseScale;
    if (!context) {
      return;
    }
    context.drawImage(video, sourceX, sourceY, size, size, 0, 0, size, size);
    const imageData = context.getImageData(0, 0, size, size);
    const uintArray = imageData.data;
    /*if (DEBUG === true) {
        var image = new Image();
        image.src = canvas.toDataURL();
        var w = window.open("");
        w?.document.write(image.outerHTML)
      }*/

    const worker = this.getWorker();

    if (!worker) {
      return;
    }

    worker.postMessage(
      {
        action: 'FIND_CARD',
        width: size,
        height: size,
        imageData: uintArray,
        x: sourceX,
        y: sourceY,
        scale,
        playerId,
        detectionId: 'manual',
      },
      [uintArray.buffer]
    );
  };

  findCard = (
    video: HTMLVideoElement,
    x: number,
    y: number,
    width: number,
    height: number,
    rotate = false,
    playerId: string,
    detectionId: string
  ) => {
    const canvas = document.createElement('canvas');
    const BUFFER = 30;
    const bufferedWidth = width + BUFFER;
    const bufferedHeight = height + BUFFER;
    const bufferedX = x - BUFFER / 2;
    const bufferedY = y - BUFFER / 2;
    canvas.width = bufferedWidth;
    canvas.height = bufferedHeight;
    const context = canvas.getContext('2d');
    if (!context) {
      return;
    }
    if (rotate) {
      x = width - x;
      y = height - y;
    }
    context.drawImage(
      video,
      bufferedX,
      bufferedY,
      bufferedWidth,
      bufferedHeight,
      0,
      0,
      bufferedWidth,
      bufferedHeight
    );
    const imageData = context.getImageData(0, 0, bufferedWidth, bufferedHeight);
    const uintArray = imageData.data;
    /*if (DEBUG === true) {
        var image = new Image();
        image.src = canvas.toDataURL();
        var w = window.open("");
        w?.document.write(image.outerHTML)
      }*/
    const numWorkers = this.workers.length;
    if (!numWorkers) {
      return;
    }
    const worker = this.getWorker();

    if (!worker) {
      return;
    }
    worker.postMessage(
      {
        action: Actions.FIND_CARD,
        width: bufferedWidth,
        height: bufferedHeight,
        imageData: uintArray,
        x: bufferedX,
        y: bufferedY,
        scale: 1,
        playerId,
        detectionId,
      },
      [uintArray.buffer]
    );
  };

  subscribe(type: string, cb: Function) {
    this.events.set(type, [...(this.events.get(type) || []), cb]);
    // returns an event to remove this callback
    return () => {
      this.events.set(
        type,
        (this.events.get(type) || []).filter((fn) => fn !== cb)
      );
    };
  }
}

export default new CardSpotterInstance();
