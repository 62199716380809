import React, { useCallback, useMemo } from 'react';
import Api from 'api';

import {
  MenuItemTypes,
  MenuItems,
  CustomMenu,
} from 'components/menus/CustomMenu';

import { useTranslation } from 'react-i18next';
import useGameApi from 'hooks/useGameApi';
import { useSetRecoilState } from 'recoil';
import { Dice, DiceSides } from 'types/dice';
import { diceState } from 'state/atoms';
import { useDice } from 'hooks/useDiceModal';
import { COIN_DICE } from 'constants/bi-names';

type DiceMenuProps = {
  children?: React.ReactNode;
  gameId?: string;
};

const coin = ['heads', 'tails'];

export const DiceMenu = (props: DiceMenuProps) => {
  const { children } = props;
  const { t } = useTranslation();
  const gameApi = useGameApi();
  const setDice = useSetRecoilState<Dice>(diceState);
  const { showDiceModal, hideDiceModal } = useDice();

  const closeMenu = useCallback((): void => {
    document.dispatchEvent(new MouseEvent('mousedown'));
  }, []);

  const openDiceModal = useCallback((): void => {
    closeMenu();
    showDiceModal();
  }, [closeMenu, showDiceModal]);
  /**
   * 1) we get results from using Math.random for either a coin or a dice
   * 2) we set two separate timers for the modal and gamelog. One timer is to make the modal disappear, the other is to show the results.
   * It needed to be set here to time the gameapi with the results and modal animation. I set the timers as global vars so we can close the modal prematurely and
   * clear the timeouts.
   */
  const rollDice = useCallback(
    (sides: DiceSides) => {
      let result: string;

      if (sides !== '2') {
        const roll = Math.floor(Math.random() * parseInt(sides ?? '')) + 1;
        result = roll.toString();
      } else {
        result = coin[Math.floor(Math.random() * 2)];
      }

      const showDiceTimer = setTimeout(() => {
        hideDiceModal();
        setDice({ sided: undefined, result: undefined, isDiceShowing: true });
      }, 4200);

      const showResultsTimer = setTimeout(() => {
        setDice({ sided: sides, result, isDiceShowing: false });
        gameApi?.rollDice(sides, result);
      }, 2900);

      Api.track(COIN_DICE, false, { sides, result });

      setDice({
        sided: sides,
        result,
        showDiceTimer,
        isDiceShowing: true,
        showResultsTimer,
      });
      openDiceModal();
    },
    [gameApi, setDice, openDiceModal, hideDiceModal]
  );

  const flipCoin = useCallback(() => {
    rollDice('2');
  }, [rollDice]);

  const rollD4 = useCallback(() => {
    rollDice('4');
  }, [rollDice]);

  const rollD6 = useCallback(() => {
    rollDice('6');
  }, [rollDice]);

  const rollD8 = useCallback(() => {
    rollDice('8');
  }, [rollDice]);

  const rollD10 = useCallback(() => {
    rollDice('10');
  }, [rollDice]);

  const rollD12 = useCallback(() => {
    rollDice('12');
  }, [rollDice]);

  const rollD20 = useCallback(() => {
    rollDice('20');
  }, [rollDice]);

  const menuItems = useMemo((): MenuItems => {
    let items = [
      {
        type: MenuItemTypes.ITEM,
        label: t('label__coin-flip'),
        onClick: flipCoin,
      },
      {
        type: MenuItemTypes.ITEM,
        label: 'D4',
        onClick: rollD4,
      },
      {
        type: MenuItemTypes.ITEM,
        label: 'D6',
        onClick: rollD6,
      },
      {
        type: MenuItemTypes.ITEM,
        label: 'D8',
        onClick: rollD8,
      },
      {
        type: MenuItemTypes.ITEM,
        label: 'D10',
        onClick: rollD10,
      },
      {
        type: MenuItemTypes.ITEM,
        label: 'D12',
        onClick: rollD12,
      },
      {
        type: MenuItemTypes.ITEM,
        label: 'D20',
        onClick: rollD20,
      },
    ];

    return items;
  }, [t, rollD10, rollD12, rollD20, rollD4, rollD6, flipCoin, rollD8]);

  return (
    <div>
      <CustomMenu
        placement="right"
        menuItems={menuItems}
        paddingClass="py-2"
        marginClass="ml-4"
      >
        {children}
      </CustomMenu>
    </div>
  );
};
