import { InfectDamage, DamageTypes } from 'types';

export default function createInfectDamageObject(
  value: number = 0
): InfectDamage {
  return {
    type: DamageTypes.Infect,
    value,
  };
}
