import { BOX_SIZE } from 'constants/dice';
import { DiceShape } from 'types/dice';
import SceneManager from './SceneManager';
/**
 `EntryPoint` is a function that takes an element as parameter,
this element will be the container of the canvas.
This function is responsible for:

1. Creating the canvas
2. Binding events
3. Creating Three.js objects
4. Starting the render loop
 */
export default (
  containerElement: HTMLDivElement,
  shape: DiceShape,
  color: string
) => {
  const canvas = createCanvas(document, containerElement);
  const sceneManager = SceneManager(canvas, shape, color);

  bindEventListeners();
  render();

  function createCanvas(document: Document, containerElement: HTMLElement) {
    const canvas = document.createElement('canvas');
    containerElement.appendChild(canvas);
    return canvas;
  }

  function bindEventListeners() {
    window.addEventListener('resize', resizeCanvas);
  }

  function resizeCanvas() {
    canvas.width = BOX_SIZE;
    canvas.height = BOX_SIZE * 0.8;
    sceneManager.onWindowResize();
  }

  function render() {
    requestAnimationFrame(render);
    sceneManager.update();
  }

  function destroy() {
    window.removeEventListener('resize', resizeCanvas);
  }

  return destroy;
};
