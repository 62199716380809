import { useState, useEffect } from 'react';

import Api from 'api';

import { useTranslation } from 'react-i18next';

export default function usePreferredLanguage(): string {
  const [language, setLanguage] = useState<string>('en');
  const { i18n } = useTranslation();
  const preferredLanguage = Api.getUserPreferences()?.language;

  useEffect(() => {
    const userLanguage = preferredLanguage || i18n.language;
    setLanguage(userLanguage);
  }, [i18n.language, preferredLanguage]);

  return language;
}
