import { Game } from 'types';

export const joinGame = async (
  wotcToken: string,
  accountId: string,
  gameId: string,
  isMobile: boolean = false
) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_GATEWAY_URL}/joinGame` || '',
    {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-api-key': process.env.REACT_APP_LAMBDA_KEY || '',
      },
      body: JSON.stringify({
        token: wotcToken,
        accountId,
        gameId,
        isMobile,
      }),
    }
  );
  const { token } = (await response.json()) as any;
  return token;
};

export const createGame = async (
  token: string,
  ownerId: string,
  data: Partial<Game>
): Promise<{ id: string }> => {
  const { name, description, format, isPublic, tags } = data;
  const response = await fetch(
    `${process.env.REACT_APP_API_GATEWAY_URL}/createGame` || '',
    {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': process.env.REACT_APP_LAMBDA_KEY || '',
      },
      body: JSON.stringify({
        token,
        name,
        description,
        format,
        isPublic,
        tags,
      }),
    }
  );
  const { id } = (await response.json()) as any;
  return { id };
};

export const saveDecklist = async (
  userId: string,
  decklistPath: string,
  token: string
): Promise<{ result?: boolean; error?: string }> => {
  const response = await fetch(
    `${process.env.REACT_APP_API_GATEWAY_URL}/saveDecklist` || '',
    {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-api-key': process.env.REACT_APP_LAMBDA_KEY || '',
      },
      body: JSON.stringify({
        userId,
        decklistPath,
        token,
      }),
    }
  );
  return (await response.json()) as any;
};
