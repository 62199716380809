import { CustomTooltip } from 'components/CustomTooltip';
import useSpectatorCount from 'hooks/useSpectatorCount';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Eye } from 'react-feather';

export const SpectatorCount: FC<{}> = () => {
  const spectators = useSpectatorCount();
  const { t } = useTranslation();
  return spectators > 0 ? (
    <CustomTooltip tooltip={t('label__spectators')}>
      <div className="flex flex-col pt-2 pb-2 w-full items-center mb-8 text-center text-green-500 bg-black rounded-5px">
        <Eye size={18} aria-label={t('label__spectators-icon')} />
        <div>{spectators}</div>
      </div>
    </CustomTooltip>
  ) : null;
};
