import firebase from 'api/firebase';
import { useEffect, useState } from 'react';

import { Game } from 'types';

import useFirebaseUser from 'hooks/useFirebaseUser';

export const MAX_USER_GAMES = 20;

export default function usePlayerGames(): Game[] {
  const user = useFirebaseUser();
  const [games, setGames] = useState<Game[]>([]);

  useEffect(() => {
    const db = firebase.firestore().collection('games');
    let mounted = true;
    const unsubscribe = user
      ? db
          .where('owner', '==', user.uid)
          .orderBy('created', 'desc')
          .onSnapshot((gamesSnapshot) => {
            if (mounted) {
              setGames(
                gamesSnapshot.docs.slice(0, MAX_USER_GAMES).map((doc) => {
                  return doc.data() as Game;
                })
              );
            }
          })
      : () => {};

    return () => {
      mounted = false;
      unsubscribe();
    };
  }, [user]);

  return games;
}
