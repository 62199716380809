import Rollbar from 'rollbar';

const isProd = process.env.NODE_ENV === 'production';

const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_ID,
  environment: process.env.NODE_ENV,
  captureUncaught: isProd,
  captureUnhandledRejections: isProd,
  autoInstrument: {
    log: isProd,
  },
  // Additional fields are default scrubbed
  scrubFields: [
    'email',
    'first_name',
    'name',
    'firstName',
    'lastName',
    'last_name',
    'displayName',
    'display_name',
    'personaId',
    'persona_id',
  ],
  payload: {
    client: {
      javascript: {
        code_version: process.env.REACT_APP_SPELLTABLE_VERSION,
      },
    },
  },
};

const logger = new Rollbar(rollbarConfig);

// only export the created logger as we only want one instance of this
export default logger;
