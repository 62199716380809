import { RefObject, useCallback, useEffect, useRef, useState } from 'react';

import { getRealVideoSize } from 'utils/realVideoSize';
import CardSpotter, { Actions } from 'cardspotter/cardspotter';

import { AnyCard, SpottedCard } from 'types';
import useGameApi from './useGameApi';
import Api from 'api';
import { CARD_SPOTTED } from 'constants/bi-names';

import usePreferredLanguage from './usePreferredLanguage';

// The are of the screen capture when you click, smaller = better perf, but may not capture enough card
const DEFAULT_CLICK_SIZE = 300;
// How much to inflate the detected size
// const CLICK_BUFFER = 0.4;
// how long to show the highlighted div
const HIGHLIGHT_DURATION = 750;

interface UseCardSpotterArguments {
  isLocal: boolean;
  videoEl: RefObject<HTMLVideoElement>;
  rotate: boolean;
  playerId: string;
}

export interface CardLookup {
  isFetching: boolean;
  card?: AnyCard;
}

interface UseCardSpotterResults {
  videoClick: (event: React.MouseEvent) => void;
  isBusy: boolean;
  highlight: number[];
  isHighlightVisible: number;
  isSearching: boolean;
}

export default function useCardSpotter({
  videoEl,
  rotate,
  playerId,
}: UseCardSpotterArguments): UseCardSpotterResults {
  const gameApi = useGameApi();
  const [highlight, setHighlight] = useState<number[]>([]);
  const [isHighlightVisible, setIsHighlightVisible] = useState(0);
  const [isBusy, setIsBusy] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const language = usePreferredLanguage();
  const timeoutIdRef = useRef<number | null>(null);
  const searchTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  // Cancels the card fading
  const cancelHighlight = useCallback((): void => {
    const timeoutId = timeoutIdRef.current;
    if (timeoutId) {
      timeoutIdRef.current = null;
      clearTimeout(timeoutId);
    }
  }, [timeoutIdRef]);

  // If highlight position as been set, clear it after 2 seconds
  useEffect(() => {
    if (isHighlightVisible) {
      timeoutIdRef.current = window.setTimeout(() => {
        setIsHighlightVisible(0);
      }, HIGHLIGHT_DURATION);
      return cancelHighlight;
    }
  }, [isHighlightVisible, timeoutIdRef, cancelHighlight]);

  const videoClick = useCallback(
    (event: React.MouseEvent): void => {
      if (isBusy || !videoEl.current) return;
      // we can assume after 5 seconds the card isn't found and we can stop the loading icon.
      // This solves for an issue where in some instances the loading icon would continue to spin into perpetuity.
      searchTimeoutRef.current = setTimeout(() => {
        setIsSearching(false);
      }, 5000);
      setIsBusy(true);
      setIsSearching(true);
      const { clientX, clientY } = event.nativeEvent;
      const { width, height, x, y } = getRealVideoSize(
        videoEl.current,
        clientX,
        clientY
      );
      CardSpotter.findCardInSquare(
        videoEl.current,
        x,
        y,
        width,
        height,
        DEFAULT_CLICK_SIZE,
        rotate,
        playerId
      );
    },
    [isBusy, playerId, rotate, videoEl]
  );

  useEffect(() => {
    // Subscribe returns a function to unsubscribe.
    return CardSpotter.subscribe(Actions.FIND_CARD, (card: SpottedCard) => {
      if (card.playerId !== playerId) {
        return;
      }
      setIsBusy(false);
      if (card.id) {
        gameApi?.addCardSpotted(card, language);
        setIsSearching(false);
        if (searchTimeoutRef.current) {
          clearTimeout(searchTimeoutRef.current);
          searchTimeoutRef.current = null;
        }
        if (videoEl.current) {
          let { x1, x2, x3, x4, y2, y1, y3, y4 } = card;
          const rect = videoEl.current.getBoundingClientRect();

          const { diffX, diffY } = getRealVideoSize(videoEl.current);

          x1 += diffX;
          x2 += diffX;
          x3 += diffX;
          x4 += diffX;
          y1 += diffY;
          y2 += diffY;
          y3 += diffY;
          y4 += diffY;

          if (rotate) {
            // TODO: that REAL ugly
            x1 = rect.width - x1;
            x2 = rect.width - x2;
            x3 = rect.width - x3;
            x4 = rect.width - x4;
            y1 = rect.height - y1;
            y2 = rect.height - y2;
            y3 = rect.height - y3;
            y4 = rect.height - y4;
          }
          Api.track(CARD_SPOTTED, false, {
            name: card.name,
            coords: { x1, y1, x2, y2, x3, y3, x4, y4 },
          });
          setHighlight([x1, y1, x2, y2, x3, y3, x4, y4]);
          // We cancel the existing timer
          cancelHighlight();
          // And we set it to a new date (number) so that the useEffect below creates the new timer
          // if we just set it to true, it woulnd't detect a change
          setIsHighlightVisible(+new Date());
        }
      }
    });
  }, [
    cancelHighlight,
    gameApi,
    highlight,
    playerId,
    rotate,
    videoEl,
    language,
  ]);

  return {
    videoClick,
    isBusy,
    highlight,
    isHighlightVisible,
    isSearching,
  };
}
