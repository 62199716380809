import useFirebaseUser from 'hooks/useFirebaseUser';
import { useHistory } from 'react-router';
import { loginRedirect } from 'utils/loginRedirect';

export default function useLoginAction(): () => void {
  const user = useFirebaseUser();
  const history = useHistory();
  return () => {
    if (user) {
      history.push('/lobby');
    } else {
      loginRedirect(true);
    }
  };
}
