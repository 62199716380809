import React, { FunctionComponent } from 'react';
import cx from 'classnames';
import { Square, CheckSquare } from 'react-feather';

import ToolTip from 'rc-tooltip';

export enum MenuItemTypes {
  ITEM = 'item',
  DANGER_ITEM = 'danger-item',
  DIVIDER = 'divider',
  TOGGLE = 'toggle',
}

// TODO: This could use better typescripting between menu item types but I just don't know how to do it.
export interface MenuItem {
  type: MenuItemTypes;
  label?: string;
  onClick?: () => void;
  enabled?: boolean;
}

export type MenuItems = MenuItem[];

interface CustomMenuProps {
  children?: any;
  placement?: string;
  menuItems?: MenuItems;
  paddingClass?: string;
  widthClass?: string;
  tight?: boolean;
  marginClass?: string;
}

export const CustomMenu: FunctionComponent<CustomMenuProps> = (props) => {
  const {
    children,
    placement = 'top',
    menuItems = [],
    paddingClass = 'py-5',
    widthClass = 'w-64',
    marginClass,
    tight = false,
  } = props;

  const containerClasses = cx(
    'bg-surface-high rounded text-sm shadow-lg',
    paddingClass,
    widthClass,
    marginClass
  );

  const menuItemEls = menuItems.map((item, index) => {
    const { type, label, onClick, enabled } = item;

    switch (type) {
      case MenuItemTypes.DIVIDER:
        return <Divider key={`divider-${index}`} tight={tight} />;
      case MenuItemTypes.TOGGLE:
        return (
          <ToggleMenuItem
            key={`menu-item-${index}`}
            tight={tight}
            enabled={enabled}
            label={label}
            onClick={onClick}
          />
        );
      default:
        return (
          <MenuItem
            key={`menu-item-${index}`}
            tight={tight}
            type={type}
            label={label}
            onClick={onClick}
          />
        );
    }
  });

  const overlay = <div className={containerClasses}>{menuItemEls}</div>;

  return (
    <ToolTip
      overlayClassName="z-1000 inline-block"
      placement={placement}
      trigger={['click']}
      overlay={overlay}
      destroyTooltipOnHide
    >
      {children}
    </ToolTip>
  );
};

interface AdditionalMenuItemProps {
  tight: boolean;
}

interface MenuItemProps extends MenuItem, AdditionalMenuItemProps {}

const MenuItem: FunctionComponent<MenuItemProps> = (props) => {
  const { type, label, onClick, tight } = props;

  const classes = cx(
    'px-4 cursor-pointer transition-all ease-in-out duration-200',
    {
      'hover:bg-surface-low hover:text-white': type === MenuItemTypes.ITEM,
      'hover:bg-red-700 hover:text-white': type === MenuItemTypes.DANGER_ITEM,
      'py-2 text-sm': !tight,
      'py-1 text-xs': tight,
    }
  );

  return (
    <div className={classes} onClick={onClick}>
      {label}
    </div>
  );
};

interface ToggleMenuItemProps extends AdditionalMenuItemProps {
  label?: string;
  enabled?: boolean;
  onClick?: () => void;
}

const ToggleMenuItem: FunctionComponent<ToggleMenuItemProps> = (props) => {
  const { enabled, label, onClick, tight } = props;

  const checkbox = enabled ? (
    <CheckSquare size="14" className="text-st-orange-normal mr-2" />
  ) : (
    <Square size="14" className="mr-2" />
  );

  const classes = cx(
    'flex items-center px-4 cursor-pointer transition-all ease-in-out duration-200 hover:bg-surface-low hover:text-white',
    {
      'py-2 text-sm': !tight,
      'py-1 text-xs': tight,
    }
  );

  return (
    <div className={classes} onClick={onClick}>
      {checkbox}
      {label}
    </div>
  );
};

const Divider: FunctionComponent<AdditionalMenuItemProps> = (props) => {
  const { tight } = props;
  const classes = cx('border-t border-surface-low', {
    'my-2': !tight,
    'my-1': tight,
  });
  return <div className={classes} style={{ height: '1px' }}></div>;
};
