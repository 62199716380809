import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { IconProps } from './types';

export const Crown: FunctionComponent<IconProps> = (props) => {
  const { size = 24, className, onClick } = props;
  const { t } = useTranslation();

  return (
    <svg
      className={className}
      width={size}
      height={size}
      onClick={onClick}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      aria-describedby="my-title"
      role="img"
    >
      <title id="my-title">{t('label__crown')}</title>
      <path
        d="M21 8L20 17.5C16.6341 16.735 14.5959 16.5435 12 16.5C9.40411 16.5435 7.36591 16.735 4 17.5L3 8L7.5 9L12 5L17 9L21 8Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  );
};
