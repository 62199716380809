import Api from 'api';
import { AudioTrack, Participant, RemoteAudioTrack } from 'livekit-client';

export function getAudioTracks(
  userId: string,
  participants: Participant[]
): AudioTrack[] {
  const blocked = Api.getUserBlocklist();
  let tracks: AudioTrack[] = [];
  participants?.forEach((p) => {
    if (p.identity === userId || p.identity.split('-')[1] === 'mobile') {
      //LocalTrack
      return;
    }
    if (blocked?.includes(p.identity)) {
      p.audioTrackPublications.forEach((pub) => {
        pub.audioTrack?.stop();
      });
      return;
    }

    p.audioTrackPublications.forEach((pub) => {
      // RemoteAudioTrack
      const audioTrack = pub.audioTrack as RemoteAudioTrack | undefined;
      if (audioTrack) {
        if (!audioTrack.isMuted && typeof audioTrack.start === 'function') {
          audioTrack.start();
        }
        tracks.push(audioTrack);
      }
    });
  });
  return tracks;
}
