import Api from 'api';
import cx from 'classnames';
import { Button } from 'components/ui/Button';
import { Checkbox } from 'components/ui/Checkbox';
import { Buttons, Header, Modal } from 'components/ui/Modal';
import { Select } from 'components/ui/Select';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { customAspectRatioState } from 'state/atoms';
import { CUSTOM_RATIOS } from 'utils/media';
import { CameraSelect } from './device-selects/CameraSelect';
import { MicSelect } from './device-selects/MicSelect';
import { SpeakerSelect } from './device-selects/SpeakerSelect';

export interface Settings {
  useMobileCamera: boolean;
  videoDeviceID?: string;
  micDeviceID?: string;
  speakerDeviceID?: string;
  customAspectRatio: number;
}

interface DevicesModalProps {
  isMobile: boolean;
  inGame?: boolean;
  onModalClose: (didChange: boolean, settings: Settings) => void;
}

export const DevicesModal: FC<DevicesModalProps> = ({
  isMobile,
  inGame,
  onModalClose,
}) => {
  const { t } = useTranslation();
  const customAspectRatio = useRecoilValue(customAspectRatioState);
  const [useMobileCamera, setUseMobileCamera] = useState(false);
  const [useCustomRatio, setUseCustomRatio] = useState(customAspectRatio > 0);
  const [customRatio, setCustomRatio] = useState(customAspectRatio);
  const [videoDeviceID, setVideoDeviceID] = useState<string>();
  const [speakerDeviceID, setSpeakerDeviceID] = useState<string>();
  const [micDeviceID, setMicDeviceID] = useState<string>();
  let originalUseMobileValue = useRef<boolean | null>(false);

  useEffect(() => {
    const fetchPrefs = async (): Promise<void> => {
      const preferences = await Api.getUserPreferences();
      originalUseMobileValue.current = preferences?.useMobileCamera || false;
      setUseMobileCamera(preferences?.useMobileCamera || false);
      setVideoDeviceID(preferences?.gameAudioVideo?.videoDeviceID);
      setSpeakerDeviceID(preferences?.gameAudioVideo?.audioOutputID);
      setMicDeviceID(preferences?.gameAudioVideo?.audioInputID);
    };
    fetchPrefs();
  }, []);

  const onVideoChange = useCallback(
    (deviceID: string) => {
      if (deviceID === videoDeviceID) {
        return;
      }
      setVideoDeviceID(deviceID);
    },
    [videoDeviceID]
  );

  const onMicChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const deviceID = e.target.value;
    setMicDeviceID(deviceID);
  };

  const onSpeakerChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const deviceID = e.target.value;
    setSpeakerDeviceID(deviceID);
  };

  const handleUseMobile = useCallback(
    (name: string, isChecked: boolean): void => {
      setUseMobileCamera(isChecked);
    },
    []
  );

  const onUseCustomRatioChange = useCallback(
    (name: string, isChecked: boolean): void => {
      setUseCustomRatio(isChecked);
    },
    []
  );

  const onCustomRatioChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      setCustomRatio(e.currentTarget.value as unknown as number);
    },
    []
  );

  const onClose = useCallback(async (): Promise<void> => {
    const useMobileChanged = originalUseMobileValue.current !== useMobileCamera;
    if (useMobileChanged) {
      await Api.setUserPreferences({
        useMobileCamera,
      });
    }

    onModalClose(useMobileChanged, {
      useMobileCamera,
      videoDeviceID,
      micDeviceID,
      speakerDeviceID,
      customAspectRatio: customRatio,
    });
  }, [
    onModalClose,
    useMobileCamera,
    videoDeviceID,
    speakerDeviceID,
    micDeviceID,
    customRatio,
  ]);

  const customRatioClass = cx('relative w-full flex items-center mb-2', {
    'opacity-25 cursor-not-allowed': inGame,
  });

  return (
    <Modal onClose={onClose}>
      <Header>{t('label__configure-inputs')}</Header>
      <CameraSelect onChange={onVideoChange} textSize="text-xs" />

      <div className="mb-4 mt-6">
        <label className={customRatioClass}>
          <Checkbox
            isChecked={useCustomRatio}
            name="useMobile"
            isDisabled={inGame}
            onChange={onUseCustomRatioChange}
          />
          <div className="text-left text-white leading-tight">
            {t('label__use-custom-aspect-ratio')}
            <span className="ml-2 inline bg-gray-600 text-white text-xs rounded px-2 py-1 ">
              {t('label__experimental')}
            </span>
          </div>
        </label>

        {useCustomRatio ? (
          <Select
            onChange={onCustomRatioChange}
            isDisabled={inGame}
            value={customRatio}
          >
            {CUSTOM_RATIOS.map(({ label, value }) => (
              <option value={value} key={label}>
                {label}
              </option>
            ))}
          </Select>
        ) : null}

        {inGame ? (
          <div className="mt-2 text-sm px-4 py-3 text-white bg-surface-faded rounded-sm">
            {t('label__use-custom-aspect-ratio-disabled-description')}
          </div>
        ) : !inGame && useCustomRatio ? (
          <div className="mt-2 text-sm px-4 py-3 text-white bg-surface-faded rounded-sm">
            {t('label__use-custom-aspect-ratio-description')}
          </div>
        ) : null}
      </div>

      {!isMobile ? (
        <>
          <MicSelect onChange={onMicChange} textSize="text-xs" />
          <SpeakerSelect onChange={onSpeakerChange} textSize="text-xs" />
          <div className="mb-4 mt-6">
            <label className="relative w-full flex items-center">
              <Checkbox
                isChecked={useMobileCamera}
                name="useMobile"
                onChange={handleUseMobile}
              />

              <div className="text-left text-white leading-tight">
                {t('label__use-mobile-device-as-webcam')}
                <span className="ml-2 inline bg-gray-600 text-white text-xs rounded px-2 py-1 ">
                  {t('label__experimental')}
                </span>
              </div>
            </label>
            <div className="mt-2 text-sm px-4 py-3 text-white bg-surface-faded rounded-sm">
              {t('label__use-mobile-device-as-webcam-description')}
            </div>
          </div>
        </>
      ) : null}

      <Buttons>
        <Button onClick={onClose} size="large">
          {t('label__close')}
        </Button>
      </Buttons>
    </Modal>
  );
};
