import { useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { customAspectRatioState, videoResolutionState } from 'state/atoms';

import { getVideoStream } from 'utils/getVideoStream';
import isSpectator from 'utils/isSpectator';
import logger from 'utils/logger';

export enum PreviewStreamStatus {
  Loading = 'loading',
  Ready = 'ready',
  Error = 'error',
}

type ReloadFunction = (
  customAspectRatio: number,
  deviceId?: string,
  currentStream?: MediaStream
) => void;

export default function usePreviewVideo(
  isVideoEnabled: boolean,
  useMobileCamera: boolean,
  isActingAsCamera: boolean
): {
  stream?: MediaStream;
  error?: string;
  reload: ReloadFunction;
} {
  const [stream, setStream] = useState<MediaStream>();
  const [error, setError] = useState<string>();
  const setVideoResolution = useSetRecoilState(videoResolutionState);
  const customAspectRatio = useRecoilValue(customAspectRatioState);

  const localStream = useRef<MediaStream>();

  const reload: ReloadFunction = useCallback(
    async (aspectRatio, deviceId, currentStream) => {
      try {
        const {
          stream,
          error: streamError,
          videoResolution,
        } = await getVideoStream(aspectRatio, deviceId, currentStream);
        localStream.current = stream;

        if (!error && videoResolution) {
          setVideoResolution(videoResolution);
        }

        setError(streamError);
        setStream(stream);
      } catch (e) {
        logger.error(
          'There was an issue with getting preview video',
          e as Error
        );
      }
    },
    [error, setVideoResolution]
  );

  useEffect(() => {
    if (!isVideoEnabled || useMobileCamera || isSpectator()) {
      return;
    }

    reload(customAspectRatio);

    return () => {
      if (localStream.current) {
        const tracks = localStream.current.getTracks();
        tracks.forEach((track) => track.stop());
      }
    };
  }, [
    isVideoEnabled,
    useMobileCamera,
    isActingAsCamera,
    customAspectRatio,
    reload,
  ]);

  return {
    reload,
    error,
    stream,
  };
}
