import firebase from 'api/firebase';
import firebaseConfig from 'constants/firebase-config';

import Players from 'api/players';
import Games from 'api/games';
import GameLog from 'api/gamelog';
import Auth from 'api/auth';
import User, { EVENTS as USER_EVENTS } from 'api/user';

import { InGameAudioVideo, Preferences } from 'types';
import { trackBi } from './utils/bi';
import { SESSION_END, SESSION_START } from 'constants/bi-names';

export const EVENTS = {
  USER: USER_EVENTS,
};

firebase.initializeApp(firebaseConfig);
// Fixes some devices
firebase
  .firestore()
  .settings({ experimentalAutoDetectLongPolling: true, merge: true });

class Api {
  private authUser: firebase.User | null = null;

  auth: Auth = new Auth();
  user: User = new User();
  players: Players = new Players();
  games: Games = new Games();
  gamelog: GameLog = new GameLog();

  init = async (user: firebase.User): Promise<void> => {
    this.authUser = user;

    await this.user.init(user, this.track.bind(this));
    this.players.init();
    this.games.init(this.track.bind(this));

    trackBi({
      name: SESSION_START,
      accountId: this.auth.wotcAccountId,
      personaId: this.auth.wotcPersonaId,
      isPII: false,
    });
  };

  updateWotcAccount = async (
    accountId: string,
    personaId: string
  ): Promise<void> => {
    await this.user.updateWotcAccount(accountId, personaId);
  };

  updateName(name: string): void {
    this.user.updateName(name);

    if (this.authUser) {
      this.players.update(this.authUser.uid, { name });
    }
  }

  setUserPreferences(preferences: Preferences): void {
    this.user.setPreferences(preferences);
  }

  setAVPreferences(gameAudioVideo: InGameAudioVideo): void {
    this.user.setAVPreferences(gameAudioVideo);
  }

  getUserPreferences = (): Preferences | undefined => {
    const userData = this.user.getUser();
    return userData?.preferences;
  };

  getUserBlocklist = (): string[] | undefined => {
    const userData = this.user.getUser();
    return userData?.blocklist;
  };

  async updatePhoto(file: File): Promise<string> {
    const newPhotoURL = await this.user.updatePhoto(file);
    try {
      if (this.authUser) {
        await this.players.update(this.authUser.uid, { photoURL: newPhotoURL });
      }
    } catch (e) {
      console.warn(e);
      return '';
    }
    return newPhotoURL;
  }

  async track(name: string, isPII: boolean, data?: {}): Promise<void> {
    await trackBi({
      name,
      data,
      accountId: this.auth.wotcAccountId,
      personaId: this.auth.wotcPersonaId,
      isPII,
    });
  }

  cleanup(): void {
    if (this.auth.wotcAccountId) {
      trackBi({
        name: SESSION_END,
        accountId: this.auth.wotcAccountId,
        personaId: this.auth.wotcPersonaId,
        isPII: false,
      });
    }
    this.authUser = null;

    this.user.cleanup();
    this.auth.wotcAccountId = undefined;
    this.auth.wotcPersonaId = undefined;
    this.auth.email = undefined;
  }
}

export default new Api();
