export enum WUBRG {
  W = 'W',
  U = 'U',
  B = 'B',
  R = 'R',
  G = 'G',
}

export enum WUBRGColors {
  W = 'white',
  U = 'blue',
  B = 'gray',
  R = 'red',
  G = 'green',
}
