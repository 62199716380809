import useGame from 'hooks/useGame';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { Video } from './Video';
import { Players } from 'types';
import throttle from 'lodash/throttle';
import { getActiveGamePlayers } from 'utils/getActiveGamePlayers';
import { useRecoilState } from 'recoil';
import {
  currentDragState,
  lastVideoPositionsState,
  videosMinimizedState,
  videoPositionsState,
} from 'state/atoms';
import useOrderedPlayers from 'hooks/useOrderedPlayers';
import useFocusedPlayerId from 'hooks/useFocusedPlayerId';
import { Maximize2, Minimize2 } from 'react-feather';
import useWindowSize from 'hooks/useWindowSize';

export const DraggableVideos: FC = () => {
  const game = useGame();
  const [currentDrag, setCurrentDrag] = useRecoilState(currentDragState);
  const [videoPosition, setVideoPosition] = useRecoilState(videoPositionsState);
  const [lastVideoPosition, setLastVideoPosition] = useRecoilState(
    lastVideoPositionsState
  );
  const [videosMinimized, setVideosMinimized] =
    useRecoilState(videosMinimizedState);
  const players = useOrderedPlayers();
  const focusedPlayerId = useFocusedPlayerId();
  const activePlayerIds = getActiveGamePlayers(game!.players);
  const { width: windowWidth, height: windowHeight } = useWindowSize();

  const maximize = useCallback(() => {
    setVideosMinimized(false);
  }, [setVideosMinimized]);
  const minimize = useCallback(() => {
    setVideosMinimized(true);
  }, [setVideosMinimized]);

  const playerSpots = useMemo((): Players => {
    if (!game) {
      return [];
    }

    const activePlayers: Players = [];
    return activePlayerIds.reduce((acc, uid) => {
      const player = players.find((p) => p && p.uid === uid);
      if (player) {
        activePlayers.push(player);
      }
      return acc;
    }, activePlayers);
  }, [game, players, activePlayerIds]);

  const handleMouseUp = useCallback(() => {
    setLastVideoPosition(videoPosition);
    setCurrentDrag(undefined);
  }, [videoPosition, setLastVideoPosition, setCurrentDrag]);
  const videos = playerSpots
    .filter((player) => {
      return player?.uid !== focusedPlayerId;
    })
    .map((player) => {
      return (
        <Video
          key={player.uid}
          playerId={player.uid}
          barPosition="left"
          isFocused={false}
          isThumbnail={true}
        />
      );
    });
  const onDrag = useCallback(
    (e: React.MouseEvent) => {
      setCurrentDrag({
        origin: {
          x: e.clientX,
          y: e.clientY,
        },
      });
    },
    [setCurrentDrag]
  );

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      if (currentDrag) {
        const X_BUFFER = 100;
        const Y_BUFFER = 75;
        const isXOver = e.pageX < X_BUFFER || e.pageX > windowWidth - X_BUFFER;
        const isYOver = e.pageY < Y_BUFFER || e.pageY > windowHeight - Y_BUFFER;
        const newX = isXOver
          ? videoPosition.x
          : currentDrag.origin.x - e.clientX + (lastVideoPosition?.x || 0);
        const newY = isYOver
          ? videoPosition.y
          : e.clientY - currentDrag.origin.y + (lastVideoPosition?.y || 0);
        setVideoPosition({
          x: newX,
          y: newY,
        });
      }
    },
    [
      currentDrag,
      setVideoPosition,
      videoPosition,
      lastVideoPosition,
      windowWidth,
      windowHeight,
    ]
  );

  useEffect(() => {
    const throttledMove = throttle(handleMouseMove, 30, { trailing: false });
    window.addEventListener('mouseup', handleMouseUp);
    window.addEventListener('mouseleave', handleMouseUp);
    window.addEventListener('mousemove', throttledMove);
    return () => {
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('mouseleave', handleMouseUp);
      window.removeEventListener('mousemove', throttledMove);
    };
  }, [handleMouseUp, handleMouseMove]);

  const { x, y } = videoPosition;
  const right = `${x}px`;
  const top = `${y}px`;
  const height = videosMinimized ? '2rem' : `${videos.length * 7 + 4}rem`;

  return useMemo(() => {
    if (!videos || videos.length === 0) {
      return null;
    }
    return (
      <div
        className="flex flex-col z-10 absolute bg-surface-low rounded"
        style={{ right, height, top, width: '14rem' }}
      >
        <div className="flex flex-row p-2">
          <button
            className="flex-grow cursor-move flex justify-center"
            onMouseDown={onDrag}
            aria-label="move video"
          >
            <div
              className="w-16 h-2 mt-1 rounded bg-surface-high"
              aria-hidden="true"
            />
          </button>
          {videosMinimized ? (
            <button
              className="flex-none"
              onMouseDown={maximize}
              aria-label="show videos"
            >
              <Maximize2 size="14" aria-hidden="true" />
            </button>
          ) : (
            <button onMouseDown={minimize} aria-label="hide videos">
              <Minimize2 size="14" aria-hidden="true" />
            </button>
          )}
        </div>
        {!videosMinimized && videos}
      </div>
    );
  }, [top, right, videos, onDrag, height, minimize, maximize, videosMinimized]);
};
