import React, { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

interface ProfileIconProps {
  className?: string;
  email: string | null;
  name: string | null;
  photoURL: string | null;
  showBorder?: boolean;
  highlighted?: boolean;
}

export const ProfileIcon: FunctionComponent<ProfileIconProps> = (props) => {
  const {
    className,
    email,
    name,
    photoURL,
    showBorder = true,
    highlighted = false,
  } = props;
  const { t } = useTranslation();

  const initials = useMemo((): string => {
    const namePart = name?.split('#')?.[0];
    if (namePart && namePart.length > 1) {
      return namePart.substr(0, 2).toUpperCase();
    }

    if (email) {
      return email.substr(0, 1).toUpperCase();
    }

    return t('label__not-available');
  }, [name, email, t]);

  const profilePic = photoURL ? (
    <div
      className="rounded h-full bg-cover bg-no-repeat"
      style={{ backgroundImage: `url(${photoURL})` }}
    ></div>
  ) : (
    <div className="uppercase text-gray-600 text-sm flex justify-center items-center h-full">
      {initials}
    </div>
  );

  const classes = cx(
    `rounded bg-surface-high w-8 h-8 transition-all ease-in-out duration-200`,
    className,
    {
      'shadow-profile-default': showBorder && !highlighted,
      'shadow-profile-highlight': showBorder && highlighted,
    }
  );

  return (
    <div
      className={classes}
      role="button"
      aria-label={t('label__profile-picture')}
    >
      {profilePic}
    </div>
  );
};
