import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-modal-hook';
import { useHistory } from 'react-router';

import Api from 'api';
import useGameApi from 'hooks/useGameApi';
import useIsHost from 'hooks/useIsHost';
import useOrderedPlayers from 'hooks/useOrderedPlayers';
import usePreferences from 'hooks/usePreferences';

import {
  CustomMenu,
  MenuItems,
  MenuItemTypes,
} from 'components/menus/CustomMenu';

import { GameRoom } from 'api/modules/GameRoom';
import { DevicesModal, Settings } from 'components/DevicesModal';
import { ManagePlayersModal } from 'components/modals/ManagePlayersModal';
import { PreferencesModal } from 'components/modals/PreferencesModal';
import { ResetModal, ResetModalParams } from 'components/modals/ResetModal';
import { DOOR_TO_PUBLIC } from 'constants/bi-names';
import useGame from 'hooks/useGame';
import { useRecoilValue } from 'recoil';
import { gameRoomState } from 'state/atoms';

interface GameMenuProps {
  useMobileCamera: boolean;
}

export const GameMenu: FC<GameMenuProps> = ({
  children,
  useMobileCamera: usingMobileCamera,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const gameApi = useGameApi();
  const game = useGame();
  const isHost = useIsHost();
  const players = useOrderedPlayers();
  const preferences = usePreferences();
  const gameRoom = useRecoilValue<GameRoom | undefined>(gameRoomState);

  // Devices modal
  const [showDevicesModal, hideDevicesModal] = useModal((): JSX.Element => {
    return (
      <DevicesModal
        inGame={true}
        isMobile={false}
        onModalClose={onDeviceModalClose}
      />
    );
  }, [gameRoom]);
  const onDeviceModalClose = useCallback(
    async (didChange: boolean, settings: Settings) => {
      // TODO: Eventually make did change more specific about what changed. And then only change that!
      if (didChange) {
        gameRoom?.setVideoEnabled(!settings.useMobileCamera);

        if (settings.videoDeviceID) {
          gameRoom?.setVideoDeviceId(settings.videoDeviceID);
        }

        if (settings.micDeviceID) {
          gameRoom?.setAudioDeviceId(settings.micDeviceID);
        }
        if (settings.speakerDeviceID) {
          gameRoom?.setAudioOutputDeviceId(settings.speakerDeviceID);
        }
      }
      hideDevicesModal();
    },
    [hideDevicesModal, gameRoom]
  );

  // Hacky way to close the tooltip component we are using in CustomMenu. :\
  const closeMenu = useCallback((): void => {
    document.dispatchEvent(new MouseEvent('mousedown'));
  }, []);

  const [showManagePlayersModal, hideManagePlayersModal] = useModal(
    (): JSX.Element => (
      <ManagePlayersModal
        players={players}
        gameApi={gameApi}
        onModalClose={hideManagePlayersModal}
      />
    ),
    [players, gameApi]
  );

  const [showPreferencesModal, hidePreferencesModal] = useModal(
    (): JSX.Element => (
      <PreferencesModal
        preferences={preferences}
        onModalClose={hidePreferencesModal}
      />
    ),
    [preferences]
  );

  const openPreferences = useCallback((): void => {
    closeMenu();
    showPreferencesModal();
  }, [closeMenu, showPreferencesModal]);

  const changeDevices = useCallback((): void => {
    closeMenu();
    showDevicesModal();
  }, [closeMenu, showDevicesModal]);

  const leaveGame = useCallback(async (): Promise<void> => {
    closeMenu();
    // TODO: Turn this into a custom dialog.
    if (gameApi && window.confirm(t('label__confirm-leave-game'))) {
      await gameApi.destroy();
      await gameApi.leave();
      history.push('/lobby');
    }
  }, [closeMenu, gameApi, history, t]);

  const [showResetModal, hideResetModal] = useModal(
    (): JSX.Element => <ResetModal onModalClose={onResetModalClose} />
  );

  const openResetModal = useCallback((): void => {
    closeMenu();
    showResetModal();
  }, [closeMenu, showResetModal]);

  const onResetModalClose = useCallback(
    (params?: ResetModalParams) => {
      hideResetModal();
      if (!params) {
        return;
      }

      gameApi?.reset(
        params.resetCommander as boolean,
        params.resetLog as boolean,
        params.resetState as boolean
      );
    },
    [hideResetModal, gameApi]
  );

  const togglePrivacy = useCallback(async () => {
    if (game) {
      await Api.games.updateGame(game, {
        isPublic: !game.isPublic,
      });
      Api.track(DOOR_TO_PUBLIC, false, { isPublic: !game.isPublic });
    }
  }, [game]);

  const orderPlayers = useCallback((): void => {
    closeMenu();
    showManagePlayersModal();
  }, [showManagePlayersModal, closeMenu]);

  const randomizePlayerOrder = useCallback((): void => {
    closeMenu();
    gameApi?.randomizeTurnOrder();
  }, [gameApi, closeMenu]);

  const togglePhases = useCallback((): void => {
    gameApi?.toggleIsUsingPhases(!game?.isUsingPhases);
  }, [gameApi, game]);

  const closeGame = useCallback((): void => {
    closeMenu();
    if (gameApi && window.confirm(t('label__confirm-close-game'))) {
      gameApi.closeGame();
      history.push('/lobby');
    }
  }, [gameApi, history, closeMenu, t]);

  const menuItems = useMemo((): MenuItems => {
    let items = [
      {
        type: MenuItemTypes.ITEM,
        label: t('label__configure-inputs'),
        onClick: changeDevices,
      },
      {
        type: MenuItemTypes.ITEM,
        label: t('label__preferences'),
        onClick: openPreferences,
      },
      {
        type: MenuItemTypes.DIVIDER,
      },
      {
        type: MenuItemTypes.ITEM,
        label: t('label__leave-game'),
        onClick: leaveGame,
      },
    ];

    if (isHost) {
      items = [
        ...items,
        {
          type: MenuItemTypes.DIVIDER,
        },
        {
          type: MenuItemTypes.ITEM,
          label: t('label__reset-game'),
          onClick: openResetModal,
        },
        {
          type: MenuItemTypes.ITEM,
          label: game?.isPublic
            ? t('action__change-to-private')
            : t('action__change-to-public'),
          onClick: togglePrivacy,
        },
        {
          type: MenuItemTypes.ITEM,
          label: !game?.isUsingPhases
            ? t('label__turn-on-phase')
            : t('label__turn-off-phases'),
          onClick: togglePhases,
        },
        {
          type: MenuItemTypes.ITEM,
          label: t('label__manage-players'),
          onClick: orderPlayers,
        },
        {
          type: MenuItemTypes.ITEM,
          label: t('label__randomize-player-order'),
          onClick: randomizePlayerOrder,
        },
        {
          type: MenuItemTypes.DIVIDER,
        },
        {
          type: MenuItemTypes.DANGER_ITEM,
          label: t('label__close-game'),
          onClick: closeGame,
        },
      ];
    }

    return items;
  }, [
    orderPlayers,
    closeGame,
    leaveGame,
    changeDevices,
    isHost,
    randomizePlayerOrder,
    openPreferences,
    game,
    togglePrivacy,
    openResetModal,
    togglePhases,
    t,
  ]);

  return (
    <div>
      <CustomMenu
        placement="right"
        menuItems={menuItems}
        paddingClass="py-2"
        marginClass="ml-4"
      >
        {children}
      </CustomMenu>
    </div>
  );
};
