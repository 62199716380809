import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { IconProps } from './types';

export const LoadingIcon: FunctionComponent<IconProps> = (props) => {
  const { size = 24, className } = props;
  const { t } = useTranslation();

  const classes = cx('animation-spin', className);

  return (
    <svg
      className={classes}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      stroke="currentColor"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
      aria-describedby="my-title"
      role="img"
    >
      <title>{t('label__loading-icon')}</title>
      <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
        <stop offset="0%" stopColor="#765BFF" />
        <stop offset="100%" stopColor="#FFB342" />
      </linearGradient>
      <path
        stroke="url(#gradient)"
        d="M22,12c0,5.5-4.5,10-10,10S2,17.5,2,12S6.5,2,12,2"
      />
    </svg>
  );
};
