import firebase from 'api/firebase';

/**
 * Batches up changes to firebase and flushes after so many seconds.
 * This limits how many updates we send to firebase when rapid changes are happening.
 */

const COMMIT_TIMEOUT = 1000;

class Batcher {
  private writeBatch?: firebase.firestore.WriteBatch | null = null;
  private commitTimer?: ReturnType<typeof setTimeout> | null = null;

  private ensureWriteBatch() {
    if (!this.writeBatch) {
      this.writeBatch = firebase.firestore().batch();
    }
  }

  private debounceTimeout() {
    // debounce the commit
    if (this.commitTimer) {
      clearTimeout(this.commitTimer);
    }
    this.commitTimer = setTimeout(this.commit, COMMIT_TIMEOUT);
  }

  set = (
    ref: firebase.firestore.DocumentReference,
    data: firebase.firestore.UpdateData,
    options: firebase.firestore.SetOptions
  ): void => {
    this.ensureWriteBatch();
    this.writeBatch?.set(ref, data, options);
    this.debounceTimeout();
  };

  update = (
    ref: firebase.firestore.DocumentReference,
    data: firebase.firestore.UpdateData
  ): void => {
    this.ensureWriteBatch();
    this.writeBatch?.update(ref, data);
    this.debounceTimeout();
  };

  delete = (ref: firebase.firestore.DocumentReference): void => {
    this.ensureWriteBatch();
    this.writeBatch?.delete(ref);
    this.debounceTimeout();
  };

  commit = () => {
    if (this.commitTimer) {
      clearTimeout(this.commitTimer);
      delete this.commitTimer;
    }

    this.writeBatch
      ?.commit()
      .then(() => {
        // console.log('batch committed');
      })
      .catch((error) => {
        console.error('batch error', error);
      });

    delete this.writeBatch;
  };
}

export default new Batcher();
