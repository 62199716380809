import { v4 as uuid } from 'uuid';
export interface TrackOptions {
  name: string;
  data?: {};
  accountId?: string;
  personaId?: string;
  isPII: boolean;
}

export const trackBi = async (opts: TrackOptions): Promise<void> => {
  if (!process.env.REACT_APP_BI_URL) {
    return;
  }
  try {
    await fetch(process.env.REACT_APP_BI_URL, {
      method: 'post',
      mode: 'no-cors',
      body: JSON.stringify({
        ...opts,
        env: process.env.NODE_ENV,
        version: process.env.REACT_APP_SPELLTABLE_VERSION,
        messageId: uuid(),
      }),
    });
  } catch (e) {
    // TODO: use a queue or something to try later
    //console.warn('Error sending BI data')
  }
};
