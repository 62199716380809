import NoSleep from 'nosleep.js';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Settings } from 'react-feather';

import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import firebase from 'api/firebase';

import {
  DevicesModal,
  Settings as DeviceModalSettings,
} from 'components/DevicesModal';
import usePreviewVideo from 'hooks/usePreviewVideo';
import { useModal } from 'react-modal-hook';

import { OverlayButton } from 'components/ui/OverlayButton';
import { useDevicesOfType } from 'hooks/useDevices';
import { useRecoilState } from 'recoil';
import { customAspectRatioState } from 'state/atoms';
import { Game } from 'types';
import logger from 'utils/logger';
import DeviceStatus from './DeviceStatus';
interface GameParams {
  id: string;
}

interface MobileJoinScreenProps {
  onJoin: (deviceId: string) => any;
}

const noSleep = new NoSleep();

export const MobileJoinScreen: FunctionComponent<MobileJoinScreenProps> = (
  props
) => {
  const { onJoin } = props;
  const { t } = useTranslation();

  const { id } = useParams<GameParams>();
  const history = useHistory();
  const mobileRef = useRef<HTMLVideoElement>(null);
  const [gameName, setGameName] = useState<string | null>(null);
  const [isInPortrait, setIsInPortrait] = useState(false);
  const { devices, initialDevice } = useDevicesOfType('videoinput');
  const [selectedDevice, setSelectedDevice] = useState(initialDevice);
  const [loadingCamera, setLoadingCamera] = useState<boolean>(true);
  const [error, setError] = useState<string>();
  const [customAspectRatio, setCustomAspectRatio] = useRecoilState(
    customAspectRatioState
  );

  const [showDevicesModal, hideDevicesModal] = useModal(
    (): JSX.Element => (
      <DevicesModal
        inGame={false}
        isMobile={true}
        onModalClose={onDeviceModalClose}
      />
    ),
    [mobileRef, devices, customAspectRatio, selectedDevice]
  );

  const {
    stream,
    error: errorMessage,
    reload,
  } = usePreviewVideo(true, true, true);

  const onDeviceModalClose = useCallback(
    async (
      mobileDidChange,
      { customAspectRatio: aspectRatio, videoDeviceID }: DeviceModalSettings
    ) => {
      const device = devices?.find(
        (device) => device.deviceId === videoDeviceID
      );

      setCustomAspectRatio(aspectRatio);
      setSelectedDevice(device);
      hideDevicesModal();
    },
    [hideDevicesModal, setCustomAspectRatio, devices]
  );

  useEffect(() => {
    const onResize = () => {
      setIsInPortrait(window.innerWidth < window.innerHeight);
    };
    window.addEventListener('resize', onResize);
    onResize();

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  // load game info to get name. kind of lame to have to do this just for the name :\
  useEffect(() => {
    const getGameName = async () => {
      try {
        const doc = await firebase
          .firestore()
          .collection('games')
          .doc(id)
          .get();
        if (!doc.exists) {
          setGameName(t('label__no-game-found'));
        }
        const { name } = doc.data() as Game;
        setGameName(name);
      } catch (e) {
        setGameName(t('label__error'));
      }
    };

    if (!gameName) {
      getGameName();
    }
  }, [id, gameName, t]);

  useEffect(() => {
    // Run setDevices on load to enable the first/default camera
    const update = async () => {
      const device = selectedDevice || initialDevice;
      if (!device) {
        return;
      }

      setLoadingCamera(true);

      await reload(
        customAspectRatio,
        device.deviceId,
        (mobileRef.current?.srcObject as MediaStream) ?? undefined
      );
      setLoadingCamera(false);
    };
    update();
  }, [reload, selectedDevice, initialDevice, customAspectRatio]);

  useEffect(() => {
    if (mobileRef.current && stream) {
      mobileRef.current.srcObject = stream || null;
    }
  }, [mobileRef, stream]);

  const handleJoin = useCallback(() => {
    noSleep.enable();
    if (!selectedDevice) {
      logger.error('No selected device');
      setError('error__problem-not-readable');
      return;
    }
    onJoin(selectedDevice.deviceId);
  }, [onJoin, selectedDevice]);

  const backToLobby = async (): Promise<void> => {
    history.push('/lobby');
  };

  const gameInfo = (
    <div className="absolute top-0 inset-x-0 flex flex-col items-center bg-black-75 py-4">
      <div className="text-white font-hairline text-2xl leading-none text-center truncate w-full px-4 pb-4">
        {gameName}
      </div>
      <div className="flex items-center">
        <button
          className="py-2 px-3 rounded transition-all ease-in-out duration-200 text-white bg-st-purple-light hover:bg-st-purple-normal mr-2"
          onClick={handleJoin}
        >
          {t('action__join-now')}
        </button>
        {(devices?.length || 0) > 1 && (
          <div
            onClick={showDevicesModal}
            className="flex items-center border-gray-700 border hover:border-gray-600 text-white rounded px-2 py-1"
          >
            <span className="pr-2">{t('label__camera-settings')}</span>
            <OverlayButton icon={Settings} />
          </div>
        )}

        <button
          className="py-2 px-3 ml-2 rounded transition-all ease-in-out duration-200 text-white border border-gray-700 hover:border-gray-600"
          onClick={backToLobby}
        >
          {t('action__back-to-lobby')}
        </button>
      </div>
    </div>
  );

  let video = (
    <video
      ref={mobileRef}
      className="absolute left-0 top-0 w-full h-full rounded-md untransform"
      style={{ maxHeight: '100%' }}
      autoPlay
      muted
      playsInline
    />
  );

  const errorKey = errorMessage || error;
  return (
    <div
      className="w-full h-full relative bg-black"
      style={{ maxHeight: '100%' }}
    >
      <div className="flex justify-center w-full h-full items-center">
        {errorKey ? <>{t(errorKey)}</> : video}
      </div>
      <DeviceStatus
        isLoading={loadingCamera}
        showPortraitWarning={isInPortrait}
      />
      {gameInfo}
    </div>
  );
};
