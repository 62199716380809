import React from 'react';
import { DiceModal } from 'components/modals/DiceModal';
import { useModal } from 'react-modal-hook';

export const useDice = () => {
  const [showDiceModal, hideDiceModal] = useModal(
    (): JSX.Element => <DiceModal onModalClose={hideDiceModal} />,
    []
  );

  return { showDiceModal, hideDiceModal };
};
