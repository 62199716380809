import React, { FunctionComponent, useCallback, useRef, useState } from 'react';
import { useEffect } from 'react';
import { positionValues, Scrollbars } from 'react-custom-scrollbars';

interface CustomScrollbarsProps {
  viewClasses?: string;
  scrollToBottom?: boolean;
}

interface RenderMethodProps {
  style: object;
}

export const CustomScrollbars: FunctionComponent<CustomScrollbarsProps> = (
  props
) => {
  const { children, viewClasses = 'px-4', scrollToBottom = false } = props;

  const scrollbar = useRef<any>();

  const [onBottom, setOnBottom] = useState<boolean>(true);

  const renderView = useCallback(
    ({ style, ...props }: RenderMethodProps): JSX.Element => {
      return (
        <div
          className={viewClasses}
          style={{
            ...style,
            overflowY: 'scroll',
            overflowX: 'hidden',
            paddingBottom: '15px',
          }}
          {...props}
        />
      );
    },
    [viewClasses]
  );

  const renderThumb = useCallback(
    ({ style, ...props }: RenderMethodProps): JSX.Element => {
      return (
        <div
          className="z-40 bg-gray-700 hover:bg-st-purple-light rounded-full transition-colors ease-in-out duration-200"
          style={{ ...style }}
          {...props}
        />
      );
    },
    []
  );

  const renderTrack = useCallback(
    ({ style, ...props }: RenderMethodProps): JSX.Element => {
      return (
        <div
          className="inset-y-0"
          style={{ ...style, right: '4px' }}
          {...props}
        />
      );
    },
    []
  );

  const renderNoTrack = useCallback(
    ({ style, ...props }: RenderMethodProps): JSX.Element => {
      return <div className="hidden" style={{ ...style }} {...props} />;
    },
    []
  );

  const onScrollFrame = (values: positionValues) => {
    setOnBottom(values.top === 1);
  };

  useEffect(() => {
    if (scrollToBottom && scrollbar.current && onBottom) {
      scrollbar.current.scrollToBottom();
    }
  }, [onBottom, children, scrollbar, scrollToBottom]);

  return (
    <Scrollbars
      renderThumbVertical={renderThumb}
      renderTrackHorizontal={renderNoTrack}
      renderTrackVertical={renderTrack}
      renderView={renderView}
      style={{ width: '100%', height: '100%' }}
      ref={scrollbar}
      onScrollFrame={onScrollFrame}
    >
      {children}
    </Scrollbars>
  );
};
