import Api from 'api';
import firebase from 'api/firebase';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useRecoilState } from 'recoil';
import { authAttemptedState, firebaseUserState } from 'state/atoms';

interface useAuthReturn {
  user: firebase.User | null;
  authAttempted: boolean;
}

export const useAuth: () => useAuthReturn = () => {
  const [user, setUser] = useRecoilState(firebaseUserState);
  const history = useHistory();
  /**
   * This is used to track if the firebase SDK has attempted a login.
   * Firebase on load will auto-login if you are still authenticated.
   */
  const [authAttempted, setAuthAttempted] = useRecoilState(authAttemptedState);

  useEffect(() => {
    const onChange = async (newUser: firebase.User | null) => {
      // getting login twice for some reason
      if (user && newUser && user.uid === newUser.uid) {
        return;
      }
      let wotcToken;
      try {
        wotcToken = await Api.auth.refreshAuth();
      } catch (e) {
        console.error('could not refresh token');
      }

      // no firebase user, get one!
      if (!newUser && wotcToken) {
        const success = await Api.auth.loginWithWotcToken(
          wotcToken.access_token
        );
        if (!success) {
          Api.cleanup();
          Api.auth.logout();
          setAuthAttempted(true);
        }
        // return here because this function will run again once the firebase user is set
        return;
      }

      // No wotc token, but we have a firebase user, logout
      if (!wotcToken && newUser) {
        Api.cleanup();
        Api.auth.logout();
        setAuthAttempted(true);
        return;
      }

      if (newUser) {
        await Api.init(newUser);
      } else {
        Api.cleanup();
      }
      const { wotcAccountId, wotcPersonaId } = Api.auth;
      if (wotcAccountId && wotcPersonaId) {
        Api.updateWotcAccount(wotcAccountId, wotcPersonaId);
      }

      setUser(newUser);
      setAuthAttempted(true);
    };

    const unsubscribe = firebase.auth().onAuthStateChanged(onChange);
    return () => unsubscribe();
  }, [user, setAuthAttempted, history, setUser]);

  return { user, authAttempted };
};
