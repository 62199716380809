import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import cx from 'classnames';

import { X } from 'react-feather';

import useGlobalShortcut, { EventTypes } from 'hooks/useGlobalShortcut';

export enum ButtonTypes {
  Close = 'close',
  Primary = 'primary',
}

interface Button {
  type: ButtonTypes;
  label: string;
  onClick?: () => void;
}

export interface CustomModalProps {
  title: string;
  buttons?: Button[];
  children?: any;
  onModalClose: () => void;
  widthClass?: string;
  showCloseX?: boolean;
}

export const CustomModal: FunctionComponent<CustomModalProps> = (props) => {
  const { t } = useTranslation();

  const {
    title,
    buttons = [
      {
        type: ButtonTypes.Close,
        label: t('label__close'),
      },
    ],
    showCloseX = false,
    children,
    onModalClose,
    widthClass = 'w-500',
  } = props;

  // Allow escape to close the modal
  useGlobalShortcut(EventTypes.keydown, 27, onModalClose);

  const buttonEls = buttons.map(
    ({ label, type = ButtonTypes.Primary, onClick }, index): JSX.Element => {
      const classes = cx(
        'text-sm py-2 px-3 rounded tranision-all ease-in-out duration-200 mx-4 text-white',
        {
          'bg-st-purple-light hover:bg-st-purple-normal': type === 'primary',
          'bg-gray-700 hover:bg-gray-600': type === 'close',
        }
      );

      const onClickHandler = type === 'close' ? onModalClose : onClick;

      return (
        <button
          key={`button-${index}`}
          className={classes}
          onClick={onClickHandler}
        >
          {label}
        </button>
      );
    }
  );

  const closeX = showCloseX ? (
    <div
      className="float-right"
      onClick={onModalClose}
      data-name="customModalClose"
      role="button"
    >
      <X
        size="18"
        className="cursor-pointer text-gray-600 hover:text-white transition-colors duration-200 ease-in-out"
      />
    </div>
  ) : null;

  const classes = cx(
    'rounded shadow-lg outline-none bg-surface-high p-8 overflow-y-auto text-center',
    widthClass
  );

  return (
    <ReactModal
      ariaHideApp={false}
      className={classes}
      overlayClassName="bg-black-50 absolute z-40 top-0 left-0 h-full w-full p-2 xs:p-10 sm:p-32 flex justify-center items-center"
      isOpen
      onRequestClose={onModalClose}
      parentSelector={() =>
        document.querySelector('#modal-container') || document.body
      }
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
    >
      {closeX}
      <div>
        <div className="text-3xl mb-8 text-white font-thin">{title}</div>
        <div>{children}</div>
        {buttonEls.length > 0 ? (
          <div className="pt-8 text-center">{buttonEls}</div>
        ) : null}
      </div>
    </ReactModal>
  );
};
