import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const LegalLink: FC<{ label: string; href?: string; to?: string }> = ({
  label,
  href,
  to,
}) => {
  const classes =
    'text-xs uppercase hover:underline text-gray-400 hover:text-st-orange-normal cursor-pointer transition-all ease-in-out duration-200 justify-center px-4';
  return Boolean(to) ? (
    <Link
      className={classes}
      target="_blank"
      rel="noopener noreferrer"
      to={to!}
    >
      {label}
    </Link>
  ) : (
    <a
      className={classes}
      target="_blank"
      rel="noopener noreferrer"
      href={href}
    >
      {label}
    </a>
  );
};

export const LegalLinks: FC = () => {
  const { t } = useTranslation();
  return (
    <div className="text-gray-800 pb-12 mt-2 flex flex-col sm:flex-row items-center justify-center">
      <LegalLink
        href="https://magic-support.wizards.com/hc/en-us/requests/new?ticket_form_id=360001196951"
        label={t('label__customer-service')}
      />
      <LegalLink
        href="https://company.wizards.com/legal/terms"
        label={t('label__terms-of-use')}
      />
      <LegalLink
        href="https://company.wizards.com/legal/wizards-coasts-privacy-policy"
        label={t('label__privacy-policy')}
      />
      <LegalLink
        href="https://company.wizards.com/legal/code-conduct"
        label={t('label__code-of-conduct')}
      />
      <LegalLink to="/cookies" label={t('label__cookies')} />
      <LegalLink
        href="https://company.wizards.com/fancontentpolicy"
        label={t('label__fan-content-policy')}
      />
      <LegalLink
        href="https://company.wizards.com/legal/wizards-coasts-privacy-policy#donotsell"
        label={t('label__do-not-sell-my-personal-information')}
      />
    </div>
  );
};
