import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Header } from 'components/homepage/Header';
import { Footer } from 'components/homepage/Footer';

const cookies: { name: string; domain: string; description: string }[] = [
  {
    name: 'clientAuth',
    domain: 'spelltable.com',
    description: 'cookie-description__client-auth',
  },
  {
    name: 'rootAuth',
    domain: 'spelltable.com',
    description: 'cookie-description__root-auth',
  },
  {
    name: 'cookie-agree',
    domain: 'spelltable.com',
    description: 'cookie-description__cookie-agree',
  },
  {
    name: '_gat_gtag',
    domain: 'spelltable.com',
    description: 'cookie-description__gat-gtag',
  },
  {
    name: '_ga',
    domain: 'spelltable.com',
    description: 'cookie-description__ga',
  },
  {
    name: '_gid',
    domain: 'spelltable.com',
    description: 'cookie-description__gid',
  },
  {
    name: 'policy-update-2023',
    domain: 'spelltable.com',
    description: 'cookies__2023-privacy',
  },
];

export const Cookies: FC = () => {
  const { t } = useTranslation();

  const cookieBody = cookies.map((c, i) => (
    <tr key={i} className="mt-1 border-t">
      <td className="p-3">{c.name}</td>
      <td>{c.domain}</td>
      <td>{t(c.description)}</td>
    </tr>
  ));

  return (
    <div>
      <div className="flex justify-center">
        <div className="flex flex-col w-full px-6 sm:px-0 sm:w-10/12 lg:w-1500 relative">
          <div>
            <Header />
          </div>
        </div>
      </div>
      <div className="flex justify-center pb-24">
        <div className="flex flex-col w-full px-6 sm:px-0 sm:w-900 flex justify-center items-start leading-loose">
          <div className="text-3xl mb-2 text-white font-thin">
            {t('title__what-are-cookies')}
          </div>

          <p>{t('label__cookie-explanation')}</p>
          <table className="table-auto w-full border border-gray-400 mt-8">
            <thead>
              <tr>
                <th className="text-left p-3">{t('label__cookie-name')}</th>
                <th className="text-left">{t('label__cookie-domain')}</th>
                <th className="text-left">{t('label__cookie-description')}</th>
              </tr>
            </thead>
            <tbody>{cookieBody}</tbody>
          </table>
        </div>
      </div>
      <div className="flex justify-center pt-16 bg-black">
        <div className="flex flex-col w-full px-6 sm:px-0 sm:w-10/12 lg:w-1500 flex justify-center items-center">
          <Footer />
        </div>
      </div>
    </div>
  );
};
