/* eslint-disable jsx-a11y/anchor-has-content */
import React, { FunctionComponent } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Header } from 'components/homepage/Header';
import { Footer } from 'components/homepage/Footer';

export const FAQ: FunctionComponent = () => {
  const { t } = useTranslation();

  const questionClasses = 'text-st-orange-normal text-lg font-bold mb-4 mt-12';

  return (
    <div>
      <div className="flex justify-center">
        <div className="flex flex-col w-full px-6 sm:px-0 sm:w-10/12 lg:w-1500 relative">
          <div>
            <Header />
          </div>
        </div>
      </div>
      <div className="flex justify-center pb-24">
        <div className="flex flex-col w-full px-6 sm:px-0 sm:w-900 flex justify-center items-start leading-loose">
          <div className="text-3xl mb-8 text-white font-thin">
            {t('title__faq')}
          </div>

          <div className={questionClasses}>
            {t('faq-question__requirements')}
          </div>
          <div>{t('faq-answer__requirements')}</div>

          <div className={questionClasses}>{t('faq-question__audio')}</div>
          <div>{t('faq-answer__audio')}</div>

          <div className={questionClasses}>
            {t('faq-question__phone-as-webcam')}
          </div>
          <div className="mb-4">{t('faq-answer__phone-as-webcam')}</div>
          <div>{t('faq-answer__phone-as-webcam-cont')}</div>
          <ol className="mb-4 list-decimal pl-6 my-4">
            <li className="pl-2 my-2">
              {t('faq-answer__phone-as-webcam-step-1')}
            </li>
            <li className="pl-2 my-2">
              {t('faq-answer__phone-as-webcam-step-2')}
            </li>
            <li className="pl-2 my-2">
              {t('faq-answer__phone-as-webcam-step-3')}
            </li>
            <li className="pl-2 my-2">
              {t('faq-answer__phone-as-webcam-step-4')}
            </li>
            <li className="pl-2 my-2">
              {t('faq-answer__phone-as-webcam-step-5')}
            </li>
          </ol>
          <div>
            <Trans i18nKey="faq-answer__phone-as-webcam-note">
              <span className="font-bold"></span>
            </Trans>
          </div>

          <div className={questionClasses}>
            {t('faq-question__how-many-players')}
          </div>
          <div>{t('faq-answer__how-many-players')}</div>

          <div className={questionClasses}>
            {t('faq-question__card-identification')}
          </div>
          <div>{t('faq-answer__card-identification')}</div>

          <div className={questionClasses}>
            {t('faq-question__card-not-identifying')}
          </div>
          <div>{t('faq-answer__card-not-identifying')}</div>
          <ul className="list-outside list-disc pl-6 my-4">
            <li className="pl-2 my-2">
              {t('faq-answer__card-not-identifying-info-1')}
            </li>
            <li className="pl-2 my-2">
              {t('faq-answer__card-not-identifying-info-2')}
            </li>
            <li className="pl-2 my-2">
              {t('faq-answer__card-not-identifying-info-3')}
            </li>
            <li className="pl-2 my-2">
              {t('faq-answer__card-not-identifying-info-4')}
            </li>
            <li className="pl-2 my-2">
              {t('faq-answer__card-not-identifying-info-5')}
            </li>
            <li className="pl-2 my-2">
              {t('faq-answer__card-not-identifying-info-6')}
            </li>
            <li className="pl-2 my-2">
              {t('faq-answer__card-not-identifying-info-7')}
            </li>
          </ul>

          <div className={questionClasses}>{t('faq-question__is-it-free')}</div>
          <div>{t('faq-answer__is-it-free')}</div>

          <div className={questionClasses}>{t('faq-question__why-login')}</div>
          <div>{t('faq-answer__why-login')}</div>

          <div className={questionClasses}>
            {t('faq-question__play-other-than-commander')}
          </div>
          <div>{t('faq-answer__play-other-than-commander')}</div>

          <div className={questionClasses}>{t('faq-question__support')}</div>
          <div>
            <Trans i18nKey="faq-answer__support">
              <Link
                target="_blank"
                to="/troubleshooting"
                className="hover:underline text-st-orange-normal hover:text-st-orange-light transition-colors duration-200 ease-in-out cursor-pointer"
              ></Link>
            </Trans>
          </div>

          <div className={questionClasses}>{t('faq-question__data')}</div>
          <div>{t('faq-answer__data')}</div>

          <div className={questionClasses}>{t('faq-question__cookies')}</div>
          <div>{t('faq-answer__cookies')}</div>
        </div>
      </div>
      <div className="flex justify-center pt-16 bg-black">
        <div className="flex flex-col w-full px-6 sm:px-0 sm:w-10/12 lg:w-1500 flex justify-center items-center">
          <Footer />
        </div>
      </div>
    </div>
  );
};
