import { useState, useEffect } from 'react';
import firebase from 'api/firebase';

import { Game, SearchCriteria, getMaxPlayers } from 'types';

import useFirebaseUser from 'hooks/useFirebaseUser';

export const MAX_SEARCH_GAMES = 12;
export const POLLING_RATE = 15000;

interface SearchedGames {
  games: Game[];
  search: SearchCriteria;
  page: number;
  maxPages: number;
  setPage: (newPage: number) => void;
  setSearch: (newSearch: SearchCriteria) => void;
}

interface GamePlayerMapData {
  numPlayers: number;
  maxPlayers: number;
}

export function getHoursAgo(num: number): firebase.firestore.Timestamp {
  const nowDate = firebase.firestore.Timestamp.now().toDate();
  nowDate.setHours(nowDate.getHours() - num);
  return firebase.firestore.Timestamp.fromDate(nowDate);
}

export default function useSearchGames(): SearchedGames {
  const user = useFirebaseUser();
  const [maxPages, setMaxPages] = useState<number>(1);
  const [page, setPage] = useState<number>(0);
  const [games, setGames] = useState<Game[]>([]);
  const [search, setSearch] = useState<SearchCriteria>({
    title: '',
    format: 'ANY',
  });

  useEffect(() => {
    // only find public games
    const db = firebase
      .firestore()
      .collection('games')
      .where('isPublic', '==', true);
    const dbSearch =
      search.format !== 'ANY' ? db.where('format', '==', search.format) : db;
    let mounted = true;

    const gameSearch = (): void => {
      const hoursAgo = getHoursAgo(2);
      if (user) {
        dbSearch
          .where('created', '>', hoursAgo)
          .orderBy('created', 'desc')
          .get()
          .then((gamesSnapshot) => {
            if (!mounted) {
              return;
            }
            const gamePlayersMap = new Map<string, GamePlayerMapData>();
            setGames(
              gamesSnapshot.docs
                // Map first so we are able to sort on the whole game list to sink full games to bottom
                .map((doc) => {
                  const game = doc.data() as Game;
                  gamePlayersMap.set(game.id, {
                    numPlayers: game.players.filter((p) => p).length,
                    maxPlayers: getMaxPlayers(game.format),
                  });
                  return game;
                })
                .sort((a, b) => {
                  const aMap = gamePlayersMap.get(a.id);
                  const bMap = gamePlayersMap.get(b.id);

                  // We only move them if the games equal the max players otherwise allow any number of players
                  return aMap?.numPlayers === aMap?.maxPlayers
                    ? 1
                    : bMap?.numPlayers === bMap?.maxPlayers
                    ? -1
                    : 0;
                })
                // Choose only the amount per page we can show after sorted
                .slice(
                  page * MAX_SEARCH_GAMES,
                  page * MAX_SEARCH_GAMES + MAX_SEARCH_GAMES
                )
            );
            setMaxPages(
              Math.ceil(gamesSnapshot.docs.length / MAX_SEARCH_GAMES)
            );
          });
      }
    };

    // Pull for initial games
    gameSearch();

    const interval = setInterval(gameSearch, POLLING_RATE);

    return () => {
      mounted = false;
      clearInterval(interval);
    };
  }, [user, search, page]);

  return {
    games,
    search,
    page,
    maxPages,
    setPage,
    setSearch,
  };
}
