import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Header } from 'components/homepage/Header';
import { Footer } from 'components/homepage/Footer';

export function MobileHelp() {
  const { t } = useTranslation();

  const headingClasses = 'text-st-orange-normal text-lg font-bold mb-4 mt-12';

  return (
    <div>
      <div className="flex justify-center">
        <div className="flex flex-col w-full px-6 sm:px-0 sm:w-10/12 lg:w-1500 relative">
          <div>
            <Header />
          </div>
        </div>
      </div>
      <div className="flex justify-center pb-24">
        <div className="flex flex-col w-full px-6 sm:px-0 sm:w-900 flex justify-center items-start leading-loose">
          <div className="text-3xl mb-8 text-white font-thin">
            {t('title__mobile-device-as-camera')}
          </div>
          <div className={headingClasses}>
            {t('troubleshooting-title__recommended-mobile-browser')}
          </div>
          <div className="mb-4">
            <Trans i18nKey="troubleshooting-info__recommended-mobile-browser-ios">
              <span className="text-gray-300 font-bold"></span>
              <span className="font-bold underline"></span>
            </Trans>
            <br />
            <Trans i18nKey="troubleshooting-info__recommended-mobile-browser-android">
              <span className="text-gray-300 font-bold"></span>
            </Trans>
          </div>
          <div className={headingClasses}>
            {t('troubleshooting-title__os-rotation-lock')}
          </div>
          <div className="mb-4">
            <Trans i18nKey="troubleshooting-info__os-rotation-lock-ios">
              <span className="text-gray-300 font-bold"></span>
              <a
                className="hover:underline text-st-orange-normal hover:text-st-orange-light transition-colors duration-200 ease-in-out cursor-pointer"
                target="_blank"
                rel="noopener noreferrer"
                href="https://support.apple.com/guide/iphone/change-or-lock-the-screen-orientation-iph3badf94ec/ios"
              >
                {' '}
              </a>
            </Trans>
          </div>
          <div className="mb-4">
            <Trans i18nKey="troubleshooting-info__os-rotation-lock-android">
              <span className="text-gray-300 font-bold"></span>
              <a
                className="hover:underline text-st-orange-normal hover:text-st-orange-light transition-colors duration-200 ease-in-out cursor-pointer"
                target="_blank"
                rel="noopener noreferrer"
                href="https://support.google.com/accessibility/android/answer/6006961?hl=en"
              >
                {' '}
              </a>
            </Trans>
          </div>
        </div>
      </div>
      <div className="flex justify-center pt-16 bg-black">
        <div className="flex flex-col w-full px-6 sm:px-0 sm:w-10/12 lg:w-1500 flex justify-center items-center">
          <Footer />
        </div>
      </div>
    </div>
  );
}
