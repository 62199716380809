import { useContext } from 'react';

import { AnyCard } from 'types';

import { GameContext, GameContextProps } from 'contexts/GameContext';

export default function useCards(): AnyCard[] | undefined {
  const { cards } = useContext<Partial<GameContextProps>>(GameContext);
  return cards;
}
