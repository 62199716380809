import firebase from 'api/firebase';
import * as SDK from 'wotc-platform-sdk-js';
import { trackBi } from './utils/bi';
import { LOGOUT } from 'constants/bi-names';

interface ExchangeTokenResult {
  accountId: string;
  personaId: string;
  displayName: string;
  customToken: string;
}

export default class Auth {
  public wotcSDK: any;
  public wotcAccountId?: string;
  public wotcPersonaId?: string;
  public email?: string;

  constructor() {
    this.wotcSDK = SDK;
    this.wotcSDK.configure({
      gameID: process.env.REACT_APP_AUTH_PLATFORM_ID,
      clientID: process.env.REACT_APP_AUTH_CLIENT_ID_PROD,
      clientSecret: process.env.REACT_APP_AUTH_SECRET_ID_PROD,
    });
  }

  //TODO: type the JWT token
  async refreshAuth(): Promise<any | null> {
    try {
      const result = await this.wotcSDK.auth.refreshAuth();
      this.wotcAccountId = result?.account_id;
      this.wotcPersonaId = result?.persona_id;
      return result;
    } catch (e) {
      return null;
    }
  }

  redirectToLogin = () => {
    this.wotcSDK.auth.redirectToMyAccounts();
  };

  async loginWithWotcToken(token: string): Promise<boolean | undefined> {
    // can't really return undefined, this is covered in the .finally
    let exchangeResult: ExchangeTokenResult;
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_GATEWAY_URL}/exchangeToken` || '',
        {
          method: 'POST',
          cache: 'no-cache',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_LAMBDA_KEY || '',
          },
          body: JSON.stringify({ token }),
        }
      );
      exchangeResult = (await response.json()) as ExchangeTokenResult;
    } catch (e) {
      return false;
    }
    let login: firebase.auth.UserCredential;
    try {
      login = await firebase
        .auth()
        .signInWithCustomToken(exchangeResult?.customToken);
    } catch (e) {
      return false;
    }
    if (!login?.user) {
      return false;
    }
    // update the display name
    await login?.user?.updateProfile({
      displayName: exchangeResult?.displayName,
    });
    this.wotcAccountId = exchangeResult?.accountId;
    this.wotcPersonaId = exchangeResult?.personaId;
    return true;
  }

  async logout(): Promise<void> {
    trackBi({
      name: LOGOUT,
      accountId: this.wotcAccountId,
      personaId: this.wotcPersonaId,
      isPII: false,
    });
    try {
      await this.wotcSDK.auth.logOut();
    } catch (e) {
      console.warn(e);
    }
    await firebase.auth().signOut();
  }
}
