import React, {
  FC,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import Api from 'api';
import isCamera from 'utils/isCamera';

import { useModal } from 'react-modal-hook';

import KEY_CODES from 'constants/keycodes';

import useGame from 'hooks/useGame';
import useGameApi from 'hooks/useGameApi';
import useGlobalShortcut, { EventTypes } from 'hooks/useGlobalShortcut';
import useVideoLayout from 'hooks/useVideoLayout';

import { GameOnboardingModal } from 'components/modals/onboarding/GameOnboardingModal';

import {
  ArrowLeft,
  ArrowRight,
  Command,
  HelpCircle,
  LifeBuoy,
  Lock,
  Mic,
  MicOff,
  Settings,
  Sidebar,
  UserPlus,
  Video,
  VideoOff,
} from 'react-feather';
import { BsDice6 } from 'react-icons/bs';

import { useHideOnboarding } from 'hooks/useHideOnboarding';

import { AccountMenu } from 'components/AccountMenu';
import { CustomTooltip } from 'components/CustomTooltip';
import { FourCornerLayoutIcon, PIPIcon } from 'components/icons';
import { InvitePlayersModal } from 'components/InvitePlayersModal';
import { GameMenu } from 'components/menus/GameMenu';
import { ShortcutsModal } from 'components/modals/ShortcutsModal';
import { TurnClock } from 'components/TurnClock';
import { IconButton } from 'components/ui/IconButton';
import { useRecoilValue } from 'recoil';
import { gameRoomState, mutedState, videoEnabledState } from 'state/atoms';
import { isMultiplayer, VideoLayout } from 'types';
import { SpectatorCount } from './SpectatorCount';

import { DiceMenu } from 'components/menus/DiceMenu';
import buildRoute from 'utils/buildRoute';

interface LeftGameBarProps {
  useMobileCamera: boolean;
  isSpectator: boolean;
}

export const LeftGameBar: FC<LeftGameBarProps> = ({
  useMobileCamera,
  isSpectator,
}) => {
  const { t } = useTranslation();
  const gameApi = useGameApi();
  const game = useGame();
  const { isDefaultLayout, isFocusedLayout, isTheaterLayout } =
    useVideoLayout();

  const [hideOnboarding] = useHideOnboarding();
  const isVideoEnabled = useRecoilValue(videoEnabledState);
  const isMuted = useRecoilValue(mutedState);
  const gameRoom = useRecoilValue(gameRoomState);
  const panelRef = useRef<HTMLDivElement | null>(null);

  const { format } = game || {};

  const [showInvitePlayersModal, hideInvitePlayersModal] = useModal(
    (): JSX.Element => (
      <InvitePlayersModal
        onModalClose={hideInvitePlayersModal}
        url={window.location.href}
      />
    )
  );

  const [showGameOnboardingModal, hideGameOnboardingModal] = useModal(
    (): JSX.Element => (
      <GameOnboardingModal onModalClose={hideGameOnboardingModal} />
    )
  );

  const [showShortcutsModal, hideShortcutsModal] = useModal(
    (): JSX.Element => <ShortcutsModal onModalClose={hideShortcutsModal} />
  );

  const [isPanelVisible, setIsPanelVisible] = useState(
    Api.getUserPreferences()?.isGamePanelVisibile ?? true
  );

  useEffect(() => {
    if (!hideOnboarding && !isCamera()) {
      showGameOnboardingModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hideOnboarding, showGameOnboardingModal]);

  const onPassTurn = useCallback(() => {
    if (!gameApi) {
      return;
    }
    if (!game?.isUsingPhases) {
      gameApi.passTurn();
      return;
    }

    const phaseIndex = game.phase;

    if (!game.activePlayer || phaseIndex === 4 || phaseIndex === undefined) {
      gameApi.passTurn();
      return;
    }

    gameApi.nextPhase(phaseIndex + 1);
  }, [game, gameApi]);

  const toggleFocusedLayout = (): void => {
    gameApi?.setVideoLayout(VideoLayout.FOCUSED);
  };

  const toggleFourCornerLayout = (): void => {
    gameApi?.setVideoLayout(VideoLayout.DEFAULT);
  };

  const toggleTheaterLayout = (): void => {
    gameApi?.setVideoLayout(VideoLayout.THEATER);
  };

  const togglePanelVisibility = useCallback(async () => {
    await Api.setUserPreferences({
      isGamePanelVisibile: !isPanelVisible,
    });

    setIsPanelVisible(!isPanelVisible);
  }, [isPanelVisible]);

  useGlobalShortcut(EventTypes.keydown, KEY_CODES.spacebar, onPassTurn);

  useGlobalShortcut(EventTypes.keydown, KEY_CODES.g, togglePanelVisibility, {
    requireMeta: true,
  });

  const leaveGame = async (): Promise<void> => {
    await gameApi?.leave();
  };

  const toggleMute = useCallback(() => {
    gameRoom?.setMute(!isMuted);
    Api.setAVPreferences({ isMuted: !isMuted });
  }, [gameRoom, isMuted]);

  useGlobalShortcut(EventTypes.keydown, KEY_CODES.m, toggleMute, {
    requireMeta: true,
  });

  const toggleVideo = useCallback(() => {
    gameRoom?.setVideoEnabled(!isVideoEnabled);
    Api.setAVPreferences({ isVideoEnabled: !isVideoEnabled });
  }, [isVideoEnabled, gameRoom]);

  const invitePlayers =
    game && game.players.filter((p) => p).length < 4 ? (
      <IconButton
        icon={UserPlus}
        isHighlighted={true}
        label={t('label__show-invite-modal')}
        onClick={showInvitePlayersModal}
        tooltip={t('action__invite-players')}
        style={{ marginRight: -6 }}
      />
    ) : null;

  const turnClock =
    game?.players?.length && game?.turnStart ? (
      <TurnClock startTime={game.turnStart} />
    ) : null;

  return (
    <div
      className="flex flex-col justify-between h-full w-24 bg-surface-medium py-8 overflow-auto"
      ref={panelRef}
      style={{
        transition: 'margin 200ms ease',
        marginLeft: isPanelVisible ? 0 : -96,
      }}
    >
      <div className="flex w-full flex-col items-center">
        <img
          alt="SpellTable"
          src={process.env.PUBLIC_URL + '/images/PWSymbol.svg'}
          className="w-8 mb-6"
        />
        {
          /* Next turn button */
          !isSpectator && (
            <CustomTooltip tooltip={t('label__pass-the-turn')}>
              <ArrowRight
                className="text-white hover:text-st-orange-normal cursor-pointer transition-all ease-in-out duration-200"
                size={32}
                onClick={onPassTurn}
                aria-label={t('label__pass-turn')}
                role="button"
              />
            </CustomTooltip>
          )
        }
        <div className="mt-4">{turnClock}</div>
      </div>

      {!isSpectator && (
        <div className="flex w-full flex-col justify-end items-center">
          <Section>
            <SpectatorCount />

            <IconButton
              icon={isMuted ? MicOff : Mic}
              isActive={!isMuted}
              label={
                isMuted
                  ? t('label__toggle-microphone-on')
                  : t('label__toggle-microphone-off')
              }
              onClick={toggleMute}
              tooltip={isMuted ? t('label__unmute') : t('label__mute')}
              size={18}
            />
            {!useMobileCamera && (
              <IconButton
                icon={isVideoEnabled ? Video : VideoOff}
                isActive={isVideoEnabled}
                label={
                  isVideoEnabled
                    ? t('label__toggle-video-off')
                    : t('label__toggle-video-on')
                }
                onClick={toggleVideo}
                tooltip={
                  isVideoEnabled
                    ? t('label__stop-video')
                    : t('label__start-video')
                }
                size={18}
              />
            )}
          </Section>

          <Section>
            <IconButton
              icon={Sidebar}
              isActive={isFocusedLayout}
              label={t('label__use-focused-layout')}
              onClick={toggleFocusedLayout}
              tooltip={t('label__toggle-to-focused-layout')}
              size={18}
            />

            {isMultiplayer(format) && (
              <IconButton
                icon={FourCornerLayoutIcon}
                isActive={isDefaultLayout}
                label={t('label__use-four-corner-layout')}
                onClick={toggleFourCornerLayout}
                tooltip={t('label__toggle-to-four-corner-layout')}
                size={18}
              />
            )}

            <IconButton
              icon={PIPIcon}
              isActive={isTheaterLayout}
              label={t('label__use-picture-in-picture-layout')}
              onClick={toggleTheaterLayout}
              tooltip={t('label__toggle-to-picture-in-picture-layout')}
              size={18}
            />

            <TogglePanelButton
              isPanelVisible={isPanelVisible}
              panelRef={panelRef}
              onClick={togglePanelVisibility}
            />
          </Section>

          <Section>
            {invitePlayers}

            <GameMenu useMobileCamera={useMobileCamera}>
              <IconButton
                icon={Settings}
                label={t('label__game-menu-and-settings')}
                tooltip={t('label__game-menu-settings')}
              />
            </GameMenu>
            <DiceMenu>
              <IconButton
                icon={BsDice6}
                label={t('label__dice-coin')}
                tooltip={t('label__dice-coin')}
              />
            </DiceMenu>
          </Section>

          <Section>
            <IconButton
              icon={Command}
              label={t('label__view-keyboard-shortcuts')}
              onClick={showShortcutsModal}
              tooltip={t('label__keyboard-shortcuts')}
            />

            <IconButton
              icon={HelpCircle}
              label={t('label__read-the-faq')}
              onClick={() => window.open(buildRoute('/faq'))}
              tooltip={t('label__view-faq')}
            />

            <IconButton
              icon={LifeBuoy}
              label={t('label__view-onboarding')}
              onClick={showGameOnboardingModal}
              tooltip={t('label__view-onboarding')}
            />
          </Section>

          <div className="mt-2">
            <AccountMenu bottom leaveGame={leaveGame} />
          </div>
        </div>
      )}
    </div>
  );
};

const Section: FC = ({ children }) => {
  return (
    <div className="border-b border-surface-border w-1/2 py-4 flex flex-col justify-center items-center">
      {children}
    </div>
  );
};

interface TogglePanelButtonProps {
  isPanelVisible: boolean;
  onClick: () => void;
  panelRef: MutableRefObject<HTMLDivElement | null>;
}

const TogglePanelButton: FC<TogglePanelButtonProps> = (props) => {
  const { isPanelVisible, onClick, panelRef } = props;
  const { t } = useTranslation();

  const mouseMoveHandler = useCallback(
    (event: MouseEvent) => {
      if (!panelRef.current) {
        return;
      }

      if (event.clientX < 10) {
        panelRef.current.style.marginLeft = '0px';
      }

      if (event.clientX > 100) {
        panelRef.current.style.marginLeft = '-96px';
      }
    },
    [panelRef]
  );

  useEffect(() => {
    if (!isPanelVisible) {
      window.addEventListener('mousemove', mouseMoveHandler);
    } else {
      window.removeEventListener('mousemove', mouseMoveHandler);
    }

    return () => {
      window.removeEventListener('mousemove', mouseMoveHandler);
    };
  }, [isPanelVisible, mouseMoveHandler]);

  return (
    <IconButton
      icon={isPanelVisible ? ArrowLeft : Lock}
      label={
        isPanelVisible
          ? t('label__hide-game-panel')
          : t('label__lock-open-game-panel')
      }
      onClick={onClick}
      tooltip={
        isPanelVisible
          ? t('label__hide-game-panel')
          : t('label__lock-open-game-panel')
      }
    />
  );
};
