import * as THREE from 'three';
import { baseDice } from './baseDice';

export default function D8(
  scene: THREE.Scene,
  radius: number,
  materialColor: string
) {
  const geometry = new THREE.OctahedronGeometry(radius, 0);
  const { update, group } = baseDice(geometry, scene, materialColor);
  return { update, group };
}
