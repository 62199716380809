import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';

import Api from 'api';
import { Room } from 'livekit-client';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { gameRoomState } from 'state/atoms';
import logger from 'utils/logger';
import { BaseDeviceSelect } from './BaseDeviceSelect';

export type MicSelectProps = {
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  textSize?: 'text-xs' | 'text-md' | 'text-sm' | 'text-lg';
};

export const MicSelect: FunctionComponent<MicSelectProps> = (props) => {
  const { t } = useTranslation();
  const { onChange, textSize } = props;
  const [chosenMic, setChosenMic] = useState<string>();
  const [micDevices, setMicDeviceList] = useState<MediaDeviceInfo[]>();
  const gameRoom = useRecoilValue(gameRoomState);

  useEffect(() => {
    const loadDevices = async () => {
      const audioDevices = await Room.getLocalDevices('audioinput');

      if (!audioDevices.length) {
        logger.error('No audio input devices reported');
        return;
      }

      const deviceIds = audioDevices.map((device) => device.deviceId);
      setMicDeviceList(audioDevices);
      const prefs = await Api.getUserPreferences();
      const audio =
        prefs?.gameAudioVideo?.audioInputID &&
        deviceIds.includes(prefs.gameAudioVideo.audioInputID)
          ? prefs.gameAudioVideo.audioInputID
          : audioDevices[0].deviceId;
      setChosenMic(audio);
    };

    loadDevices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onMicChange = useCallback(
    async (e: React.ChangeEvent<HTMLSelectElement>) => {
      onChange && onChange(e);
      const audioInputID = e.target.value;
      setChosenMic(audioInputID);
      await gameRoom?.setAudioDeviceId(audioInputID);
      Api.setAVPreferences({ audioInputID });
    },
    [onChange, gameRoom]
  );

  return (
    <>
      {micDevices && chosenMic && (
        <BaseDeviceSelect
          onChange={onMicChange}
          devices={micDevices}
          label={t('label__mic-source')}
          chosenDeviceId={chosenMic}
          textSize={textSize}
        />
      )}
    </>
  );
};
