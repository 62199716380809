import firebase from 'api/firebase';
import React, { FunctionComponent, useState, useEffect, useRef } from 'react';
import { Clock } from 'react-feather';

function padWithZero(number: number): string {
  return number < 10 ? `0${number}` : number.toString();
}

function getSeconds(time: firebase.firestore.Timestamp): number {
  const now = firebase.firestore.Timestamp.now();
  return Math.ceil(now.seconds - time.seconds);
}

interface TurnClockProps {
  startTime: firebase.firestore.Timestamp;
}

export const TurnClock: FunctionComponent<TurnClockProps> = (props) => {
  const { startTime } = props;
  const [seconds, setSeconds] = useState(getSeconds(startTime));
  const intervalRef = useRef<number | null>(null);

  useEffect(() => {
    intervalRef.current = window.setInterval(() => {
      setSeconds(seconds + 1);
    }, 1000);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, [seconds]);

  useEffect(() => {
    setSeconds(getSeconds(startTime));
  }, [startTime]);

  const mins = Math.max(0, Math.floor(seconds / 60));
  const secs = Math.max(0, seconds % 60);

  if (isNaN(seconds) || mins > 99) {
    return null;
  }

  return (
    <div className="flex items-center text-white font-mono font-thin text-xs bg-st-purple-light py-1 px-2 rounded shadow-md">
      <Clock size="12" />
      <div className="ml-1">
        {padWithZero(mins)}:{padWithZero(secs)}
      </div>
    </div>
  );
};
