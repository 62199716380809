import React, {
  FunctionComponent,
  useState,
  useMemo,
  useCallback,
} from 'react';

import useVideoLayout from 'hooks/useVideoLayout';

import { VideoContextProvider } from 'contexts/VideoContext';

import { FocusedVideos } from './FocusedVideos';
import { FourCornerVideos } from './FourCornerVideos';
import { VideoLayout } from 'types';
import { TheaterViewVideos } from './TheaterModeVideos';

export const Videos: FunctionComponent = () => {
  const { videoLayout } = useVideoLayout();

  const [rotatedVideos, setRotatedVideos] = useState<string[]>([]);

  const toggleVideoRotate = useCallback(
    (uid) => {
      const updated = [...rotatedVideos];
      const index = updated.indexOf(uid);
      if (index !== -1) {
        updated.splice(index, 1);
      } else {
        updated.push(uid);
      }
      setRotatedVideos(updated);
    },
    [rotatedVideos]
  );

  const context = useMemo(() => {
    return {
      rotatedVideos,
      toggleVideoRotate,
    };
  }, [rotatedVideos, toggleVideoRotate]);

  let videos = null;
  switch (videoLayout) {
    case VideoLayout.FOCUSED:
      videos = <FocusedVideos />;
      break;
    case VideoLayout.THEATER:
      videos = <TheaterViewVideos />;
      break;
    case VideoLayout.DEFAULT:
    default:
      videos = <FourCornerVideos />;
  }

  return <VideoContextProvider value={context}>{videos}</VideoContextProvider>;
};
