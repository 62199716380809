import { useMemo } from 'react';

import { CommanderDamage, InfectDamage } from 'types';

import { DAMAGE_TYPES } from 'constants/magic';
import createCommanderDamageObject from 'utils/createCommanderDamageObject';
import createInfectDamageObject from 'utils/createInfectDamageObject';
import { isCommanderDamage } from 'api/utils/isCommanderDamage';
import { isInfectDamage } from 'api/utils/isInfectDamage';

import usePlayer from 'hooks/usePlayer';
import useCommanders, { PlayerCommander } from 'hooks/useCommanders';

interface DamageByPlayerValue {
  name: string;
  damage: CommanderDamage[];
}

interface DamageByPlayer {
  [playerId: string]: DamageByPlayerValue;
}

export interface usePlayerDamagesResult {
  damageByPlayer: DamageByPlayer;
  miscDamage: InfectDamage[];
}

export default function usePlayerDamages(
  playerId: string | undefined
): usePlayerDamagesResult {
  const { damage: playerDamageData = [] } = usePlayer(playerId) || {};
  const commanders = useCommanders();

  const playerCommanderDamages = playerDamageData.filter(isCommanderDamage);
  const playerInfectDamage = playerDamageData.filter(isInfectDamage);

  const damageByPlayer = useMemo(() => {
    return commanders.reduce(
      (results: DamageByPlayer, item: PlayerCommander) => {
        const { player, commander } = item;
        const playerSet = results[player.uid] || {
          name: player.name.split('#')[0],
          damage: [],
        };

        playerSet.damage.push(
          playerCommanderDamages.find(
            (d: CommanderDamage) =>
              d.type === DAMAGE_TYPES.COMMANDER &&
              d.owner.uid === player.uid &&
              d.commander.id === commander.id
          ) || createCommanderDamageObject(player, commander)
        );

        results[player.uid] = playerSet;

        return results;
      },
      {}
    );
  }, [commanders, playerCommanderDamages]);

  const miscDamage = useMemo(() => {
    return [
      playerInfectDamage.find(
        (d: InfectDamage) => d.type === DAMAGE_TYPES.INFECT
      ) || createInfectDamageObject(),
    ];
  }, [playerInfectDamage]);

  return {
    damageByPlayer,
    miscDamage,
  };
}
