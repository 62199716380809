import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from 'types';

import { DrawerCard } from './DrawerCard';
import { MenuItemTypes } from 'components/menus/CustomMenu';

interface DrawerCardFullDataProps {
  hoverDisabled?: boolean;
  card: Card;
}

export const DrawerCardFullData: FunctionComponent<DrawerCardFullDataProps> = (
  props
) => {
  const { hoverDisabled = false, card } = props;

  const {
    name,
    multiverseId,
    image_uris,
    back_image_uris,
    related_uris,
    isBack,
  } = card;

  const { t } = useTranslation();

  const imageUrl = isBack
    ? back_image_uris?.normal
    : image_uris?.normal || undefined;
  const hoverImages = [];

  if (image_uris) {
    hoverImages.push(image_uris.normal);
  }

  if (back_image_uris) {
    hoverImages.push(back_image_uris.normal);
  }

  const viewOnGatherer = useCallback(() => {
    window.gtag?.('event', 'gatherer_clicked');
    const url = related_uris?.gatherer
      ? related_uris?.gatherer
      : `https://gatherer.wizards.com/Pages/Card/Details.aspx?multiverseid=${multiverseId}`;
    window.open(url, '_blank');
  }, [related_uris, multiverseId]);

  const menuItems = useMemo(() => {
    let items: any[] = [];

    if (related_uris) {
      const relatedUriItems = [];

      if (related_uris?.gatherer || multiverseId) {
        relatedUriItems.push({
          type: MenuItemTypes.ITEM,
          label: t('label__view-on-gatherer'),
          onClick: viewOnGatherer,
        });
      }

      if (relatedUriItems.length) {
        items = [...items, { type: MenuItemTypes.DIVIDER }, ...relatedUriItems];
      }
    }

    return items;
  }, [multiverseId, related_uris, viewOnGatherer, t]);

  return (
    <DrawerCard
      card={card}
      hoverDisabled={hoverDisabled}
      name={name}
      imageUrl={imageUrl}
      hoverImages={hoverImages}
      menuItems={menuItems}
    />
  );
};
