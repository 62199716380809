import firebase from 'api/firebase';
import debounce from 'lodash/debounce';

export enum GameLogAction {
  Eliminated = 'Eliminated',
  Joined = 'Joined',
  Left = 'Left',
  SetLife = 'SetLife',
  IdentifiedCard = 'IdentifiedCard',
  PassedTurn = 'PassedTurn',
  SetCommander = 'SetCommander',
  FlippedCoin = 'FlippedCoin',
  RolledDice = 'RolledDice',
  BecameMonarch = 'BecameMonarch',
}

export interface GameLogItem {
  gameId: string;
  displayName: string;
  action: GameLogAction;
  params: string[];
  timestamp: Object;
}

const DEBOUNCE_TIME = 500;
export default class GameLog {
  getGameLogDBRef(gameId: string): firebase.database.Reference {
    return firebase.database().ref(`/game-logs/${gameId}`);
  }

  debouncedGameLog = debounce(this.addGameLog, DEBOUNCE_TIME);

  async addGameLog(
    gameId: string,
    action: GameLogAction,
    displayName: string,
    params: string[] = []
  ) {
    const newGameLogRef = this.getGameLogDBRef(gameId).push();
    await newGameLogRef.set({
      gameId,
      action,
      displayName: displayName.split('#')[0],
      params,
      timestamp: firebase.database.ServerValue.TIMESTAMP,
    });
  }

  async resetGameLog(gameId: string) {
    const gameLogRef = this.getGameLogDBRef(gameId);
    await gameLogRef.remove();
  }
}
