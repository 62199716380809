import React, { FunctionComponent, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { useModal } from 'react-modal-hook';

import { UserPlus, Trash2, Play } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { MAX_USER_GAMES } from 'hooks/usePlayerGames';

import { Game, getMaxPlayers, MagicFormat } from 'types';
import { InvitePlayersModal } from 'components/InvitePlayersModal';
import { DestroyGameModal } from 'components/DestroyGameModal';
import { IconButton } from 'components/ui/IconButton';
import tagListAsString from 'utils/tagListAsString';
import buildRoute from 'utils/buildRoute';

export const MyGamesList: FunctionComponent<{ games: Game[] }> = ({
  games,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [gameId, setGameId] = useState<undefined | string>();
  const [showInvitePlayersModal, hideInvitePlayersModal] =
    useModal((): JSX.Element | null => {
      if (!gameId) {
        return null;
      }
      return (
        <InvitePlayersModal
          onModalClose={hideInvitePlayersModal}
          url={buildRoute(`/game/${gameId}`)}
        />
      );
    }, [gameId]);
  const [showDeleteGameModal, hideDeleteGameModal] =
    useModal((): JSX.Element | null => {
      if (!gameId) {
        return null;
      }
      return (
        <DestroyGameModal onModalClose={hideDeleteGameModal} id={gameId} />
      );
    }, [gameId]);
  const gamesRows = useMemo(() => {
    return games.slice(0, MAX_USER_GAMES).map((game) => {
      const { id, name, players } = game;
      const numPlayers = players.filter((p) => p).length;
      const join = () => {
        history.push(`/game/${id}`);
      };
      const invite = () => {
        setGameId(id);
        showInvitePlayersModal();
      };
      const destroyGame = () => {
        setGameId(id);
        showDeleteGameModal();
      };

      const tagList = tagListAsString(t, game.tags);

      return (
        <div
          className="flex items-center text-gray-700 hover:text-gray-400 hover:bg-surface-low rounded-lg transition-all ease-in-out duration-200 group hover:no-underline p-4"
          key={id}
        >
          <div className="truncate flex-1 text-lg pr-2">
            <Link to={`/game/${id}`}>{name}</Link>
          </div>
          <div className="px-2 text-xs">{tagList}</div>
          <div className="px-2">
            {numPlayers}/{getMaxPlayers(game.format)}
          </div>
          <div className="px-2">{game.format || MagicFormat.Commander}</div>
          <div className="flex justify-center pl-2">
            <IconButton
              icon={Play}
              tooltip={t('action__join-game')}
              label={t('action__join-game')}
              onClick={join}
              className="m-3"
            />
            <IconButton
              icon={UserPlus}
              label={t('label__show-invite-modal')}
              onClick={invite}
              tooltip={t('action__invite-players')}
              className="mr-3"
            />
            <IconButton
              icon={Trash2}
              label={t('action__delete-game')}
              onClick={destroyGame}
              tooltip={t('action__delete-game')}
            />
          </div>
        </div>
      );
    });
  }, [games, history, showDeleteGameModal, showInvitePlayersModal, t]);

  return (
    <div className="border-t border-surface-border p-6">
      {games.length ? (
        gamesRows
      ) : (
        <div className="text-center py-4">{t('label__no-games')}</div>
      )}
    </div>
  );
};
