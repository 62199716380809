import { FunctionComponent, useEffect } from 'react';

import firebase from 'api/firebase';

import { useRecoilState } from 'recoil';
import { userProfileState } from 'state/atoms';
import { User } from 'types';

interface UserProfileSubscriptionProps {
  uid: string;
}

export const UserProfileSubscription: FunctionComponent<
  UserProfileSubscriptionProps
> = ({ uid }) => {
  const db = firebase.firestore();
  const [, setUserProfile] = useRecoilState<User | undefined>(
    userProfileState(uid)
  );
  useEffect(() => {
    const unsubscribe = db
      .collection('users')
      .doc(uid)
      .onSnapshot((snapshot) => {
        const user = snapshot.data() as User;
        setUserProfile({
          ...user,
        });
      });
    return unsubscribe;
  }, [db, setUserProfile, uid]);
  return null;
};
