import React, {
  FunctionComponent,
  useState,
  useRef,
  useLayoutEffect,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import Api from 'api';

import { Modal } from 'components/ui/Modal';
import { CustomScrollbars } from 'components/CustomScrollbars';

import useFirebaseUser from 'hooks/useFirebaseUser';
import { MagicFormat, Tags } from 'types';
import { Select } from './ui/Select';
import { Checkbox } from './ui/Checkbox';
import { SpokenLanguages } from 'constants/languages';
import { getLanguageTag } from 'constants/tags';
import { GAME_LANGUAGE, IS_STREAMING } from 'constants/bi-names';

interface CreateGameModalProps {
  history: any;
  onModalClose: () => any;
}

export const CreateGameModal: FunctionComponent<CreateGameModalProps> = (
  props
) => {
  const { history, onModalClose } = props;
  const { t, i18n } = useTranslation();

  const user = useFirebaseUser();
  const nameRef = useRef<HTMLInputElement>(null);
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(false);
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [format, setFormat] = useState<MagicFormat>(MagicFormat.Commander);
  const [isPublic, setIsPublic] = useState<boolean>(false);
  const [isStreamed, setIsStreamed] = useState<boolean>(false);
  const [language, setLanguage] = useState(
    getLanguageTag(i18n.language || 'en')
  );

  useLayoutEffect(() => {
    let timeout = setTimeout(() => {
      if (nameRef.current) {
        nameRef.current.focus();
      }
    });

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setNameError(false);
    setName(e.target.value);
  };

  const onNameBlur = (): void => {
    setNameError(!isValid(name));
  };

  const onDescriptionChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ): void => {
    setDescription(e.target.value);
  };

  const onFormatChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    setFormat(e.target.value as MagicFormat);
  };

  const onIsPublicChange = useCallback(
    (name: string, isChecked: boolean): void => {
      setIsPublic(isChecked);
    },
    []
  );

  const onIsStreamedChange = useCallback(
    (name: string, isChecked: boolean): void => {
      setIsStreamed(isChecked);
    },
    []
  );

  const onLanguageChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    setLanguage(e.target.value);
  };

  const LanguageOptions = Object.values(SpokenLanguages).map(
    ({ tag, label }) => {
      return (
        <option value={tag} key={tag}>
          {label}
        </option>
      );
    }
  );

  const FormatOptions = Object.values(MagicFormat).map((format) => {
    return (
      <option value={format} key={format}>
        {format}
      </option>
    );
  });

  const isValid = (name: string): boolean => {
    return name.trim() !== '';
  };

  const buildTagList = useCallback((): Tags => {
    const tags: Tags = {};

    // Tags are only used if games are public
    if (isPublic) {
      tags.isStreaming = isStreamed;
      tags.language = language;
    }

    return tags;
  }, [isStreamed, language, isPublic]);

  const onSubmit = useCallback(() => {
    const submit = async (): Promise<void> => {
      if (loading) {
        return;
      }
      setNameError(false);

      if (!isValid(name)) {
        setNameError(true);
        return;
      }
      setLoading(true);

      const tags = buildTagList();

      try {
        const wotcToken = await Api.auth.refreshAuth();
        if (user && wotcToken) {
          const game = await Api.games.createGame(
            user.uid,
            wotcToken.access_token,
            {
              name,
              description,
              owner: user.uid,
              host: user.uid,
              format,
              isPublic,
              tags,
            }
          );
          // TODO move this to an api or something
          if (!game) {
            setLoading(false);
            return;
          }
          window.gtag?.('set', {
            gameId: game.id,
          });
          window.gtag?.('event', 'create_game');

          if (isPublic && tags) {
            if (tags.isStreaming) {
              Api.track(IS_STREAMING, false);
            }
            Api.track(GAME_LANGUAGE, false, { language: tags.language });
          }

          history.push(`/game/${game.id}`);
        }
      } catch (e) {
        setLoading(false);
        alert(t('error__something-went-wrong'));
      }
    };
    submit();
  }, [
    loading,
    description,
    name,
    user,
    history,
    isPublic,
    format,
    buildTagList,
    t,
  ]);

  const inputClasses =
    'focus:outline-none select-auto bg-surface-medium placeholder-gray-700 appearance-none w-full focus:border-st-orange-normal block text-white border border-surface-low rounded py-3 px-4 leading-tight focus:outline-none tranision-all ease-in-out duration-200';

  const nameClasses = cx(inputClasses, {
    'border-red-600': nameError,
  });

  const descriptionClasses = cx(inputClasses, 'resize-none h-32');

  const errorEl = nameError ? (
    <div className="absolute right-0 bg-red-600 leading-relaxed px-3 text-white text-xs rounded">
      {t('label__required')}
    </div>
  ) : null;

  const languageSelector = (
    <div className="flex flex-col mb-6">
      <label className="block uppercase tracking-wide text-gray-400 text-xs font-bold mb-2">
        {t('label__language')}
      </label>
      <Select onChange={onLanguageChange} value={language}>
        {LanguageOptions}
      </Select>
      <div className="ml-2 mt-2 text-white text-xs px-2 py-1">
        ({t('label__language-explanation')})
      </div>
    </div>
  );

  const streamingSelector = (
    <div className="mb-6 mt-6">
      <label className="inline-block relative w-full flex items-center">
        <Checkbox
          isChecked={isStreamed}
          name="isStreamed"
          onChange={onIsStreamedChange}
        />
        <div className="text-left text-white leading-tight">
          {t('label__will-be-streamed')}
        </div>
      </label>
    </div>
  );

  return (
    <Modal onClose={onModalClose} style={{ height: '32rem' }}>
      <CustomScrollbars viewClasses="pr-4">
        <div className="text-3xl mb-6 text-white font-thin">
          {t('action__create-game')}
        </div>
        <div className="mb-8">
          <div className="flex flex-col mb-8 relative">
            <label className="block uppercase tracking-wide text-gray-400 text-xs font-bold mb-2">
              {t('label__name')}
            </label>
            <input
              className={nameClasses}
              type="text"
              maxLength={84}
              placeholder={t('label__name')}
              onBlur={onNameBlur}
              onChange={onNameChange}
              ref={nameRef}
              value={name}
            />
            {errorEl}
          </div>{' '}
          <div className="flex flex-col mb-6">
            <label className="block uppercase tracking-wide text-gray-400 text-xs font-bold mb-2">
              {t('label__format')}
            </label>
            <Select onChange={onFormatChange} value={format}>
              {FormatOptions}
            </Select>
          </div>
          <div className="mb-4 mt-6">
            <label className="inline-block relative w-full flex items-center">
              <Checkbox
                isChecked={isPublic}
                name="isPublic"
                onChange={onIsPublicChange}
              />

              <div className="text-left text-white leading-tight">
                {t('label__make-game-public')}
              </div>
            </label>

            <div className="ml-2 mt-2 text-white text-xs px-2 py-1 ">
              ({t('label__public-game-explanation')})
            </div>
          </div>
          {isPublic && (
            <>
              {languageSelector}
              {streamingSelector}
            </>
          )}
          <div className="flex flex-col mb-6">
            <label className="block uppercase tracking-wide text-gray-400 text-xs font-bold mb-2">
              {t('label__description')}
            </label>
            <textarea
              className={descriptionClasses}
              onChange={onDescriptionChange}
              placeholder={t('label__description-placeholder')}
              value={description}
            ></textarea>
          </div>
        </div>
        {loading ? (
          <div className="flex justify-center">{t('label__loading')}</div>
        ) : (
          <div className="flex justify-center pb-2">
            <button
              className="bg-st-purple-light hover:bg-st-purple-normal text-sm text-white py-2 px-3 mx-4 rounded tranision-all ease-in-out duration-200"
              onClick={onSubmit}
            >
              {t('label__create')}
            </button>
            <button
              className="bg-gray-700 hover:bg-gray-600 text-sm text-white py-2 px-3 mx-4 rounded tranision-all ease-in-out duration-200"
              onClick={onModalClose}
            >
              {t('action__cancel')}
            </button>
          </div>
        )}
      </CustomScrollbars>
    </Modal>
  );
};
