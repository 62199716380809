import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Smartphone } from 'react-feather';

import useGameApi from 'hooks/useGameApi';

import { useGameContext } from 'contexts/GameContext';
import useFirebaseUser from 'hooks/useFirebaseUser';
import useQRCode from 'hooks/useQRCode';
import { useRecoilValue } from 'recoil';
import {
  errorMessageState,
  participantsState,
  roomConnectionStatusState,
  userProfileState,
} from 'state/atoms';

import useGame from 'hooks/useGame';
import ClickIdentification from './cards/ClickIdentification';
import { VideoRenderer } from './VideoRenderer';

import LoadingVideo from 'components/game/LoadingVideo';

import { PhaseIndicator } from 'components/player/PhaseIndicator';
import { ConnectionQuality } from 'livekit-client';
import { ConnectionState } from 'types';
import logger from 'utils/logger';

interface StreamProps {
  playerId: string;
  rotate?: boolean;
  searchPosition?: string;
  disableIdentification?: boolean;
  isThumbnail?: boolean;
  isFocused?: boolean;
  playerUserId?: string;
}

const MESSAGE_LOCAL_RECONNECTING = 'message__offline_local_reconnecting';
const MESSAGE_CONNECTING = 'message__connecting-server';

export const Stream: FunctionComponent<StreamProps> = ({
  playerId,
  rotate,
  disableIdentification = false,
  searchPosition = 'left',
  isThumbnail = false,
  isFocused = false,
  playerUserId,
}) => {
  const { t } = useTranslation();
  const videoEl = useRef<HTMLVideoElement>(null);
  const gameApi = useGameApi();
  const { userBlocklist } = useGameContext();
  const user = useFirebaseUser();
  const game = useGame();
  const userProfile = useRecoilValue(userProfileState(playerId));
  const playerBlocklist = userProfile?.blocklist;
  const isPlayerBlocked = user?.uid && playerBlocklist?.includes(user.uid);

  const isBlocked =
    isPlayerBlocked || (playerUserId && userBlocklist?.includes(playerUserId));
  const isLocal = user?.uid === playerUserId;
  const participants = useRecoilValue(participantsState);
  const participant = participants.find((p) => {
    return p.identity === playerUserId;
  });

  const streamErrorMessage = useRecoilValue(errorMessageState);
  const roomConnectionStatus = useRecoilValue(roomConnectionStatusState);
  const [participantOnline, setParticipantOnline] = useState(true);

  const mobileParticipant = participants.find((p) => {
    return (
      p.identity.split('-')[1] === 'mobile' &&
      p.identity.split('-')[0] === playerUserId
    );
  });

  useEffect(() => {
    const update = (status: ConnectionQuality) => {
      if (participantOnline && status === ConnectionQuality.Lost) {
        setParticipantOnline(false);
      } else if (!participantOnline && status !== ConnectionQuality.Lost) {
        setParticipantOnline(true);
      }
    };

    participant?.on('connectionQualityChanged', update);

    return () => {
      participant?.off('connectionQualityChanged', update);
    };
  }, [participant, participantOnline]);

  const { qrcode, isAwaitingMobileCamera, qrCodeUrl } = useQRCode({
    user,
    playerId,
    mobileCameraReady: Boolean(mobileParticipant),
  });

  const kickDisconnectedPlayers = useCallback(
    async (playerId) => {
      if (gameApi) {
        await gameApi.kickPlayer(playerId, true);
      }
    },
    [gameApi]
  );

  const unblockPlayer = useCallback((): void => {
    if (!playerUserId) {
      return;
    }
    if (window.confirm(t('label__confirm-unblock-this-player'))) {
      gameApi?.toggleBlockPlayer(playerUserId);
    }
  }, [playerUserId, gameApi, t]);

  if (isPlayerBlocked) {
    return (
      <div className="text-st-orange-normal text-sm flex flex-col justify-center items-center">
        {t('label__this-user-blocked-you')}
      </div>
    );
  }

  if (isBlocked) {
    return isThumbnail ? (
      <div className="text-st-orange-normal text-sm flex flex-col justify-center items-center">
        {t('label__you-have-blocked-this-user')}
      </div>
    ) : (
      <div className="w-full flex flex-col justify-center items-center p-10 text-gray-600 text-sm text-center max-w-500">
        <div>
          <span className="text-st-orange-normal">
            {t('label__you-have-blocked-this-user')}
          </span>
          <br />
          <Trans i18nKey="label__you-can-unblock-user-here">
            <button
              className="underline select-text cursor-pointer"
              onClick={unblockPlayer}
              role="link"
            ></button>
          </Trans>
        </div>
      </div>
    );
  }

  if (isAwaitingMobileCamera) {
    return isThumbnail ? (
      <div className="text-st-orange-normal text-sm flex flex-col justify-center items-center">
        <Smartphone size="28" className="mb-1" />
        {t('label__waiting-for-mobile-connection')}
      </div>
    ) : (
      <div className="w-full flex flex-col justify-center items-center p-10 text-gray-600 text-sm text-center max-w-500">
        <img className="mb-4" alt="QR Code" src={qrcode} />
        <div>
          <span className="text-st-orange-normal">
            {t('label__waiting-for-camera-connection')}
          </span>
          <br />
          <Trans
            i18nKey="label__scan-qr-code"
            tOptions={{ location: qrCodeUrl }}
          >
            <span className="underline select-text"></span>
          </Trans>
        </div>
      </div>
    );
  }

  if (
    // only show for online player
    roomConnectionStatus === ConnectionState.CONNECTED &&
    // show the boot option for offline remote players only
    !participantOnline &&
    !isLocal
  ) {
    return (
      <ConnectionIssueMessage
        message={t('message__remote-disconnect')}
        actionButtonOnClick={() => kickDisconnectedPlayers(playerId)}
        actionButtonText={t('action__kick-non-host')}
      />
    );
  }

  if (roomConnectionStatus === ConnectionState.DISCONNECTED) {
    return isLocal ? (
      <ConnectionIssueMessage
        message={t('message__offline_local_disconnected')}
      />
    ) : (
      <ConnectionIssueMessage message={t('message__offline')} />
    );
  }

  if (!game) {
    logger.error('Game is undefined in Stream');
    return <></>; // TODO this shouldn't happen
  }

  const cardIdentification = disableIdentification ? null : (
    <ClickIdentification // Otherwise we're using Click Identification
      playerId={playerId}
      isLocal={isLocal}
      rotate={rotate}
      videoEl={videoEl}
      searchPosition={searchPosition}
    />
  );

  const activeStream =
    !isBlocked &&
    !isPlayerBlocked &&
    videoEl &&
    roomConnectionStatus === ConnectionState.CONNECTED;

  const isReconnecting = roomConnectionStatus === ConnectionState.RECONNECTING;
  const loadingMessage = isReconnecting
    ? MESSAGE_LOCAL_RECONNECTING
    : MESSAGE_CONNECTING;

  return (
    <div className="flex relative w-full h-full items-center justify-center">
      {streamErrorMessage ? (
        <div className="relative flex align-center justify-center items-center">
          {t(streamErrorMessage)}
        </div>
      ) : roomConnectionStatus === ConnectionState.CONNECTING ||
        isReconnecting ? (
        <LoadingVideo message={loadingMessage} />
      ) : activeStream && participant ? (
        <>
          <VideoRenderer
            participant={mobileParticipant ? mobileParticipant : participant}
            rotate={!!rotate}
            vid={videoEl}
          />
          {cardIdentification}
          <PhaseIndicator />
        </>
      ) : (
        <div className="flex justify-center items-center">
          {t('error__edge-video')}
        </div>
      )}
    </div>
  );
};

interface ConnectionIssueMessageProps {
  message: string;
  actionButtonOnClick?: () => void;
  actionButtonText?: string;
}
const ConnectionIssueMessage: FunctionComponent<
  ConnectionIssueMessageProps
> = ({ message, actionButtonOnClick, actionButtonText }) => (
  <div className="flex flex-row relative w-full h-full text-center items-center justify-center">
    <p>
      {message}
      {actionButtonOnClick && actionButtonText ? (
        <button
          className="underline select-text cursor-pointer text-st-orange-normal ml-1"
          role="link"
          onClick={actionButtonOnClick}
        >
          {actionButtonText}
        </button>
      ) : null}
    </p>
  </div>
);
