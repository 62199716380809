export const getRealVideoSize = (
  element: HTMLVideoElement,
  clientX: number = 0,
  clientY: number = 0
): {
  width: number;
  height: number;
  diffX: number;
  diffY: number;
  x: number;
  y: number;
  ratio: number;
} => {
  const rect = element.getBoundingClientRect();
  // Ratio of the video's intrinsic dimensions
  const videoRatio = element.videoWidth / element.videoHeight;
  // The width and height of the video element
  let width = rect.width;
  let height = rect.height;
  let ratio = 1;
  // The ratio of the element's width to its height
  const elementRatio = width / height;
  // If the video element is short and wide
  if (elementRatio > videoRatio) {
    width = height * videoRatio;
    ratio = height / element.videoHeight;
  } else {
    height = width / videoRatio;
    ratio = width / element.videoWidth;
  }
  const diffY = rect.height > height ? (rect.height - height) / 2 : 0;
  const diffX = rect.width > width ? (rect.width - width) / 2 : 0;
  const x = clientX - rect.left - diffX;
  const y = clientY - rect.top - diffY;
  return { width, height, diffX, diffY, x, y, ratio };
};
