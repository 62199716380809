import * as THREE from 'three';
import { DiceShape } from 'types/dice';
import GeneralLights from './GeneralLights';

type WidthHeight = { width: number; height: number };
/**
 * Much of the source code and inspiration can be attributed to this repo:
 * https://github.com/BenDMyers/DiceRoller
 */
export default (canvas: HTMLCanvasElement, shape: DiceShape, color: string) => {
  const clock = new THREE.Clock();
  const origin = new THREE.Vector3(0, 0, 0);

  const screenDimensions = { width: canvas.width, height: canvas.height };

  const mousePosition = { x: 0, y: 0 };

  const scene = new THREE.Scene();
  const renderer = buildRender(screenDimensions);
  const camera = buildCamera(screenDimensions);
  const sceneSubjects = createSceneSubjects(scene);

  function buildRender({ width, height }: WidthHeight) {
    const renderer = new THREE.WebGLRenderer({
      canvas,
      alpha: true,
      antialias: true,
    });
    const devicePixelRatio = window.devicePixelRatio
      ? window.devicePixelRatio
      : 1;
    renderer.setPixelRatio(devicePixelRatio);
    renderer.setSize(width, height);

    return renderer;
  }

  function buildCamera({ width, height }: WidthHeight) {
    const aspectRatio = width / height;
    const fieldOfView = 60;
    const nearPlane = 4;
    const farPlane = 100;
    const camera = new THREE.PerspectiveCamera(
      fieldOfView,
      aspectRatio,
      nearPlane,
      farPlane
    );
    camera.position.z = 40;
    return camera;
  }

  function createSceneSubjects(scene: THREE.Scene) {
    const newShape = shape(scene, 15, color);

    const sceneSubjects = [GeneralLights(scene), newShape];
    return sceneSubjects;
  }

  function update() {
    const elapsedTime = clock.getElapsedTime();

    sceneSubjects.forEach((subject) => {
      subject.update(elapsedTime);
    });

    updateCameraPositionRelativeToMouse();
    renderer.render(scene, camera);
  }

  function updateCameraPositionRelativeToMouse() {
    camera.position.x += (mousePosition.x * 0.01 - camera.position.x) * 0.01;
    camera.position.y += (-(mousePosition.y * 0.01) - camera.position.y) * 0.01;
    camera.lookAt(origin);
  }

  function onWindowResize() {
    const { width, height } = canvas;

    screenDimensions.width = width;
    screenDimensions.height = height;

    camera.aspect = width / height;
    camera.updateProjectionMatrix();

    renderer.setSize(width, height);
  }

  function onMouseMove(x: number, y: number) {
    mousePosition.x = x;
    mousePosition.y = y;
  }

  return { update, onWindowResize, onMouseMove };
};
