import { useEffect } from 'react';
import { isMac } from 'utils/isMac';

export enum EventTypes {
  keyup = 'keyup',
  keydown = 'keydown',
}

type Modifiers = {
  requireCtrl?: boolean;
  requireMeta?: boolean;
  requireShift?: boolean;
  skipInput?: boolean;
};

export default function useGlobalShortcut(
  event: EventTypes,
  key: number,
  callback: (e: KeyboardEvent) => void,
  modifiers?: Modifiers
) {
  const knownEventType = Object.values(EventTypes).includes(event);

  if (!knownEventType) {
    console.error(
      `Unknown shortcut event type "${event}" passed to useGlobalShortcut.`
    );
  }

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      const { keyCode, repeat } = e;
      const isOnMac = isMac();
      if (
        e?.target instanceof Element &&
        (e?.target?.tagName === 'INPUT' || e?.target.tagName === 'TEXTAREA') &&
        !modifiers?.skipInput
      ) {
        return;
      }

      if (!isOnMac && modifiers?.requireMeta && !e.ctrlKey) {
        return;
      }

      if (modifiers?.requireCtrl && !e.ctrlKey) {
        return;
      }

      if (isOnMac && modifiers?.requireMeta && !e.metaKey) {
        return;
      }

      if (modifiers?.requireShift && !e.shiftKey) {
        return;
      }
      if (keyCode === key && callback && !repeat) {
        e.preventDefault();
        callback(e);
      }
    };

    if (knownEventType) {
      window.addEventListener(event, handler);
    }

    return () => {
      if (knownEventType) {
        window.removeEventListener(event, handler);
      }
    };
  }, [event, key, knownEventType, callback, modifiers]);
}
