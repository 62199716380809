import React, { FunctionComponent, useEffect } from 'react';
import { ModalProvider } from 'react-modal-hook';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import './i18n';

import { useAuth } from 'hooks/useAuth';

import { Health } from 'routes/Health';
import { Logout } from 'routes/Logout';
import { PrivateRoute } from 'routes/PrivateRoute';

import Api from 'api';
import { CookieBanner } from 'components/CookieBanner';
import { LoadingIcon } from 'components/icons';
import { LOGIN } from 'constants/bi-names';
import { Cookies } from 'pages/cookies/Cookies';
import { FAQ } from 'pages/faq/FAQ';
import { Game } from 'pages/game/Game';
import { Home } from 'pages/home/Home';
import { Lobby } from 'pages/lobby/Lobby';
import { MobileHelp } from 'pages/mobile-help/MobileHelp';
import { Troubleshooting } from 'pages/troubleshooting/Troubleshooting';
import { useTranslation } from 'react-i18next';

if (process.env.REACT_APP_SPELLTABLE_VERSION) {
  console.log('SpellTable version:', process.env.REACT_APP_SPELLTABLE_VERSION);
}

const App: FunctionComponent = () => {
  const { authAttempted } = useAuth();
  const { t } = useTranslation();
  useEffect(() => {
    //eslint-disable-next-line no-restricted-globals
    const searchParams = new URLSearchParams(location.search.toLowerCase());
    if (searchParams.has('login') && searchParams.get('login') === 'true') {
      Api.track(LOGIN, false);
    }
  }, []);
  return (
    <>
      <CookieBanner />

      <ModalProvider>
        <div className="h-full bg-surface-low">
          {
            /**
             * We wait here for firebase to perform its initial auto login attempt
             * prior to rendering anything. Without this we see a flash of the login
             * page prior to Firebase logging you in and redirecting into the app.
             */
            !!authAttempted ? (
              <Router basename={process.env.PUBLIC_URL}>
                <Switch>
                  <Route exact path="/" component={Home} />
                  <Route exact path="/faq" component={FAQ} />
                  <Route exact path="/mobile-help" component={MobileHelp} />
                  <Route
                    exact
                    path="/troubleshooting"
                    component={Troubleshooting}
                  />
                  <Route exact path="/cookies" component={Cookies} />
                  <PrivateRoute path="/lobby" component={Lobby} />
                  <PrivateRoute path="/game/:id" component={Game} />
                  <Route path="/logout" component={Logout} />
                  <Route path="/health" component={Health} />
                  <Route render={() => <Redirect to="/" />} />
                </Switch>
              </Router>
            ) : (
              <div className="mx-auto w-full flex container justify-center mt-32">
                <div className="flex flex-col items-center">
                  <LoadingIcon size="30" />
                  <div className="pt-10">{t('label__loading')}</div>
                </div>
              </div>
            )
          }
        </div>
        <div id="modal-container"></div>
      </ModalProvider>
    </>
  );
};

export default App;
