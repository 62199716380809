import { BOX_SIZE } from 'constants/dice';
import React, { useEffect, useState } from 'react';
import { DiceShape, DiceSides } from 'types/dice';
import ThreeContainer from './ThreeContainer';

type Props = { sides: DiceSides; shape: DiceShape; color: string };

export function DiceBox(props: Props) {
  const [width, setWidth] = useState<number>(BOX_SIZE);
  const [height, setHeight] = useState<number>(BOX_SIZE);

  useEffect(() => {
    window.addEventListener('resize', () => {
      setWidth(BOX_SIZE);
      setHeight(BOX_SIZE);
    });
    return () => {
      window.removeEventListener('resize', () => {
        setWidth(BOX_SIZE);
        setHeight(BOX_SIZE);
      });
    };
  });

  return (
    // eslint-disable-next-line
    <a
      style={{ width, height }}
      className="cursor-default flex flex-row justify-center items-center"
    >
      <div className="flex flex-row justify-center items-center">
        <ThreeContainer {...props} />
      </div>
    </a>
  );
}
