import React, { FunctionComponent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { AnyCard } from 'types';

import useGameApi from 'hooks/useGameApi';
import { useHover } from 'use-events';

import { MoreHorizontal, Trash } from 'react-feather';
import {
  MenuItemTypes,
  CustomMenu,
  MenuItems,
} from 'components/menus/CustomMenu';
import { CardHoverPopover } from 'components/CardHoverPopover';

interface DrawerCard {
  card: AnyCard;
  hoverDisabled?: boolean;
  name: string;
  imageUrl?: string;
  hoverImages: string[];
  backImageUrl?: string;
  tcgPlayerBtn?: JSX.Element;
  menuItems?: MenuItems;
}

export const DrawerCard: FunctionComponent<DrawerCard> = (props) => {
  const {
    card,
    hoverDisabled = false,
    name,
    imageUrl,
    hoverImages,
    tcgPlayerBtn,
    menuItems = [],
  } = props;

  const { t } = useTranslation();

  const gameApi = useGameApi();

  const [hovered, hoverBindings] = useHover();

  const removeCard = useCallback((): void => {
    window.gtag?.('event', 'card_removed');
    gameApi?.removeCardSpotted(card);
  }, [gameApi, card]);

  const removeMenuItem = {
    type: MenuItemTypes.ITEM,
    label: t('label__remove-card'),
    onClick: removeCard,
  };

  const fullMenuItems = menuItems.length
    ? [...menuItems, { type: MenuItemTypes.DIVIDER }, removeMenuItem]
    : [removeMenuItem];

  const classes = cx(
    'relative bg-no-repeat bg-contain bg-gray-gray-950 w-card-md h-card-md md:w-card-lg md:h-card-lg z-30'
  );

  const removeBtnClasses = cx(
    'flex items-center rounded-sm cursor-pointer text-white hover:text-gray-950 hover:bg-white shadow-md bg-st-purple-light p-1 transition-all duration-200 ease-in-out',
    {
      'opacity-100': hovered,
      'opacity-0': !hovered,
    }
  );

  const imageEl = imageUrl ? (
    <img alt={name} src={imageUrl} className="rounded-5px md:rounded-12px" />
  ) : null;

  return (
    <CardHoverPopover
      imageUrl={hoverImages[0]}
      backImageUrl={hoverImages[1]}
      disabled={hoverDisabled}
      placement="left"
    >
      <div className="w-full flex justify-center" {...hoverBindings}>
        <div className={classes}>
          {imageEl}
          <div className="absolute top-card-actions-sm md:top-card-actions-lg right-0 flex flex-col justify-start items-end transition-opacity duration-200 ease-in-out w-full h-full">
            <div className="flex flex-row justify-end px-1 py-1 md:px-3">
              {tcgPlayerBtn}
              <CustomMenu
                placement="bottomRight"
                tight
                menuItems={fullMenuItems}
                widthClass="w-auto"
                paddingClass="py-1"
              >
                <div className="flex items-center rounded-sm cursor-pointer text-white hover:text-gray-950 hover:bg-white shadow-md bg-st-purple-light p-1 transition-all duration-200 ease-in-out">
                  <MoreHorizontal size="14" />
                </div>
              </CustomMenu>
            </div>
            <div className="px-1 md:px-3">
              <div className={removeBtnClasses} onClick={removeCard}>
                <Trash size="14" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </CardHoverPopover>
  );
};
