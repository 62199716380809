import React, { FunctionComponent, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import keycodes from 'constants/keycodes';

import { ChevronLeft, ChevronRight, Clock, Layers } from 'react-feather';
import useGlobalShortcut, { EventTypes } from 'hooks/useGlobalShortcut';
import { CardDrawer } from 'components/card-drawer/CardDrawer';
import { GameLog } from 'components/gamelog/GameLog';

enum SUBSECTION {
  CARDS,
  LOG,
}

export const RightGameBar: FunctionComponent = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);
  const [subSection, setSubSection] = useState<SUBSECTION>(SUBSECTION.CARDS);

  const onOpen = (): void => {
    setOpen(!open);
  };

  const toggleDrawerOpen = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const setSection = (section: SUBSECTION) => () => {
    setSubSection(section);
  };
  useGlobalShortcut(EventTypes.keydown, keycodes.d, toggleDrawerOpen, {
    requireMeta: true,
  });

  const arrow = open ? <ChevronRight size="20" /> : <ChevronLeft size="20" />;

  const containerClasses = cx(
    'h-full flex flex-col overflow-hidden bg-surface-medium transition-all ease-in-out duration-200',
    {
      'w-drawer-md-full md:w-drawer-lg-full': open,
      'w-drawer-bar': !open,
    }
  );

  const cardsSelected = subSection === SUBSECTION.CARDS;
  const logSelected = subSection === SUBSECTION.LOG;
  const cardBtnClass = `${
    cardsSelected ? 'text-st-orange-normal' : ''
  } flex flex-row flex-grow justify-center`;
  const logBtnClass = `${
    logSelected ? 'text-st-orange-normal' : ''
  } flex flex-row flex-grow justify-left`;

  return (
    <div className={containerClasses}>
      <div className="flex items-center h-full relative">
        <div
          className="h-full flex flex-col px-2 justify-center items-center text-st-orange-normal hover:text-white transition-all ease-in-out duration-200 cursor-pointer"
          onClick={onOpen}
          aria-label={t('label__toggle-card-drawer')}
        >
          {arrow}
        </div>
        <div className="absolute inset-y-0 flex flex-col justify-start items-center h-full py-2 left-drawer-w w-drawer-md md:w-drawer-lg">
          <div className="mt-4 mb-4 flex phone:flex-col md:flex-row items-stretch w-full">
            <button
              onClick={setSection(SUBSECTION.CARDS)}
              className={cardBtnClass}
            >
              <Layers className="mr-1" /> {t('label__cards')}
            </button>
            <button
              onClick={setSection(SUBSECTION.LOG)}
              className={logBtnClass}
            >
              <Clock className="mr-1" /> {t('label__game-log')}
            </button>
          </div>
          {cardsSelected && <CardDrawer isOpen={open} setOpen={setOpen} />}
          {logSelected && <GameLog />}
        </div>
      </div>
    </div>
  );
};
