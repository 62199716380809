import Api from 'api';
import { HDConstraints, VideoResolution } from 'types';
import logger from './logger';
import { getConstraints, MAX_DIMENSION } from './media';

const DEFAULT_ERROR_STRING = 'error__problem-changing-camera';

type StreamBaseData = {
  constraints: HDConstraints;
  videoTrack?: MediaStreamTrack;
  videoResolution?: VideoResolution;
};

type StreamData = StreamBaseData & {
  stream: MediaStream;
  error?: never;
};

type StreamDataError = StreamBaseData & {
  error: string;
  stream?: never;
};

export async function getVideoStream(
  customAspectRatio: number,
  deviceId?: string,
  currentStream?: MediaStream
): Promise<StreamData | StreamDataError> {
  const constraints = getConstraints();
  let localStream: MediaStream | undefined;
  let videoTrack: MediaStreamTrack | undefined;
  let error: string | undefined;
  let videoResolution: VideoResolution | undefined;

  try {
    if (currentStream) {
      // we have a current stream, we should stopp all tracks before moving carrying on
      currentStream.getTracks().forEach((track) => {
        track.stop();
        currentStream.removeTrack(track);
      });
    }

    const videoDeviceIds = (await navigator.mediaDevices.enumerateDevices())
      .filter((device) => device.kind === 'videoinput')
      .map((device) => device.deviceId);

    const videoId = deviceId
      ? deviceId
      : await Api.getUserPreferences()?.gameAudioVideo?.videoDeviceID;

    const videoDeviceId =
      videoId && videoDeviceIds.includes(videoId) ? videoId : videoDeviceIds[0];

    const videoConstraints = {
      deviceId: videoDeviceId,
      ...constraints.video,
    };

    if (customAspectRatio > 0) {
      // remove resized mode limit as it will cause issues when combined with aspectRatio
      if (videoConstraints.resizeMode) {
        videoConstraints.resizeMode = undefined;
      }
      videoConstraints.height = Math.floor(MAX_DIMENSION / customAspectRatio);
      videoConstraints.width = MAX_DIMENSION;
    }

    localStream = await navigator.mediaDevices.getUserMedia({
      audio: constraints.audio,
      video: videoConstraints,
    });

    videoTrack = localStream.getVideoTracks()[0];

    videoResolution = getMaxResolutionFromTrack(videoTrack);
  } catch (e) {
    logger.error('Error getting user media:', e as Error);
    error = localizationKeyFromError(e as Error);
  }

  if (!localStream || error) {
    error = error ?? DEFAULT_ERROR_STRING;
    return { constraints, videoTrack, error };
  } else {
    return { stream: localStream, constraints, videoTrack, videoResolution };
  }
}

export function localizationKeyFromError(error: Error): string {
  let result = DEFAULT_ERROR_STRING;

  if (error.name.includes('NotAllowed')) {
    result = 'error__problem-permissions-denied';
  }

  if (error.name.includes('Overconstrained')) {
    result = 'error__problem-overconstrained';
  }

  if (error.name.includes('NotReadable')) {
    result = 'error__problem-not-readable';
  }

  return result;
}

const getMaxResolutionFromTrack = (
  videoTrack: MediaStreamTrack
): VideoResolution | undefined => {
  const { height, width } = videoTrack.getSettings() ?? {};
  if (!height || !width) {
    return undefined;
  }

  if (height < MAX_DIMENSION && width < MAX_DIMENSION) {
    return { height, width };
  }

  const isLandscape = width > height;
  const ratio = isLandscape ? width / MAX_DIMENSION : height / MAX_DIMENSION;

  return {
    height: isLandscape ? Math.floor(height / ratio) : MAX_DIMENSION,
    width: isLandscape ? MAX_DIMENSION : Math.floor(width / ratio),
  };
};
