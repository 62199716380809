export enum Colors {
  White = 'W',
  Blue = 'U',
  Black = 'B',
  Red = 'R',
  Green = 'G',
}

export interface ImageUris {
  art_crop: string;
  border_crop: string;
  large: string;
  normal: string;
  png: string;
  small: string;
}

export interface RelatedUris {
  edhrec: string | null;
  gatherer: string | null;
  mtgtop8: string | null;
  tcgplayer_decks: string | null;
}

export interface Prices {
  eur: string | null;
  tix: string | null;
  usd: string | null;
  usd_foil: string | null;
}

export interface CardFace {
  name: string;
  artist?: string;
  color_indicator?: Colors[];
  colors?: Colors[];
  flavor_text?: string;
  illustration_id?: string;
  image_uris?: ImageUris;
  loyalty?: string;
  mana_cost: string;
  object: string;
  oracle_text?: string;
  power?: string;
  printed_name?: string;
  printed_text?: string;
  printed_type_line?: string;
  toughness?: string;
  type_line: string;
  watermark?: string;
}

export interface ScryfallCard {
  id: string;
  multiverse_ids?: number[];
  tcgplayer_id?: number;
  card_faces?: CardFace[];
  collector_number: string;
  color_identity: Colors[];
  image_uris?: ImageUris;
  name: string;
  printed_name?: string;
  prices: Prices;
  related_uris: RelatedUris;
  scryfall_uri: string;
  set: string;
  set_name: string;
  // object is either a card or error
  object?: string;
  status?: number | string;
  lang?: string;
}
