import { SpokenLanguages } from 'constants/languages';

export interface SortTag {
  name: string;
  sort: number;
}

export const StreamedTag: SortTag = {
  name: 'isStreaming',
  sort: 5,
};

export const LanguageTag: SortTag = {
  name: 'language',
  sort: 0,
};

export const NotFoundTag: SortTag = {
  name: 'notfound',
  sort: -1,
};

export function getTagFromName(name: string): SortTag {
  if (name === StreamedTag.name) return StreamedTag;
  else if (name === LanguageTag.name) return LanguageTag;
  else return NotFoundTag;
}

export function getLanguageTag(lang: string): string {
  const matched = SpokenLanguages.find((x) => x.matches.includes(lang));
  // We default to Other if there is no match
  if (!matched) return SpokenLanguages[SpokenLanguages.length - 1].tag;
  return matched.tag;
}
