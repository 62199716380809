import React, { useState } from 'react';

import { Check, Clipboard } from 'react-feather';

export default function useCopyIcon(url: string): JSX.Element {
  const [copied, setCopied] = useState(false);

  const copyUrl = (): void => {
    const el = document.createElement('textarea');
    el.value = url;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    setCopied(true);
  };

  const copyIcon = copied ? (
    <Check size="18" className="ml-2 text-st-orange-normal" />
  ) : (
    <Clipboard
      size="18"
      className="ml-2 cursor-pointer hover:text-gray-400 transition-all duration-300 ease-in-out"
      onClick={copyUrl}
    />
  );

  return copyIcon;
}
