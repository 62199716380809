import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import * as serviceWorker from './serviceWorker';

// this will be compiled.
import { ErrorBoundary, Provider } from '@rollbar/react';
import { RecoilRoot } from 'recoil';

import logger from 'utils/logger';
import './styles/tailwind.css';

ReactDOM.render(
  <RecoilRoot>
    <Provider instance={logger}>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </Provider>
  </RecoilRoot>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
