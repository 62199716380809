import firebase from 'api/firebase';

export type GamePlayer = string;
export type GamePlayerSlot = GamePlayer | null;

export type GamePlayers = [
  GamePlayerSlot,
  GamePlayerSlot,
  GamePlayerSlot,
  GamePlayerSlot
];

export enum MagicFormat {
  Commander = 'Commander',
  Standard = 'Standard',
  Sealed = 'Sealed',
  Modern = 'Modern',
  Vintage = 'Vintage',
  Legacy = 'Legacy',
  Brawl2 = 'Brawl Two Player',
  Brawl = 'Brawl Multiplayer',
  TwoHeadedGiant = 'Two Headed Giant',
  Pauper = 'Pauper',
  PauperEdh = 'Pauper EDH',
  Pioneer = 'Pioneer',
  Oathbreaker = 'Oathbreaker',
}

export const DEFAULT_COMMANDER_LETHAL = 21;

type CommanderFormat = {
  startingLife: number;
  hasCommander: boolean;
  commanderLethal?: number;
};

export const FORMAT_INFO: Record<MagicFormat, CommanderFormat> = {
  [MagicFormat.Commander]: {
    startingLife: 40,
    hasCommander: true,
  },
  [MagicFormat.Standard]: {
    startingLife: 20,
    hasCommander: false,
  },
  [MagicFormat.Sealed]: {
    startingLife: 20,
    hasCommander: false,
  },
  [MagicFormat.Modern]: {
    startingLife: 20,
    hasCommander: false,
  },
  [MagicFormat.Vintage]: {
    startingLife: 20,
    hasCommander: false,
  },
  [MagicFormat.Legacy]: {
    startingLife: 20,
    hasCommander: false,
  },
  [MagicFormat.Brawl2]: {
    startingLife: 25,
    hasCommander: true,
  },
  [MagicFormat.Brawl]: {
    startingLife: 30,
    hasCommander: true,
  },
  [MagicFormat.Pioneer]: {
    startingLife: 20,
    hasCommander: false,
  },
  [MagicFormat.TwoHeadedGiant]: {
    startingLife: 30,
    hasCommander: false,
  },
  [MagicFormat.Pauper]: {
    startingLife: 20,
    hasCommander: false,
  },
  [MagicFormat.PauperEdh]: {
    startingLife: 30,
    hasCommander: true,
    commanderLethal: 16,
  },
  [MagicFormat.Oathbreaker]: {
    startingLife: 20,
    hasCommander: true,
  },
};

export const MULTIPLAYER_FORMATS = [
  MagicFormat.Commander,
  MagicFormat.TwoHeadedGiant,
  MagicFormat.Brawl,
  MagicFormat.Oathbreaker,
  MagicFormat.PauperEdh,
];

export function isMultiplayer(format: MagicFormat = MagicFormat.Commander) {
  return MULTIPLAYER_FORMATS.includes(format);
}

export function getMaxPlayers(format: MagicFormat = MagicFormat.Commander) {
  return isMultiplayer(format) ? 4 : 2;
}

export type Tags = {
  language?: String;
  isStreaming?: Boolean;
};
export interface Game {
  id: string;
  activePlayer: string | null;
  name: string;
  owner: string;
  created: firebase.firestore.Timestamp;
  lastUpdated: firebase.firestore.Timestamp;
  description: string | null;
  host: string | null;
  turnStart: firebase.firestore.Timestamp | null;
  players: GamePlayers;
  format?: MagicFormat;
  isPublic?: boolean;
  tags?: Tags;
  phase?: number;
  isUsingPhases?: boolean;
}

export enum Phases {
  Begin = 'label__begin-phase',
  MainPhase = 'label__main-phase',
  Combat = 'label__combat-phase',
  MainPhaseAgain = 'label__main-phase-two',
  End = 'label__end-phase',
}
