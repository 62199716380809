import * as THREE from 'three';
import { ThreeGeometry } from 'types/dice';

/**
 * Much of the source code and inspiration can be attributed to this repo:
 * https://github.com/BenDMyers/DiceRoller
 */
export function baseDice(
  geometry: ThreeGeometry,
  scene: THREE.Scene,
  materialColor: string
) {
  let speed = 7;

  const animationSlowdown = setInterval(() => {
    if (speed < 1.0) {
      clearInterval(animationSlowdown);
    }
    speed -= 0.01;
  }, 10);

  const group = new THREE.Group();

  const material = new THREE.MeshLambertMaterial({
    color: materialColor,
    side: THREE.DoubleSide,
    opacity: 0,
    transparent: true,
  });
  const mesh = new THREE.Mesh(geometry, material);

  const wireframe = new THREE.LineSegments(
    //@ts-ignore
    new THREE.EdgesGeometry(geometry),
    new THREE.LineBasicMaterial()
  );

  group.add(mesh);
  group.add(wireframe);

  scene.add(group);

  function update(time: number) {
    const angle = time * speed;
    group.rotation.z = -angle;
    group.rotation.x = angle;
  }

  return { group, update };
}
