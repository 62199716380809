import React, {
  FunctionComponent,
  useState,
  useRef,
  useCallback,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';

import Api from 'api';

import useFirebaseUser from 'hooks/useFirebaseUser';

import { Modal } from 'components/ui/Modal';
import { LoadingIcon } from './icons';
import { Select } from './ui/Select';
import { LocalizedLanguages } from 'constants/languages';
import { USER_LANGUAGE } from 'constants/bi-names';

interface UpdateProfileModalProps {
  onModalClose: () => any;
}

export const UpdateProfileModal: FunctionComponent<UpdateProfileModalProps> = (
  props
) => {
  const { onModalClose } = props;
  const { t, i18n } = useTranslation();

  const user = useFirebaseUser();

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [photoURL, setPhotoURL] = useState(user?.photoURL);
  const [pronouns, setPronouns] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [language, setLanguage] = useState(i18n.language || 'en');

  useEffect(() => {
    const fetchPrefs = async (): Promise<void> => {
      const preferences = await Api.getUserPreferences();
      setPronouns(preferences?.pronouns || '');
      setIsLoading(false);
    };
    fetchPrefs();
  }, []);

  const onPronounsChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setPronouns(e.target.value);
  };

  const onSave = useCallback((): void => {
    const save = async () => {
      i18n.changeLanguage(language);
      if (!pronouns || pronouns.trim() === '') {
        setPronouns('');
      }
      Api.track(USER_LANGUAGE, false, { language });
      const pns = pronouns.length > 28 ? pronouns.substr(0, 28) : pronouns;
      await Api.setUserPreferences({ pronouns: pns, language });
      onModalClose();
    };
    setIsLoading(true);
    save();
  }, [pronouns, language, i18n, onModalClose]);

  const openFileDialog = (): void => {
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const onFileChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    const files = e.target.files;
    if (fileInputRef && fileInputRef.current && files) {
      const newPhotoURL = await Api.updatePhoto(files[0]);
      setPhotoURL(newPhotoURL);
    }
  };

  const onLanguageChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    setLanguage(e.target.value);
  };

  const LanguageOptions = Object.values(LocalizedLanguages).map(
    ({ lang, label }) => {
      return (
        <option value={lang} key={lang}>
          {label}
        </option>
      );
    }
  );

  if (isLoading) {
    return (
      <Modal onClose={onModalClose}>
        <div className="flex justify-center">
          <div className="w-32 h-32 relative">
            <LoadingIcon />
          </div>
        </div>
      </Modal>
    );
  }

  return (
    <Modal onClose={onModalClose}>
      <div className="flex justify-center">
        <div className="w-32 h-32 relative">
          <div
            className="absolute top-0 left-0 rounded-full h-full w-full opacity-0 bg-black-50 hover:opacity-100 flex justify-center items-center cursor-pointer tranision-all ease-in-out duration-200"
            onClick={openFileDialog}
          >
            <span className="text-white w-1/2 text-center">
              {t('label__change-photo')}
            </span>
          </div>
          <div
            className="rounded-full h-full w-full bg-contain bg-surface-medium border-8 border-surface-border"
            style={{ backgroundImage: `url(${photoURL})` }}
          ></div>
        </div>
        <input
          ref={fileInputRef}
          onChange={onFileChange}
          type="file"
          className="hidden"
        />
      </div>

      <div className="pt-8">
        <div>
          <label
            className="block uppercase tracking-wide text-gray-400 text-xs font-bold mb-2"
            htmlFor="language"
          >
            {t('label__language')}
          </label>
          <Select onChange={onLanguageChange} value={language}>
            {LanguageOptions}
          </Select>
        </div>
        <div className="pt-8 flex flex-col">
          <label
            className="block uppercase tracking-wide text-gray-400 text-xs font-bold mb-2"
            htmlFor="pronouns"
          >
            {t('label__pronouns')}
          </label>
          <input
            className="focus:outline-none select-auto bg-surface-medium appearance-none w-full focus:border-st-orange-normal block text-white border border-surface-low rounded py-3 px-4 leading-tight focus:outline-none tranision-all ease-in-out duration-200"
            type="text"
            id="pronouns"
            placeholder={t('label__pronouns-placeholder')}
            onChange={onPronounsChange}
            value={pronouns}
            maxLength={28}
          />
        </div>
      </div>
      <div className="pt-8 text-center">
        <button
          className="bg-st-purple-light hover:bg-st-purple-normal text-sm text-white py-2 px-3 rounded transision-colors ease-in-out duration-200"
          onClick={onSave}
        >
          {t('label__save')}
        </button>
      </div>
    </Modal>
  );
};
