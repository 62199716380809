import { LoadingIcon } from 'components/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = { message: string; isLoading?: boolean };

const LoadingVideo = ({ message, isLoading = true }: Props) => {
  const { t } = useTranslation();
  return (
    <div className="flex w-full h-full flex-row justify-center items-center">
      {isLoading && <LoadingIcon />}
      <p className="ml-2">{t(message)}</p>
    </div>
  );
};

export default LoadingVideo;
