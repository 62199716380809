import React, { FunctionComponent } from 'react';

import ToolTip from 'rc-tooltip';

interface CardHoverPopoverProps {
  children: any;
  placement?: string;
  imageUrl?: string;
  backImageUrl?: string;
  disabled?: boolean;
}

export const CardHoverPopover: FunctionComponent<CardHoverPopoverProps> = (
  props
) => {
  const {
    children,
    placement = 'top',
    imageUrl,
    backImageUrl,
    disabled = false,
  } = props;

  const getPopupContainer = () => {
    return document.getElementById('popover-container');
  };

  if (!imageUrl && !backImageUrl) {
    return null;
  }

  const frontImage = (
    <div
      className="w-64 bg-surface-low bg-center bg-no-repeat bg-cover"
      style={{
        backgroundImage: `url(${imageUrl})`,
        borderRadius: '10px',
        width: '311px',
        height: '443px',
      }}
    ></div>
  );

  const backImage = backImageUrl ? (
    <div
      className="w-64 bg-surface-low bg-center bg-no-repeat bg-cover"
      style={{
        backgroundImage: `url(${backImageUrl})`,
        borderRadius: '10px',
        width: '311px',
        height: '443px',
      }}
    ></div>
  ) : null;

  const overlay = (
    <span className="flex m-4">
      {frontImage}
      {backImage}
    </span>
  );

  if (disabled || (!frontImage && !backImage)) {
    return children;
  }

  return (
    <ToolTip
      // @ts-ignore
      mouseLeaveDelay="0"
      overlayClassName="z-100 inline-block"
      getPopupContainer={getPopupContainer}
      placement={placement}
      trigger={['hover']}
      overlay={overlay}
      destroyTooltipOnHide
    >
      {children}
    </ToolTip>
  );
};
