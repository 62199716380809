import React, { FunctionComponent } from 'react';

import ToolTip from 'rc-tooltip';

/**
 * HOC to add a tooltip to a component.
 * Uses rc-tooltip: https://github.com/react-component/tooltip
 */

interface CustomTooltipProps {
  children: any;
  delay?: number;
  tooltip: string;
  placement?: string;
  overlayClassname?: string;
  style?: any;
}

export const CustomTooltip: FunctionComponent<CustomTooltipProps> = (props) => {
  const {
    children,
    delay = 0.5,
    tooltip,
    placement = 'right',
    overlayClassname,
    style,
  } = props;

  const getPopupContainer = (): HTMLElement | null => {
    return document.getElementById('tooltip-container');
  };

  const overlay = (
    <div
      style={style}
      className={`block p-2 ${overlayClassname ? overlayClassname : ''}`}
    >
      <span className="bg-black p-2 rounded opacity-75">
        <span className="text-white text-sm font-thin leading-loose">
          {tooltip}
        </span>
      </span>
    </div>
  );

  return (
    <ToolTip
      overlayClassName="z-50 inline-block"
      // @ts-ignore
      getPopupContainer={getPopupContainer}
      placement={placement}
      trigger={['hover']}
      overlay={overlay}
      mouseEnterDelay={delay}
      destroyTooltipOnHide
    >
      {children}
    </ToolTip>
  );
};
