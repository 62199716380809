/* eslint-disable jsx-a11y/anchor-has-content */
import React, { FC, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { X } from 'react-feather';

// import usePreferredLanguage from 'hooks/usePreferredLanguage';

const LOCAL_STORAGE_KEY = 'livekit-two';

export const AnnouncementHeader: FC = () => {
  const [hide, setHide] = useState<boolean>(
    window.localStorage.getItem(LOCAL_STORAGE_KEY) === 'true'
  );
  const { t } = useTranslation();
  // Date.now() throws a type error
  const isNotShown =
    new Date() > new Date('01/24/2024') || new Date() < new Date('01/17/2024');
  // Commenting out for easy reuseability
  // const language = usePreferredLanguage();
  const onDismiss = (): void => {
    window.localStorage.setItem(LOCAL_STORAGE_KEY, 'true');
    setHide(true);
  };

  if (hide) {
    return null;
  }
  // Commenting out for easy reuseability
  // const convertLang = () => {
  //   if (language === 'en-US') {
  //     return 'en';
  //   }
  //   if (language === 'zh-CN') {
  //     return 'zh-Hans';
  //   }
  //   if (language === 'zh-TW') {
  //     return 'zh-Hant';
  //   }
  //   if (language === 'pt') {
  //     return 'pt-br';
  //   }
  //   return language;
  // };

  return (
    <>
      {!isNotShown ? (
        <div className="flex justify-center items-center text-center bg-st-orange-normal py-2 text-sm font-thin">
          <div className="px-2  text-surface-high">
            <Trans i18nKey="label__livekit-two">
              {/* Commenting out for easy reuseability */}
              {/* <a
                className="font-bold"
                href={`https://company.wizards.com/${convertLang()}/legal/wizards-coasts-privacy-policy`}
                target="_blank"
                rel="noopener noreferrer"
              ></a> */}
            </Trans>
          </div>
          <div className="px-2">
            <button
              onClick={onDismiss}
              className="flex text-surface-high items-center cursor-pointer border border-black hover:bg-gray-950 hover:text-gray-600 rounded text-xs py-1 px-2 transition-colors ease-in-out duration-200"
            >
              <X className="mr-1" size="12" /> {t('action__dismiss')}
            </button>
          </div>
        </div>
      ) : null}
    </>
  );
};
