import { selector } from 'recoil';
import { PlayerWithProfile } from 'types';
import { playersState, userProfileState } from './atoms';

export const playerProfileState = selector<PlayerWithProfile[]>({
  key: 'playerProfiles',
  get: ({ get }) => {
    const players = get(playersState);
    return players.map((player) => {
      return {
        ...player,
        profile: { ...(get(userProfileState(player.uid)) || {}) },
      } as PlayerWithProfile;
    });
  },
});
