import { GamePlayers, GamePlayer, Player } from 'types';

import { getActiveGamePlayers } from 'utils/getActiveGamePlayers';

export function getActiveAndAlivePlayers(
  gamePlayers: GamePlayers,
  players: Player[]
): GamePlayer[] {
  const activePlayerIds = getActiveGamePlayers(gamePlayers);
  const alivePlayerIds = players.filter((p) => !p.isDead).map((p) => p.uid);
  return activePlayerIds.filter((x) => alivePlayerIds.includes(x));
}
