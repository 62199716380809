// Inspired by Twilio Video App, Apache 2.0 license
import Api from 'api';
import { useEffect, useMemo, useState } from 'react';
import { InGameAudioVideo } from 'types';
import { ensureMediaPermissions } from 'utils/media';

function useDevicesList() {
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);

  useEffect(() => {
    const getDevices = () =>
      ensureMediaPermissions().then(() =>
        navigator.mediaDevices.enumerateDevices().then((devices) => {
          setDevices(devices);
        })
      );

    try {
      navigator.mediaDevices.addEventListener('devicechange', getDevices);
      getDevices();
    } catch (e) {
      console.log(e);
    }

    return () => {
      try {
        navigator.mediaDevices.removeEventListener('devicechange', getDevices);
      } catch (e) {
        console.log(e);
      }
    };
  }, []);

  return devices;
}

export function useDeviceListOfType(deviceType: MediaDeviceKind) {
  const devices = useDevicesList();
  return useMemo(
    () => devices.filter((device) => device.kind === deviceType),
    [devices, deviceType]
  );
}

export function useDevicesOfType(deviceType: MediaDeviceKind): {
  devices?: MediaDeviceInfo[];
  initialDevice?: MediaDeviceInfo;
} {
  const devices = useDeviceListOfType(deviceType);
  const [initialDevice, setInitialDevice] = useState(devices[0]);

  useEffect(() => {
    const getInitialDevice = async () => {
      if (!devices.length) {
        return;
      }

      const prefs = await Api.getUserPreferences();
      const deviceIdKey = deviceIdKeyMap[deviceType];
      const preferredDeviceId = prefs?.gameAudioVideo?.[deviceIdKey];

      const preferredDevice = devices.find(
        (device) => device.deviceId === preferredDeviceId
      );

      setInitialDevice(preferredDevice || devices[0]);
    };
    getInitialDevice();
  }, [deviceType, devices]);

  return {
    devices,
    initialDevice,
  };
}

const deviceIdKeyMap: Record<MediaDeviceKind, keyof InGameAudioVideo> = {
  audioinput: 'audioInputID',
  audiooutput: 'audioOutputID',
  videoinput: 'videoDeviceID',
};
