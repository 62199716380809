import React, { FC, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { IconProps } from './types';

export const FourCornerLayoutIcon: FC<IconProps> = forwardRef<
  SVGSVGElement,
  IconProps
>((props, ref) => {
  const { size = 24, ...rest } = props;
  const { t } = useTranslation();

  return (
    <svg
      fill="none"
      height={size}
      ref={ref}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <title id="my-title">{t('label__four-corner-layout')}</title>
      <path
        d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 12L20 12"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 20L12 4"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});
