import { useCallback } from 'react';

import keycodes from 'constants/keycodes';

import { useGameContext } from 'contexts/GameContext';
import useGlobalShortcut, { EventTypes } from 'hooks/useGlobalShortcut';

export function useKeyboardShortcuts() {
  const { gameApi } = useGameContext();

  // up arrow
  const incrementLife = useCallback(
    (e: KeyboardEvent) => {
      const { shiftKey } = e;
      const increment = shiftKey ? 10 : 1;
      const player = gameApi?.getLocalPlayer();

      e.preventDefault();

      if (player) {
        const { life } = player;
        gameApi?.updateLife(life + increment);
      }
    },
    [gameApi]
  );

  useGlobalShortcut(EventTypes.keydown, keycodes.up_arrow, incrementLife);

  // down arrow
  const decrementLife = useCallback(
    (e: KeyboardEvent) => {
      const { shiftKey } = e;
      const increment = shiftKey ? 10 : 1;
      const player = gameApi?.getLocalPlayer();

      e.preventDefault();

      if (player) {
        const { life } = player;
        gameApi?.updateLife(life - increment);
      }
    },
    [gameApi]
  );

  useGlobalShortcut(EventTypes.keydown, keycodes.down_arrow, decrementLife);
}
