import React, { useEffect, useRef } from 'react';
import { DiceShape } from 'types/dice';
import threeEntryPoint from './EntryPoint';

type ContainerProps = {
  shape: DiceShape;
  color: string;
};

export default function ThreeContainer({ shape, color }: ContainerProps) {
  const threeRootElement = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let destroy: () => void | undefined;
    if (threeRootElement.current) {
      destroy = threeEntryPoint(threeRootElement.current, shape, color);
    }
    return () => {
      destroy?.();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="flex flex-row justify-center items-center"
      ref={threeRootElement}
    />
  );
}
