import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';

import Api from 'api';
import { useTranslation } from 'react-i18next';

import { useDevicesOfType } from 'hooks/useDevices';
import { useRecoilValue } from 'recoil';
import { gameRoomState } from 'state/atoms';
import { BaseDeviceSelect } from './BaseDeviceSelect';

export type CameraSelectProps = {
  onChange?: (deviceId: string) => void;
  textSize?: 'text-xs' | 'text-md' | 'text-sm' | 'text-lg';
};

export const CameraSelect: FunctionComponent<CameraSelectProps> = ({
  textSize,
  onChange,
}) => {
  const { t } = useTranslation();
  const { devices, initialDevice } = useDevicesOfType('videoinput');
  const [chosenVideo, setChosenVideo] = useState<string>(
    initialDevice?.deviceId ?? ''
  );
  const gameRoom = useRecoilValue(gameRoomState);

  useEffect(() => {
    if (initialDevice) {
      setChosenVideo(initialDevice.deviceId);
    }
  }, [initialDevice]);

  const onVideoChange = useCallback(
    async (e: React.ChangeEvent<HTMLSelectElement>) => {
      const deviceID = e.target.value;
      onChange && onChange(deviceID);
      await gameRoom?.setVideoDeviceId(deviceID);
      setChosenVideo(deviceID);
      Api.setAVPreferences({ videoDeviceID: deviceID });
    },
    [onChange, gameRoom]
  );

  return (
    <>
      {devices && chosenVideo && (
        <BaseDeviceSelect
          onChange={onVideoChange}
          devices={devices}
          label={t('label__camera-source')}
          chosenDeviceId={chosenVideo}
          textSize={textSize}
        />
      )}
    </>
  );
};
