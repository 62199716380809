import { useContext } from 'react';

import { Players } from 'types';

import { GameContext, GameContextProps } from 'contexts/GameContext';

export default function usePlayers(): Players | undefined {
  const { players } = useContext<Partial<GameContextProps>>(GameContext);
  return players;
}
