import { SpottedCard, NormalizedSpottedCard, CardTypes } from 'types';

import { fixSpottedCardId } from 'utils/fixSpottedCardId';
import { isBackOfCard } from 'utils/isBackOfCard';
import { getImageUrl } from 'constants/scryfall';

export function normalizeSpottedCard(card: SpottedCard): NormalizedSpottedCard {
  const { id, name, set, url } = card;

  // Trim off any characters card spotter put in the id. it puts letters for flip cards.
  const trimmedId = fixSpottedCardId(card.id);

  return {
    id,
    type: CardTypes.SpottedCard,
    name,
    collector_number: trimmedId,
    spottedImageUrl: getImageUrl(url),
    spottedId: id, // keep the original card spotter id around.
    set,
    isBack: isBackOfCard(id), // flag if the back of the card was spotted.
  };
}
