import React, { FunctionComponent, createContext, useContext } from 'react';

import { Player } from 'types';

interface PlayerContextProps {
  playerId: string;
  onRotate: Function;
  player: Player;
}

export const PlayerContext = createContext<Partial<PlayerContextProps>>({
  onRotate: () => {},
});

export function usePlayerContext() {
  const context = useContext(PlayerContext);
  return context;
}

interface PlayerContextProviderProps {
  value: Partial<PlayerContextProps>;
}

export const PlayerContextProvider: FunctionComponent<
  PlayerContextProviderProps
> = (props) => {
  const { children, value } = props;
  return (
    <PlayerContext.Provider value={value}>{children}</PlayerContext.Provider>
  );
};
