import classNames from 'classnames';
import { LoadingIcon } from 'components/icons';
import useCardSpotter from 'hooks/useCardSpotter';
import React, { RefObject } from 'react';

interface ClickIdentificationProps {
  isLocal: boolean;
  videoEl: RefObject<HTMLVideoElement>;
  rotate?: boolean;
  playerId: string;
  searchPosition: string;
}

function ClickIdentification({
  isLocal,
  videoEl,
  playerId,
  rotate,
  searchPosition,
}: ClickIdentificationProps) {
  const { videoClick, isBusy, highlight, isHighlightVisible, isSearching } =
    useCardSpotter({
      isLocal,
      videoEl,
      rotate: Boolean(rotate),
      playerId,
    });

  const opacity = isHighlightVisible ? 75 : 0;
  const highlightClass = classNames(
    { [`opacity-${opacity}`]: true },
    'h-full',
    'w-full',
    'right-0',
    'bottom-0',
    'top-0',
    'left-0',
    'absolute',
    'block',
    'transition-opacity',
    'duration-500',
    'ease-in-out'
  );
  const searchClasses = classNames(
    'absolute bottom-0 p-3 transition-all ease-in-out duration-200',
    {
      'left-0': searchPosition === 'left',
      'right-0': searchPosition === 'right',
      'opacity-75': isBusy,
      'opacity-0': !isBusy,
    }
  );

  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        onClick={videoClick}
        className={highlightClass}
      >
        <polygon
          points={highlight.join(',')}
          stroke="green"
          fillOpacity="0.3"
          fill="white"
          strokeWidth="8"
          strokeLinejoin="round"
        />
      </svg>
      {isSearching ? (
        <div className={searchClasses}>
          <div className="flex flex-row p-2 bg-surface-medium rounded text-xs pointer-events-none">
            <LoadingIcon size="18" />
          </div>
        </div>
      ) : null}
    </>
  );
}

export default ClickIdentification;
