import * as THREE from 'three';
import { DiceManager, DiceD10 } from 'threejs-dice';
import CANNON from 'cannon';
import { baseDice } from './baseDice';

window.THREE = THREE;
window.CANNON = CANNON;

interface Dice10 extends DiceD10 {
  getGeometry?: Function;
  chamfer?: number;
}

export default function D10(
  scene: THREE.Scene,
  radius: number,
  materialColor: string
) {
  const world = new CANNON.World();
  DiceManager.setWorld(world);
  const d10Model: Dice10 = new DiceD10({ size: radius });

  d10Model.chamfer = 1;

  const geometry = d10Model.getGeometry && d10Model.getGeometry();
  const { update, group } = baseDice(geometry, scene, materialColor);
  return { update, group };
}
