import Batcher from 'api/batcher';
import firebase from 'api/firebase';

import { Player } from 'types';

import { appendLastUpdated } from 'api/utils/appendLastUpdated';

const COLLECTION = 'players';

export default class Players {
  private db = firebase.firestore();
  private collectionRef = this.db.collection(COLLECTION);

  init() {}

  async getDoc(uid: string): Promise<firebase.firestore.DocumentSnapshot> {
    return await this.collectionRef.doc(uid).get();
  }

  async exists(uid: string): Promise<boolean> {
    const doc = await this.getDoc(uid);
    return doc.exists;
  }

  async getData(uid: string): Promise<Player | undefined> {
    const doc = await this.getDoc(uid);
    if (!doc.exists) {
      return;
    }
    return doc.data() as Player;
  }

  async setPeerId(uid: string, peerId: string): Promise<void> {
    const docRef = this.collectionRef.doc(uid);
    const update = appendLastUpdated({
      peerIds: firebase.firestore.FieldValue.arrayUnion(peerId),
    });
    await docRef.update(update);
  }

  async create(uid: string, data: Player): Promise<void> {
    // push to firebase
    const docRef = this.collectionRef.doc(uid);
    await docRef.set(appendLastUpdated(data), { merge: true });
  }

  update(uid: string, data: Partial<Player>, { batch = true } = {}): void {
    // push to firebase
    const docRef = this.collectionRef.doc(uid);
    const update = appendLastUpdated(data);
    if (batch) {
      Batcher.update(docRef, update);
    } else {
      docRef.update(update);
    }
  }

  delete(uid: string): void {
    // push to firebase
    const docRef = this.collectionRef.doc(uid);
    docRef.delete();
  }
}
