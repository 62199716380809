import Api from 'api';
import { Room } from 'livekit-client';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { gameRoomState } from 'state/atoms';
import logger from 'utils/logger';
import { BaseDeviceSelect } from './BaseDeviceSelect';

export type SpeakerSelectProps = {
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  textSize?: 'text-xs' | 'text-md' | 'text-sm' | 'text-lg';
};

export const SpeakerSelect: FunctionComponent<SpeakerSelectProps> = (props) => {
  const { t } = useTranslation();
  const { onChange, textSize } = props;
  const [chosenSpeaker, setChosenSpeaker] = useState<string>();
  const [speakerDevices, setSpeakerDeviceList] = useState<MediaDeviceInfo[]>();
  const gameRoom = useRecoilValue(gameRoomState);

  useEffect(() => {
    const loadDevices = async () => {
      const audioDevices = (await Room.getLocalDevices('audiooutput')).filter(
        (device) => device.deviceId
      );

      if (!audioDevices.length) {
        logger.error('No audio output devices reported');
        return;
      }
      const deviceIds = audioDevices.map((device) => device.deviceId);
      setSpeakerDeviceList(audioDevices);
      const prefs = await Api.getUserPreferences();
      const audio =
        prefs?.gameAudioVideo?.audioOutputID &&
        deviceIds.includes(prefs.gameAudioVideo.audioOutputID)
          ? prefs.gameAudioVideo.audioOutputID
          : audioDevices[0].deviceId;

      setChosenSpeaker(audio);
    };

    loadDevices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSpeakerChange = useCallback(
    async (e: React.ChangeEvent<HTMLSelectElement>) => {
      onChange && onChange(e);
      const audioOutputID = e.target.value;
      setChosenSpeaker(audioOutputID);
      await gameRoom?.setAudioOutputDeviceId(audioOutputID);
      Api.setAVPreferences({ audioOutputID });
    },
    [onChange, gameRoom]
  );

  return (
    <>
      {speakerDevices && chosenSpeaker && (
        <BaseDeviceSelect
          onChange={onSpeakerChange}
          devices={speakerDevices}
          label={t('label__speaker-source')}
          chosenDeviceId={chosenSpeaker}
          textSize={textSize}
        />
      )}
    </>
  );
};
