import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from 'components/ui/Modal';
import { Button } from 'components/ui/Button';
import Api from 'api';
import { saveDecklist } from 'api/gateway/gateway';
import { LoadingIcon } from 'components/icons';
import useGlobalShortcut, { EventTypes } from 'hooks/useGlobalShortcut';
import KEY_CODES from 'constants/keycodes';
import { NONE } from 'constants/app';

type RequestBody = {
  decklistPath: string;
  userId: string;
  token: string;
};

const URL_WHITELIST = [
  'moxfield.com',
  'archidekt.com',
  'www.moxfield.com',
  'www.archidekt.com',
];

interface DecklistModalProps {
  onModalClose: () => void;
}

export const DecklistModal: FunctionComponent<DecklistModalProps> = ({
  onModalClose,
}) => {
  const { t } = useTranslation();
  const [decklistPath, setDecklistPath] = useState<string>();
  const [error, setError] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const user = Api.user.getUser();

  const standardError = t('error__standard-decklist');
  const invalidUrlError = t('error__url-decklist');
  const nonWhitelistUrlError = t('error__whitelist-decklist');

  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const cancel = useCallback(() => {
    onModalClose();
  }, [onModalClose]);

  const inputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setDecklistPath(e.target.value);
  }, []);

  const flashError = (errorMessage: string) => {
    setError(errorMessage);
    setTimeout(() => {
      if (isMounted.current === true) {
        setError(undefined);
      }
    }, 2000);
  };

  const clearDecklist = useCallback(async () => {
    setIsLoading(true);
    if (!user?.accountId) {
      setIsLoading(false);
      flashError(standardError);
      return;
    }
    const wotcToken = await Api.auth.refreshAuth();
    const response = await saveDecklist(
      user.accountId,
      NONE,
      wotcToken.access_token
    );
    setIsLoading(false);
    if (response.error) {
      flashError(standardError);
      return;
    }
    onModalClose();
  }, [user, onModalClose, standardError]);

  const stringIsAValidUrl = (
    requestBody: RequestBody
  ): { isSuccess: boolean; message?: string } => {
    let message: string;
    let validUrl: URL;
    try {
      validUrl = new URL(requestBody.decklistPath);
    } catch (err) {
      message = 'Invalid URL';
      return { isSuccess: false, message };
    }

    if (!URL_WHITELIST.includes(validUrl.host)) {
      message = 'That URL is not allowed';
      return { isSuccess: false, message };
    }

    return { isSuccess: true };
  };

  const submitDecklist = useCallback(async () => {
    setIsLoading(true);
    if (!user?.accountId || !decklistPath) {
      setIsLoading(false);
      flashError(standardError);
      return;
    }
    const wotcToken = await Api.auth.refreshAuth();

    const reqBody = {
      decklistPath,
      userId: user.accountId,
      token: wotcToken.access_token,
    };

    const { isSuccess, message } = stringIsAValidUrl(reqBody);
    if (!isSuccess && message) {
      setIsLoading(false);
      decklistErrorMessage(message);
      return;
    }

    const response = await saveDecklist(
      user.accountId,
      decklistPath,
      wotcToken.access_token
    );
    setIsLoading(false);
    if (response.error) {
      decklistErrorMessage(response.error);
      return;
    }
    onModalClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [decklistPath, onModalClose, user]);

  const decklistErrorMessage = (message: string) => {
    switch (message) {
      case 'Invalid URL':
        flashError(invalidUrlError);
        break;
      case 'That URL is not allowed':
        flashError(nonWhitelistUrlError);
        break;
      default:
        flashError(standardError);
    }
  };

  useGlobalShortcut(EventTypes.keydown, KEY_CODES.enter, submitDecklist, {
    skipInput: true,
  });

  const decklistMessage = error ? error : t('label__decklist-modal');

  const inputClass = `focus:outline-none text-white select-auto border border-surface-border focus:border-st-orange-normal 
  bg-surface-low p-2 w-full rounded placeholder-gray-700 transition-all ease-in-out duration-200 text-xs py-1`;

  return (
    <Modal
      style={{ width: 400, height: 300 }}
      className="flex flex-col justify-center items-center"
      onClose={onModalClose}
    >
      {isLoading ? (
        <LoadingIcon className="mb-4" />
      ) : (
        <div className={`mb-10 flex flex-col ${error && 'text-red-600'}`}>
          {decklistMessage}
          {user?.decklistPath && user?.decklistPath !== NONE && (
            <div className="mt-4 text-xs text-gray-400 flex flex-col justify-center items-center">
              <div className="flex">{`Current decklist: ${user.decklistPath}`}</div>
              <div
                className="flex hover:text-st-orange-normal hover:underline cursor-pointer"
                onClick={clearDecklist}
              >
                {t('label__clear-decklist')}
              </div>
            </div>
          )}
        </div>
      )}
      <div style={{ width: 260 }} className="flex flex-col items-center">
        <input
          placeholder="https://www.moxfield.com/decks/wDHf-XLpKUi4ktsNtoye8g"
          className={inputClass}
          onChange={inputChange}
        />
        <div className="flex flex-row mt-4">
          <Button onClick={submitDecklist} className="mr-4">
            {t('action__submit')}
          </Button>
          <Button onClick={cancel}>{t('action__cancel')}</Button>
        </div>
      </div>
    </Modal>
  );
};
