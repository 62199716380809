import firebase from 'api/firebase';

import { Card } from './cards';
import { User } from './users';

export interface DamageCommander {
  id: string;
  name: string;
}

export interface DamageOwner {
  uid: string;
  name: string;
}

export enum DamageTypes {
  Commander = 'commander',
  Infect = 'infect',
}

export interface CommanderDamage {
  commander: DamageCommander;
  owner: DamageOwner;
  type: DamageTypes.Commander;
  value: number;
}

export interface InfectDamage {
  type: DamageTypes.Infect;
  value: number;
}

export type Damage = CommanderDamage | InfectDamage;

export type Damages = Damage[];

export interface PlayerProperties {
  life: number;
  commanderLethal?: number;
  commanders?: Card[];
  damage: Damages;
  isDead: boolean;
  isMonarch: boolean;
}

export interface PlayerMetadata {
  uid: string;
  name: string;
  gameId?: string;
  playerSlot?: number;
  photoURL: string | null;
  peerIds?: Array<string> | firebase.firestore.FieldValue | null;
  inactive?: boolean;
  lastUpdated: firebase.firestore.Timestamp;
}

export type Player = PlayerMetadata & PlayerProperties;

export type PlayerWithProfile = Player & {
  profile: User;
};

export type Players = Player[];
