import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { usePlayerContext } from 'contexts/PlayerContext';
import { Phases } from 'types/games';
import useGame from 'hooks/useGame';

export const PhaseIndicator: FunctionComponent = () => {
  const { t } = useTranslation();
  const { player } = usePlayerContext();
  const [activePlayer, setActivePlayer] = useState<string | null>();
  const [isHovered, setIsHovered] = useState<boolean>();
  const currentGame = useGame();

  const ROMAN_NUMERALS = ['I', 'II', 'III', 'IV', 'V'];

  const mouseEnter = () => {
    setIsHovered(true);
  };

  const mouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    if (currentGame) {
      setActivePlayer(currentGame.activePlayer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentGame]);

  useEffect(() => {
    setIsHovered(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentGame?.phase]);

  const phases = Object.values(Phases).map((phase, index) => {
    const currentPhase = currentGame?.phase === index;
    const phaseBarClass = `rounded-md ${
      currentPhase ? `bg-white text-black` : 'bg-st-purple-normal text-white'
    }  flex text-center justify-center items-center p-2 mx-1`;

    return (
      <div key={index}>
        {currentPhase ? (
          <div
            onMouseEnter={mouseEnter}
            onMouseLeave={mouseLeave}
            onTouchStart={mouseEnter}
            onTouchEnd={mouseLeave}
            key={index}
            className={phaseBarClass}
            style={{
              marginLeft: '2px',
              marginRight: '2px',
              width: '80px',
              height: isHovered ? '40px' : '10px',
              fontSize: 10,
              opacity: currentPhase ? 1 : 0.3,
              zIndex: 500,
            }}
          >
            {isHovered && currentPhase ? t(phase) : ROMAN_NUMERALS[index]}
          </div>
        ) : (
          <div
            key={index}
            className={phaseBarClass}
            style={{
              marginLeft: '2px',
              marginRight: '2px',
              width: '80px',
              height: '10px',
              fontSize: 10,
              opacity: currentPhase ? 1 : 0.3,
              zIndex: 500,
            }}
          >
            {ROMAN_NUMERALS[index]}
          </div>
        )}
      </div>
    );
  });

  return (
    <div
      className="absolute flex flex-row w-full justify-between items-end"
      style={{ bottom: 5 }}
      key="phase-indicator"
    >
      {activePlayer === player?.uid && currentGame?.isUsingPhases
        ? phases
        : null}
    </div>
  );
};
