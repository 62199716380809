import React, { FC } from 'react';

import useOrderedPlayers from 'hooks/useOrderedPlayers';
import useFocusedPlayerId from 'hooks/useFocusedPlayerId';

import { Video } from './Video';
import { DraggableVideos } from './DraggableVideos';

export const TheaterViewVideos: FC = () => {
  const players = useOrderedPlayers();
  const focusedPlayerId = useFocusedPlayerId();

  return (
    <>
      <div className="w-full h-full absolute left-0 top-0">
        <DraggableVideos />
      </div>
      <div className="flex-1 flex w-full h-full justify-center items-center">
        <Video
          playerId={focusedPlayerId || players[0]?.uid}
          barPosition="left"
          isFocused={true}
        />
      </div>
    </>
  );
};
