import { LoadingIcon } from 'components/icons';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// TODO: Keeping for mobile but will need re-work in future.

const DeviceStatus: FC<{
  isLoading: boolean;
  showPortraitWarning?: boolean;
}> = ({ isLoading, showPortraitWarning }) => {
  const { t } = useTranslation();
  if (!isLoading && !showPortraitWarning) {
    return null;
  }
  return (
    <div className="absolute left-0 top-0 w-full h-full rounded-md flex flex-col justify-center items-center text-gray-600">
      {isLoading && <LoadingIcon size="56" className="py-2" />}
      {showPortraitWarning && (
        <div className="py-2 w-full h-full flex flex-col justify-center items-center">
          <Link
            to="/mobile-help"
            target="_blank"
            className="text-white bg-black-75 p-4 text-sm flex flex-col justify-center items-center"
          >
            <div className="text-center">
              {t('label__rotate-your-device-to-landscape')}
            </div>
            <div className="text-st-orange-normal">
              {t('label__click-here-for-more-help')}
            </div>
          </Link>
        </div>
      )}
    </div>
  );
};

export default DeviceStatus;
