import React, {
  FunctionComponent,
  useState,
  useRef,
  useCallback,
  useEffect,
  useMemo,
  useLayoutEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { ScryfallCard } from 'types';
import keycodes from 'constants/keycodes';

import useScryfallSearch from 'hooks/useScryfallSearch';
import { useBodyClick, contains } from 'hooks/useBodyClick';

import useGlobalShortcut, { EventTypes } from 'hooks/useGlobalShortcut';

import ToolTip from 'rc-tooltip';
import { LoadingIcon } from 'components/icons/LoadingIcon';
import { CustomScrollbars } from 'components/CustomScrollbars';

import { isHtmlElement } from 'api/utils/isHtmlElement';
import { parseShortcut } from 'utils/parseShortcut';

interface ScryfallCardSearchProps {
  placeholderText?: string;
  inputClasses?: string;
  onCardSelect?: (card: ScryfallCard) => void;
  globalShortcut?: string;
  onSearchFocus?: () => any;
}

export const ScryfallCardSearch: FunctionComponent<ScryfallCardSearchProps> = (
  props
) => {
  const { t } = useTranslation();
  const {
    placeholderText = t('label__search-ellipses'),
    inputClasses,
    onCardSelect,
    globalShortcut,
    onSearchFocus,
  } = props;

  const containerRef = useRef<HTMLDivElement>(null);
  const searchInput = useRef<HTMLInputElement>(null);
  const [searchText, setSearchText] = useState('');
  const { results, isFetching } = useScryfallSearch(searchText);
  const [popoverVisible, setPopoverVisible] = useState(false);

  const shortcut = useMemo(() => {
    if (!globalShortcut) {
      return;
    }

    return parseShortcut(globalShortcut);
  }, [globalShortcut]);

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (!shortcut || !shortcut.key) {
        return;
      }

      const { keyCode, repeat, metaKey, ctrlKey, shiftKey, altKey } = e;
      const matchesShortcut =
        shortcut.key === keyCode &&
        shortcut.metaKey === metaKey &&
        shortcut.shiftKey === shiftKey &&
        shortcut.altKey === altKey &&
        shortcut.ctrlKey === ctrlKey;

      if (matchesShortcut && !repeat) {
        // always prevent the default
        e.preventDefault();

        if (searchInput.current) {
          searchInput.current.focus();
        }
      }
    };

    if (shortcut) {
      window.addEventListener('keydown', handler);
    }

    return () => {
      if (shortcut) {
        window.removeEventListener('keydown', handler);
      }
    };
  }, [shortcut]);

  const resetSearch = useCallback((): void => {
    if (searchInput.current) {
      searchInput.current.value = '';
    }
  }, [searchInput]);

  const onSearchInputFocus = useCallback(() => {
    if (onSearchFocus) {
      onSearchFocus();
    }
  }, [onSearchFocus]);

  const onSearchChange = useCallback((): void => {
    if (searchInput.current) {
      setSearchText(searchInput.current.value);
    }
  }, []);

  const onSelect = useCallback(
    (card: ScryfallCard): void => {
      resetSearch();
      setPopoverVisible(false);
      if (onCardSelect) {
        onCardSelect(card);
      }
    },
    [resetSearch, onCardSelect]
  );

  const closeResults = useCallback((): void => {
    setPopoverVisible(false);
  }, []);

  // TODO: Figure out a better way to have the search close on drawer close.
  const blurAndClear = useCallback(() => {
    closeResults();
    resetSearch();

    if (searchInput.current) {
      searchInput.current.blur();
    }
  }, [resetSearch, closeResults]);

  useGlobalShortcut(EventTypes.keydown, keycodes.d, blurAndClear, {
    requireMeta: true,
  });

  useEffect(() => {
    setPopoverVisible(results.length > 0 || isFetching);
  }, [results, isFetching]);

  const getPopupContainer = (): Element | null => {
    return document.querySelector('#popover-container');
  };

  const popover = (
    <SearchResults
      parentRef={containerRef}
      searching={isFetching}
      results={results}
      onSelect={onSelect}
      close={closeResults}
    />
  );

  const classes =
    inputClasses ||
    'focus:outline-none text-white select-auto border border-surface-border focus:border-st-orange-normal bg-surface-low p-2 w-full rounded placeholder-gray-700 transition-all ease-in-out duration-200 text-xs py-1';

  return (
    <ToolTip
      overlayClassName="z-1000 inline-block"
      // @ts-ignore
      getPopupContainer={getPopupContainer}
      placement="bottom"
      trigger={[]}
      overlay={popover}
      destroyTooltipOnHide
      visible={popoverVisible}
    >
      <div className="relative w-full flex justify-center" ref={containerRef}>
        <input
          aria-label={placeholderText}
          className={classes}
          placeholder={placeholderText}
          onChange={onSearchChange}
          ref={searchInput}
          onFocus={onSearchInputFocus}
        />
      </div>
    </ToolTip>
  );
};

interface SearchResultsProps {
  onSelect: (card: ScryfallCard) => any;
  results?: ScryfallCard[];
  searching?: boolean;
  parentRef: React.RefObject<HTMLElement>;
  close: () => any;
}

const SearchResults: FunctionComponent<SearchResultsProps> = (props) => {
  const { onSelect, results = [], searching = false, parentRef, close } = props;

  const resultsRef = useRef<HTMLDivElement>(null);

  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  useBodyClick(close, (target: EventTarget | null): boolean => {
    if (!isHtmlElement(target)) {
      return false;
    }

    return (
      contains(parentRef.current, target) ||
      contains(resultsRef.current, target)
    );
  });

  const moveSelectionUp = useCallback(() => {
    let newIndex;

    if (results.length === 0) {
      setSelectedIndex(null);
      return;
    }

    if (selectedIndex === null) {
      newIndex = results.length - 1;
    } else {
      newIndex = selectedIndex - 1;
    }

    setSelectedIndex(newIndex < 0 ? results.length - 1 : newIndex);
  }, [selectedIndex, results]);

  const moveSelectionDown = useCallback(() => {
    let newIndex;

    if (results.length === 0) {
      setSelectedIndex(null);
      return;
    }

    const lastIndex = results.length - 1;

    if (selectedIndex === null) {
      newIndex = 0;
    } else {
      newIndex = selectedIndex + 1;
    }

    setSelectedIndex(newIndex > lastIndex ? 0 : newIndex);
  }, [results, selectedIndex]);

  useEffect(() => {
    setSelectedIndex(null);
  }, [results]);

  useEffect(() => {
    let mounted = true;

    const onKeyDown = (e: KeyboardEvent): void => {
      if (!mounted) {
        return;
      }

      // 38 up
      // 40 down
      if (e.keyCode === 38) {
        moveSelectionUp();
      }

      if (e.keyCode === 40) {
        moveSelectionDown();
      }

      if (e.keyCode === 27) {
        close();
      }

      // enter
      if (e.keyCode === 13) {
        if (selectedIndex !== null) {
          onSelect(results[selectedIndex]);
        }
      }
    };

    window.addEventListener('keydown', onKeyDown);

    return () => {
      mounted = false;
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [
    close,
    moveSelectionUp,
    moveSelectionDown,
    onSelect,
    results,
    selectedIndex,
  ]);

  const resultItems = results.map((card, i) => (
    <SearchItem
      key={card.id}
      card={card}
      isSelected={i === selectedIndex}
      onSelect={onSelect}
    />
  ));

  const resultContainer =
    results.length > 0 ? (
      <CustomScrollbars viewClasses="pr-4">{resultItems}</CustomScrollbars>
    ) : null;

  const loadingSpinner = searching ? (
    <div
      className="absolute w-full h-full flex flex-col justify-center items-center inset-0"
      aria-label="Loading Results"
    >
      <LoadingIcon />
    </div>
  ) : null;

  return (
    <div
      ref={resultsRef}
      className="bg-surface-high mr-4 shadow-md rounded border border-surface-border w-300 p-2 relative pointer-events-auto"
    >
      <div className="h-300">
        {resultContainer}
        {loadingSpinner}
      </div>
    </div>
  );
};

interface SearchItemProps {
  card: ScryfallCard;
  onSelect: (card: ScryfallCard) => any;
  isSelected: boolean;
}

const SearchItem: FunctionComponent<SearchItemProps> = (props) => {
  const { card, onSelect, isSelected } = props;

  const ref = useRef<HTMLDivElement>(null);

  const onClick = (): void => {
    onSelect(card);
  };

  useLayoutEffect(() => {
    if (isSelected && ref.current) {
      ref.current.scrollIntoView({ block: 'nearest' });
    }
  }, [isSelected]);

  const classes = cx(
    'p-2 truncate text-sm hover:bg-surface-high cursor-pointer rounded hover:text-white transition-all ease-in-out duration-200',
    {
      'text-gray-600': !isSelected,
      'text-white bg-gray-700': isSelected,
    }
  );

  return (
    <div ref={ref} className={classes} onClick={onClick} role="button">
      {card.printed_name || card.name}
    </div>
  );
};
