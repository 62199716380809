import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Api from 'api';

import { Preferences } from 'types';

import { Modal, Header, Label, Buttons } from 'components/ui/Modal';
import { Toggle } from 'components/ui';
import { Button } from 'components/ui/Button';

interface PreferencesModalProps {
  onModalClose: () => void;
  preferences: Preferences;
}

export const PreferencesModal: FC<PreferencesModalProps> = (props) => {
  const { onModalClose, preferences } = props;
  const { t } = useTranslation();

  const { focusVideoOnTurnChange = false } = preferences;

  const toggleFocusVideo = () => {
    Api.user.setPreferences({
      focusVideoOnTurnChange: !focusVideoOnTurnChange,
    });
  };

  return (
    <Modal onClose={onModalClose}>
      <Header>{t('label__preferences')}</Header>
      <Label>{t('label__focused-layout')}</Label>
      <Row>
        <div>{t('label__switch-focused-on-turn-change')}</div>
        <Toggle enabled={focusVideoOnTurnChange} onClick={toggleFocusVideo} />
      </Row>
      <Buttons>
        <Button onClick={onModalClose} size="large" variant="secondary">
          {t('label__close')}
        </Button>
      </Buttons>
    </Modal>
  );
};

const Row: FC = ({ children }) => {
  return (
    <div className="flex justify-between text-gray-300 items-center py-2 mb-4 last:mb-0 text-sm">
      {children}
    </div>
  );
};
