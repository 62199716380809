import React, { FunctionComponent } from 'react';
import cx from 'classnames';

import { ToggleLeft, ToggleRight } from 'react-feather';

interface ToggleProps {
  enabled: boolean;
  onClick: () => any;
}

export const Toggle: FunctionComponent<ToggleProps> = (props) => {
  const { enabled, onClick } = props;

  const classes = cx(
    'cursor-pointer transition-colors duration-200 ease-in-out',
    {
      'text-st-orange-normal hover:text-st-orange-light': enabled,
      'text-gray-600 hover:text-white': !enabled,
    }
  );

  return enabled ? (
    <ToggleRight size="26" className={classes} onClick={onClick} />
  ) : (
    <ToggleLeft size="26" className={classes} onClick={onClick} />
  );
};
