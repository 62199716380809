import React, {
  FunctionComponent,
  useState,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import KEY_CODES from 'constants/keycodes';
import cx from 'classnames';
import { Plus, Minus, ChevronUp, ChevronDown } from 'react-feather';

export enum Themes {
  LIGHT = 'light',
  DARK = 'dark',
}

export enum Sizes {
  LARGE = 'large',
  SMALL = 'small',
  VERY_SMALL = 'very-small',
}

export enum ButtonPosition {
  TOP_BOTTOM = 'top-bottom',
  LEFT_RIGHT = 'left-right',
}

interface CounterProps {
  buttonPosition: ButtonPosition;
  canEdit?: boolean;
  value: number;
  onChange: (value: number) => any;
  size: Sizes;
  theme: Themes;
  max?: number;
  min?: number;
}

export const Counter: FunctionComponent<CounterProps> = (props) => {
  const {
    // canGoInfinite = false,
    buttonPosition,
    canEdit = false,
    value,
    onChange,
    size = Sizes.LARGE,
    theme = Themes.LIGHT,
    max,
    min,
  } = props;
  const { t } = useTranslation();

  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState(value ? value.toString() : '');

  const updateValues = useCallback(
    (newValue: number): void => {
      if (
        newValue !== Infinity &&
        (newValue >= 1000 ||
          (max !== undefined && newValue > max) ||
          (min !== undefined && newValue < min)) &&
        inputRef.current
      ) {
        inputRef.current.value = inputValue;
        return;
      }

      setInputValue(newValue === Infinity ? '∞' : newValue.toString());

      if (onChange) {
        onChange(newValue);
      }
      // Api.players.updatePlayer(user.uid, { life: newValue });
    },
    [onChange, inputValue, min, max]
  );

  // on prop change update the input value
  useEffect(() => {
    setInputValue(value === Infinity ? '∞' : value.toString());
  }, [value]);

  const increment = (incrementValue: number = 1): void => {
    const newValue = value + incrementValue;
    updateValues(newValue);
  };

  const decrement = (decrementValue: number = 1): void => {
    const newValue = value - decrementValue >= 0 ? value - decrementValue : 0;
    updateValues(newValue);
  };

  const parseValue = (value: string): void => {
    const sanitized = parseInt(value.trim().replace(/[^\-.0-9]+/g, ''));

    if (isNaN(sanitized)) {
      setInputValue(value.toString());
      return;
    }

    updateValues(sanitized);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value.trim();

    if (value.length > 3) {
      if (inputRef.current) {
        inputRef.current.value = inputValue;
      }
      return;
    }

    setInputValue(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent): void => {
    const { keyCode, shiftKey } = e;

    switch (keyCode) {
      case KEY_CODES.up_arrow:
        increment(shiftKey ? 10 : 1);
        e.preventDefault();
        break;
      case KEY_CODES.down_arrow:
        decrement(shiftKey ? 10 : 1);
        e.preventDefault();
        break;
      case KEY_CODES.enter:
        e.stopPropagation();
        if (inputRef.current) {
          inputRef.current.blur();
        }
        break;
      default:
        break;
    }
  };

  const handleBlur = (): void => {
    if (inputValue.trim() === '') {
      setInputValue(value.toString());
      return;
    }

    parseValue(inputValue);
  };

  const handlePlusBtn = (): void => {
    increment();
  };

  const handleMinusBtn = (): void => {
    decrement();
  };

  let plusBtn;
  let minusBtn;

  if (buttonPosition) {
    switch (buttonPosition) {
      case ButtonPosition.TOP_BOTTOM: {
        plusBtn = canEdit ? (
          <div
            role="button"
            aria-label={t('label__increase')}
            className="absolute left-0 flex justify-center cursor-pointer w-full text-gray-600 hover:text-gray-200 transition-all ease-in-out duration-200"
            onClick={handlePlusBtn}
            style={{ top: size === Sizes.LARGE ? '-4px' : '-8px' }}
          >
            <ChevronUp size="16" aria-hidden="true" />
          </div>
        ) : null;

        minusBtn = canEdit ? (
          <div
            role="button"
            aria-label={t('label__decrease')}
            className="absolute left-0 flex justify-center cursor-pointer w-full text-gray-600 hover:text-gray-200 transition-all ease-in-out duration-200"
            onClick={handleMinusBtn}
            style={{ bottom: size === Sizes.LARGE ? '-6px' : '-10px' }}
          >
            <ChevronDown size="16" aria-hidden="true" />
          </div>
        ) : null;
        break;
      }
      case ButtonPosition.LEFT_RIGHT: {
        plusBtn = canEdit ? (
          <div
            role="button"
            aria-label={t('label__increase')}
            className="flex justify-center cursor-pointer w-full text-gray-600 hover:text-gray-200 transition-all ease-in-out duration-200"
            onClick={handlePlusBtn}
          >
            <Plus size="12" aria-hidden="true" />
          </div>
        ) : null;

        minusBtn = canEdit ? (
          <div
            role="button"
            aria-label={t('label__decrease')}
            className="flex justify-center cursor-pointer w-full text-gray-600 hover:text-gray-200 transition-all ease-in-out duration-200"
            onClick={handleMinusBtn}
          >
            <Minus size="12" aria-hidden="true" />
          </div>
        ) : null;
        break;
      }
      default:
        break;
    }
  }

  let inputClasses = cx(
    'focus:outline-none bg-transparent font-bold text-center select-auto',
    {
      'text-gray-900': theme === Themes.DARK,
      'text-white': theme === Themes.LIGHT,
      'text-5xl w-24': size === Sizes.LARGE,
      'text-white text-xl w-12': size === Sizes.SMALL,
      'text-white w-8 font-mono text-sm': size === Sizes.VERY_SMALL,
    }
  );

  return (
    <div className="flex items-center h-full relative">
      {minusBtn}
      <input
        aria-label={t('label__counter-input')}
        className={inputClasses}
        disabled={!canEdit}
        value={inputValue}
        onBlur={handleBlur}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        ref={inputRef}
        type="number"
      />
      {plusBtn}
    </div>
  );
};
