import React, { FC, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactDOM from 'react-dom';
import { useModal } from 'react-modal-hook';
import cx from 'classnames';

import useFirebaseUser from 'hooks/useFirebaseUser';
import usePopOverEvents from 'hooks/usePopOverEvents';

import { ProfileIcon } from 'components/ProfileIcon';
import { UpdateProfileModal } from 'components/UpdateProfileModal';
import { useHistory } from 'react-router';

interface AccountMenuProps {
  signoutCleanup?: () => any;
  bottom?: boolean;
  leaveGame?: () => any;
}

export const AccountMenu: FC<AccountMenuProps> = (props) => {
  const { signoutCleanup, bottom, leaveGame } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const user = useFirebaseUser();
  const containerRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const [showMenu, setShowMenu] = useState(false);
  const [pos, setPos] = useState<{ right: number; top: number }>({
    right: 75,
    top: 60,
  });

  usePopOverEvents(showMenu, setShowMenu, menuRef);

  const { displayName, photoURL, email } = user!;

  const onModalClose = (): void => {
    hideProfileModal();
  };

  const [showModal, hideModal] = useModal(
    (): JSX.Element => <UpdateProfileModal onModalClose={onModalClose} />
  );

  const showProfileModal = (): void => {
    showModal();
  };

  const hideProfileModal = (): void => {
    hideModal();
  };

  const onClick = (): void => {
    // TODO: not entirely sure why this is portal'd in but it means we have to manually set a pos
    if (containerRef?.current) {
      const { x, top } = containerRef.current.getBoundingClientRect();
      setPos({ right: window.innerWidth - x - 32, top: top + 20 });
    }
    setShowMenu(true);
  };

  const onUpdateProfile = (): void => {
    setShowMenu(false);
    showProfileModal();
  };

  const onSignOut = async (): Promise<void> => {
    try {
      if (signoutCleanup) {
        signoutCleanup();
      }
      window.gtag?.('event', 'account_logout', {
        context: 'account menu',
      });
      if (leaveGame) {
        await leaveGame();
      }
      history.push('/logout');
    } catch (e) {
      console.error(e);
    }
  };

  const sectionClasses = 'border-t border-surface-low pt-2 pb-2';
  const menuItemClasses =
    'px-4 py-2 hover:bg-surface-low hover:text-white cursor-pointer text-sm transition-all ease-in-out duration-200';
  const menuClasses = cx(
    'absolute bg-surface-high pt-4 rounded text-sm w-64 shadow-lg',
    {
      'bottom-0 left-0': bottom,
      'top-100 right-0 mt-4': !bottom,
    }
  );
  const profileClasses = cx('relative', {
    'mt-3': bottom,
  });
  const overridePosition = bottom
    ? {
        left: 71,
        zIndex: 51,
        bottom: 10,
      }
    : {
        zIndex: 51,
        right: pos.right,
        top: pos.top,
      };

  const menu = showMenu ? (
    <Menu>
      <div className={menuClasses} style={overridePosition} ref={menuRef}>
        <div className="flex flex-col justify-start px-4 pb-2">
          <span className="font-bold mb-1 text-st-orange-normal">
            {displayName}
          </span>
          <span className="text-gray-600">{email}</span>
        </div>
        <div className={sectionClasses}>
          <div className={menuItemClasses} onClick={onUpdateProfile}>
            {t('label__update-profile-and-language')}
          </div>
          {/* <div className={menuItemClasses}>Settings (Inactive)</div> */}
        </div>
        <div className={sectionClasses}>
          <div className={menuItemClasses} onClick={onSignOut}>
            {t('action__logout')}
          </div>
        </div>
      </div>
    </Menu>
  ) : null;

  return (
    <div className={profileClasses}>
      <div
        className="cursor-pointer"
        onClick={onClick}
        aria-label={t('label__change-photo')}
        ref={containerRef}
      >
        <ProfileIcon
          name={displayName}
          email={email}
          photoURL={photoURL}
          showBorder={false}
        />
      </div>
      {menu}
    </div>
  );
};

const Menu: FC = ({ children }) => {
  return ReactDOM.createPortal(children, document.body);
};
