import React, { FunctionComponent } from 'react';
import cx from 'classnames';
import { X } from 'react-feather';

import useGameApi from 'hooks/useGameApi';

interface CommanderItemProps {
  id: string;
  name: string;
  canEdit?: boolean;
}

export const CommanderItem: FunctionComponent<CommanderItemProps> = (props) => {
  const gameApi = useGameApi();
  const { id, name, canEdit } = props;

  if (!id || !name) {
    return null;
  }

  const removeCommander = (): void => {
    if (canEdit) {
      gameApi?.removeCommander(id);
    }
  };

  const classes = cx(
    'flex justify-start items-center w-full rounded px-2 py-1 text-xs bg-st-purple-light border border-st-purple-light text-white max-w-sm transition-colors ease-in-out duration-200',
    {
      'hover:border-st-purple-normal cursor-pointer hover:bg-st-purple-normal':
        canEdit,
    }
  );

  return (
    <div className={classes} onClick={removeCommander}>
      {canEdit ? <X size="12" className="mr-1" /> : null}
      <div className="truncate flex-1 leading-normal">{name}</div>
    </div>
  );
};
