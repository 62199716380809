export const LocalizedLanguages = [
  { lang: 'en', label: 'English' },
  { lang: 'fr', label: 'Français' },
  { lang: 'de', label: 'Deutsch' },
  { lang: 'it', label: 'Italiano' },
  { lang: 'ja', label: '日本語' },
  { lang: 'ko', label: '한국어' },
  { lang: 'pt', label: 'Português' },
  { lang: 'ru', label: 'Русский' },
  { lang: 'es', label: 'Español' },
  { lang: 'zh-CN', label: '简体中文' },
  { lang: 'zh-TW', label: '繁體中文' },
];

/**
 * The matches field is an array in the case that one spoken
 * language can match to multiple written languages.
 *
 */
export const SpokenLanguages = [
  { matches: ['en'], label: 'English', tag: 'english' },
  { matches: ['fr'], label: 'Français', tag: 'french' },
  { matches: ['de'], label: 'Deutsch', tag: 'german' },
  { matches: ['it'], label: 'Italiano', tag: 'italian' },
  { matches: ['ja'], label: '日本語', tag: 'japanese' },
  { matches: ['ko'], label: '한국어', tag: 'korean' },
  { matches: ['pt'], label: 'Português', tag: 'portuguese' },
  { matches: ['ru'], label: 'Русский', tag: 'russian' },
  { matches: ['es'], label: 'Español', tag: 'spanish' },
  // Mandarin is most *probably* spoken with Simplified Chinese
  // Mandarin is also most *probably* spoken with Traditional Chinese
  { matches: ['zh-CN', 'zh-TW'], label: '中文 - 普通话', tag: 'mandarin' },
  { matches: [], label: '中文 - 广东话', tag: 'cantonese' },
  { matches: ['other'], label: 'Other', tag: 'other' },
];
