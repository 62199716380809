import firebase from 'api/firebase';

import { Game } from 'types';

import { appendLastUpdated } from 'api/utils/appendLastUpdated';

import { CLOSE_GAME, CREATE_GAME } from 'constants/bi-names';
import { ensureFullGamePlayers } from 'utils/ensureFullGamePlayers';
import isCamera from 'utils/isCamera';
import isSpectator from 'utils/isSpectator';
import { createGame as apiCreateGame } from './gateway/gateway';

const COLLECTION = 'games';

const GAME_ACTIVE_TIME = 3600; // one hour (in seconds)

export default class Games {
  private db = firebase.firestore();
  private collectionRef = this.db.collection(COLLECTION);
  // TODO: this is ugly being passed from the instantiated class
  private track: (name: string, isPII: boolean, data?: {}) => Promise<void> =
    async () => {
      return;
    }; // init with noop

  init(track: (name: string, isPII: boolean, data?: {}) => Promise<void>) {
    this.track = track;
  }

  async isUserInGame(uid: string): Promise<boolean> {
    const query = await this.collectionRef
      .where('players', 'array-contains', uid)
      .limit(1)
      .get();

    if (query.empty) {
      return false;
    }

    const doc = query.docs[0];
    return !!doc.id;
  }

  async getGameDoc(
    gameId: string
  ): Promise<firebase.firestore.DocumentSnapshot> {
    return await this.collectionRef.doc(gameId).get();
  }

  async getGame(gameId: string): Promise<Game> {
    const doc = await this.getGameDoc(gameId);
    return doc.data() as Game;
  }

  async getUserGames(
    userId: string
  ): Promise<firebase.firestore.QuerySnapshot> {
    return await this.collectionRef
      .where('owner', '==', userId)
      .orderBy('created', 'desc')
      .get();
  }

  async createGame(
    ownerId: string,
    token: string,
    data: Partial<Game>
  ): Promise<Game | undefined> {
    let gameId: string;
    try {
      const result = await apiCreateGame(token, ownerId, data);
      gameId = result.id;
    } catch (e) {
      return undefined; // TODO, fix this, this is silly
    }
    if (!gameId) {
      return undefined;
    }

    // send to firebase
    const newGameDoc = await this.collectionRef.doc(gameId).get();
    const newGame = newGameDoc.data() as Game;
    if (!newGame) {
      return undefined;
    }
    this.track(CREATE_GAME, false, {
      gameId: newGame.id,
      isPublic: data.isPublic,
      format: data.format,
      isCamera: isCamera(),
      isSpectator: isSpectator(),
      language: data.tags?.language,
    });
    return newGame;
  }

  async updateGame(currentGame: Game, data: Partial<Game>): Promise<void> {
    // push to firebase
    const docRef = this.collectionRef.doc(currentGame.id);
    // ensure that game.players is always an array with length of 4.
    // I don't think this is needed it's more for self-defense. just in case.
    if (data.players) {
      data.players = ensureFullGamePlayers(data.players, currentGame.format);
    }

    await docRef.update(appendLastUpdated(data));
  }

  async deleteGame(id: string): Promise<void> {
    const docRef = this.collectionRef.doc(id);
    await docRef.delete();
    this.track(CLOSE_GAME, false, {
      gameId: id,
    });
  }

  async doesGameExistAndIsActive(id: string): Promise<boolean> {
    const gameDoc = await this.getGameDoc(id);

    if (!gameDoc.exists) {
      return false;
    }

    const { lastUpdated } = gameDoc.data() as Game;
    const now = firebase.firestore.Timestamp.now();
    const age = now.seconds - lastUpdated.seconds;

    return age < GAME_ACTIVE_TIME;
  }
}
