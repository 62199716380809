import * as THREE from 'three';

export default (scene: THREE.Scene) => {
  const spotlightColor = new THREE.Color('white');
  const spotlight = new THREE.SpotLight(spotlightColor, 2);
  spotlight.position.set(0, 20, 40);
  scene.add(spotlight);

  // exported to avoid errors in SceneManager
  function update(time: number) {}

  return { update };
};
