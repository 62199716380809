import { VideoCodec } from 'livekit-client';
import { HDConstraints } from 'types';
import logger from './logger';

export const MAX_DIMENSION = 960;
// ideal dimensions based on max with a 16:9 aspect
export const IDEAL_CAM_HEIGHT = 540;
export const IDEAL_CAM_WIDTH = 960;

export const FPS = 15;
export const MAX_BITRATE = 1_000_000;
export const CODEC: VideoCodec = 'vp9';

export const CUSTOM_RATIOS: { label: string; value: number }[] = [
  { label: 'Device Provided (Default)', value: -1 },
  { label: '16:9 (Widescreen)', value: 1.778 },
  { label: '4:3 (Standard)', value: 1.333 },
  { label: '1:1 (Square)', value: 1 },
  { label: '21:9 (Ultrawide)', value: 2.333 },
];

// for audio and video at the same time (as opposed to separate requests).
// borrowed from TwilioVideoApp under Apache 2.0 license
// This function ensures that the user has granted the browser permission to use audio and video
// devices. If permission has not been granted, it will cause the browser to ask for permission
export const ensureMediaPermissions = () => {
  return navigator.mediaDevices
    .enumerateDevices()
    .then((devices) =>
      devices.every((device) => !(device.deviceId && device.label))
    )
    .then((shouldAskForMediaPermissions) => {
      if (shouldAskForMediaPermissions) {
        return navigator.mediaDevices
          .getUserMedia({ audio: true, video: true })
          .then((mediaStream) =>
            mediaStream.getTracks().forEach((track) => track.stop())
          )
          .catch((err) => {
            console.log(err); // not allowed probably?
          });
      }
    });
};

export const baseConstraints: HDConstraints = {
  video: {
    height: { ideal: MAX_DIMENSION },
    width: { ideal: MAX_DIMENSION },
    advanced: [
      {
        frameRate: { max: FPS },
      },
    ],
  },
  audio: {
    echoCancellation: true,
    noiseSuppression: true,
    autoGainControl: true,
  },
};

export const getConstraints = (): HDConstraints => {
  const allowedConstraints = { ...baseConstraints };

  try {
    const constraints = navigator.mediaDevices.getSupportedConstraints();
    const supportsResizeMode = (constraints as any).resizeMode;

    if (supportsResizeMode && allowedConstraints.video) {
      allowedConstraints.video.resizeMode = ['none'];
    }
  } catch (error) {
    logger.error('Error getting constraints', error as Error);
  }

  return allowedConstraints;
};
