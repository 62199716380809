import React, { FC, useEffect } from 'react';
import { Modal } from 'components/ui/Modal';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Dice } from 'types/dice';
import { DiceBox } from 'components/dice-rolling/DiceBox';
import D4 from 'components/dice-rolling/Dice/D4';
import D10 from 'components/dice-rolling/Dice/D10';
import { useRecoilValue } from 'recoil';
import { diceState } from 'state/atoms';
import D6 from 'components/dice-rolling/Dice/D6';
import D8 from 'components/dice-rolling/Dice/D8';
import D12 from 'components/dice-rolling/Dice/D12';
import D20 from 'components/dice-rolling/Dice/D20';
import Coin from 'components/dice-rolling/Dice/Coin';

interface DiceModalProps {
  onModalClose: () => void;
}

export const DiceModal: FC<DiceModalProps> = (props) => {
  const { onModalClose } = props;
  const diceValue = useRecoilValue<Dice>(diceState);

  useEffect(() => {
    return () => {
      if (diceValue.showDiceTimer) {
        clearTimeout(diceValue.showDiceTimer);
      }
      if (diceValue.showResultsTimer) {
        clearTimeout(diceValue.showResultsTimer);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Dice = () => {
    return (
      <>
        {diceValue.sided === '4' && (
          <DiceBox sides={diceValue.sided} shape={D4} color={'white'} />
        )}
        {diceValue.sided === '6' && (
          <DiceBox sides={diceValue.sided} shape={D6} color={'white'} />
        )}
        {diceValue.sided === '8' && (
          <DiceBox sides={diceValue.sided} shape={D8} color={'white'} />
        )}
        {diceValue.sided === '10' && (
          <DiceBox sides={diceValue.sided} shape={D10} color={'white'} />
        )}
        {diceValue.sided === '12' && (
          <DiceBox sides={diceValue.sided} shape={D12} color={'white'} />
        )}
        {diceValue.sided === '20' && (
          <DiceBox sides={diceValue.sided} shape={D20} color={'white'} />
        )}
        {diceValue.sided === '2' && (
          <DiceBox sides={'2'} shape={Coin} color={'yellow'} />
        )}
      </>
    );
  };

  return (
    <Modal
      style={{ width: 300, height: 300 }}
      className="flex flex-row justify-center items-center"
      onClose={onModalClose}
    >
      <div className="flex flex-col justify-center items-center">
        {diceValue.isDiceShowing ? (
          <Dice />
        ) : (
          <div>
            You {`${diceValue.sided === '2' ? 'flipped' : 'rolled'}`} a
            {` ${diceValue.result}`}
          </div>
        )}
      </div>
    </Modal>
  );
};
