import React, { FunctionComponent } from 'react';

import useOrderedPlayers from 'hooks/useOrderedPlayers';

import { Video } from './Video';

export const FourCornerVideos: FunctionComponent = () => {
  const players = useOrderedPlayers();

  const clockwisePlayers = [players[0], players[1], players[3], players[2]];

  const videos = clockwisePlayers.map((player, index) => {
    const barPosition = index === 0 || index === 2 ? 'left' : 'right';

    return (
      <div
        key={`player-${index}`}
        className="w-1/2"
        style={{ maxHeight: '50%' }}
      >
        <Video playerId={player?.uid} barPosition={barPosition} />
      </div>
    );
  });

  return (
    <div className="flex-1 flex flex-row w-full h-full flex-wrap justify-center max-h-full">
      {videos}
    </div>
  );
};
