import React, { FC } from 'react';
import { Trans, Translation } from 'react-i18next';

import { GameLogAction, GameLogItem } from 'api/gamelog';
import moment from 'moment';

const HIGHLIGHT_CLASSES = 'text-st-orange-normal';

const Highlight: FC = ({ children }) => {
  return <span className={HIGHLIGHT_CLASSES}>{children}</span>;
};

const ACTIONS = {
  [GameLogAction.Eliminated]: {
    requiredParams: 0,
    render: (item: GameLogItem) => {
      return (
        <Translation>
          {(t) => (
            <span className="text-red-600">
              {t('game-log__eliminated', { player: item.displayName })}
            </span>
          )}
        </Translation>
      );
    },
  },
  [GameLogAction.BecameMonarch]: {
    requiredParams: 0,
    render: (item: GameLogItem) => {
      return (
        <>
          <Trans i18nKey="game-log__became-monarch">
            <Highlight>{{ player: item.displayName }}</Highlight>
          </Trans>
        </>
      );
    },
  },
  [GameLogAction.FlippedCoin]: {
    requiredParams: 1,
    render: (item: GameLogItem) => {
      return (
        <>
          <Trans i18nKey="game-log__flipped-coin">
            <Highlight>{{ player: item.displayName }}</Highlight>
            <Highlight>{{ coin: item.params[0] }}</Highlight>
          </Trans>
        </>
      );
    },
  },
  [GameLogAction.SetLife]: {
    requiredParams: 1,
    render: (item: GameLogItem) => {
      return (
        <>
          <Trans i18nKey="game-log__set-life">
            <Highlight>{{ player: item.displayName }}</Highlight>
            <Highlight>{{ life: item.params[0] }}</Highlight>
          </Trans>
        </>
      );
    },
  },
  [GameLogAction.IdentifiedCard]: {
    requiredParams: 1,
    render: (item: GameLogItem) => {
      return (
        <>
          <Trans i18nKey="game-log__identified-card">
            <Highlight>{{ player: item.displayName }}</Highlight>
            <Highlight>{{ card: item.params[0] }}</Highlight>
          </Trans>
        </>
      );
    },
  },
  [GameLogAction.Joined]: {
    requiredParams: 0,
    render: (item: GameLogItem) => {
      return (
        <Translation>
          {(t) => (
            <span className="text-st-purple-normal">
              {t('game-log__joined', { player: item.displayName })}
            </span>
          )}
        </Translation>
      );
    },
  },
  [GameLogAction.Left]: {
    requiredParams: 0,
    render: (item: GameLogItem) => {
      return (
        <Translation>
          {(t) => (
            <span className="text-st-purple-normal">
              {t('game-log__left', { player: item.displayName })}
            </span>
          )}
        </Translation>
      );
    },
  },
  [GameLogAction.SetCommander]: {
    requiredParams: 1,
    render: (item: GameLogItem) => {
      return (
        <>
          <Trans i18nKey="game-log__set-commander">
            <Highlight>{{ player: item.displayName }}</Highlight>
            <Highlight>{{ commander: item.params[0] }}</Highlight>
          </Trans>
        </>
      );
    },
  },
  [GameLogAction.PassedTurn]: {
    requiredParams: 0,
    render: (item: GameLogItem) => {
      return (
        <>
          <Trans i18nKey="game-log__passed-turn">
            <Highlight>{{ player: item.displayName }}</Highlight>
          </Trans>
        </>
      );
    },
  },
  [GameLogAction.RolledDice]: {
    requiredParams: 2,
    render: (item: GameLogItem) => {
      return (
        <>
          <Trans i18nKey="game-log__rolled-dice">
            <Highlight>{{ player: item.displayName }}</Highlight>
            <Highlight>{{ sides: item.params[0] }}</Highlight>
            <Highlight>{{ roll: item.params[1] }}</Highlight>
          </Trans>
        </>
      );
    },
  },
};

export const LogItem: FC<{ item: GameLogItem }> = ({ item }) => {
  const time = moment(new Date(item.timestamp as number)).format('hh:mm a');
  const action = ACTIONS[item.action];
  const itemParamNum = item.params?.length || 0;
  if (itemParamNum !== action.requiredParams) {
    console.log(item, action.requiredParams, 'had an error');
    throw Error('parameter mismatch in gamelog');
  }
  const output = action.render(item);
  return (
    <div>
      <span>{time} </span>
      <span className="text-white">{output}</span>
    </div>
  );
};
