import React, { useRef, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { isMac } from 'utils/isMac';

import { ScryfallCard } from 'types';

import useGameApi from 'hooks/useGameApi';

import { ScryfallCardSearch } from 'components/popovers/ScryfallCardSearch';
import api from 'api';
import { CARD_SEARCH_SELECT } from 'constants/bi-names';

interface DrawerCardSearchProps {
  onSearchFocus?: () => any;
}

export const DrawerCardSearch: FunctionComponent<DrawerCardSearchProps> = (
  props
) => {
  const { onSearchFocus } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const gameApi = useGameApi();
  const { t } = useTranslation();

  const onCardSelect = (card: ScryfallCard): void => {
    if (gameApi) {
      gameApi.addCardScryfall(card);
      api.track(CARD_SEARCH_SELECT, false, { name: card.name });
    }
  };

  const shortcut = isMac()
    ? t('label__search-shortcut-mac')
    : t('label__search-shortcut-windows');

  return (
    <div className="relative flex justify-center w-full" ref={containerRef}>
      <ScryfallCardSearch
        inputClasses="focus:outline-none text-white select-auto border border-surface-border focus:border-st-orange-normal bg-surface-low p-2 w-full rounded placeholder-gray-700 transition-all ease-in-out duration-200 text-xs py-1"
        onCardSelect={onCardSelect}
        globalShortcut={shortcut}
        onSearchFocus={onSearchFocus}
      />
    </div>
  );
};
