import Api from 'api';
import firebase from 'api/firebase';
import QRCode from 'qrcode';
import { useEffect, useState } from 'react';
import isCamera from 'utils/isCamera';

interface UseQRCodeArguments {
  user?: firebase.User | null;
  playerId: string;
  mobileCameraReady?: boolean | null;
}

export default function useQRCode({
  user,
  playerId,
  mobileCameraReady,
}: UseQRCodeArguments) {
  const [qrcode, setQRCode] = useState('');

  const isAwaitingMobileCamera =
    user &&
    playerId === user.uid &&
    !isCamera() &&
    Api.getUserPreferences()?.useMobileCamera &&
    !mobileCameraReady;

  const { origin, pathname } = window.location;
  const qrCodeUrl = origin + pathname + '?camera=true';

  // QR Code generation
  useEffect(() => {
    const generateQRCode = async () => {
      const dataURL = await QRCode.toDataURL(qrCodeUrl, {
        margin: 1,
        color: {
          dark: '#2d3748',
          light: '#edf2f7',
        },
      });
      setQRCode(dataURL);
    };

    if (isAwaitingMobileCamera) {
      generateQRCode();
    } else {
      setQRCode('');
    }
  }, [isAwaitingMobileCamera, qrCodeUrl]);

  return {
    qrcode,
    isAwaitingMobileCamera,
    qrCodeUrl,
  };
}
