import useCurrentSlide from 'hooks/useCurrentSlide';
import React, { FunctionComponent } from 'react';
import cx from 'classnames';
import { Dot } from 'pure-react-carousel';

interface SlideshowDot {
  slide: number;
}

export const SlideshowDot: FunctionComponent<SlideshowDot> = (props) => {
  const { slide } = props;

  const currentSlide = useCurrentSlide();
  const isCurrentSlide = slide === currentSlide;

  const classes = cx(
    'mx-1 rounded-full transition-colors duration-200 ease-in-out',
    {
      'bg-st-orange-normal': isCurrentSlide,
      'bg-surface-medium': !isCurrentSlide,
    }
  );

  return (
    <Dot slide={slide}>
      <div className={classes} style={{ width: '10px', height: '10px' }}></div>
    </Dot>
  );
};
