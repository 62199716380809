import { useEffect, useRef, useState } from 'react';

import { ENDPOINTS } from 'constants/scryfall';
import { ScryfallCard } from 'types';

import usePreferredLanguage from './usePreferredLanguage';

import { useRecoilValue } from 'recoil';
import { includeExtrasState, includeLanguagesState } from 'state/atoms';

export interface useScryfallSearchReturn {
  results: ScryfallCard[];
  isFetching: boolean;
}

export default function useScryfallSearch(
  searchText: string,
  cardBlacklist?: string[]
): useScryfallSearchReturn {
  const requestTimeout = useRef<ReturnType<typeof setTimeout> | null>();
  const [results, setResults] = useState<ScryfallCard[]>([]);
  const [isFetching, setIsFetching] = useState(false);

  const preferredLanguage = usePreferredLanguage();

  const includeExtras = useRecoilValue(includeExtrasState);
  const includeOtherLanguages = useRecoilValue(includeLanguagesState);

  useEffect(() => {
    let isMounted = true;

    setResults([]);

    if (searchText.trim().length <= 1) {
      setIsFetching(false);
      return;
    }

    setIsFetching(true);

    if (requestTimeout.current) {
      clearTimeout(requestTimeout.current);
      requestTimeout.current = null;
    }

    // Throttle requests to Scryfall so we don't bombard them with requests...
    requestTimeout.current = setTimeout(() => {
      const request = new XMLHttpRequest();
      const endpoint = `${ENDPOINTS.CARD_SEARCH}${encodeURIComponent(
        searchText
      )}${includeOtherLanguages ? '&include_multilingual=true' : ''}${
        includeExtras ? '&include_extras=true' : ''
      } `;

      request.open('GET', endpoint, true);

      request.onload = () => {
        if (!isMounted) {
          return;
        }

        const jsonResponse = JSON.parse(request.response);
        let results: ScryfallCard[] = [];

        if (jsonResponse.object !== 'error' && jsonResponse.total_cards > 0) {
          results = jsonResponse.data.slice(0, 100);
        }

        // filter out any blacklisted cards
        if (cardBlacklist) {
          results = results.filter((i) => !cardBlacklist.includes(i.id));
        }

        setResults(results);
        setIsFetching(false);
      };
      request.send();

      requestTimeout.current = null;
    }, 200);

    return () => {
      isMounted = false;
    };
  }, [
    searchText,
    cardBlacklist,
    preferredLanguage,
    includeExtras,
    includeOtherLanguages,
  ]);

  return {
    results,
    isFetching,
  };
}
