import useLoginAction from 'hooks/useLoginAction';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const Header: FunctionComponent = () => {
  const { t } = useTranslation();
  const login = useLoginAction();

  return (
    <div className="w-full flex flex-col items-center justify-center xxs:flex-row xxs:justify-between py-12">
      <Link to="/" className="flex items-start">
        <img
          alt="SpellTable"
          src={
            process.env.PUBLIC_URL +
            '/images/logo/MTG_SpellTable_Logo_Horiz.svg'
          }
          className="w-128"
        />
      </Link>
      <div className="items-center py-8 hidden xxs:flex">
        <button
          onClick={login}
          className="text-st-orange-normal hover:text-st-orange-light hover:underline mr-6 cursor-pointer transition-colors ease-in-out duration-200"
        >
          {t('action__login')}
        </button>
        <button
          onClick={login}
          className="bg-st-purple-light text-white hover:bg-st-purple-normal cursor-pointer rounded px-4 py-2 shadow-md transition-colors ease-in-out duration-200"
        >
          {t('action__get-started')}
        </button>
      </div>
    </div>
  );
};
