import React, { FunctionComponent, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { WUBRG, WUBRGColors } from 'types';

export const LoadingScreen: FunctionComponent = () => {
  const [filledPips, setFilledPips] = useState(-1);
  const { t } = useTranslation();

  useEffect(() => {
    const interval = setInterval(() => {
      setFilledPips((prev) => {
        if (prev === 5) {
          return -1;
        }

        return prev + 1;
      });
    }, 200);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const pips = Object.values(WUBRG).map((pipColor, index) => {
    let colorClass;
    switch (pipColor) {
      case 'W':
        colorClass = 'bg-white';
        break;
      case 'B':
        colorClass = `bg-surface-high`;
        break;
      default:
        colorClass = `bg-${WUBRGColors[pipColor]}-600`;
        break;
    }
    const classes = cx(
      'w-32 rounded mx-1 transition-all ease-in-out duration-200',
      filledPips < index ? 'bg-surface-low' : colorClass
    );
    return (
      <div key={pipColor} className={classes} style={{ height: '3px' }}></div>
    );
  });

  return (
    <div className="flex flex-col w-full h-full justify-center items-center">
      <div className="w-500 flex-col justify-center align-middle rounded-lg shadow-md p-12 text-gray-200 bg-surface-medium">
        <div className="flex justify-center items-start mb-8">
          <img
            alt="SpellTable"
            src={
              process.env.PUBLIC_URL +
              '/images/logo/MTG_SpellTable_Logo_Horiz.svg'
            }
            className="w-64"
          />
        </div>
        <div className="flex justify-center mb-8">
          <div className="flex justify-around w-200 items-center">{pips}</div>
        </div>
        <div className="text-center">{t('label__joining-game')}</div>
      </div>
    </div>
  );
};
