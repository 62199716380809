import React, { FunctionComponent, useMemo } from 'react';

import { Players } from 'types';

import useOrderedPlayers from 'hooks/useOrderedPlayers';
import useGame from 'hooks/useGame';
import useFocusedPlayerId from 'hooks/useFocusedPlayerId';

import { getActiveGamePlayers } from 'utils/getActiveGamePlayers';

import { Video } from './Video';

export const FocusedVideos: FunctionComponent = () => {
  const game = useGame();
  const players = useOrderedPlayers();
  const focusedPlayerId = useFocusedPlayerId();

  const playerSpots = useMemo((): Players => {
    if (!game) {
      return [];
    }

    const activePlayerIds = getActiveGamePlayers(game.players);
    const activePlayers: Players = [];
    return activePlayerIds.reduce((acc, uid) => {
      const player = players.find((p) => p && p.uid === uid);
      if (player) {
        activePlayers.push(player);
      }
      return acc;
    }, activePlayers);
  }, [game, players]);

  const videos = playerSpots.map((player, index) => {
    return (
      <div key={`player-${index}`} className="w-full my-2">
        <Video playerId={player?.uid} isThumbnail={true} barPosition="left" />
      </div>
    );
  });

  return (
    <div className="flex-1 flex w-full h-full px-4">
      <div className="flex flex-col py-4 pr-4 w-1/4 h-full justify-center max-h-full">
        {videos}
      </div>
      <div className="flex-1 flex justify-center items-center">
        <div className="w-full" style={{ maxHeight: '100%' }}>
          <Video
            playerId={focusedPlayerId || players[0]?.uid}
            barPosition="left"
            isFocused={true}
          />
        </div>
      </div>
    </div>
  );
};
