import { GamePlayers, GamePlayerSlot, getMaxPlayers, MagicFormat } from 'types';

export function ensureFullGamePlayers(
  players: GamePlayerSlot[],
  format: MagicFormat = MagicFormat.Commander
): GamePlayers {
  const maxPlayers = getMaxPlayers(format);
  const defaultPlayers: GamePlayers = Array(maxPlayers).fill(
    null
  ) as GamePlayers;

  if (!Array.isArray(players)) {
    return defaultPlayers;
  }

  const returnPlayers = players.reduce((acc, curr, index) => {
    acc[index] = curr;
    return acc;
  }, defaultPlayers);
  if (returnPlayers.length > maxPlayers) {
    return returnPlayers.slice(0, maxPlayers) as GamePlayers;
  }
  return returnPlayers;
}
