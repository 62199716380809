import React, { FunctionComponent } from 'react';

import { NormalizedSpottedCard } from 'types';

import { DrawerCard } from './DrawerCard';

interface DrawerCardNoDataProps {
  hoverDisabled?: boolean;
  card: NormalizedSpottedCard;
}

export const DrawerCardNoData: FunctionComponent<DrawerCardNoDataProps> = (
  props
) => {
  const { hoverDisabled, card } = props;

  const { name, spottedImageUrl } = card;

  return (
    <DrawerCard
      card={card}
      hoverDisabled={hoverDisabled}
      name={name}
      imageUrl={spottedImageUrl}
      hoverImages={[spottedImageUrl]}
    />
  );
};
