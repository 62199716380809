import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { ScryfallCard } from 'types';

import { usePlayerContext } from 'contexts/PlayerContext';
import useGameApi from 'hooks/useGameApi';
import useFirebaseUser from 'hooks/useFirebaseUser';

import { CardHoverPopover } from 'components/CardHoverPopover';
import { CommanderItem } from 'components/CommanderItem';
import { ScryfallCardSearch } from 'components/popovers/ScryfallCardSearch';

export const Commanders: FunctionComponent = () => {
  const user = useFirebaseUser();
  const { player } = usePlayerContext();

  const canEdit = player && user?.uid === player.uid;

  const { uid, commanders = [] } = player || {};

  const items = commanders.map((commander, index): JSX.Element => {
    const { id, image_uris, name, back_image_uris } = commander;
    return (
      <CardHoverPopover
        key={`${uid}-${id}`}
        imageUrl={image_uris?.normal}
        backImageUrl={back_image_uris?.normal}
        placement={index === 0 ? 'left' : 'right'}
      >
        <div className="w-1/2 px-1" style={{ maxWidth: '50%' }}>
          <CommanderItem canEdit={canEdit} id={id} name={name} />
        </div>
      </CardHoverPopover>
    );
  });

  while (items.length < 2) {
    items.push(
      <div
        key={`${uid}-add-${items.length}`}
        className="w-1/2 px-1"
        style={{ maxWidth: '50%' }}
      >
        <AddCommanderItem canEdit={canEdit} />
      </div>
    );
  }

  return <div className="flex px-1">{items}</div>;
};

interface AddCommanderItemProps {
  canEdit?: boolean;
}

const AddCommanderItem: FunctionComponent<AddCommanderItemProps> = (props) => {
  const { canEdit } = props;
  const gameApi = useGameApi();
  const { t } = useTranslation();

  const addCommander = (card: ScryfallCard): void => {
    window.gtag?.('event', 'add_commander', {
      name: card.name,
      multiverseId: card.multiverse_ids,
    });
    gameApi?.addCommander(card);
  };

  return (
    <div className="flex justify-start items-center w-full border border-gray-700 rounded px-2 py-1 max-w-sm">
      {canEdit ? (
        <>
          <ScryfallCardSearch
            inputClasses="focus:outline-none text-white select-auto bg-transparent px-1 leading-normal text-xs w-full rounded placeholder-gray-600"
            placeholderText={t('label__add-commander')}
            onCardSelect={addCommander}
          />
        </>
      ) : null}
      {!canEdit ? (
        <div className="text-gray-600 text-xs leading-normal">
          {t('label__no-commander-set')}
        </div>
      ) : null}
    </div>
  );
};
