import React, { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { WUBRGColors, Colors } from 'types';

import arrayUniq from 'utils/arrayUniq';

import { usePlayerContext } from 'contexts/PlayerContext';

export const ColorIdentity: FunctionComponent = () => {
  const { player } = usePlayerContext();
  const { commanders = [] } = player || {};
  const { t } = useTranslation();

  const playerColors = useMemo(() => {
    const colors = commanders.reduce((acc: Colors[], curr) => {
      if (!curr.colors) {
        return acc;
      }
      return arrayUniq(acc.concat(curr.colors));
    }, []);

    const pips: JSX.Element[] = [];

    Object.values(Colors).forEach((c) => {
      if (colors.includes(c)) {
        let colorClass;
        let color = '';
        switch (c) {
          case 'W':
            color = t('label__white');
            colorClass = 'bg-white';
            break;
          case 'B':
            colorClass = `bg-surface-high`;
            break;
          default:
            color = t(`label__${WUBRGColors[c]}`);
            colorClass = `bg-${color}-600`;
            break;
        }
        pips.push(
          <div
            aria-label={color}
            key={c}
            className={`rounded-full ${colorClass}`}
            style={{
              marginLeft: '2px',
              marginRight: '2px',
              width: '20px',
              height: '2px',
            }}
          ></div>
        );
      }
      // } else {
      //   pips.push(<div key={`disabled-${c}`} className="rounded-full bg-surface-medium" style={{ marginLeft: '2px', marginRight: '2px', width: '20px', height: '3px' }}></div>);
      // }
    });

    return pips;
  }, [commanders, t]);

  return (
    <div className="flex" aria-label={t('label__color-identity')}>
      {playerColors}
    </div>
  );
};
