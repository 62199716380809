import cx from 'classnames';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import {
  DamageCommander,
  DamageOwner,
  Damages,
  DamageTypes,
  DEFAULT_COMMANDER_LETHAL,
  FORMAT_INFO,
  MagicFormat,
} from 'types';

import { DAMAGE_TYPES } from 'constants/magic';

import { usePlayerContext } from 'contexts/PlayerContext';
import useFirebaseUser from 'hooks/useFirebaseUser';
import useGameApi from 'hooks/useGameApi';
import usePlayerDamages from 'hooks/usePlayerDamages';

import { isCommanderDamage } from 'api/utils/isCommanderDamage';
import { ButtonPosition, Counter, Sizes, Themes } from 'components/Counter';
import useGame from 'hooks/useGame';

export const DamageBars: FunctionComponent = () => {
  const { t } = useTranslation();
  const { playerId } = usePlayerContext();
  const { damageByPlayer, miscDamage } = usePlayerDamages(playerId);

  const playerSections = Object.keys(damageByPlayer).map(
    (opponentId: string): JSX.Element => {
      const { name, damage } = damageByPlayer[opponentId];
      return (
        <DamageSection
          key={`${opponentId}-damages`}
          title={name}
          damageData={damage}
        />
      );
    }
  );

  const miscSection = (
    <DamageSection title={t('label__misc')} damageData={miscDamage} />
  );

  return (
    <div className="flex flex-col justify-start">
      {playerSections}
      {miscSection}
    </div>
  );
};

interface DamageSesionProps {
  title: string;
  damageData: Damages;
}

const DamageSection: FunctionComponent<DamageSesionProps> = (props) => {
  const { title, damageData } = props;

  return (
    <div className="w-full px-1 pb-2">
      <div className="p-2 px-1 bg-surface-high rounded">
        <div className="text-xs uppercase text-st-orange-normal font-bold px-1">
          {title}
        </div>
        <div className="flex flex-row w-full mt-1">
          {damageData.map((item, index) => {
            const { type, value } = item;

            if (isCommanderDamage(item)) {
              const { commander, owner } = item;
              return (
                <CommanderDamageRow
                  key={`${owner.uid}-${commander.id}`}
                  type={type}
                  owner={owner}
                  commander={commander}
                  value={value}
                  widthClass={damageData.length > 1 ? 'w-1/2' : 'w-full'}
                />
              );
            }

            return (
              <CommanderDamageRow
                key={`${type}-${index}-damage-row`}
                type={type}
                value={value}
                widthClass={damageData.length > 1 ? 'w-1/2' : 'w-full'}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

interface CommanderDamageRowProps {
  type: DamageTypes;
  owner?: DamageOwner;
  commander?: DamageCommander;
  value?: number;
  widthClass?: string;
}

const CommanderDamageRow: FunctionComponent<CommanderDamageRowProps> = (
  props
) => {
  const { type, owner, commander, value = 0, widthClass } = props;
  const { t } = useTranslation();

  const user = useFirebaseUser();
  const { playerId } = usePlayerContext();
  const gameApi = useGameApi();
  const { format = MagicFormat.Commander } = useGame() ?? {};
  const commanderLethal =
    FORMAT_INFO[format].commanderLethal ?? DEFAULT_COMMANDER_LETHAL;
  const max = type === DAMAGE_TYPES.INFECT ? 10 : commanderLethal;
  const progress = (value / max) * 100;

  const updateDamage = (newValue: number): void => {
    if (type === DamageTypes.Infect) {
      gameApi?.updateInfectDamage(newValue);
    } else if (type === DamageTypes.Commander) {
      gameApi?.updateCommanderDamage(owner!, commander!, newValue);
    }
  };

  const label =
    type === DamageTypes.Infect ? t('label__infect') : commander?.name;

  const classes = cx('px-1', widthClass);

  return (
    <div className={classes}>
      <div className="flex items-center justify-between w-full">
        <div className="truncate flex-1 text-white text-xs">{label}</div>
        <div className="pl-2">
          <Counter
            canEdit={user?.uid === playerId}
            value={value}
            max={max}
            min={0}
            onChange={updateDamage}
            theme={Themes.LIGHT}
            size={Sizes.VERY_SMALL}
            buttonPosition={ButtonPosition.LEFT_RIGHT}
          />
        </div>
      </div>
      <div
        className="mt-2 w-full bg-surface-medium rounded-full flex justify-start"
        style={{ height: '4px' }}
      >
        <div
          aria-label={t('label__percentage', { percent: progress })}
          className="inline-block bg-st-purple-light rounded-full"
          style={{ width: `${progress}%`, height: '4px' }}
        ></div>
      </div>
    </div>
  );
};
