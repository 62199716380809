import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { useHistory, useParams } from 'react-router';

import { SessionStatus, UNJOINABLE_STATUSES } from 'types';

import { GameContextProvider } from 'contexts/GameContext';

import { useKeyboardShortcuts } from 'hooks/shortcuts/useKeyboardShortcuts';
import useGameSession, { useGameSessionReturn } from 'hooks/useGameSession';

import { JoinScreen } from 'components/game/JoinScreen';

import { LeftGameBar } from 'components/game/LeftGameBar';
import { LoadingScreen } from 'components/game/LoadingScreen';
import { MessageBox } from 'components/game/MessageBox';
import { MobileJoinScreen } from 'components/game/MobileJoinScreen';
import { PopupMessage } from 'components/game/PopupMessage';

import { MobileVideo } from 'components/videos/MobileVideo';
import { Videos } from 'components/videos/Videos';

import { UserProfileSubscription } from 'components/UserProfileSubscription';
import usePlayers from 'hooks/usePlayers';
import isCamera from 'utils/isCamera';
import { isChromeOnIOS } from 'utils/isCrios';

import useJoinGame from 'hooks/useJoinGame';

import { RightGameBar } from 'components/game/RightGameBar';
import isSpectator from 'utils/isSpectator';

import { GameRoom as Room } from 'api/modules/GameRoom';
import { GameRoom } from 'components/game/GameRoom';
import useGame from 'hooks/useGame';
import { useRecoilValue } from 'recoil';
import { gameRoomState } from 'state/atoms';

interface GameViewProps {
  useMobileCamera: boolean;
  isSpectator: boolean;
  mobileDeviceId: string;
}

const GameView: FunctionComponent<GameViewProps> = ({
  useMobileCamera,
  isSpectator,
  mobileDeviceId,
}) => {
  const { t } = useTranslation();
  const players = usePlayers();
  const game = useGame();

  useKeyboardShortcuts();
  useEffect(() => {
    if (isChromeOnIOS()) {
      alert(t('error__chrome-on-ios'));
    }
  }, [t]);
  if (isCamera() && useMobileCamera) {
    return (
      <div className="flex w-full h-full">
        {game && <MobileVideo gameId={game.id} deviceId={mobileDeviceId} />}
      </div>
    );
  }

  const subscriptions = players?.map((player) => {
    return <UserProfileSubscription uid={player.uid} key={player.uid} />;
  });

  return (
    <>
      {game && <GameRoom game={game} />}
      <div className="flex w-full h-full">
        {subscriptions}

        {!isCamera() && !isSpectator && (
          <LeftGameBar
            useMobileCamera={useMobileCamera}
            isSpectator={isSpectator}
          />
        )}
        <div className="flex flex-1 flex-col items-center overflow-hidden">
          <div className="w-full h-full relative">
            <Videos />
            <PopupMessage />
          </div>
        </div>

        <RightGameBar />
      </div>
    </>
  );
};

interface GameParams {
  id: string;
}

export const Game: FunctionComponent = () => {
  const { t } = useTranslation();
  const { joined, handleJoin, mobileDeviceId, handleMobileJoin } =
    useJoinGame();
  //TODO: Spectator functionality being kept for possible future implementation
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [spectateError, setSpectateError] = useState<boolean>(false);
  const { id } = useParams<GameParams>();
  const spectator = isSpectator();
  const history = useHistory();
  const state = useGameSession(id, spectator);

  const [delayContent, setDelayContent] = useState(true);

  // TODO: remove this as soon as possible, it's extremely hacky attempt to prevent too many spectators
  // this needs a backend solution, the frontend way means we must connect and then wait for roster
  useEffect(() => {
    let timeout: number;

    if (
      state.status !== SessionStatus.LOADING &&
      state.status !== SessionStatus.JOINING
    ) {
      timeout = window.setTimeout(() => {
        setDelayContent(false);
      }, 1000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [state.status]);

  const backToLobby = useCallback(() => {
    history.push('/lobby');
  }, [history]);

  if (spectateError) {
    return (
      <MessageBox
        title={t('error__spectator-error')}
        message={t('error__too-many-spectator')}
        buttonLabel={t('label__return-to-lobby')}
        onDismiss={backToLobby}
      />
    );
  }

  if (joined && !delayContent) {
    return (
      <InGame
        isSpectator={spectator}
        mobileDeviceId={mobileDeviceId}
        gameState={state}
      />
    );
  }

  if (isCamera()) {
    return <MobileJoinScreen onJoin={handleMobileJoin} />;
  }

  return <JoinScreen onJoin={handleJoin} />;
};

interface InGameProps {
  isSpectator: boolean;
  mobileDeviceId: string;
  gameState: useGameSessionReturn;
}

export const InGame: FunctionComponent<InGameProps> = (props) => {
  const { t } = useTranslation();
  const { isSpectator, mobileDeviceId, gameState: state } = props;
  const gameRoom = useRecoilValue<Room | undefined>(gameRoomState);
  const history = useHistory();
  const [delayContent, setDelayContent] = useState(true);

  useEffect(() => {
    let timeout: number;

    if (
      state.status !== SessionStatus.LOADING &&
      state.status !== SessionStatus.JOINING
    ) {
      timeout = window.setTimeout(() => {
        setDelayContent(false);
      }, 1000);
    }
    // leave the meeting if any of these things happen
    if (UNJOINABLE_STATUSES.includes(state.status)) {
      try {
        gameRoom?.leave();
      } catch (e) {
        console.warn(e);
      }
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.status]);

  const backToLobby = useCallback(async (): Promise<void> => {
    // No need to wait for this to happen before leaving.
    state.gameApi?.destroy();
    history.push('/lobby');
  }, [history, state.gameApi]);

  const content = useMemo((): JSX.Element | undefined => {
    if (delayContent) {
      return <LoadingScreen />;
    }

    let result;

    switch (state.status) {
      case SessionStatus.NO_GAME_EXISTS:
        result = (
          <MessageBox
            title={t('error__no-game-exists')}
            message={t('error__no-game-exists-message')}
            buttonLabel={t('label__return-to-lobby')}
            onDismiss={backToLobby}
          />
        );
        break;
      case SessionStatus.GAME_FULL:
        result = (
          <MessageBox
            title={t('error__game-full')}
            message={t('error__game-full-message')}
            buttonLabel={t('label__return-to-lobby')}
            onDismiss={backToLobby}
          />
        );
        break;
      case SessionStatus.GAME_DELETED:
        result = (
          <MessageBox
            title={t('error__game-deleted')}
            message={t('error__game-deleted-message')}
            buttonLabel={t('label__return-to-lobby')}
            onDismiss={backToLobby}
          />
        );
        break;
      case SessionStatus.KICKED:
        // TODO: since players can now be kicked for losing connection
        // we should implement some more nuanced action of dropping a player which
        // is different from kicked
        result = (
          <MessageBox
            title={t('error__kicked')}
            message={t('error__kicked-message')}
            buttonLabel={t('label__return-to-lobby')}
            showRejoin={false}
            onDismiss={backToLobby}
          />
        );
        break;
      case SessionStatus.TIMED_OUT:
        result = (
          <MessageBox
            title={t('error__timed-out')}
            message={t('error__timed-out-message')}
            buttonLabel={t('label__return-to-lobby')}
            showRejoin
            onDismiss={backToLobby}
          />
        );
        break;
      case SessionStatus.ERROR:
        result = (
          <MessageBox
            title={t('error__generic-error')}
            message={t('error__generic-error-message')}
            buttonLabel={t('label__return-to-lobby')}
            onDismiss={backToLobby}
          />
        );
        break;
      case SessionStatus.READY:
        result = (
          <GameView
            isSpectator={isSpectator}
            useMobileCamera={state.isUsingMobileCamera}
            mobileDeviceId={mobileDeviceId}
          />
        );
        break;
      default:
        break;
    }

    return result;
  }, [
    state.status,
    backToLobby,
    delayContent,
    state.isUsingMobileCamera,
    isSpectator,
    mobileDeviceId,
    t,
  ]);

  return (
    <GameContextProvider value={{ ...state }}>{content}</GameContextProvider>
  );
};
