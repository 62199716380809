import cx from 'classnames';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Card, FORMAT_INFO, MagicFormat, Player } from 'types';

import { Crown } from 'components/icons';
import { MoreHorizontal, Shield } from 'react-feather';

import useFirebaseUser from 'hooks/useFirebaseUser';
import useGame from 'hooks/useGame';
import useGameApi from 'hooks/useGameApi';

import { CardHoverPopover } from 'components/CardHoverPopover';
import { CustomTooltip } from 'components/CustomTooltip';
import { PlayerMenu } from 'components/menus/PlayerMenu';
import MicActivity from 'components/MicActivity';
import { ColorIdentity } from 'components/player/ColorIdentity';
import { CounterHorizontal } from 'components/player/CounterHorizontal';
import { PlayerPopover } from 'components/popovers/PlayerPopover';
import { useRecoilValue } from 'recoil';
import { userProfileState } from 'state/atoms';

import firebase from 'api/firebase';
import { NONE } from 'constants/app';

interface SlimPlayerBarProps {
  player: Player;
  showMenu?: boolean;
  orientation?: 'ltr' | 'rtl';
  size?: string;
}

export const SlimPlayerBar: FunctionComponent<SlimPlayerBarProps> = (props) => {
  const {
    player,
    showMenu = false,
    orientation = 'ltr',
    size = 'large',
  } = props;

  const user = useFirebaseUser();
  const gameApi = useGameApi();
  const { host, format } = useGame() || {};

  const { commanders = [], life = 40, name, uid, isDead, isMonarch } = player;
  const [playerDecklist, setPlayerDecklist] = useState<string>();
  const { t } = useTranslation();

  const editedName = name.split('#')[0];
  const profile = useRecoilValue(userProfileState(player.uid));
  const canEdit = uid === user?.uid;

  const playerIsHost = host === uid;

  const { hasCommander } = FORMAT_INFO[format || MagicFormat.Commander];

  useEffect(() => {
    const getDecklist = async () => {
      await firebase
        .firestore()
        .collection('users')
        .doc(uid)
        .onSnapshot((snap) => {
          setPlayerDecklist(snap?.data()?.decklistPath);
        });
    };
    getDecklist();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLifeChange = (life: number): void => {
    gameApi?.updateLife(life);
  };

  const menuClasses = cx(
    'opacity-100 transition-all ease-in-out duration-200',
    'opacity-100 transition-all ease-in-out duration-200 flex flex-row items-center'
  );

  const menuBtnClasses = cx(
    'p-1 shadow-md rounded text-white hover:bg-white hover:text-gray-950 transition-all ease-in-out duration-200',
    {
      'bg-surface-high': !showMenu,
      'bg-st-purple-light': showMenu,
    }
  );

  const decklistClasses = `flex flex-row text-white p-1 m-1 hover:bg-white bg-st-purple-light rounded cursor-pointer text-xxs items-end`;

  const nameTextClasses = cx(
    'font-bold flex flex-row truncate items-end leading-snug',
    {
      'text-sm': size === 'large',
      'text-xs': size === 'small',
      'line-through text-red-600': isDead,
    }
  );

  const flexClass = orientation === 'ltr' ? 'flex-row' : 'flex-row-reverse';

  const containerClasses = cx(
    'w-full px-2 py-1 flex items-center bg-black-50 hover:bg-black-75 transition-colors ease-in-out duration-200',
    flexClass
  );

  const infoContainerClasses = cx(
    'flex-1 overflow-hidden flex items-center',
    flexClass
  );

  const nameContainerClasses = cx(
    'cursor-pointer text-white w-full overflow-hidden flex flex-col justify-center',
    {
      'px-4': size === 'large' && canEdit,
      'px-2': size === 'small' || (size === 'large' && !canEdit),
      'text-right': orientation === 'rtl',
    }
  );

  const commandersContainerClasses = cx(
    'text-xs italic text-gray-400 truncate leading-snug flex',
    {
      'justify-end': orientation === 'rtl',
    }
  );

  const nameClasses = cx('flex items-center w-full', {
    'justify-start': orientation === 'ltr',
    'justify-end': orientation === 'rtl',
  });

  const pronounTextClasses = cx('px-2 truncate leading-snug', {
    'text-sm': size === 'large',
    'text-xs': size === 'small',
    'text-red-600': isDead,
  });

  const noCommandersText = canEdit
    ? t('label__decklist-commander')
    : t('label__no-commanders-set');

  const hostIcon = playerIsHost ? (
    <CustomTooltip tooltip={t('label__game-host')} placement="top">
      <div className="mr-1 text-st-orange-normal" style={{ width: '12px' }}>
        <Shield size="12" aria-label={t('label__shield-icon-game-host')} />
      </div>
    </CustomTooltip>
  ) : null;

  const monarch = isMonarch ? (
    <div className="px-2">
      <CustomTooltip tooltip={t('label__monarch')} placement="top">
        <div className="mr-1 text-st-orange-normal" style={{ width: '12px' }}>
          <Crown size="18" aria-label={t('label__monarch')} />
        </div>
      </CustomTooltip>
    </div>
  ) : null;

  const colorIdentityClasses = cx('mt-1 flex', {
    'justify-end': orientation === 'rtl',
  });

  const openDecklist = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      window.open(playerDecklist);
    },
    [playerDecklist]
  );

  const commanderNames = useMemo((): JSX.Element[] => {
    const items: JSX.Element[] = [];
    commanders.forEach((commander, index): void => {
      items.push(<CommanderName key={commander.id} commander={commander} />);

      if (index === 0 && commanders.length > 1) {
        items.push(
          <span key="commander-divider" className="px-1">
            /
          </span>
        );
      }
    });
    return items;
  }, [commanders]);

  return (
    <div className={containerClasses}>
      <div className={infoContainerClasses}>
        <div className="w-6">
          <MicActivity participantId={player.uid} />
        </div>
        <CounterHorizontal
          canEdit={canEdit}
          size={size}
          value={life}
          textColor={isDead ? 'text-red-600' : 'text-white'}
          onChange={onLifeChange}
        />
        <PlayerPopover position={orientation === 'rtl' ? 'right' : 'left'}>
          <div className="flex-1">
            {/* this is for screenreaders */}
            <input
              type="text"
              aria-describedby="playerpopover"
              style={{ display: 'none' }}
            />
            <CustomTooltip
              delay={1}
              tooltip={
                canEdit
                  ? t('label__click-to-edit-damage')
                  : t('label__click-to-view-damage')
              }
              placement="bottom"
            >
              <div className={nameContainerClasses}>
                <div className={nameClasses}>
                  {hostIcon}
                  <div className={nameTextClasses}>{editedName}</div>
                  <div className={pronounTextClasses}>
                    {profile?.preferences?.pronouns &&
                      `(${profile?.preferences?.pronouns})`}
                  </div>
                </div>
                {hasCommander && (
                  <div className={commandersContainerClasses}>
                    {commanders.length ? commanderNames : noCommandersText}
                  </div>
                )}
                <div className={colorIdentityClasses} style={{ height: '3px' }}>
                  <ColorIdentity />
                </div>
              </div>
            </CustomTooltip>
          </div>
        </PlayerPopover>
      </div>
      {monarch}
      <div className={menuClasses}>
        {playerDecklist && playerDecklist !== NONE && orientation === 'rtl' && (
          <div onClick={openDecklist} className={decklistClasses}>
            {t('label__view-decklist')}
          </div>
        )}
        <PlayerMenu position={orientation === 'rtl' ? 'right' : 'left'}>
          <button
            className={menuBtnClasses}
            aria-label={t('label__player-options')}
          >
            <MoreHorizontal size="14" aria-hidden="true" />
          </button>
        </PlayerMenu>
        {playerDecklist && playerDecklist !== NONE && orientation !== 'rtl' && (
          <div onClick={openDecklist} className={decklistClasses}>
            {t('label__view-decklist')}
          </div>
        )}
      </div>
    </div>
  );
};

interface CommanderNameProps {
  commander: Card;
}

const CommanderName: FunctionComponent<CommanderNameProps> = ({
  commander,
}) => {
  const { id, image_uris, back_image_uris, name } = commander;

  return (
    <CardHoverPopover
      key={id}
      imageUrl={image_uris?.normal}
      backImageUrl={back_image_uris?.normal}
      placement="bottom"
    >
      <div>{name}</div>
    </CardHoverPopover>
  );
};
