import React, { FunctionComponent, useEffect } from 'react';

import Api from 'api';

import { joinGame } from 'api/gateway/gateway';
import { GameRoom as Room, RoomUpdate } from 'api/modules/GameRoom';
import { AudioRenderer } from 'components/AudioRenderer';
import useFirebaseUser from 'hooks/useFirebaseUser';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  audioTracksState,
  errorMessageState,
  gameRoomState,
  mutedSpeakersState,
  mutedState,
  participantsState,
  roomConnectionStatusState,
  speakersState,
  videoEnabledState,
  videoResolutionState,
} from 'state/atoms';
import { Game } from 'types';
import { getAudioTracks } from 'utils/getAudioTrack';
import { localizationKeyFromError } from 'utils/getVideoStream';
import logger from 'utils/logger';

// Recoil needs things to be in a component, so this component exists
// only to update recoil and connect to a game
export const GameRoom: FunctionComponent<{ game: Game }> = ({ game }) => {
  const user = useFirebaseUser();
  const setSpeakers = useSetRecoilState(speakersState);
  const setVideoEnabled = useSetRecoilState(videoEnabledState);
  const setMuted = useSetRecoilState(mutedState);
  const setRoomConnectionState = useSetRecoilState(roomConnectionStatusState);
  const setGameRoom = useSetRecoilState(gameRoomState);
  const setMutedSpeakers = useSetRecoilState(mutedSpeakersState);
  const videoResolution = useRecoilValue(videoResolutionState);
  const [participants, setParticipants] = useRecoilState(participantsState);
  const [audioTracks, setAudioTracks] = useRecoilState(audioTracksState);
  const blocked = Api.getUserBlocklist();
  const setErrorMessage = useSetRecoilState(errorMessageState);

  useEffect(() => {
    if (!game?.id || !user?.uid) {
      logger.error('user or game not found');
      return;
    }
    const onUpdate = (update: RoomUpdate) => {
      setSpeakers(update.speakers);
      setMuted(update.isMuted);
      setVideoEnabled(update.isVideoEnabled);
      setRoomConnectionState(update.connectionState);
      setMutedSpeakers(update.speakersMuted);
      setParticipants(update.participants);
      setAudioTracks(update.audioTracks);
    };
    let room: Room;
    const init = async () => {
      const wotcToken = await Api.auth.refreshAuth();
      const joinToken = await joinGame(
        wotcToken.access_token,
        Api.auth.wotcAccountId!,
        game.id
      );
      try {
        room = new Room(user.uid, joinToken, videoResolution, onUpdate);
        await room.tryInit();
      } catch (e) {
        const mes = localizationKeyFromError(e as Error);
        setErrorMessage(mes);
        logger.error('Trouble initializing room', e as Error);
        return;
      }

      setGameRoom(room);
    };
    init();
    return () => {
      room?.leave();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!user?.uid) {
      return;
    }
    const tracks = getAudioTracks(user.uid, participants);
    setAudioTracks(tracks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blocked?.length]);

  return (
    <>
      {audioTracks.map((track, index) => {
        return <AudioRenderer key={index} track={track} />;
      })}
    </>
  );
};
