import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router';

import { Triangle, Circle } from 'react-feather';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/ui/Button';

import { Game, getMaxPlayers, MagicFormat } from 'types';
import tagListAsString from 'utils/tagListAsString';

interface GameCardProps {
  game: Game;
}

export const GameCard: FunctionComponent<GameCardProps> = (props) => {
  const { game } = props;

  const history = useHistory();
  const { t } = useTranslation();

  const numPlayers = game.players.filter((p) => p).length;

  const join = () => {
    history.push(`/game/${game.id}`);
  };

  const tagList = tagListAsString(t, game.tags);

  return (
    <div className="block rounded bg-surface-low p-4 w-full">
      <div className="flex flex-row justify-between w-full mb-4">
        <div className="flex flex-row content-center items-center text-xl text-white">
          {game.tags?.isStreaming ? (
            <Circle size="18" className="mr-2 fill-current text-red-700" />
          ) : (
            <Triangle size="18" className="mr-2" />
          )}
          {game.name}
        </div>
        <div className="flex flex-row content-center items-center text-xs text-muted">
          {tagList}
        </div>
      </div>
      <div className="flex flex-row justify-between w-full">
        <div className="flex flex-col">
          <span className="text-white uppercase font-bold text-sm">
            {t('label__players')}
          </span>
          <span className="text-muted text-sm">
            {numPlayers}/{getMaxPlayers(game.format)}
          </span>
        </div>
        <div className="flex flex-col">
          <span className="text-white uppercase font-bold text-sm">
            {t('label__format')}
          </span>
          <span className="text-muted text-sm">
            {game.format || MagicFormat.Commander}
          </span>
        </div>
        <div className="flex flex-col">
          <Button onClick={join} size="large">
            {t('action__join')}
          </Button>
        </div>
      </div>
    </div>
  );
};
