import React, { FunctionComponent, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Api from 'api';
import firebase from 'api/firebase';

import { MARKETING_LINK_CLICKED } from 'constants/bi-names';
import { AppSettings } from 'types';

const GEO_AMAZON_LINK = 'https://gtly.to/ERdBX8lqk';

export const ShopMagicOverlay: FunctionComponent = () => {
  const { t } = useTranslation();
  const [superdropUrl, setSuperdropUrl] = useState<string | null>();
  const [overlayVisibility, setOverlayVisibility] = useState<boolean>(true);
  const trackSEL = () => {
    Api.track(MARKETING_LINK_CLICKED, false, {
      link: 'store-locator',
      inGame: true,
    });
  };
  const trackAmazon = () => {
    Api.track(MARKETING_LINK_CLICKED, false, { link: 'amazon', inGame: true });
  };
  const trackSuperdrop = () => {
    Api.track(MARKETING_LINK_CLICKED, false, {
      link: 'superdrop',
      inGame: true,
    });
  };

  const hideOverlay = () => {
    setOverlayVisibility(false);
  };

  useEffect(() => {
    let mounted = true;
    const load = async () => {
      const settings = await firebase
        .firestore()
        .collection('settings')
        .doc('root')
        .get();
      const { superdrop_url } = settings.data() as AppSettings;
      if (mounted && superdrop_url) {
        setSuperdropUrl(superdrop_url);
      }
    };
    load();
    return () => {
      mounted = false;
    };
  }, []);

  if (!overlayVisibility) {
    return <></>;
  }
  return (
    <>
      {/* Used for the overlay background and not interrupting the opacity of other elements */}
      <div className="absolute bg-black-50 opacity-75 w-full h-full flex justify-center items-center z-10"></div>
      <div className="absolute w-full h-full flex justify-center items-center z-20">
        <div className="flex flex-col justify-center items-center">
          <div className="pb-2 uppercase text-lg">
            <Trans i18nKey="label__shop-magic">
              <span className="text-white font-extrabold"></span>
            </Trans>
          </div>
          <div className="flex flex-row">
            <a
              className="flex h-16 p-2 sm:p-4 items-center text-sm sm:text-base md:text-lg flex-col sm:flex-row border-white border-solid border-r-2"
              href="https://locator.wizards.com/?src=spelltable"
              target="_blank"
              rel="noopener noreferrer"
              onClick={trackSEL}
            >
              <img
                src={process.env.PUBLIC_URL + '/images/icon-wpn.png'}
                alt="WPN Logo"
              />
            </a>

            {superdropUrl && (
              <a
                className="flex h-16 p-2 sm:p-4 items-center text-sm sm:text-base md:text-lg flex-col sm:flex-row border-white border-solid border-r-2"
                href={superdropUrl}
                target="_blank"
                rel="noopener noreferrer"
                onClick={trackSuperdrop}
              >
                <img
                  src={process.env.PUBLIC_URL + '/images/icon-secret-lair.png'}
                  alt="Secret Lair Logo"
                />
              </a>
            )}

            <a
              className="flex h-16 p-2 sm:p-4 items-center text-sm sm:text-base md:text-lg flex-col sm:flex-row"
              href={GEO_AMAZON_LINK}
              target="_blank"
              rel="noopener noreferrer"
              onClick={trackAmazon}
            >
              <img
                src={process.env.PUBLIC_URL + '/images/icon-amazon.png'}
                alt="Amazon Logo"
              />
            </a>
          </div>
          <div className="pt-4">
            <span
              className="text-white underline cursor-pointer"
              onClick={hideOverlay}
            >
              {t('action__dismiss')}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
