import keycodes from 'constants/keycodes';

interface Shortcut {
  key: number | null;
  metaKey: boolean;
  shiftKey: boolean;
  altKey: boolean;
  ctrlKey: boolean;
}

export function parseShortcut(shortcut: string): Shortcut {
  const parts = shortcut.toLowerCase().split('+');
  const key = parts.pop();
  return {
    key: key ? keycodes[key] : null,
    metaKey: parts.includes('cmd'),
    shiftKey: parts.includes('shift'),
    altKey: parts.includes('alt'),
    ctrlKey: parts.includes('ctrl'),
  };
}
