import { useEffect, useState } from 'react';

// TODO: make this work when spectating is implemented.
// Counts how many spectators there are
export default function useSpectatorCount() {
  const [spectators, setSpectators] = useState<number>(0);
  useEffect(() => {
    setSpectators(0);
  }, []);

  return spectators;
}
