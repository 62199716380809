import React, { FunctionComponent, useCallback, useState } from 'react';

import { Select } from 'components/ui/Select';

export type DeviceSelectProps = {
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  devices: MediaDeviceInfo[];
  label: string;
  chosenDeviceId: string;
  textSize?: 'text-xs' | 'text-md' | 'text-sm' | 'text-lg';
};

export const BaseDeviceSelect: FunctionComponent<DeviceSelectProps> = ({
  onChange,
  devices,
  label,
  textSize,
  chosenDeviceId,
}) => {
  const [chosenDevice, setChosenDevice] = useState<string>();
  const currentDevice = chosenDevice ? chosenDevice : chosenDeviceId;

  const onDeviceChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      onChange && onChange(e);
      const deviceID = e.target.value;
      setChosenDevice(deviceID);
    },
    [onChange]
  );

  const DeviceOptions = devices?.map((device) => (
    <option value={device.deviceId} key={device.deviceId}>
      {device.label}
    </option>
  ));

  return (
    <>
      {devices && devices?.length > 0 && (
        <div className="flex flex-col align-center justify-center my-1">
          <span className={`ml-2 mb-1 ${textSize ? textSize : ''}`}>
            {label}
          </span>
          {currentDevice && devices?.length > 1 && (
            <Select
              onChange={onDeviceChange}
              value={currentDevice}
              variant="compact"
            >
              {DeviceOptions}
            </Select>
          )}
          {devices?.length === 1 && (
            <div className="ml-2">{devices[0].label}</div>
          )}
        </div>
      )}
    </>
  );
};
