import { Colors, ImageUris, RelatedUris, Prices } from './scryfall';

export enum CardTypes {
  Card = 'card',
  SpottedCard = 'spotted-card',
}

export interface Card {
  [index: string]:
    | CardTypes.Card
    | string
    | number
    | boolean
    | Colors[]
    | ImageUris
    | Prices
    | RelatedUris
    | undefined;
  type: CardTypes.Card;
  id: string;
  collector_number: string;
  colors?: Colors[];
  image_uris?: ImageUris;
  back_image_uris?: ImageUris;
  multiverseId?: number;
  name: string;
  prices: Prices;
  related_uris: RelatedUris;
  scryfall_uri: string;
  set: string;
  set_name: string;
  tcgplayer_id?: number;
  isFlipCard?: boolean;
  isBack?: boolean;
}

export interface SpottedCard {
  id: string;
  name: string;
  set: string;
  url: string;
  playerId: string;
  x1: number;
  x2: number;
  x3: number;
  x4: number;
  y1: number;
  y2: number;
  y3: number;
  y4: number;
  detectionId: string;
}

export interface NormalizedSpottedCard {
  id: string;
  type: CardTypes.SpottedCard;
  name: string;
  collector_number: string;
  spottedImageUrl: string;
  spottedId: string;
  set: string;
  isBack: boolean;
}

export type AnyCard = Card | NormalizedSpottedCard;
