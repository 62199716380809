import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MicOff } from 'react-feather';
import { mutedSpeakersState, speakersState } from 'state/atoms';
import { useRecoilValue } from 'recoil';

export const MicActivity: React.FC<{ participantId: string }> = ({
  participantId,
  ...rest
}) => {
  const { t } = useTranslation();
  const [volume, setVolume] = useState<Number>(0);
  const speakers = useRecoilValue<string[]>(speakersState);
  const mutedSpeakers = useRecoilValue<string[]>(mutedSpeakersState);
  const [muted, setMuted] = useState<boolean>(false);
  useEffect(() => {
    if (mutedSpeakers.includes(participantId)) {
      setMuted(true);
    } else {
      setMuted(false);
    }
    setVolume(speakers.includes(participantId) ? 1 : 0);
  }, [speakers, participantId, mutedSpeakers]);

  return (
    <div
      {...rest}
      aria-label={t('label__player-volume-indicator')}
      className={muted ? 'w-4 h-4' : 'h-4 w-2'}
    >
      {muted ? (
        <MicOff className="w-4 h-4" aria-label={t('label__player-muted')} />
      ) : (
        <div
          aria-hidden="true"
          className="w-full h-full bg-purple-500 transition-transform duration-200"
          style={{ transform: `scaleY(${volume})` }}
        ></div>
      )}
    </div>
  );
};

export default MicActivity;
