import React, { FunctionComponent, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import shortid from 'shortid';
import cx from 'classnames';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { Player } from 'types';
import { OrderedPlayers } from 'hooks/useOrderedPlayers';

import { UserX } from 'react-feather';

import { GameSession } from 'api/modules/GameSession';

import { CustomTooltip } from 'components/CustomTooltip';
import { Modal, Header, Buttons } from 'components/ui/Modal';
import { Button } from 'components/ui/Button';

interface ManagePlayersModalProps {
  onModalClose: () => void;
  players?: OrderedPlayers;
  gameApi?: GameSession;
}

interface DragDropLocation {
  index: number;
}

interface onDragEndResult {
  source?: DragDropLocation;
  destination?: DragDropLocation;
}

export const ManagePlayersModal: FunctionComponent<ManagePlayersModalProps> = (
  props
) => {
  const { onModalClose, players, gameApi } = props;
  const { t } = useTranslation();

  const updatePlayerPosition = useCallback(
    (result: onDragEndResult): void => {
      const { source, destination } = result;
      if (!source || !destination) {
        return;
      }
      const fromIndex = source.index;
      const toIndex = destination.index;

      if (fromIndex !== toIndex) {
        const player = players && players[fromIndex];
        if (player) {
          gameApi?.setPlayerPosition(player.uid, fromIndex, toIndex);
        }
      }
    },
    [gameApi, players]
  );

  const draggables = useMemo((): JSX.Element[] => {
    if (!players) {
      return [];
    }

    return players.map((player, index) => {
      const id = player?.uid || shortid.generate();
      const key = `player-slot-${id}`;
      return player ? (
        <PlayerDraggable
          key={key}
          player={player}
          index={index}
          gameApi={gameApi}
        />
      ) : (
        <EmptySlotDraggable key={key} index={index} />
      );
    });
  }, [players, gameApi]);

  return (
    <Modal onClose={onModalClose}>
      <Header>{t('label__manage-players')}</Header>
      <DragDropContext onDragEnd={updatePlayerPosition}>
        <Droppable droppableId="order-players" direction="vertical">
          {(provided) => (
            <div
              ref={provided.innerRef}
              className="bg-surface-medium px-2 pt-2 w-full rounded flex flex-col"
              {...provided.droppableProps}
            >
              {draggables}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <p className="mt-3 text-sm text-center">{t('label__drag-players')}</p>

      <Buttons>
        <Button onClick={onModalClose} size="large" variant="secondary">
          {t('label__close')}
        </Button>
      </Buttons>
    </Modal>
  );
};

interface PlayerDraggableProps {
  player: Player;
  index: number;
  gameApi?: GameSession;
}

const PlayerDraggable: FunctionComponent<PlayerDraggableProps> = (props) => {
  const { player, index, gameApi } = props;
  const { t } = useTranslation();

  const kickPlayer = useCallback(
    (uid: string): void => {
      gameApi?.kickPlayer(uid);
    },
    [gameApi]
  );

  return (
    <Draggable draggableId={player.uid} index={index}>
      {(provided, snapshot) => {
        const { isDragging } = snapshot;
        const classes = cx(
          'flex items-center hover:text-white hover:bg-surface-high justify-between w-full py-2 px-4 mb-2 rounded bg-surface-low cursor-move text-left transition-colors ease-in-out duration-200',
          {
            'bg-st-orange-normal text-gray-950': isDragging,
          }
        );

        const onKick = () => {
          const playerName = player.name.split('#')[0];
          if (
            window.confirm(
              playerName
                ? t('label__confirm-kick-player', { player: playerName })
                : t('label__confirm-kick-this-player')
            )
          ) {
            kickPlayer(player.uid);
          }
        };

        const kickButton = !gameApi?.isUserHost(player.uid) ? (
          <CustomTooltip tooltip={t('label__kick-player')}>
            <UserX
              size="18"
              className="text-red-600 hover:text-white cursor-pointer"
              onClick={onKick}
            />
          </CustomTooltip>
        ) : null;

        return (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={classes}
          >
            <div className="truncate">{player.name.split('#')[0]}</div>
            {kickButton}
          </div>
        );
      }}
    </Draggable>
  );
};

interface EmptySlotDraggableProps {
  index: number;
}

const EmptySlotDraggable: FunctionComponent<EmptySlotDraggableProps> = (
  props
) => {
  const { index } = props;
  const { t } = useTranslation();
  return (
    <Draggable draggableId={`empty-${index}`} index={index}>
      {(provided, snapshot) => {
        const { isDragging } = snapshot;
        const classes = cx(
          'flex items-center hover:text-white hover:border-surface-border justify-between w-full py-2 px-4 mb-2 rounded bg-surface-medium border border-surface-border cursor-move text-left transition-colors ease-in-out duration-200',
          {
            'border-st-orange-normal': isDragging,
          }
        );

        return (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={classes}
          >
            <div className="truncate">{t('label__empty-slot')}</div>
          </div>
        );
      }}
    </Draggable>
  );
};
