import {
  CommanderDamage,
  DamageTypes,
  DamageOwner,
  DamageCommander,
} from 'types';

export default function createCommanderDamageObject(
  owner: DamageOwner,
  commander: DamageCommander,
  value: number = 0
): CommanderDamage {
  return {
    type: DamageTypes.Commander,
    owner: {
      uid: owner.uid,
      name: owner.name,
    },
    commander: {
      id: commander.id,
      name: commander.name,
    },
    value,
  };
}
