import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

import { ChevronLeft, ChevronRight, Square, CheckSquare } from 'react-feather';

import { useHideOnboarding } from 'hooks/useHideOnboarding';

import { CustomModal } from 'components/modals/CustomModal';
import { SlideshowDot } from 'components/ui/SlideshowDot';

const TOUR_SLIDES = [
  {
    title: 'label__invite-players',
    image: '/images/tour/invite-players.jpg',
    caption: 'caption__invite-players',
  },
  {
    title: 'label__multiple-layouts',
    image: '/images/tour/layouts.jpg',
    caption: 'caption__multiple-layouts',
  },
  {
    title: 'label__game-menu',
    image: '/images/tour/game-menu.jpg',
    caption: 'caption__game-menu',
  },
  {
    title: 'label__player-info',
    image: '/images/tour/player-info.jpg',
    video: '/videos/tour/player-info.mp4',
    caption: 'caption__player-info',
  },
  {
    title: 'label__player-menu',
    image: '/images/tour/player-menu.jpg',
    video: '/videos/tour/player-menu.mp4',
    caption: 'caption__player-menu',
  },
  {
    title: 'label__identifying-cards',
    image: '/images/tour/card-spotting.jpg',
    video: '/videos/tour/card-spotting.mp4',
    caption: 'caption__identifying-cards',
  },
  {
    title: 'label__manual-card-search',
    image: '/images/tour/card-search.jpg',
    caption: 'caption__manual-card-search',
  },
  {
    title: 'label__additional-help',
    image: '/images/tour/additional-help.jpg',
    caption: 'caption__additional-help',
  },
  {
    title: 'label__rule-zero',
    image: '/images/tour/hero-image.jpg',
    caption: 'description__rule-zero',
  },
];

interface GameOnboardingModalProps {
  onModalClose: () => void;
}

export const GameOnboardingModal: FunctionComponent<
  GameOnboardingModalProps
> = (props) => {
  const { onModalClose } = props;

  const { t } = useTranslation();
  const [hideOnboarding, setHideOnboarding] = useHideOnboarding();

  const toggeHideOnboarding = () => {
    setHideOnboarding(!hideOnboarding);
  };

  const slides = TOUR_SLIDES.map((slide, index) => (
    <TourSlide
      key={`slide-${index}`}
      index={index}
      title={slide.title}
      video={slide.video}
      image={slide.image}
      caption={slide.caption}
    />
  ));

  const dots = TOUR_SLIDES.map((slide, index) => (
    <SlideshowDot key={`dot-${index}`} slide={index} />
  ));

  const dontShowCheck = hideOnboarding ? (
    <CheckSquare
      size="16"
      className="cursor-pointer text-st-orange-normal hover:text-white transition-colors duration-200 ease-in-out"
    />
  ) : (
    <Square
      size="16"
      className="cursor-pointer text-gray-600 hover:text-white transition-colors duration-200 ease-in-out"
    />
  );

  return (
    <CustomModal
      title={t('label__welcome')}
      buttons={[]}
      onModalClose={onModalClose}
      widthClass="w-full sm:w-1000"
      showCloseX
    >
      <div className="mb-4">{t('label__onboaring-intro')}</div>

      <CarouselProvider
        naturalSlideWidth={854}
        naturalSlideHeight={480}
        totalSlides={TOUR_SLIDES.length}
      >
        <div className="flex">
          <ButtonBack className="px-2">
            <ChevronLeft className="cursor-pointer text-gray-600 hover:text-white transition-colors duration-200 ease-in-out" />
          </ButtonBack>
          <Slider className="flex-1">{slides}</Slider>
          <ButtonNext className="px-2">
            <ChevronRight className="cursor-pointer text-gray-600 hover:text-white transition-colors duration-200 ease-in-out" />
          </ButtonNext>
        </div>
        <div className="w-full flex justify-center items-center py-6">
          {dots}
        </div>
        <div className="text-sm flex justify-center items-center">
          <div className="mr-2" onClick={toggeHideOnboarding}>
            {dontShowCheck}
          </div>
          <div className="text-gray-600">{t('label__dont-show-again')}</div>
        </div>
      </CarouselProvider>
    </CustomModal>
  );
};
interface TourSlide {
  index: number;
  title: string;
  caption: string;
  image: string;
  video?: string;
}

const TourSlide: FunctionComponent<TourSlide> = (props) => {
  const { index, title, caption, image, video } = props;
  const { t } = useTranslation();

  let imageOrVideo = (
    <img src={process.env.PUBLIC_URL + image} alt={t(title)} />
  );

  if (video) {
    imageOrVideo = (
      <video
        width="854"
        autoPlay
        muted
        loop
        src={process.env.PUBLIC_URL + video}
      >
        <source src={process.env.PUBLIC_URL + video} type="video/mp4" />
        <img src={process.env.PUBLIC_URL + image} alt={t(title)} />
      </video>
    );
  }

  return (
    <Slide index={index}>
      <div className="relative border border-surface-low">
        {imageOrVideo}
        <div className="absolute inset-x-0 bottom-0 bg-black text-white py-2 px-3 text-center">
          {t(caption)}
        </div>
      </div>
    </Slide>
  );
};
