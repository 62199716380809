import { useMemo } from 'react';
import { Player } from 'types';
import usePlayers from 'hooks/usePlayers';

export default function usePlayer(uid: string | undefined): Player | undefined {
  const players = usePlayers();
  return useMemo(() => {
    return players?.find((p) => p.uid === uid);
  }, [uid, players]);
}
