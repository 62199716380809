import React, { FunctionComponent, useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Modal, Header, Buttons } from 'components/ui/Modal';
import { Button } from './ui/Button';
import Api from 'api';
import { LoadingIcon } from './icons';

interface DestroyGameModalProps {
  id: string;
  onModalClose: () => any;
}

export const DestroyGameModal: FunctionComponent<DestroyGameModalProps> = (
  props
) => {
  const { onModalClose, id } = props;
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const destroyGame = useCallback(async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    await Api.games.deleteGame(id);
    setLoading(false);
    onModalClose();
  }, [onModalClose, id, loading]);

  return (
    <Modal onClose={onModalClose}>
      <Header>{t('label__delete-game')}</Header>

      <p className="mt-3 text-sm text-center">
        <Trans i18nKey="label__delete-game-warning">
          <strong></strong>
        </Trans>
      </p>

      <Buttons>
        <Button
          onClick={destroyGame}
          size="large"
          variant="primary"
          className="mr-2"
        >
          {loading ? <LoadingIcon /> : t('action__delete-game')}
        </Button>
        <Button onClick={onModalClose} size="large" variant="secondary">
          {t('action__cancel')}
        </Button>
      </Buttons>
    </Modal>
  );
};
