import { CustomScrollbars } from 'components/CustomScrollbars';
import React, { FunctionComponent, useState, useEffect } from 'react';
import firebase from 'api/firebase';
import useGame from 'hooks/useGame';
import { GameLogItem } from 'api/gamelog';
import { LogItem } from './LogItem';
import { useTranslation } from 'react-i18next';

export const GameLog: FunctionComponent = () => {
  const { id } = useGame() || {};
  const { t } = useTranslation();
  const [gameLogItems, setGameLogItems] = useState<GameLogItem[]>([]);
  useEffect(() => {
    const db = firebase.database().ref(`/game-logs/${id}`);
    let mounted = true;
    db.on('value', (snapshot) => {
      if (mounted) {
        const gameLogs: GameLogItem[] = [];

        // Need to use forEach so that we retain ordering
        snapshot.forEach((child) => {
          gameLogs.push(child.val() as GameLogItem);
        });
        setGameLogItems(gameLogs);
      }
    });

    return () => {
      mounted = false;
      db.off();
    };
  }, [id]);
  const gameLog = gameLogItems.map((item, i) => {
    return <LogItem key={i} item={item} />;
  });

  return (
    <div className="py-2 flex flex-col flex-1 w-full">
      <div className="flex-1">
        <CustomScrollbars scrollToBottom={true} viewClasses="pr-4 mt-2">
          {t('description__rule-zero')}
          {gameLog}
        </CustomScrollbars>
      </div>
    </div>
  );
};
