import { AccountMenu } from 'components/AccountMenu';
import { Button } from 'components/ui/Button';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

interface LobbyHeaderProps {
  signoutCleanup?: () => any;
  showBackButton?: boolean;
}

export const LobbyHeader: FunctionComponent<LobbyHeaderProps> = ({
  signoutCleanup,
  showBackButton = false,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <div className="flex items-center w-full flex-col phone:flex-row">
      <Link to="/lobby" className="mr-6">
        <img
          alt="SpellTable"
          src={
            process.env.PUBLIC_URL +
            '/images/logo/MTG_SpellTable_Logo_Horiz.svg'
          }
          className="mt-6 mb-6 w-96"
        />
      </Link>

      <div className="ml-auto flex items-center w-full phone:w-auto mt-6 phone:mt-0">
        <div className="ml-auto phone:ml-0 flex flex-row items-center">
          {showBackButton ? (
            <Button
              className=" "
              onClick={() => history.push('/lobby?login=true')}
            >
              {t('label__lobby-config-page')}
            </Button>
          ) : (
            <AccountMenu signoutCleanup={signoutCleanup} />
          )}
        </div>
      </div>
    </div>
  );
};
