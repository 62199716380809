import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Header } from 'components/homepage/Header';
import { Footer } from 'components/homepage/Footer';

export function Troubleshooting() {
  const { t } = useTranslation();

  const headingClasses = 'text-st-orange-normal text-lg font-bold mb-4 mt-12';

  const emailLink = (
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    <a
      className="hover:underline text-st-orange-normal hover:text-st-orange-light transition-colors duration-200 ease-in-out cursor-pointer"
      href="https://magic-support.wizards.com/hc/en-us/requests/new?ticket_form_id=360000005043"
    ></a>
  );

  return (
    <div>
      <div className="flex justify-center">
        <div className="flex flex-col w-full px-6 sm:px-0 sm:w-10/12 lg:w-1000 relative">
          <div>
            <Header />
          </div>
        </div>
      </div>
      <div className="flex justify-center pb-24">
        <div className="flex flex-col w-full px-6 sm:px-0 sm:w-600 flex justify-center items-start leading-loose">
          <div className="text-3xl mb-8 text-white font-thin">
            {t('title__troubleshooting-guide')}
          </div>
          <div className={headingClasses}>
            {t('troubleshooting-title__recommended-desktop-browser')}
          </div>
          <div className="mb-4">
            <span className="font-bold text-white">
              {t('label__google-chrome')}
            </span>
          </div>
          <div className="mb-4">
            {t('troubleshooting-info__recommended-desktop-browser')}
          </div>
          <div className={headingClasses}>
            {t('troubleshooting-title__recommended-mobile-browser')}
          </div>
          <div className="mb-4">
            <Trans i18nKey="troubleshooting-info__recommended-mobile-browser-ios">
              <span className="text-gray-300 font-bold"></span>
              <span className="font-bold underline"></span>
            </Trans>
            <br />
            <Trans i18nKey="troubleshooting-info__recommended-mobile-browser-android">
              <span className="text-gray-300 font-bold"></span>
            </Trans>
          </div>
          <div className={headingClasses}>
            {t('troubleshooting-title__video-stays-black')}
          </div>
          <div className="mb-4">
            {t('troubleshooting-info__video-stays-black')}
          </div>
          <div className={headingClasses}>
            {t('troubleshooting-title__not-found-error')}
          </div>
          <div className="mb-4">
            {t('troubleshooting-info__not-found-error')}
          </div>
          <div className={headingClasses}>
            {t('troubleshooting-title__not-readable-error')}
          </div>
          <div className="mb-4">
            {t('troubleshooting-info__not-readable-error')}
          </div>
          <div className={headingClasses}>
            {t('troubleshooting-title__over-constrained-error')}
          </div>
          <div className="mb-4">
            <Trans i18nKey="troubleshooting-info__over-constrained-error">
              {emailLink}
            </Trans>
          </div>
          <div className={headingClasses}>
            {t('troubleshooting-title__not-allowed-error')}
          </div>
          <div className="mb-4">
            {t('troubleshooting-info__not-allowed-error')}
          </div>
          <div className={headingClasses}>
            {t('troubleshooting-title__type-error')}
          </div>
          <div className="mb-4">
            <Trans i18nKey="troubleshooting-info__type-error">
              {emailLink}
            </Trans>
          </div>
          <div className={headingClasses}>
            {t('troubleshooting-title__uncaught-error')}
          </div>
          <div className="mb-4">
            <Trans i18nKey="troubleshooting-info__uncaught-error">
              {emailLink}
            </Trans>
          </div>
          <div className={headingClasses}>
            {t('troubleshooting-title__didnt-find-a-solution')}
          </div>
          <div className="mb-4">
            <Trans i18nKey="troubleshooting-info__didnt-find-a-solution">
              {emailLink}
            </Trans>
          </div>
        </div>
      </div>
      <div className="flex justify-center pt-16 bg-black">
        <div className="flex flex-col w-full px-6 sm:px-0 sm:w-10/12 lg:w-1500 flex justify-center items-center">
          <Footer />
        </div>
      </div>
    </div>
  );
}
