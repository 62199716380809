import { ScryfallCard, Card, CardTypes } from 'types';

export function getCardFromScryfallData(data: ScryfallCard): Card {
  const {
    card_faces,
    collector_number,
    color_identity,
    id,
    image_uris,
    name,
    multiverse_ids,
    prices,
    related_uris,
    set,
    set_name,
    tcgplayer_id,
    scryfall_uri,
  } = data;

  const result: Card = {
    type: CardTypes.Card,
    id,
    colors: color_identity || [],
    collector_number,
    image_uris,
    name: name,
    prices,
    related_uris,
    set,
    set_name,
    tcgplayer_id,
    multiverseId: multiverse_ids && multiverse_ids.length && multiverse_ids[0],
    scryfall_uri,
  };

  /**
   * if the card has card_faces AND those card faces have image_uris
   * then its a flip card with a front and back.
   */
  if (card_faces && card_faces?.length > 0 && card_faces[0].image_uris) {
    result.isFlipCard = true;
    result.image_uris = card_faces[0]?.image_uris;
    result.back_image_uris = card_faces[1]?.image_uris;
  }

  // Sanitize off any undefined values just so it never makes it to Firebase.
  const sanitizedResult = Object.keys(result).reduce((acc, prop) => {
    if (result[prop] !== undefined) {
      acc[prop] = result[prop];
    }
    return acc;
  }, {} as Card);

  return sanitizedResult;
}
