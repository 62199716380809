import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from 'locales/en.json';
import de from 'locales/de.json';
import es from 'locales/es.json';
import fr from 'locales/fr.json';
import it from 'locales/it.json';
import ja from 'locales/ja.json';
import ko from 'locales/ko.json';
import pt from 'locales/pt.json';
import ru from 'locales/ru.json';
import zhCN from 'locales/zh-CN.json';
import zhTW from 'locales/zh-TW.json';

const availableLocales = [
  'en',
  'de',
  'es',
  'fr',
  'it',
  'ja',
  'ko',
  'pt',
  'ru',
  'zh-CN',
  'zh-TW',
];

const resources = {
  en: { translation: en },
  de: { translation: de },
  es: { translation: es },
  fr: { translation: fr },
  it: { translation: it },
  ja: { translation: ja },
  ko: { translation: ko },
  pt: { translation: pt },
  ru: { translation: ru },
  'zh-CN': { translation: zhCN },
  'zh-TW': { translation: zhTW },
};
i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: (code) => {
      // This will help catch cases where a code does not match up exactly
      // Example: 'de-DE' so we split it to fallback on 'de' which we do have
      const simpleCode = code.split('-')[0];
      const fallbackIndex = availableLocales
        .map((c) => c.split('-')[0])
        .indexOf(code);
      // All fallbacks include 'en' in case their are missing keys between them
      if (availableLocales.includes(code)) {
        // exact match
        return [code, 'en'];
      } else if (availableLocales.includes(simpleCode)) {
        // match of base string of input lang
        return [simpleCode, 'en'];
      } else if (fallbackIndex >= 0) {
        // match of base input
        return [availableLocales[fallbackIndex], 'en'];
      }
      return 'en';
    },
    keySeparator: false, // we do not use keys in form messages.welcome
    debug: false,
    nonExplicitSupportedLngs: true,
    detection: {
      caches: ['localStorage'],
    },
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
