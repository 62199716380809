import React, { FC, useLayoutEffect, useState } from 'react';
import { ShoppingBag } from 'react-feather';
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

import Api from 'api';
import firebase from 'api/firebase';

import { MARKETING_LINK_CLICKED } from 'constants/bi-names';
import { useTranslation } from 'react-i18next';
import { AppSettings } from 'types';

import { SlideshowDot } from './ui/SlideshowDot';
import { CURRENT_DATE } from 'constants/dates';
import { LoadingIcon } from './icons';

const GEO_AMAZON_LINK = 'https://gtly.to/ERdBX8lqk';

export const Marketing: FC = () => {
  const { t } = useTranslation();
  const [superdropUrl, setSuperdropUrl] = useState<string | null>();
  const [shouldShowSuperdrop, setShouldShowSuperdrop] =
    useState<boolean>(false);
  const [isLoadDone, setIsLoadDone] = useState<boolean>(false);
  const [bannerImagePath, setBannerImagePath] = useState<string>();

  const trackSEL = () => {
    Api.track(MARKETING_LINK_CLICKED, false, { link: 'store-locator' });
  };
  const trackAmazon = () => {
    Api.track(MARKETING_LINK_CLICKED, false, { link: 'amazon' });
  };
  const trackSuperdrop = () => {
    Api.track(MARKETING_LINK_CLICKED, false, { link: 'superdrop' });
  };
  const trackSuperdropBanner = () => {
    Api.track(MARKETING_LINK_CLICKED, false, { link: 'superdrop-banner' });
  };

  useLayoutEffect(() => {
    let mounted = true;
    const load = async () => {
      const settings = await firebase
        .firestore()
        .collection('settings')
        .doc('banner')
        .get();
      const superdropData = settings.data() as AppSettings | undefined;

      if (superdropData) {
        const superdropExpiration: Date | undefined =
          superdropData.superdrop_expiration
            ? new Date(superdropData?.superdrop_expiration?.seconds * 1000)
            : undefined;

        if (superdropExpiration && superdropExpiration < CURRENT_DATE) {
          setShouldShowSuperdrop(false);
          setIsLoadDone(true);
        } else {
          const bannerPath = await (
            await firebase.storage().ref('/banner').list()
          ).items[0].getDownloadURL();

          setBannerImagePath(bannerPath);
          setShouldShowSuperdrop(true);
          setIsLoadDone(true);
        }
      } else {
        setIsLoadDone(true);
      }
      if (mounted) {
        setSuperdropUrl(
          superdropData?.superdrop_url
            ? superdropData.superdrop_url
            : 'https://secretlair.wizards.com'
        );
      }
    };

    load();
    return () => {
      mounted = false;
    };
  }, []);
  return (
    <>
      {!isLoadDone ? (
        <LoadingIcon className="flex flex-row w-full justify-center items-center" />
      ) : (
        <CarouselProvider
          naturalSlideWidth={1023}
          naturalSlideHeight={200}
          totalSlides={shouldShowSuperdrop ? 2 : 1}
          isPlaying={true}
          interval={4000}
        >
          <Slider className="flex-1">
            {shouldShowSuperdrop && (
              <Slide index={0}>
                <div className="flex justify-center">
                  <a
                    href={superdropUrl ?? ''}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={trackSuperdropBanner}
                  >
                    <img src={bannerImagePath} alt="Secret Lair" />
                  </a>
                </div>
              </Slide>
            )}

            <Slide index={shouldShowSuperdrop ? 1 : 0}>
              <div className="flex flex-col p-2 items-center text-white">
                <span className="uppercase font-bold">
                  {t('Marketing__where-to-buy')}
                </span>
                <div className="mt-4 flex flex-row w-full justify-center">
                  <a
                    className="flex h-16 p-2 sm:p-4 border-2 border-white rounded-lg items-center mr-5 text-sm sm:text-base md:text-lg"
                    href="https://locator.wizards.com/?src=spelltable"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={trackSEL}
                  >
                    <ShoppingBag className="mr-2" />
                    {t('Marketing__your-local-store')}
                  </a>
                  <a
                    className="flex h-16 p-2 sm:p-4 border-2 border-white rounded-lg items-center mr-5 text-sm sm:text-base md:text-lg flex-col sm:flex-row"
                    href={GEO_AMAZON_LINK}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={trackAmazon}
                  >
                    <img
                      src={process.env.PUBLIC_URL + '/images/icon-amazon.png'}
                      alt="Amazon Logo"
                      className="sm:mr-2 w-1/2 sm:w-1/3 md:w-auto"
                    />
                    Amazon
                  </a>
                  {superdropUrl && (
                    <a
                      className="flex h-16 p-2 sm:p-4 border-2 border-white rounded-lg items-center"
                      href={superdropUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={trackSuperdrop}
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL + '/images/SLD_Logo_white.png'
                        }
                        alt="Secret Lair Logo"
                        className="mx-2 sm:mx-4"
                      />
                    </a>
                  )}
                </div>
              </div>
            </Slide>
          </Slider>
          {shouldShowSuperdrop && (
            <div className="w-full flex justify-center items-center pt-2">
              <SlideshowDot key={`dot-0`} slide={0} />
              <SlideshowDot key={`dot-1`} slide={1} />
            </div>
          )}
        </CarouselProvider>
      )}
    </>
  );
};
