import { useState, useEffect, useCallback } from 'react';

import Api from 'api';

type useHideOnboardingReturn = [boolean, (hide: boolean) => void];

export function useHideOnboarding(): useHideOnboardingReturn {
  const user = Api.user.getUser();
  const [hide, setHide] = useState<boolean | undefined>(user?.hideOnboarding);

  const set = useCallback(async (hideOnboarding: boolean) => {
    await Api.user.update({ hideOnboarding });
    setHide(hideOnboarding);
  }, []);

  /**
   * If hideOnboarding doesn't exist on the user then we default it to true.
   * This would be the first time they see the onboarding.
   */
  useEffect(() => {
    const hideByDefault = async () => {
      await Api.user.update({ hideOnboarding: true });
      setHide(true);
    };

    if (hide === undefined) {
      hideByDefault();
    }
  }, [hide]);

  return [hide === true, set];
}
