import * as THREE from 'three';
import { baseDice } from './baseDice';

export default function Coin(
  scene: THREE.Scene,
  radius: number,
  materialColor: string
) {
  const geometry = new THREE.CylinderGeometry(radius, radius, 2, 1000);
  const { update, group } = baseDice(geometry, scene, materialColor);
  return { update, group };
}
