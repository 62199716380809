import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { usePlayerContext } from 'contexts/PlayerContext';

import useFirebaseUser from 'hooks/useFirebaseUser';
import useGameApi from 'hooks/useGameApi';
import useIsHost from 'hooks/useIsHost';

import {
  CustomMenu,
  MenuItems,
  MenuItemTypes,
} from 'components/menus/CustomMenu';
import { ReportPlayerModal } from 'components/modals/ReportPlayerModal';
import { useGameContext } from 'contexts/GameContext';
import useGame from 'hooks/useGame';
import { useModal } from 'react-modal-hook';
import isSpectator from 'utils/isSpectator';

interface PlayerMenuProps {
  position?: 'right' | 'left';
}

export const PlayerMenu: FunctionComponent<PlayerMenuProps> = (props) => {
  const { children, position } = props;
  const { t } = useTranslation();

  const placement = position === 'right' ? 'bottomLeft' : 'bottomRight';

  const user = useFirebaseUser();
  const isHost = useIsHost();
  const gameApi = useGameApi();
  const game = useGame();

  const { userBlocklist } = useGameContext();
  const { onRotate, player } = usePlayerContext();
  const { uid, isDead = false, isMonarch = false } = player || {};
  const { format } = game || {};
  const playerIsUser = uid === user?.uid;
  const [showReportModal, hideReportModal] = useModal((): JSX.Element => {
    return <ReportPlayerModal player={player} onModalClose={hideReportModal} />;
  });

  // Hacky way to close the tooltip component we are using in CustomMenu. :\
  const closeMenu = useCallback((): void => {
    document.dispatchEvent(new MouseEvent('mousedown'));
  }, []);

  const handleRotate = useCallback((): void => {
    closeMenu();
    if (onRotate) {
      onRotate();
    }
  }, [onRotate, closeMenu]);

  const toggleDead = useCallback((): void => {
    closeMenu();
    if (!uid) {
      return;
    }
    gameApi?.togglePlayerDead(uid, !isDead);
  }, [gameApi, uid, isDead, closeMenu]);

  const toggleMonarch = useCallback((): void => {
    closeMenu();
    if (!uid) {
      return;
    }
    gameApi?.togglePlayerAsMonarch(uid, !isMonarch);
  }, [gameApi, uid, closeMenu, isMonarch]);

  const resetPlayer = useCallback((): void => {
    closeMenu();
    if (uid && window.confirm(t('label__confirm-reset-player'))) {
      gameApi?.resetPlayer(uid, true, true, format);
    }
  }, [gameApi, uid, closeMenu, t, format]);

  const kickPlayer = useCallback((): void => {
    closeMenu();
    if (!player) {
      return;
    }

    const playerName = player.name.split('#')[0];
    if (
      window.confirm(
        playerName
          ? t('label__confirm-kick-player', { player: playerName })
          : t('label__confirm-kick-this-player')
      )
    ) {
      gameApi?.kickPlayer(player.uid);
    }
  }, [gameApi, player, closeMenu, t]);

  const blockPlayer = useCallback((): void => {
    closeMenu();
    if (!player) {
      return;
    }
    const isBlocked = userBlocklist?.includes(player.uid);
    const playerName = player.name.split('#')[0];
    if (
      window.confirm(
        playerName
          ? t(`label__confirm-${isBlocked ? 'un' : ''}block-player`, {
              player: playerName,
            })
          : t(`label__confirm-${isBlocked ? 'un' : ''}block-this-player`)
      )
    ) {
      gameApi?.toggleBlockPlayer(player.uid);
    }
  }, [gameApi, player, closeMenu, userBlocklist, t]);

  const menuItems = useMemo((): MenuItems => {
    let items: MenuItems = [
      {
        type: MenuItemTypes.ITEM,
        label: t('label__flip-video'),
        onClick: handleRotate,
      },
      { type: MenuItemTypes.DIVIDER },
    ];

    // TODO: this needs to be enforced on the backend
    if (!isSpectator()) {
      items.push({
        type: MenuItemTypes.ITEM,
        label: isMonarch ? t('label__clear-monarch') : t('label__set-monarch'),
        onClick: toggleMonarch,
      });

      // Add dead option.
      items.push({
        type: MenuItemTypes.TOGGLE,
        label: t('label__eliminated'),
        onClick: toggleDead,
        enabled: isDead,
      });
    }

    if (playerIsUser) {
      items = [
        ...items,
        { type: MenuItemTypes.DIVIDER },
        {
          type: MenuItemTypes.ITEM,
          label: t('label__reset'),
          onClick: resetPlayer,
        },
      ];
    }

    if (isHost && !playerIsUser) {
      items = [
        ...items,
        { type: MenuItemTypes.DIVIDER },
        {
          type: MenuItemTypes.DANGER_ITEM,
          label: t('label__kick-player'),
          onClick: kickPlayer,
        },
      ];
    }

    if (!playerIsUser) {
      const isBlocked = userBlocklist?.includes(player?.uid || '');
      items = [
        ...items,
        {
          type: MenuItemTypes.DANGER_ITEM,
          label: isBlocked
            ? t('label__unblock-player')
            : t('label__block-player'),
          onClick: blockPlayer,
        },
      ];
    }

    if (!playerIsUser) {
      items = [
        ...items,
        {
          type: MenuItemTypes.DANGER_ITEM,
          label: t('label__report-player'),
          onClick: showReportModal,
        },
      ];
    }

    return items;
  }, [
    handleRotate,
    isHost,
    kickPlayer,
    playerIsUser,
    isDead,
    isMonarch,
    toggleDead,
    toggleMonarch,
    resetPlayer,
    blockPlayer,
    userBlocklist,
    player,
    showReportModal,
    t,
  ]);

  return (
    <div>
      <CustomMenu
        placement={placement}
        menuItems={menuItems}
        paddingClass="py-1"
        widthClass="w-40"
        tight
      >
        {children}
      </CustomMenu>
    </div>
  );
};
