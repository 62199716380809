import { useCallback, useState } from 'react';

// Handles joining a game and has been greatly reduced in terms of functionality.
// Could have this in the game file but does reduce some lines of code.
export default function useJoinGame() {
  const [joined, setJoined] = useState(false);
  // TODO: This should probably be changed to be global state rather than being passed around everywhere
  const [mobileDeviceId, setMobileDeviceId] = useState('');
  const handleJoin = useCallback(() => {
    setJoined(true);
  }, []);

  const handleMobileJoin = useCallback((deviceId: string) => {
    setMobileDeviceId(deviceId);
    setJoined(true);
  }, []);

  return {
    handleJoin,
    handleMobileJoin,
    joined,
    mobileDeviceId,
  };
}
