/* eslint-disable jsx-a11y/anchor-has-content */
import React, { FunctionComponent } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { HelpCircle, Radio } from 'react-feather';

import { Header } from 'components/homepage/Header';
import { Footer } from 'components/homepage/Footer';
import useLoginAction from 'hooks/useLoginAction';
import { AnnouncementHeader } from 'components/homepage/ImportantHeader';

export const Home: FunctionComponent = () => {
  const { t } = useTranslation();
  const login = useLoginAction();

  return (
    <div>
      <AnnouncementHeader />
      <div
        className="bg-cover bg-center flex flex-col justify-center items-center pb-24"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/card-bg-2.jpg)`,
        }}
      >
        <div className="flex flex-col w-full px-6 sm:px-0 sm:w-10/12 lg:w-1500 relative">
          <div>
            <Header />
          </div>
          <div className="flex-1 flex flex-col justify-center py-8 xxxs:py-16 sm:py-28 md:py-48">
            <div className="text-5xl text-center xs:text-6xl xxs:text-6xl xs:text-7xl text-white font-hairline leading-tight">
              <Trans i18nKey="title__spelltable-tagline">
                <span className="font-bold text-st-purple-light"></span>
                <span className="text-st-orange-normal"></span>
              </Trans>
            </div>
            <div className="pt-12 text-center flex justify-center items-center xxs:hidden">
              <button
                onClick={login}
                className="text-st-orange-normal hover:text-st-orange-light hover:underline mr-6 cursor-pointer transition-colors ease-in-out duration-200"
              >
                {t('action__login')}
              </button>
              <button
                onClick={login}
                className="inline-block bg-st-purple-light text-white hover:bg-st-purple-normal font-bold cursor-pointer rounded px-4 py-2 shadow-md transition-colors ease-in-out duration-200"
              >
                {t('action__get-started')}
              </button>
            </div>
          </div>
          <div className="justify-center flex pt-8 xxxs:pt-16 sm:pt-24">
            <div className="w-full lg:w-1500 flex justify-center">
              <div
                className="bg-contain w-full rounded-md shadow-lg border border-gray-700"
                style={{
                  backgroundImage: `url(${process.env.PUBLIC_URL}/images/screenshots/hero-image.jpg)`,
                  paddingTop: '47.46%',
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center py-24 bg-surface-medium">
        <div className="flex flex-col w-full px-6 sm:px-0 sm:w-10/12 lg:w-1500 flex justify-center items-center">
          <div className="w-full xxs:w-9/12 bg-st-orange-normal text-surface-high text-lg xxs:text-3xl p-4 xxs:p-12 font-hairline italic text-center">
            <Trans i18nKey="home__spelltable-info">
              <span className="font-bold"></span>
            </Trans>
          </div>
        </div>
      </div>
      <div className="flex justify-center py-24">
        <div className="flex flex-col w-full px-6 sm:px-0 sm:w-10/12 lg:w-1500 flex justify-center items-center">
          <div className="w-full sm:w-11/12">
            <div className="text-center text-white text-left text-xl uppercase sm:text-3xl font-hairline">
              {t('title__features')}
            </div>

            <div className="flex justify-start w-full">
              <div
                className="flex flex-col sm:flex-row w-full items-center py-6"
                style={{ maxWidth: '1000px' }}
              >
                <div
                  className="w-full bg-black relative self-start sm:self-auto rounded-md shadow-lg border border-gray-700"
                  style={{ maxWidth: '399px', maxHeight: '256px' }}
                >
                  <div
                    className="w-full bg-contain rounded-md"
                    style={{
                      backgroundImage: `url(${process.env.PUBLIC_URL}/images/features/audio-video.jpg)`,
                      paddingTop: '64%',
                    }}
                  ></div>
                  <div className="absolute bottom-0 right-0 bg-st-purple-light mx-4 leading-loose xxs:-mr-16 -mb-8 sm:mb-8 rounded text-white sm:text-xl py-4 px-6 font-thin shadow-lg">
                    <Trans i18nKey="feature__audio-video">
                      <span className="font-bold"></span>
                    </Trans>
                  </div>
                </div>
                <div className="w-full xxs:w-2/3 sm:w-3/5 sm:pl-48 text-gray-600 sm:text-lg leading-loose bg-surface-medium pt-20 py-12 px-6 sm:pt-12 sm:px-12 sm:mt-32 sm:-ml-24 rounded">
                  {t('feature-info__audio-video')}
                </div>
              </div>
            </div>

            <div className="flex justify-end w-full">
              <div
                className="flex flex-col-reverse sm:flex-row w-full justify-end items-center py-6"
                style={{ maxWidth: '1000px' }}
              >
                <div className="w-full xxs:w-2/3 sm:w-3/5 sm:pr-48 text-gray-600 sm:text-lg leading-loose bg-surface-medium pt-20 py-12 px-6 sm:pt-12 sm:px-12 sm:pt-12 sm:mt-32 sm:-mr-24 rounded">
                  {t('feature-info__card-identification')}
                </div>
                <div
                  className="w-full bg-black relative self-end sm:self-auto rounded-md shadow-lg border border-gray-700"
                  style={{ maxWidth: '399px', maxHeight: '256px' }}
                >
                  <div
                    className="w-full bg-contain rounded-md"
                    style={{
                      backgroundImage: `url(${process.env.PUBLIC_URL}/images/features/card-spotting.jpg)`,
                      paddingTop: '64%',
                    }}
                  ></div>
                  <div className="absolute bottom-0 sm:bottom-auto sm:top-0 left-0 bg-st-purple-light mx-4 leading-loose xxs:-ml-16 -mb-8 sm:mt-8 rounded text-white sm:text-xl py-4 px-6 font-thin shadow-lg">
                    <Trans i18nKey="feature__card-identification">
                      <span className="font-bold"></span>
                    </Trans>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex justify-start w-full">
              <div
                className="flex flex-col sm:flex-row w-full items-center py-6"
                style={{ maxWidth: '1000px' }}
              >
                <div
                  className="w-full bg-black relative self-start sm:self-auto rounded-md shadow-lg border border-gray-700"
                  style={{ maxWidth: '399px', maxHeight: '256px' }}
                >
                  <div
                    className="w-full bg-contain rounded-md"
                    style={{
                      backgroundImage: `url(${process.env.PUBLIC_URL}/images/features/damage-totals.jpg)`,
                      paddingTop: '64%',
                    }}
                  ></div>
                  <div className="absolute bottom-0 sm:bottom-auto sm:top-0 right-0 bg-st-purple-light mx-4 leading-loose xxs:-mr-16 -mb-8 sm:mt-8 rounded text-white sm:text-xl py-4 px-6 font-thin shadow-lg">
                    <Trans i18nKey="feature__tracking">
                      <span className="font-bold"></span>
                    </Trans>
                  </div>
                </div>
                <div className="w-full xxs:w-2/3 sm:w-3/5 sm:pl-48 text-gray-600 sm:text-lg leading-loose bg-surface-medium pt-20 py-12 px-6 sm:pt-12 sm:px-12 sm:pt-12 sm:mt-32 sm:-ml-24 rounded">
                  {t('feature-info__tracking')}
                </div>
              </div>
            </div>

            <div className="flex justify-end w-full">
              <div
                className="flex flex-col-reverse sm:flex-row w-full justify-end items-center py-6"
                style={{ maxWidth: '1000px' }}
              >
                <div className="w-full xxs:w-2/3 sm:w-3/5 sm:pr-48 text-gray-600 sm:text-lg leading-loose bg-surface-medium pt-20 py-12 px-6 sm:pt-12 sm:px-12 sm:pt-12 sm:mt-32 sm:-mr-24 rounded">
                  {t('feature-info__video-layouts')}
                </div>
                <div
                  className="w-full bg-black relative self-end sm:self-auto rounded-md shadow-lg border border-gray-700"
                  style={{ maxWidth: '399px', maxHeight: '256px' }}
                >
                  <div
                    className="w-full bg-contain rounded-md"
                    style={{
                      backgroundImage: `url(${process.env.PUBLIC_URL}/images/features/layouts.jpg)`,
                      paddingTop: '64%',
                    }}
                  ></div>
                  <div className="absolute bottom-0 left-0 bg-st-purple-light mx-4 leading-loose xxs:-ml-16 -mb-8 sm:mb-8 rounded text-white sm:text-xl py-4 px-6 font-thin shadow-lg">
                    <Trans i18nKey="feature__video-layouts">
                      <span className="font-bold"></span>
                    </Trans>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex justify-start w-full">
              <div
                className="flex flex-col sm:flex-row w-full items-center py-6"
                style={{ maxWidth: '1000px' }}
              >
                <div
                  className="w-full bg-black relative self-start sm:self-auto rounded-md shadow-lg border border-gray-700"
                  style={{ maxWidth: '399px', maxHeight: '256px' }}
                >
                  <div
                    className="w-full bg-contain rounded-md"
                    style={{
                      backgroundImage: `url(${process.env.PUBLIC_URL}/images/features/turn-clock.jpg)`,
                      paddingTop: '64%',
                    }}
                  ></div>
                  <div className="absolute bottom-0 right-0 bg-st-purple-light mx-4 leading-loose xxs:-mr-16 -mb-8 sm:mb-8 rounded text-white sm:text-xl py-4 px-6 font-thin shadow-lg">
                    <Trans i18nKey="feature__turn-indicator">
                      <span className="font-bold"></span>
                    </Trans>
                  </div>
                </div>
                <div className="w-full xxs:w-2/3 sm:w-3/5 sm:pl-48 text-gray-600 sm:text-lg leading-loose bg-surface-medium pt-20 py-12 px-6 sm:pt-12 sm:px-12 sm:pt-12 sm:mt-32 sm:-ml-24 rounded">
                  {t('feature-info__turn-indicator')}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center py-24 bg-surface-medium">
        <div className="flex flex-col w-full px-6 sm:px-0 sm:w-10/12 lg:w-1500 flex justify-center">
          <div className="flex flex-col sm:flex-row flex-wrap w-full w-10/12 justify-center items-start">
            <div className="w-full sm:w-1/3 py-6 px-12 text-center">
              <div className="flex justify-center py-4">
                <div className="w-16 sm:w-24">
                  <HelpCircle
                    className="text-st-purple-light"
                    style={{ width: '100%', height: '100%' }}
                  />
                </div>
              </div>
              <div className="text-white font-thin text-2xl sm:text-3xl">
                {t('label__have-any-questions')}
              </div>
              <div className="text-gray-600 py-4">
                <Trans i18nKey="label__check-our-faq-link">
                  <Link
                    target="_blank"
                    to="/faq"
                    className="hover:text-st-orange-light text-st-orange-normal hover:underline cursor-pointer transition-colors ease-in-out duration-200"
                  ></Link>
                </Trans>
              </div>
            </div>
            <div className="w-full sm:w-1/3 py-6 px-12 text-center">
              <div className="flex justify-center py-4">
                <div className="w-16 sm:w-24">
                  <Radio
                    className="text-st-purple-light"
                    style={{ width: '100%', height: '100%' }}
                  />
                </div>
              </div>
              <div className="text-white font-thin text-2xl sm:text-3xl">
                {t('label__news-and-release-notes')}
              </div>
              <div className="text-gray-600 py-4">
                <Trans i18nKey="label__keep-up-on-news">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="http://blog.spelltable.com"
                    className="hover:text-st-orange-light text-st-orange-normal hover:underline cursor-pointer justify-centertransition-colors ease-in-out duration-200"
                  ></a>
                </Trans>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center pt-16 bg-black">
        <div className="flex flex-col w-full px-6 sm:px-0 sm:w-10/12 lg:w-1500 flex justify-center items-center">
          <Footer />
        </div>
      </div>
    </div>
  );
};
