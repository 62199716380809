import React, { ComponentType, CSSProperties, FC, forwardRef } from 'react';
import cx from 'classnames';
import { CustomTooltip } from 'components/CustomTooltip';

import { IconProps as RFProps } from 'react-feather';

interface ButtonProps {
  icon: ComponentType<RFProps> | FC;
  label: string;
  tooltip: string;
  className?: string;
  isActive?: boolean;
  isHighlighted?: boolean;
  onClick?: () => void;
  size?: number;
  style?: CSSProperties;
}

export const IconButton: FC<ButtonProps> = forwardRef((props, ref) => {
  const {
    icon,
    isActive = false,
    isHighlighted = false,
    label,
    onClick,
    size = 20,
    style,
    tooltip,
    className,
  } = props;

  const Icon = icon;

  const classes = cx(
    'my-2 cursor-pointer',
    isActive ? 'text-st-orange-normal' : 'text-gray-600 hover:text-white',
    isHighlighted ? 'text-st-orange-normal hover:text-white' : '',
    className
  );

  return (
    <CustomTooltip tooltip={tooltip}>
      <Icon
        aria-label={label}
        className={classes}
        onClick={onClick}
        role="button"
        size={size}
        style={style}
      />
    </CustomTooltip>
  );
});
