import Api from 'api';
import {
  AudioTrack,
  DisconnectReason,
  Participant,
  RemoteTrack,
  Room,
  RoomEvent,
  RoomOptions,
  Track,
} from 'livekit-client';
import { ConnectionState, VideoResolution } from 'types';
import { getAudioTracks } from 'utils/getAudioTrack';
import logger from 'utils/logger';

import { CODEC, FPS, MAX_BITRATE } from 'utils/media';

export interface RoomUpdate {
  isMuted: boolean;
  isVideoEnabled: boolean;
  speakers: string[];
  audioTracks: AudioTrack[];
  speakersMuted: string[];
  participants: Participant[];
  connectionState: ConnectionState;
  errorMessage?: string;
}

export class GameRoom {
  private token: string;
  private userId: string;
  private room?: Room;
  private isMobile: boolean;
  private mobileDeviceId?: string;
  private update: (update: RoomUpdate) => void;
  private participants: Participant[] = [];
  private speakers: Participant[] = [];
  private speakersMuted: string[] = [];
  private audioTracks: AudioTrack[] = [];
  private connectionState: ConnectionState = ConnectionState.CONNECTING;
  private videoResolution: VideoResolution;

  constructor(
    userId: string,
    token: string,
    videoResolution: VideoResolution,
    update: (update: RoomUpdate) => void,
    mobileDeviceId?: string
  ) {
    this.videoResolution = videoResolution;
    this.userId = userId;
    this.token = token;
    this.update = update;
    this.isMobile = userId.split('-')[1] === 'mobile';
    this.mobileDeviceId = mobileDeviceId;
  }
  /**
   * This leverages livekit API which can throw and would be uncatchable.
   * This function is exposed to enable the caller to try/catch appropriately.
   */
  tryInit = async () => {
    if (this.room) {
      await this.room.removeAllListeners();
      await this.room.disconnect();
    }

    const AVPrefs = await Api.getUserPreferences()?.gameAudioVideo;
    const isVideoEnabled =
      AVPrefs?.isVideoEnabled === true ||
      AVPrefs?.isVideoEnabled === undefined ||
      this.isMobile;

    const useMobileCamera = Api.getUserPreferences()?.useMobileCamera;

    const videoIds = (await Room.getLocalDevices('videoinput')).map(
      (device) => device.deviceId
    );

    const audioIds = (await Room.getLocalDevices('audioinput')).map(
      (device) => device.deviceId
    );

    const videoId =
      this.mobileDeviceId ??
      (AVPrefs?.videoDeviceID &&
      videoIds.includes(AVPrefs.videoDeviceID) &&
      !this.isMobile
        ? AVPrefs.videoDeviceID
        : videoIds[0]);

    const audioId =
      AVPrefs?.audioInputID &&
      audioIds.includes(AVPrefs.audioInputID) &&
      !this.isMobile
        ? AVPrefs.audioInputID
        : undefined;

    const roomOptions: RoomOptions = {
      audioCaptureDefaults: {
        noiseSuppression: true,
        echoCancellation: true,
        deviceId: audioId,
      },
      /* 
      WebAudioMix is enabled by default as of LK2.0
      but it seems to be the cause of the following error:
      "AudioContext.setSinkId(): failed: the device default is not found."
      TODO: re-enable if this ever gets resolved
      */
      webAudioMix: false,
      dynacast: true,
      adaptiveStream: true,
    };

    const shouldPublishTrack =
      (this.isMobile && useMobileCamera) ||
      (!this.isMobile && !useMobileCamera);
    if (shouldPublishTrack) {
      roomOptions.videoCaptureDefaults = {
        deviceId: videoId,
        resolution: this.videoResolution,
      };
      roomOptions.publishDefaults = {
        videoEncoding: {
          maxBitrate: MAX_BITRATE,
          maxFramerate: FPS,
        },
        videoSimulcastLayers: [
          {
            encoding: {
              maxBitrate: MAX_BITRATE,
              maxFramerate: FPS,
            },
            ...this.videoResolution,
          } as any,
        ],
        videoCodec: CODEC,
      };
    }

    this.room = new Room(roomOptions);

    try {
      await this.room?.prepareConnection(
        process.env.REACT_APP_LIVEKIT_URL!,
        this.token
      );
    } catch (e) {
      logger.error(
        `This user: ${this.userId} failed to prepare connection: ${e}`
      );
    }

    this.room
      ?.on(RoomEvent.ParticipantConnected, this.onParticipantsChanged)
      .on(RoomEvent.ParticipantDisconnected, this.onParticipantsChanged)
      .on(RoomEvent.ActiveSpeakersChanged, this.onActiveSpeakerChange)
      .on(RoomEvent.TrackSubscribed, this.onSubscribedTrackChanged)
      .on(RoomEvent.TrackUnsubscribed, this.onSubscribedTrackChanged)
      .on(RoomEvent.LocalTrackPublished, this.onParticipantsChanged)
      .on(RoomEvent.LocalTrackUnpublished, this.onParticipantsChanged)
      // trigger a state change by re-sorting participants
      .on(RoomEvent.AudioPlaybackStatusChanged, this.onParticipantsChanged)
      .on(RoomEvent.Reconnecting, this.onReconnecting)
      .on(RoomEvent.Reconnected, this.onReconnected)
      .on(RoomEvent.Connected, this.onReconnected)
      .on(RoomEvent.Disconnected, this.onDisconnect)
      .on(RoomEvent.TrackMuted, this.onParticipantsChanged)
      .on(RoomEvent.TrackUnmuted, this.onParticipantsChanged);

    try {
      await this.room?.connect(process.env.REACT_APP_LIVEKIT_URL!, this.token, {
        autoSubscribe: !this.isMobile,
      });
    } catch (e) {
      logger.error(`This user: ${this.userId} failed to connect: ${e}`);
      this.broadcast();
      return;
    }

    if (!this.isMobile) {
      await this.room?.localParticipant.setMicrophoneEnabled(
        !Boolean(AVPrefs?.isMuted)
      );
    }

    if (AVPrefs?.audioOutputID && !this.isMobile) {
      const deviceIds = (await Room.getLocalDevices('audiooutput')).map(
        (device) => device.deviceId
      );
      if (deviceIds.includes(AVPrefs.audioOutputID)) {
        await this.room?.switchActiveDevice(
          'audiooutput',
          AVPrefs.audioOutputID
        );
      }
    }

    await this.setVideoDeviceId(videoId!);
    this.room?.startAudio();
    if (shouldPublishTrack) {
      await this.room?.localParticipant.setCameraEnabled(isVideoEnabled);
    }

    this.initalizeRoom();
  };

  leave = async () => {
    await this.room?.disconnect();
  };

  setVideoDeviceId = async (id: string) => {
    const deviceIds = (await Room.getLocalDevices('videoinput')).map(
      (device) => device.deviceId
    );
    if (deviceIds.includes(id)) {
      await this.room?.switchActiveDevice('videoinput', id);
    }
  };

  setAudioDeviceId = async (id: string) => {
    const deviceIds = (await Room.getLocalDevices('audioinput')).map(
      (device) => device.deviceId
    );
    if (deviceIds.includes(id)) {
      await this.room?.switchActiveDevice('audioinput', id);
    }
  };

  setAudioOutputDeviceId = async (id: string) => {
    const deviceIds = (await Room.getLocalDevices('audiooutput')).map(
      (device) => device.deviceId
    );
    if (deviceIds.includes(id)) {
      await this.room?.switchActiveDevice('audiooutput', id);
    }
  };

  setVideoEnabled = async (isVideoEnabled: boolean) => {
    await this.room?.localParticipant.setCameraEnabled(isVideoEnabled);
    this.broadcast();
  };

  setMute = async (isMuted: boolean) => {
    await this.room?.localParticipant.setMicrophoneEnabled(!isMuted);
    this.broadcast();
  };

  private broadcast = () => {
    this.update({
      isMuted: !Boolean(this.room?.localParticipant.isMicrophoneEnabled),
      isVideoEnabled: !!this.room?.localParticipant.isCameraEnabled,
      speakers: this.speakers.map((p) => p.identity),
      audioTracks: this.audioTracks,
      speakersMuted: this.speakersMuted,
      participants: this.participants,
      connectionState: this.connectionState,
    });
  };

  private onReconnecting = () => {
    this.connectionState = ConnectionState.RECONNECTING;
    this.broadcast();
  };

  private onReconnected = () => {
    this.connectionState = ConnectionState.CONNECTED;
    this.broadcast();
  };

  private onParticipantsChanged = () => {
    if (!this.room) {
      return;
    }
    this.checkMobile();
    this.setParticipants();
    this.setMutedSpeakers();
    this.updateAudio();
    this.broadcast();
  };

  private setMutedSpeakers = () => {
    const mutedSpeakers: string[] = [];
    this.participants.forEach((p) => {
      if (!p.isMicrophoneEnabled) {
        mutedSpeakers.push(p.identity);
      }
    });
    this.speakersMuted = mutedSpeakers;
  };

  private checkMobile = () => {
    if (!this.room) {
      return;
    }
    // If a mobile user is present with your ID turn your camera off.
    if (
      Api.getUserPreferences()?.useMobileCamera &&
      !this.isMobile &&
      this.room.localParticipant.isCameraEnabled
    ) {
      this.room?.localParticipant.setCameraEnabled(false);
    }
  };

  private initalizeRoom = () => {
    if (!this.room) {
      return;
    }
    this.checkMobile();
    this.setParticipants();
    this.setMutedSpeakers();
    this.updateAudio();
    this.broadcast();
  };

  private onSubscribedTrackChanged = (track?: RemoteTrack) => {
    // ordering may have changed, re-sort
    this.onParticipantsChanged();
    if (track && track.kind !== Track.Kind.Audio) {
      return;
    }
    this.updateAudio();
    this.broadcast();
  };

  private onDisconnect = (reason?: DisconnectReason) => {
    this.room?.removeAllListeners();
    this.connectionState = ConnectionState.DISCONNECTED;
    this.broadcast();
    if (
      reason === DisconnectReason.JOIN_FAILURE ||
      reason === DisconnectReason.STATE_MISMATCH ||
      reason === DisconnectReason.SERVER_SHUTDOWN ||
      reason === DisconnectReason.UNKNOWN_REASON
    ) {
      logger.error(
        `User: ${this.userId} disconnected because:`,
        DisconnectReason[reason]
      );
    }
  };

  private onActiveSpeakerChange = (speakers: Participant[]) => {
    this.speakers = speakers;
    this.updateAudio();
    this.broadcast();
  };

  private updateAudio = () => {
    if (!this.room) {
      return;
    }

    this.setParticipants();

    const tracks = getAudioTracks(this.userId, this.participants);
    this.audioTracks = tracks;
  };

  private setParticipants = () => {
    if (!this.room) {
      return;
    }

    const remotes = Array.from(this.room.remoteParticipants.values());
    const participants: Participant[] = [this.room.localParticipant];
    participants.push(...remotes);
    this.participants = participants;
  };
}
