import firebase from 'api/firebase';

import {
  AnyCard,
  Card,
  Damage,
  DamageCommander,
  DamageOwner,
  Game,
  GamePlayers,
  getMaxPlayers,
  isMultiplayer,
  MagicFormat,
  NormalizedSpottedCard,
  Player,
  ScryfallCard,
  SessionStatus,
  SpottedCard,
  User,
  VideoLayout,
} from 'types';

import Api, { EVENTS } from 'api';

import { isCommanderDamage } from 'api/utils/isCommanderDamage';
import { isSpottedCard } from 'api/utils/isSpottedCard';

import { DAMAGE_TYPES } from 'constants/magic';
import { ENDPOINTS } from 'constants/scryfall';

import {
  getDefaultPlayerProps,
  getResetablePlayerProps,
} from 'api/utils/getResetablePlayerProps';

import { GameLogAction } from 'api/gamelog';
import { GAME_JOINED, SET_COMMANDER } from 'constants/bi-names';
import { DiceSides } from 'types/dice';
import createCommanderDamageObject from 'utils/createCommanderDamageObject';
import createInfectDamageObject from 'utils/createInfectDamageObject';
import { ensureFullGamePlayers } from 'utils/ensureFullGamePlayers';
import { fixSpottedCardId } from 'utils/fixSpottedCardId';
import { getActiveAndAlivePlayers } from 'utils/getActiveAndAlivePlayers';
import { getActiveGamePlayers } from 'utils/getActiveGamePlayers';
import { getCardFromScryfallData } from 'utils/getCardFromScryfallData';
import { getGamePlayerCount } from 'utils/getGamePlayerCount';
import isCamera from 'utils/isCamera';
import { normalizeSpottedCard } from 'utils/normalizeSpottedCard';
import { shuffle } from 'utils/shuffle';

export const DEFAULT_STATUS = SessionStatus.LOADING;

const VIDEO_LAYOUT_STORAGE_KEY = 'videoLayout';

async function createPlayer(
  user: firebase.User,
  gameId: string,
  playerSlot: number | undefined,
  format?: MagicFormat
): Promise<void> {
  const { uid, displayName: name, photoURL } = user;
  const data: Player = {
    ...getDefaultPlayerProps(format),
    uid: uid,
    gameId,
    playerSlot,
    name: name || 'Unknown',
    photoURL,
    lastUpdated: firebase.firestore.Timestamp.now(),
    inactive: false,
  };

  await Api.players.create(uid, data);
}

async function updatePlayer(
  player: Player,
  user: firebase.User,
  gameId: string,
  playerSlot: number | undefined,
  format?: MagicFormat
): Promise<void> {
  const { uid, gameId: playerGameId } = player;
  let update: Partial<Player> = {
    inactive: false,
    playerSlot,
    name: user.displayName || 'Unknown',
  };
  if (playerGameId !== gameId) {
    update = {
      ...getDefaultPlayerProps(format),
      gameId,
      ...update,
    };
  }
  await Api.players.update(uid, update, { batch: false });
}

export interface SessionBroadcastResult {
  status: SessionStatus;
  game?: Game;
  players: Player[];
  cards: AnyCard[];
  focusedPlayerId: string;
  videoLayout: VideoLayout;
  useMobileCamera: boolean;
  userBlocklist?: string[];
}

export type SessionCallback = (result: SessionBroadcastResult) => void;

export class GameSession {
  private db = firebase.firestore();

  private uid: string;
  private sessionStatus: SessionStatus = DEFAULT_STATUS;
  private game?: Game;
  private players: Player[] = [];
  private cards: AnyCard[] = [];
  private focusedPlayerId: string;
  private videoLayout: VideoLayout;
  private useMobileCamera: boolean;
  private isActingAsCamera: boolean;

  private realtimeDbConnectedRef?: firebase.database.Reference;
  private gameUnsubscribe?: Function;
  private playersUnsubscribe?: Function;
  private usersUnsubscribe?: Function;

  private userBlocklist: string[] | undefined;

  constructor(
    private gameId: string,
    private user: firebase.User,
    private isSpectator: boolean,
    private callback: SessionCallback
  ) {
    this.uid = user.uid;
    this.focusedPlayerId = this.uid;
    this.isSpectator = isSpectator;

    this.videoLayout =
      (window.localStorage.getItem(VIDEO_LAYOUT_STORAGE_KEY) as VideoLayout) ||
      VideoLayout.DEFAULT;

    this.useMobileCamera = Api.getUserPreferences()?.useMobileCamera || false;
    this.isActingAsCamera = this.useMobileCamera && isCamera();
    this.userBlocklist = Api.getUserBlocklist();
    this.setup();
  }

  async setup(): Promise<void> {
    const gameExists = await this.gameExists();

    // If the game doesn't exist then stop here.
    if (!gameExists) {
      this.status = SessionStatus.NO_GAME_EXISTS;
      this.broadcast();
      // Continue to hook things up.
    } else {
      if (!this.isActingAsCamera && !this.isSpectator) {
        // donn't fuck with presence if you're acting as a camera
        // We use the Firebase realtime DB to track user presence in game.
        this.realtimeDbConnectedRef = firebase
          .database()
          .ref('.info/connected');
        this.setupPresence();
      }
      // Setup Firestore watchers.
      this.setupWatchers();
    }
  }

  set status(status: SessionStatus) {
    this.sessionStatus = status;
  }

  get status(): SessionStatus {
    return this.sessionStatus;
  }

  async destroy(): Promise<void> {
    if (this.gameUnsubscribe) {
      this.gameUnsubscribe();
    }

    if (this.playersUnsubscribe) {
      this.playersUnsubscribe();
    }

    // disable the realtime db connection we use for presence
    if (this.realtimeDbConnectedRef) {
      this.realtimeDbConnectedRef.off();
    }

    if (this.usersUnsubscribe) {
      this.usersUnsubscribe();
    }
  }

  broadcast(): void {
    this.callback({
      status: this.status,
      game: this.game,
      players: this.players,
      cards: this.cards,
      focusedPlayerId: this.focusedPlayerId,
      videoLayout: this.videoLayout,
      useMobileCamera: this.useMobileCamera,
      userBlocklist: this.userBlocklist,
    });
  }

  async gameExists(): Promise<boolean> {
    const doc = await Api.games.getGameDoc(this.gameId);
    return doc.exists;
  }

  setFocusedPlayerId(uid: string): void {
    if (this.focusedPlayerId !== uid) {
      this.focusedPlayerId = uid;
      this.broadcast();
    }
  }

  isGameFull(): boolean {
    if (!this.game) {
      return false;
    }

    const { players, format } = this.game;
    const maxPlayers = getMaxPlayers(format);
    return (
      this.game.players.length >= maxPlayers && !players.includes(this.uid)
    );
  }

  playerInGame(uid: string): boolean {
    return this.game?.players.findIndex((id) => id === uid) !== -1;
  }

  isUserHost(uid: string): boolean {
    return this.game?.host === uid;
  }

  ensureHostAfterUpdate(gameUpdate: Partial<Game>): Partial<Game> {
    if (!this.game) {
      return gameUpdate;
    }

    const { owner, host: currentHost } = this.game;
    let nextHost = currentHost;
    // set the host if one is not set or if this user is the original game owner.
    if (!currentHost || (owner === this.uid && currentHost !== this.uid)) {
      nextHost = this.uid;
    }

    // ensure host exist in players list whether being updated or not
    // players list is always 4 so prune to non-null
    const playersList = (
      gameUpdate.players?.length ? gameUpdate.players : this.game.players
    ).filter(Boolean);
    if (!playersList.includes(nextHost)) {
      nextHost = playersList[0];
    }

    if (nextHost !== currentHost) {
      gameUpdate.host = nextHost;
    }

    return gameUpdate;
  }

  /**
   * Find the first available player slot in the game.
   * If preferredSlot is passed we will attempt to give them that slot if its available.
   */
  getAvailablePlayerSlot(
    preferredSlot: number | undefined
  ): number | undefined {
    if (!this.game) {
      return;
    }

    const { players } = this.game;

    // if the preferred slot is empty OR the player still holds the slot
    // then return the preferred slot.
    if (
      preferredSlot !== undefined &&
      (!players[preferredSlot] || players[preferredSlot] === this.uid)
    ) {
      return preferredSlot;
    }

    let availableSlot;
    for (let i = 0; i < players.length; i++) {
      if (!players[i]) {
        availableSlot = i;
        break;
      }
    }

    return availableSlot;
  }

  async addPlayerToGame(): Promise<void> {
    if (!this.game) {
      return;
    }

    // get the player's data
    const playerDoc = await Api.players.getDoc(this.uid);
    const data = playerDoc.data() as Player;
    const isRejoining = data?.gameId === this.gameId;

    // if the player exists we try to get the same player slot if available.
    const preferredSlot = isRejoining ? data?.playerSlot : undefined;
    const availableSlot = this.getAvailablePlayerSlot(preferredSlot);

    // if the player document doesn't exist create it.
    if (!playerDoc.exists) {
      await createPlayer(
        this.user,
        this.gameId,
        availableSlot,
        this.game?.format
      );
      // else update the player document with the game info.
    } else {
      await updatePlayer(
        data,
        this.user,
        this.gameId,
        availableSlot,
        this.game?.format
      );
    }

    let gameUpdate: Partial<Game> = {};

    // add the player to game's players array if they aren't in there.
    if (!this.playerInGame(this.uid)) {
      if (availableSlot === undefined) {
        throw new Error('No slot found to insert player into game.');
      }

      const updatedPlayers = ensureFullGamePlayers(
        this.game.players,
        this.game.format
      );
      updatedPlayers[availableSlot] = this.uid;
      gameUpdate.players = updatedPlayers;
    }

    gameUpdate = this.ensureHostAfterUpdate(gameUpdate);

    // send the game updates.
    if (Object.keys(gameUpdate).length > 0) {
      await Api.games.updateGame(this.game, gameUpdate);
    }
  }
  async join(): Promise<void> {
    try {
      this.useMobileCamera = Api.getUserPreferences()?.useMobileCamera || false;
      this.isActingAsCamera = this.useMobileCamera && isCamera();

      window.gtag?.('set', {
        gameId: this.gameId,
      });
      // this is a secondary camera and should not trigger this metric
      if (!this.isActingAsCamera) {
        window.gtag?.('event', 'game_join');
        Api.track(GAME_JOINED, false, {
          gameId: this.gameId,
        });

        if (this.user.displayName) {
          Api.gamelog.addGameLog(
            this.gameId,
            GameLogAction.Joined,
            this.user.displayName
          );
        }
      }

      if (!this.isActingAsCamera && !this.isSpectator) {
        await this.addPlayerToGame();
      }

      // Flag the session as ready.
      this.status = SessionStatus.READY;
      this.broadcast();

      if (
        !isMultiplayer(this.game?.format) &&
        this.videoLayout === VideoLayout.DEFAULT
      ) {
        this.setVideoLayout(VideoLayout.THEATER);
      }
    } catch (e) {
      console.error(e);
      // TODO: Do we need to cleanup here?
      this.status = SessionStatus.ERROR;
      this.broadcast();
      this.leave();
      this.destroy();
    }
  }

  async leave(): Promise<void> {
    if (!this.isActingAsCamera) {
      const index = this.game?.players.indexOf(this.uid);
      const playerName = this.players.find((p) => p.uid === this.uid)?.name;
      const isIndexDefined = index === 0 || (index && index !== -1);
      const playerIndex = index ?? 0;

      if (this.game && isIndexDefined) {
        const player = this.players[playerIndex];

        if (player) {
          Api.gamelog.addGameLog(
            this.gameId,
            GameLogAction.Left,
            playerName ?? player.name
          );
        }
        const players = ensureFullGamePlayers(
          this.game.players,
          this.game.format
        );
        players[playerIndex] = null;

        const gameUpdate = this.ensureHostAfterUpdate({ players });
        await Api.games.updateGame(this.game, gameUpdate);
      }
      await Api.players.update(this.uid, { inactive: true }, { batch: false });
      // remove the user's presence record in the realtime database as well.
      await this.cleanupRealtimeDb();
    }
  }

  async setupWatchers(): Promise<void> {
    const exists = await this.gameExists();
    if (exists) {
      this.gameUnsubscribe = this.watchGame();
      this.playersUnsubscribe = this.watchPlayers();
      this.usersUnsubscribe = Api.user.subscribe(
        EVENTS.USER.UPDATE,
        this.onUserUpdate
      );
    }
  }

  onUserUpdate = (data: User): void => {
    if (
      this.isActingAsCamera &&
      !(data.preferences?.useMobileCamera || false)
    ) {
      this.leave();
      this.status = SessionStatus.KICKED;
    } else {
      this.useMobileCamera = data?.preferences?.useMobileCamera || false;
      this.isActingAsCamera = this.useMobileCamera && isCamera();
    }
    this.broadcast();
  };

  watchGame(): Function {
    return this.db
      .collection('games')
      .doc(this.gameId)
      .onSnapshot((snapshot) => {
        const before = this.game;
        const after = snapshot.data() as Game;

        this.game = after;

        const maxPlayers = getMaxPlayers(this.game?.format);

        // toggle focused player if the user has the preference on.
        const userData = Api.user.getUser();
        if (
          this.videoLayout === VideoLayout.FOCUSED &&
          userData?.preferences?.focusVideoOnTurnChange &&
          after?.activePlayer &&
          this.focusedPlayerId !== after.activePlayer
        ) {
          this.setFocusedPlayerId(after.activePlayer);
        }

        const isStatusLoading = this.status === SessionStatus.LOADING;
        const isStatusReady = this.status === SessionStatus.READY;
        // Update session status accordingly.
        if (isStatusLoading) {
          if (this.isSpectator && this.status !== SessionStatus.JOINING) {
            this.status = SessionStatus.JOINING;
            this.join();
          } else {
            const { players } = after;

            // migrate games to the new slot system.
            // TODO: Remove this a day or so after the slot update goes out.
            if (players.length !== maxPlayers) {
              const newPlayers = ensureFullGamePlayers(
                players,
                this.game.format
              );
              Api.games.updateGame(this.game, {
                players: newPlayers,
              });

              updatePlayerSlots(newPlayers);
              return;
            }

            const userInGame = players.indexOf(this.uid) !== -1;
            const playerCount = getGamePlayerCount(after);
            // If the game is at max players and the user is not one of those players then its full.
            if (playerCount === maxPlayers && !userInGame) {
              this.status = SessionStatus.GAME_FULL;
              // Let the user join.
            } else if (this.status !== SessionStatus.JOINING) {
              this.status = SessionStatus.JOINING;
              this.join();
            }
          }
          // If the game has disappeared it has been deleted meaning all users left.
        } else if (before && !after) {
          this.status = SessionStatus.GAME_DELETED;
        } else if (
          isStatusReady &&
          before?.players.includes(this.uid) &&
          !after.players.includes(this.uid) &&
          !this.isSpectator
        ) {
          this.status = SessionStatus.KICKED;
        }
        this.broadcast();
      });
  }

  watchPlayers(): Function {
    return this.db
      .collection('players')
      .where('gameId', '==', this.gameId)
      .where('inactive', '==', false)
      .onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => {
          const { type, doc } = change;

          const index = this.players.findIndex((p) => p.uid === doc.id);
          const data = doc.data() as Player;

          switch (type) {
            case 'added':
            case 'modified': {
              if (index !== -1) {
                this.players = [
                  ...this.players.slice(0, index),
                  data,
                  ...this.players.slice(index + 1),
                ];

                if (data.inactive && doc.id === this.focusedPlayerId) {
                  this.focusedPlayerId = this.uid;
                }
                break;
              }

              this.players = [...this.players, data];
              break;
            }
            case 'removed': {
              if (index === -1) {
                return;
              }
              this.players = [
                ...this.players.slice(0, index),
                ...this.players.slice(index + 1),
              ];

              if (doc.id === this.focusedPlayerId) {
                this.focusedPlayerId = this.uid;
              }
              break;
            }
            default:
              break;
          }
        });

        this.broadcast();
      });
  }

  /**
   * Start Realtime Database presence code.
   */

  getRealtimeDbRef(): firebase.database.Reference {
    return firebase.database().ref(`/user-status/${this.gameId}/${this.uid}`);
  }

  /**
   * We use the realtime data onDisconnect functionality to watch user presence in games.
   * Unfortunately Firestore doesn't support this type fo functionality so we use the
   * realtime database and a cloud function to sync the change over to Firestore.
   * It's lame but the recommended way to do it.
   * https://firebase.google.com/docs/firestore/solutions/presence
   */
  /* NOTE: TODO: use livekit webhooks for presence
   */
  async setupPresence(): Promise<void> {
    const uid = this.uid;
    const gameId = this.gameId;
    const userStateDbRef = this.getRealtimeDbRef();

    // this is super confusing code because this is how firebase works. You
    // store the change you want to make ahead of time and it executes in the cloud
    // if you lose presence
    this.realtimeDbConnectedRef?.on('value', function (snapshot) {
      if (snapshot.val() === false) {
        return;
      }

      userStateDbRef
        .onDisconnect()
        .cancel()
        .then(function () {
          userStateDbRef.set({
            uid,
            gameId,
            state: 'online',
            last_changed: firebase.database.ServerValue.TIMESTAMP,
          });
        });
    });
  }

  async cleanupRealtimeDb(): Promise<void> {
    const userStateDbRef = this.getRealtimeDbRef();
    await userStateDbRef.remove();
  }

  /**
   * End Realtime Database presence code.
   */

  async toggleBlockPlayer(uid: string): Promise<void> {
    if (!this.game) {
      return;
    }
    await Api.user.toggleBlockuser(uid);
    this.userBlocklist = Api.getUserBlocklist();
    this.broadcast();
  }

  async kickPlayer(uid: string, allowHostKick?: boolean): Promise<void> {
    const hostBeingDropped = this.isUserHost(uid);
    if (!this.game || (hostBeingDropped && !allowHostKick)) {
      return;
    }

    const { players } = this.game;

    const index = players.indexOf(uid);
    if (index === -1) {
      return;
    }

    const updatedPlayers = ensureFullGamePlayers(players, this.game.format);
    updatedPlayers[index] = null;

    this.game = {
      ...this.game,
      players: updatedPlayers,
    };

    const gameUpdate = this.ensureHostAfterUpdate({
      players: updatedPlayers,
    });

    Api.games.updateGame(this.game, gameUpdate);

    Api.players.update(uid, { inactive: true }, { batch: false });

    this.broadcast();
  }

  async closeGame(): Promise<void> {
    if (!this.isUserHost(this.uid)) {
      return;
    }

    this.cleanupRealtimeDb();
    this.destroy();

    await Api.games.deleteGame(this.gameId);
  }

  getLocalPlayer(): Player | undefined {
    const index = this.players.findIndex((p) => p.uid === this.uid);
    if (index !== -1) {
      return this.players[index];
    }
  }

  // Gets the card info, but does not add it
  async getScryFallCard(card: SpottedCard): Promise<AnyCard | undefined> {
    const normalizedCard = normalizeSpottedCard(card);

    return normalizedCard;
  }

  setPlayerPosition(uid: string, oldIndex: number, newIndex: number): void {
    if (!this.game) {
      return;
    }

    const { players, format } = this.game;

    // update the game players array
    let updatedPlayers = ensureFullGamePlayers(players, format);
    updatedPlayers.splice(oldIndex, 1);
    updatedPlayers.splice(newIndex, 0, uid);
    this.game = {
      ...this.game,
      players: updatedPlayers,
    };
    Api.games.updateGame(this.game, {
      players: updatedPlayers,
    });

    // update each player's saved slot position
    updatePlayerSlots(updatedPlayers);

    this.broadcast();
  }

  randomizeTurnOrder() {
    // onlt the host can randomize turn order
    if (!this.isUserHost(this.uid) || !this.game) {
      return;
    }

    const { players, format } = this.game;
    const activePlayers = getActiveGamePlayers(players);
    const randomized = ensureFullGamePlayers(shuffle(activePlayers), format);

    Api.games.updateGame(this.game, {
      players: randomized,
    });

    updatePlayerSlots(randomized);
  }

  updateLocalPlayer(data: Partial<Player>): void {
    const index = this.players.findIndex((p) => p.uid === this.uid);
    if (index !== -1) {
      const players = [...this.players];
      players[index] = { ...players[index], ...data };
      this.players = players;
    }
    this.broadcast();
  }

  updateLife(life: number): void {
    this.updateLocalPlayer({ life });
    Api.players.update(this.uid, { life });
    const player = this.players.find((p) => p.uid === this.uid);
    if (player) {
      Api.gamelog.debouncedGameLog(
        this.gameId,
        GameLogAction.SetLife,
        player.name,
        ['' + life]
      );
    }
  }

  togglePlayerDead(uid: string, isDead: boolean): void {
    const update = { isDead };
    if (this.uid === uid) {
      this.updateLocalPlayer(update);
    }
    Api.players.update(uid, update);
    const player = this.players.find((p) => p.uid === uid);
    if (player && isDead) {
      Api.gamelog.addGameLog(
        this.gameId,
        GameLogAction.Eliminated,
        player.name
      );
    }
  }

  togglePlayerAsMonarch(uid: string, isMonarch: boolean): void {
    const update = { isMonarch };

    // if we are clearing monarch we just need to update the one player.
    if (!isMonarch) {
      Api.players.update(uid, update);
      return;
    }

    // Else we need to make sure no other players are monarch and set the player as monarch.
    this.players.forEach((player) => {
      // toggle all other players to not be monarch.
      if (player.isMonarch && player.uid !== uid) {
        Api.players.update(player.uid, {
          isMonarch: false,
        });
      }

      // Set the player as monarch.
      if (player.uid === uid && !player.isMonarch) {
        Api.players.update(player.uid, update);
        Api.gamelog.addGameLog(
          this.gameId,
          GameLogAction.BecameMonarch,
          player.name
        );
      }
    });
  }

  async getCardFromScryfall(
    card: NormalizedSpottedCard,
    lang: string
  ): Promise<ScryfallCard | null> {
    try {
      let noLangResponse;
      const response = await fetch(
        `${ENDPOINTS.CARD_BY_SET}${card.set}/${fixSpottedCardId(
          card.collector_number
        )}/${lang}`
      );

      if (!response.ok) {
        noLangResponse = await fetch(
          `${ENDPOINTS.CARD_BY_SET}${card.set}/${fixSpottedCardId(
            card.collector_number
          )}`
        );
      }
      const data = noLangResponse
        ? await noLangResponse.json()
        : ((await response.json()) as ScryfallCard);

      return data;
    } catch (e) {
      return null;
    }
  }

  getCardFromCards(card: AnyCard): AnyCard {
    return this.cards[this.getCardIndex(card)];
  }

  getCardIndex(card: AnyCard): number {
    // if the card has no set or collector_number then just treat it like it doesn't exist
    if (!card.set || !card.collector_number) {
      return -1;
    }

    return this.cards.findIndex((c) => {
      return c.set === card.set && c.collector_number === card.collector_number;
    });
  }

  cardExists(card: AnyCard): boolean {
    return this.getCardIndex(card) !== -1;
  }

  // Adds a card to the cards array. Moving it to the start of the array if it already exists.
  addCard(card: Card | NormalizedSpottedCard, isLogged?: boolean): void {
    let updatedCards = this.cards ? [...this.cards] : [];
    const index = this.getCardIndex(card);
    if (index !== -1) {
      updatedCards.splice(index, 1);
    }
    this.cards = [card, ...updatedCards];
    this.broadcast();
    if (isLogged && this.user.displayName) {
      Api.gamelog.addGameLog(
        this.gameId,
        GameLogAction.IdentifiedCard,
        this.user.displayName,
        [card.name]
      );
    }
  }

  removeCardSpotted(card: AnyCard): void {
    // Filter out this card and we'll move it to the front
    this.cards = (this.cards || []).filter(
      (c) =>
        !(c.set === card.set && c.collector_number === card.collector_number)
    );
    this.broadcast();
  }

  // Adds a card directly from a Scryfall search to the cards array.
  async addCardScryfall(data: ScryfallCard): Promise<void> {
    const card = getCardFromScryfallData(data);
    this.addCard(card);
  }

  // Adds a spotted card to the cards array.
  async addCardSpotted(card: SpottedCard, lang: string): Promise<void> {
    const normalizedCard = normalizeSpottedCard(card);
    const existingCard = this.getCardFromCards(normalizedCard);
    const cardToAdd = existingCard
      ? { ...existingCard }
      : { ...normalizedCard };
    // the card right away so the spotting is fast.
    this.addCard(cardToAdd, true);
    // now go get scryfall data. pass in the original card Id
    await this.populateScryfallData(cardToAdd, lang);
  }

  // Populates scryfall data for cards spotted in the video.
  async populateScryfallData(card: AnyCard, lang: string) {
    // if the card already has scryfall data we are good.
    if (!isSpottedCard(card)) {
      return;
    }

    // if the card no longer is in the cards array then we can ignore.
    if (!this.cardExists(card)) {
      return;
    }

    const data = await this.getCardFromScryfall(card, lang);

    if (data) {
      const updatedCard = { ...card, ...getCardFromScryfallData(data) };

      // if the card no longer is in the cards array then we can ignore.
      if (!this.cardExists(updatedCard)) {
        return;
      }

      // update the existing card with the scryfall data.
      const index = this.getCardIndex(updatedCard);
      this.cards.splice(index, 1, updatedCard);
      this.cards = [...this.cards];
      this.broadcast();
    }
    return;
  }

  rollDice(sides: DiceSides, roll: string): void {
    if (!this.game || !this.user.displayName) {
      return;
    }
    if (sides !== '2') {
      Api.gamelog.addGameLog(
        this.gameId,
        GameLogAction.RolledDice,
        this.user.displayName,
        [sides ?? '', roll]
      );
    }
    if (sides === '2') {
      Api.gamelog.addGameLog(
        this.gameId,
        GameLogAction.FlippedCoin,
        this.user.displayName,
        [roll]
      );
    }
  }

  passTurn(): void {
    if (!this.game) {
      return;
    }

    const { players, activePlayer } = this.game;
    const possiblePlayerIds = getActiveAndAlivePlayers(players, this.players);

    const clearActiveTurn = () => {
      if (this.game) {
        Api.games.updateGame(this.game, {
          activePlayer: null,
          turnStart: null,
        });
      }
    };

    // if there are no alive players then clear the turn data.
    if (possiblePlayerIds.length === 0) {
      clearActiveTurn();
      return;
    }

    const activeIndex = players.indexOf(activePlayer);
    let nextActivePlayer;

    let index = activeIndex === -1 ? 0 : activeIndex;
    let loopCount = 0;
    while (loopCount < players.length) {
      const playerId = players[index];

      // once we hit the first player that is not the currently active player
      // and the player is still alive
      if (
        playerId &&
        playerId !== activePlayer &&
        possiblePlayerIds.includes(playerId)
      ) {
        nextActivePlayer = playerId;
        break;
      }

      index++;

      // loop back around
      if (index >= players.length) {
        index = 0;
      }

      loopCount++;
    }

    // if we didn't find the next player then
    if (!nextActivePlayer) {
      clearActiveTurn();
      return;
    }

    if (this.user.displayName) {
      Api.gamelog.addGameLog(
        this.gameId,
        GameLogAction.PassedTurn,
        this.user.displayName
      );
    }

    // set the next active player
    Api.games.updateGame(this.game, {
      activePlayer: nextActivePlayer,
      phase: 0,
      turnStart: firebase.firestore.Timestamp.now(),
    });
  }

  nextPhase(phase: number) {
    if (this.game) {
      Api.games.updateGame(this.game, {
        phase,
      });
    }
  }

  toggleIsUsingPhases(isUsingPhases: boolean) {
    if (this.game) {
      Api.games.updateGame(this.game, {
        isUsingPhases,
      });
    }
  }

  async addCommander(scryfallCard: ScryfallCard): Promise<void> {
    const player = await Api.players.getData(this.uid);

    if (!player) {
      return;
    }

    const { id } = scryfallCard;
    const { commanders = [] } = player;

    // limit number of commanders to 2.
    if (commanders.length >= 2) {
      return;
    }

    const index = commanders.findIndex((c) => c.id === id);

    if (index !== -1) {
      return;
    }

    Api.players.update(
      this.uid,
      { commanders: [...commanders, getCardFromScryfallData(scryfallCard)] },
      { batch: false }
    );

    Api.gamelog.addGameLog(
      this.gameId,
      GameLogAction.SetCommander,
      player.name,
      [scryfallCard.name]
    );

    Api.track(SET_COMMANDER, false, {
      name: scryfallCard.name,
    });
  }

  async removeCommander(commanderId: string): Promise<void> {
    const player = await Api.players.getData(this.uid);

    if (!player) {
      return;
    }

    const { commanders = [] } = player;
    const index = commanders.findIndex((c) => c.id === commanderId);

    if (index === -1) {
      return;
    }

    const newCommanders = [...commanders];
    newCommanders.splice(index, 1);
    Api.players.update(
      this.uid,
      { commanders: newCommanders },
      { batch: false }
    );
  }

  async updateCommanderDamage(
    owner: DamageOwner,
    commander: DamageCommander,
    value: number
  ): Promise<void> {
    const player = this.getLocalPlayer();

    if (!player) {
      return;
    }

    const { damage = [] } = player;
    const index = damage
      .filter(isCommanderDamage)
      .findIndex(
        (d) => d.owner.uid === owner.uid && d.commander.id === commander.id
      );

    let updatedDamage: Damage[] = [];

    if (index !== -1) {
      // update
      updatedDamage = [...damage];
      updatedDamage[index] = { ...updatedDamage[index], value };
    } else {
      // add
      updatedDamage = [...damage];

      // ensure that any non commander types stay at the end.
      const miscIndex = updatedDamage.findIndex(
        (d) => d.type !== DAMAGE_TYPES.COMMANDER
      );
      const newDamage = createCommanderDamageObject(owner, commander, value);

      if (miscIndex !== -1) {
        updatedDamage = [
          ...updatedDamage.slice(0, miscIndex),
          newDamage,
          ...updatedDamage.slice(miscIndex),
        ];
      } else {
        updatedDamage.push(newDamage);
      }
    }

    this.updateLocalPlayer({ damage: updatedDamage });
    Api.players.update(this.uid, { damage: updatedDamage });
  }

  async updateInfectDamage(value: number): Promise<void> {
    const player = this.getLocalPlayer();

    if (!player) {
      return;
    }

    const { damage = [] } = player;
    const index = damage.findIndex((d) => d.type === DAMAGE_TYPES.INFECT);

    let updatedDamage: Damage[] = [];

    if (index !== -1) {
      // update
      updatedDamage = [...damage];
      updatedDamage[index] = { ...updatedDamage[index], value };
    } else {
      // add
      updatedDamage = [...damage, createInfectDamageObject(value)];
    }

    this.updateLocalPlayer({ damage: updatedDamage });
    Api.players.update(this.uid, { damage: updatedDamage });
  }

  reset(
    resetCommander: boolean,
    resetGameLog: boolean,
    resetGamestate: boolean
  ): void {
    if (!this.game) {
      return;
    }

    Api.games.updateGame(this.game, {
      activePlayer: null,
      turnStart: null,
    });

    if (resetGameLog) {
      Api.gamelog.resetGameLog(this.game.id);
    }

    this.players.forEach((player) => {
      this.resetPlayer(
        player.uid,
        resetGamestate,
        resetCommander,
        this.game?.format
      );
    });
  }

  resetPlayer(
    playerId: string,
    resetGamestate: boolean,
    resetCommander: boolean,
    format?: MagicFormat
  ): void {
    Api.players.update(playerId, {
      ...getResetablePlayerProps(format, resetCommander, resetGamestate),
    });
  }

  setVideoLayout(layout: VideoLayout) {
    this.videoLayout = layout;
    window.localStorage.setItem(VIDEO_LAYOUT_STORAGE_KEY, layout);
    this.broadcast();
  }
}

function updatePlayerSlots(players: GamePlayers) {
  players.forEach((playerId, index) => {
    if (playerId) {
      Api.players.update(playerId, {
        playerSlot: index,
      });
    }
  });
}
