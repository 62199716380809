import React, { FunctionComponent } from 'react';
import { Route } from 'react-router-dom';
import useFirebaseUser from 'hooks/useFirebaseUser';
import { loginRedirect } from 'utils/loginRedirect';

interface PrivateRouteProps {
  component: any;
  exact?: boolean;
  path: string;
}

export const PrivateRoute: FunctionComponent<PrivateRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const user = useFirebaseUser();
  if (!user) {
    loginRedirect(false);
    return null;
  }
  return <Route {...rest} render={(props) => <Component {...props} />} />;
};
