import cx from 'classnames';
import React, { FC } from 'react';
import ReactModal from 'react-modal';

interface ModalProps {
  onClose: () => void;

  className?: string;

  style?: React.CSSProperties;
}

export const Modal: FC<ModalProps> = ({
  children,
  className,
  onClose,
  style,
}) => {
  const classes = cx(
    'w-full phone:w-500 phone:overflow-auto phone:max-h-3/4 phone:rounded rounded-none shadow-lg outline-none bg-surface-high p-8',
    className
  );

  return (
    <ReactModal
      ariaHideApp={false}
      style={{ content: style }}
      className={classes}
      overlayClassName="bg-black-50 absolute z-40 top-0 left-0 h-full w-full flex justify-center xs:items-center items:start"
      isOpen
      onRequestClose={onClose}
      // @ts-ignore
      parentSelector={() => document.querySelector('#modal-container')}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
    >
      {children}
    </ReactModal>
  );
};

export const Header: FC = ({ children }) => {
  return <h1 className="text-2xl mb-8 text-white text-center">{children}</h1>;
};

export const Label: FC = ({ children }) => {
  return (
    <span className="block uppercase tracking-wide text-xs font-bold my-2 text-left">
      {children}
    </span>
  );
};

export const Buttons: FC = ({ children }) => {
  return <div className="mt-10 space-x-2 flex justify-center">{children}</div>;
};
