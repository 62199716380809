export const WUBRG = ['W', 'U', 'B', 'R', 'G'];

export const WUBRG_COLORS = {
  W: 'white',
  U: 'blue',
  B: 'gray',
  R: 'red',
  G: 'green',
};

export const DAMAGE_TYPES = {
  INFECT: 'infect',
  COMMANDER: 'commander',
};
