import React, { FunctionComponent, createContext, useContext } from 'react';
import { Game, Player, AnyCard, SessionStatus, VideoLayout } from 'types';

import { GameSession } from 'api/modules/GameSession';

export type FocusedInput = any | null;

export interface GameContextProps {
  peer: any;
  focusedPlayerId: string;
  gameApi: GameSession;
  game: Game;
  players: Player[];
  cards: AnyCard[];
  status: SessionStatus;
  videoLayout: VideoLayout;
  userBlocklist: string[];
}

export const GameContext = createContext<Partial<GameContextProps>>({});

export function useGameContext() {
  const context = useContext(GameContext);
  return context;
}

interface GameContextProviderProps {
  value: Partial<GameContextProps>;
}

export const GameContextProvider: FunctionComponent<
  GameContextProviderProps
> = (props) => {
  const { children, value } = props;

  const context = {
    ...value,
  };

  return (
    <GameContext.Provider value={context}>{children}</GameContext.Provider>
  );
};
