import { useMemo } from 'react';

import { GamePlayerSlot, getMaxPlayers, Player } from 'types';

import useGame from 'hooks/useGame';
import usePlayers from 'hooks/usePlayers';

type OrderedPlayer = Player | null;

export type OrderedPlayers =
  | [OrderedPlayer, OrderedPlayer, OrderedPlayer, OrderedPlayer]
  | [OrderedPlayer, OrderedPlayer];

export default function useOrderedPlayers(): OrderedPlayers {
  const game = useGame();
  const players = usePlayers();

  return useMemo(() => {
    const orderedPlayers: OrderedPlayers = Array(
      getMaxPlayers(game?.format)
    ).fill(null) as OrderedPlayers;

    if (!game) {
      return orderedPlayers;
    }

    const { players: gamePlayers = [] } = game;

    gamePlayers.forEach((id: GamePlayerSlot, index) => {
      const player = players?.find((p) => id === p.uid);
      if (player) {
        orderedPlayers[index] = player;
      }
    });

    return orderedPlayers;
  }, [players, game]);
}
