import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { AnyCard, Game, Players, SessionStatus, VideoLayout } from 'types';

import {
  DEFAULT_STATUS,
  GameSession,
  SessionCallback,
} from 'api/modules/GameSession';

import useFirebaseUser from 'hooks/useFirebaseUser';
import { useRecoilState } from 'recoil';
import { gameState, playersState } from 'state/atoms';

export interface useGameSessionReturn {
  focusedPlayerId?: string;
  gameApi?: GameSession;
  game?: Game;
  players: Players;
  cards?: AnyCard[];
  status: SessionStatus;
  videoLayout?: VideoLayout;
  isUsingMobileCamera: boolean;
  userBlocklist: string[];
}

export default function useGameSession(
  gameId: string,
  isSpectator: boolean = false
): useGameSessionReturn {
  const user = useFirebaseUser();
  const history = useHistory();

  const [session, setSession] = useState<GameSession>();

  const [game, setGame] = useRecoilState<Game | undefined>(gameState);
  const [players, setPlayers] = useRecoilState<Players>(playersState);
  const [cards, setCards] = useState<AnyCard[]>([]);
  const [focusedPlayerId, setFocusedPlayerId] = useState<string>();
  const [videoLayout, setVideoLayout] = useState<VideoLayout>();
  const [status, setStatus] = useState<SessionStatus>(DEFAULT_STATUS);
  const [isUsingMobileCamera, setIsUsingMobileCamera] =
    useState<boolean>(false);
  const [userBlocklist, setUserBlocklist] = useState<string[]>([]);

  useEffect((): (() => void) => {
    let session: GameSession;

    const onUpdate: SessionCallback = ({
      status,
      game,
      players,
      cards,
      focusedPlayerId,
      videoLayout,
      useMobileCamera,
      userBlocklist,
    }) => {
      setStatus(status);
      setFocusedPlayerId(focusedPlayerId);
      setGame(game);
      setPlayers(players);
      setCards(cards);
      setVideoLayout(videoLayout);
      setIsUsingMobileCamera(useMobileCamera);
      setUserBlocklist(userBlocklist || []);
    };

    const createSession = (): void => {
      session = new GameSession(gameId, user!, isSpectator, onUpdate);
      setSession(session);
    };

    createSession();

    return (): void => {
      if (session) {
        session.destroy();
      }
    };
  }, [gameId, user, history, isSpectator, setPlayers, setGame]);

  return {
    focusedPlayerId,
    gameApi: session,
    game,
    players,
    cards,
    status,
    videoLayout,
    isUsingMobileCamera,
    userBlocklist,
  };
}
