import classNames from 'classnames';
import LoadingVideo from 'components/game/LoadingVideo';
import { useParticipant } from 'hooks/useParticipant';
import { Participant, Track } from 'livekit-client';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';

interface VideoRendererProps {
  participant: Participant;
  rotate: boolean;
  vid: React.RefObject<HTMLVideoElement>;
}

const INITIAL_MESSAGE = 'message__loading-video';
const ERROR_TIMEOUT_MESSAGE = 'error__video-timeout';
const MESSAGE_NO_VIDEO = 'message__video-off';

export const VideoRenderer: FunctionComponent<VideoRendererProps> = ({
  participant,
  rotate,
  vid,
}) => {
  const {
    isSubscribed,
    isMuted: videoDisabled,
    track: participantTrack,
  } = useParticipant(participant).cameraPublication ?? {};
  const [videoMessage, setVideoMessage] = useState(INITIAL_MESSAGE);
  const messageTimeout = useRef<NodeJS.Timeout | null>();
  const [isLoading, setIsLoading] = useState(true);
  const videoClasses = classNames(
    { flip: rotate },
    { untransform: !rotate },
    'absolute',
    'left-0',
    'top-0',
    'w-full',
    'h-full'
  );

  const track: Track | undefined =
    isSubscribed && !videoDisabled && participantTrack
      ? participantTrack
      : undefined;

  useEffect(() => {
    if (videoDisabled) {
      setVideoMessage(MESSAGE_NO_VIDEO);
      setIsLoading(false);
      return;
    }

    if (!track && !messageTimeout.current) {
      setIsLoading(true);
      setVideoMessage(INITIAL_MESSAGE);
      messageTimeout.current = setTimeout(() => {
        setVideoMessage(ERROR_TIMEOUT_MESSAGE);
        messageTimeout.current = null;
      }, 4000);
    }
  }, [track, videoDisabled]);

  useEffect(() => {
    const el = vid.current;

    if (!el) {
      return;
    }

    if (track) {
      track.attach(el);
    }
    return () => {
      if (el && track) {
        track.detach(el);
      }
      if (messageTimeout.current) {
        clearTimeout(messageTimeout.current);
        messageTimeout.current = null;
      }
    };
  }, [track, vid]);

  return (
    <>
      {!track ? (
        <LoadingVideo isLoading={isLoading} message={videoMessage} />
      ) : (
        <video className={videoClasses} playsInline autoPlay muted ref={vid} />
      )}
    </>
  );
};
