import { getTagFromName, LanguageTag, StreamedTag } from 'constants/tags';
import { TFunction } from 'i18next';
import { Tags } from 'types';

export default function tagListAsString(t: TFunction, tags?: Tags): string {
  if (!t || !tags) return '';

  const out = [];
  const entries = Object.entries(tags);
  entries.sort(([aKey], [bKey]) => {
    const matchA = getTagFromName(aKey).sort;
    const matchB = getTagFromName(bKey).sort;
    return matchA - matchB;
  });

  for (const [key, value] of entries) {
    if (key === StreamedTag.name && value) {
      out.push(StreamedTag.name);
    } else if (key === LanguageTag.name) {
      out.push(value as string);
    }
  }
  return out
    .map((x) => (x === StreamedTag.name ? t('label__streaming-tag') : x))
    .join(' ');
}
