export enum SessionStatus {
  LOADING = 'loading',
  READY = 'ready',
  ERROR = 'error',
  JOINING = 'joining',
  GAME_FULL = 'gameFull',
  NO_GAME_EXISTS = 'noGameExists',
  GAME_DELETED = 'gameDeleted',
  KICKED = 'kicked',
  TIMED_OUT = 'timed_out',
}

export enum ConnectionState {
  CONNECTING = 'connecting',
  RECONNECTING = 'reconnecting',
  CONNECTED = 'connected',
  DISCONNECTED = 'disconnected',
}

export enum VideoLayout {
  DEFAULT = 'default',
  FOCUSED = 'focused',
  THEATER = 'theater',
}

export const UNJOINABLE_STATUSES = [
  SessionStatus.GAME_FULL,
  SessionStatus.KICKED,
  SessionStatus.NO_GAME_EXISTS,
  SessionStatus.GAME_DELETED,
  SessionStatus.ERROR,
];
