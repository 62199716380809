import { useEffect } from 'react';

export function contains(
  root: HTMLElement | null,
  n: HTMLElement | null
): boolean {
  let node: (Node & ParentNode) | null = n;
  while (node) {
    if (node === root) {
      return true;
    }
    node = node.parentNode;
  }

  return false;
}

export function useBodyClick(
  callback: Function,
  shouldIgnore: (target: EventTarget | null) => boolean
): void {
  useEffect(() => {
    const onMouseDown = (e: MouseEvent) => {
      if (shouldIgnore(e.target)) {
        return;
      }

      if (callback) {
        callback();
      }
    };

    window.addEventListener('mousedown', onMouseDown);

    return () => {
      window.removeEventListener('mousedown', onMouseDown);
    };
  }, [callback, shouldIgnore]);
}
