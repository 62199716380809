import React, { FC } from 'react';
import { ChevronDown } from 'react-feather';

interface SelectProps {
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  value?: string | number;
  isDisabled?: boolean;
  level?: 'low' | 'medium';
  variant?: 'regular' | 'compact';
}

export const Select: FC<SelectProps> = (props) => {
  const {
    children,
    onChange,
    value,
    isDisabled,
    level = 'medium',
    variant = 'regular',
  } = props;

  const selectPadding =
    variant === 'regular' ? 'px-4 py-2 pr-8' : 'px-2 py-1 pr-6';

  return (
    <div className="relative w-full flex items-center">
      <select
        value={value}
        onChange={onChange}
        disabled={isDisabled}
        className={`block w-full appearance-none text-white rounded shadow leading-tight focus:outline-none border border-surface-low focus:border-st-orange-normal ${
          'bg-surface-' + level
        } ${selectPadding}`}
      >
        {children}
      </select>
      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
        <ChevronDown size="14" />
      </div>
    </div>
  );
};
