import React, { FunctionComponent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal, Header } from 'components/ui/Modal';
import { Button } from 'components/ui/Button';
import { CustomTooltip } from 'components/CustomTooltip';
import { Checkbox } from 'components/ui/Checkbox';

export type ResetModalParams = {
  resetState?: boolean;
  resetCommander?: boolean;
  resetLog?: boolean;
};

interface ResetModalProps {
  onModalClose: (params?: ResetModalParams) => void;
}

export const ResetModal: FunctionComponent<ResetModalProps> = (props) => {
  const { onModalClose } = props;
  const { t } = useTranslation();
  const [isGameStateReset, setIsGameStateReset] = useState<boolean>(true);
  const [isGamelogReset, setIsGamelogReset] = useState<boolean>(true);
  const [isCommanderReset, setIsCommanderReset] = useState<boolean>(true);

  const resetGame = useCallback((name: string, isChecked: boolean) => {
    setIsGameStateReset(isChecked);
  }, []);

  const resetGamelog = useCallback((name: string, isChecked: boolean) => {
    setIsGamelogReset(isChecked);
  }, []);

  const resetCommander = useCallback((name: string, isChecked: boolean) => {
    setIsCommanderReset(isChecked);
  }, []);

  const submitResetParams = useCallback(() => {
    onModalClose({
      resetState: isGameStateReset,
      resetCommander: isCommanderReset,
      resetLog: isGamelogReset,
    });
  }, [isCommanderReset, isGameStateReset, isGamelogReset, onModalClose]);

  const closeModal = useCallback(() => {
    // When params are undefined the callback knows to not reset anything.
    // This doesn't work the same when passed into the modal's onClose.
    onModalClose();
  }, [onModalClose]);

  return (
    <Modal
      style={{ width: 300, height: 300 }}
      className="phone:w-auto"
      onClose={closeModal}
    >
      <div className="flex flex-col items-center">
        <Header>{t('label__reset-game')}</Header>
        <div className="flex flex-col">
          <CustomTooltip
            tooltip={t('label__life-totals-monarch-and-elimination-status')}
          >
            <div className="flex flex-row justify-between">
              <label className="ml-2 inline-block relative w-full flex items-center justify-between">
                <span className="mr-2">{t('label__reset-game')}</span>
                <Checkbox
                  isChecked={isGameStateReset}
                  name="isResetGame"
                  onChange={resetGame}
                />
              </label>
            </div>
          </CustomTooltip>
          <div className="flex flex-row justify-between mt-2">
            <label className="ml-2 inline-block relative w-full flex items-center justify-between">
              <span className="mr-2 mt-1">{t('label__reset-commander')}</span>
              <Checkbox
                isChecked={isCommanderReset}
                name="isResetCommander"
                onChange={resetCommander}
              />
            </label>
          </div>
          <div className="flex flex-row justify-between mt-2">
            <label className="ml-2 inline-block relative w-full flex items-center justify-between">
              <span className="mr-2 mt-1">{t('label__reset-gamelog')}</span>
              <Checkbox
                isChecked={isGamelogReset}
                name="isResetGamelog"
                onChange={resetGamelog}
              />
            </label>
          </div>
        </div>
        <div className="flex flex-row mt-5">
          <Button onClick={submitResetParams} className="mt-5 w-20 mr-2">
            {t('label__reset')}
          </Button>
          <Button onClick={closeModal} className="mt-5 w-20">
            {t('action__cancel')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
