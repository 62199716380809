import React, { FunctionComponent, useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import useSearchGames from 'hooks/useSearchGames';

import { Button } from 'components/ui/Button';
import { Select } from 'components/ui/Select';
import { GameCard } from 'components/lobby/GameCard';

import { MagicFormat, Game } from 'types';

const GameCards: FunctionComponent<{ games: Game[] }> = ({ games }) => {
  return (
    <>
      {games.map((game, index) => (
        <GameCard game={game} key={index} />
      ))}
    </>
  );
};

export const LobbyGamesList: FunctionComponent = () => {
  const { t } = useTranslation();

  const { games, search, page, maxPages, setPage, setSearch } =
    useSearchGames();

  const onPageChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    setPage(parseInt(e.target.value, 10));
  };

  const previousPage = useCallback(async (): Promise<void> => {
    setPage(Math.max(0, page - 1));
  }, [page, setPage]);

  const nextPage = useCallback(async (): Promise<void> => {
    setPage(Math.max(0, page + 1));
  }, [page, setPage]);

  const clearSearch = useCallback(async (): Promise<void> => {
    setSearch({
      title: '',
      format: 'ANY',
    });
    setPage(0);
  }, [setPage, setSearch]);

  const onFormatChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    setPage(0);
    setSearch({
      title: search.title,
      format: e.target.value as MagicFormat,
    });
  };

  const FormatOptions = Object.values(MagicFormat).map((format) => {
    return (
      <option value={format} key={format}>
        {format}
      </option>
    );
  });

  const PageOptions = () => {
    const options = [];
    for (let i = 0; i < maxPages; i++) {
      options.push(
        <option value={i} key={i + 1}>
          {i + 1}
        </option>
      );
    }
    return options;
  };

  return (
    <div className="bg-surface-medium rounded-lg relative">
      <div className="flex flex-col tablet:flex-row justify-between items-center p-6">
        <div className="uppercase mb-4 py-2 tablet:mb-0 font-bold text-xl text-white">
          {t('title__lobby')}
        </div>

        <span className="ml-auto flex flex-col tablet:flex-row w-full tablet:w-auto">
          {(search.format !== 'ANY' || search.title !== '') && (
            <Button onClick={clearSearch} size="large" variant="secondary">
              {t('action__clear-search')}
            </Button>
          )}
        </span>
      </div>
      <div className="border-t border-surface-border p-6 flex flex-col tablet:flex-row w-full tablet:w-auto">
        <div className="flex flex-col w-full h-full">
          <div className="flex flex-row justify-between w-full h-full">
            <div className="flex flex-row content-center items-center">
              <div className="flex flex-row content-center items-center mr-2">
                {t('label__format')}
              </div>
              <Select
                onChange={onFormatChange}
                value={search.format}
                level="low"
                variant="compact"
              >
                <option value={'ANY'} key={'ANY'}>
                  {t('label__any-format')}
                </option>
                {FormatOptions}
              </Select>
            </div>
            <div className="flex flex-row content-center items-center">
              {page !== 0 && maxPages > 1 && (
                <Button
                  onClick={previousPage}
                  size="default"
                  variant="secondary"
                  className="mr-2"
                >
                  {t('action__previous')}
                </Button>
              )}
              <div className="flex flex-row content-center items-center mr-2">
                {t('label__page')}
              </div>
              <Select
                onChange={onPageChange}
                value={String(page)}
                level="low"
                variant="compact"
              >
                {PageOptions()}
              </Select>
              {page !== maxPages - 1 && maxPages > 1 && (
                <Button
                  onClick={nextPage}
                  size="default"
                  variant="secondary"
                  className="ml-2"
                >
                  {t('action__next')}
                </Button>
              )}
            </div>
          </div>
          {games.length > 0 ? (
            <div className="grid grid-cols-1 tablet:grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4 w-full h-full mt-6">
              <GameCards games={games} />
            </div>
          ) : (
            <span className="block w-full text-white text-center m-6">
              {t('label__no-matching-filters')}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};
