import {
  ConnectionQuality,
  LocalParticipant,
  Participant,
  ParticipantEvent,
  Track,
  TrackPublication,
} from 'livekit-client';
import { useEffect, useState } from 'react';

export interface ParticipantState {
  connectionQuality: ConnectionQuality;
  isLocal: boolean;
  metadata?: string;
  publications: TrackPublication[];
  subscribedTracks: TrackPublication[];
  cameraPublication?: TrackPublication;
  microphonePublication?: TrackPublication;
  screenSharePublication?: TrackPublication;
}

export function useParticipant(participant: Participant): ParticipantState {
  const [connectionQuality, setConnectionQuality] = useState<ConnectionQuality>(
    participant.connectionQuality
  );
  const [metadata, setMetadata] = useState<string>();
  const [publications, setPublications] = useState<TrackPublication[]>([]);
  const [subscribedTracks, setSubscribedTracks] = useState<TrackPublication[]>(
    []
  );

  useEffect(() => {
    let mounted = true;
    const onMetadataChanged = () => {
      if (mounted) {
        if (participant.metadata) {
          setMetadata(participant.metadata);
        }
      }
    };
    const onConnectionQualityUpdate = () => {
      if (mounted) {
        setConnectionQuality(participant.connectionQuality);
      }
    };

    const onPublicationsChanged = () => {
      if (mounted) {
        setPublications(Array.from(participant.trackPublications.values()));
        setSubscribedTracks(
          Array.from(participant.trackPublications.values()).filter((pub) => {
            return pub.isSubscribed && pub.track !== undefined;
          })
        );
      }
    };
    // register listeners
    participant
      .on(ParticipantEvent.ParticipantMetadataChanged, onMetadataChanged)
      .on(ParticipantEvent.TrackPublished, onPublicationsChanged)
      .on(ParticipantEvent.TrackUnpublished, onPublicationsChanged)
      .on(ParticipantEvent.TrackSubscribed, onPublicationsChanged)
      .on(ParticipantEvent.TrackUnsubscribed, onPublicationsChanged)
      .on(ParticipantEvent.LocalTrackPublished, onPublicationsChanged)
      .on(ParticipantEvent.LocalTrackUnpublished, onPublicationsChanged)
      .on(ParticipantEvent.ConnectionQualityChanged, onConnectionQualityUpdate);

    // set initial state
    onMetadataChanged();
    onPublicationsChanged();

    return () => {
      mounted = false;
      // cleanup
      participant
        .off(ParticipantEvent.ParticipantMetadataChanged, onMetadataChanged)
        .off(ParticipantEvent.TrackPublished, onPublicationsChanged)
        .off(ParticipantEvent.TrackUnpublished, onPublicationsChanged)
        .off(ParticipantEvent.TrackSubscribed, onPublicationsChanged)
        .off(ParticipantEvent.TrackUnsubscribed, onPublicationsChanged)
        .off(ParticipantEvent.LocalTrackPublished, onPublicationsChanged)
        .off(ParticipantEvent.LocalTrackUnpublished, onPublicationsChanged)
        .off(
          ParticipantEvent.ConnectionQualityChanged,
          onConnectionQualityUpdate
        );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participant]);

  return {
    isLocal: participant instanceof LocalParticipant,
    connectionQuality,
    publications,
    subscribedTracks,
    cameraPublication: participant.getTrackPublication(Track.Source.Camera),
    microphonePublication: participant.getTrackPublication(
      Track.Source.Microphone
    ),
    screenSharePublication: participant.getTrackPublication(
      Track.Source.ScreenShare
    ),
    metadata,
  };
}
