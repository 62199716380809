import firebase from 'api/firebase';
import { BLOCK_USER, UNBLOCK_USER } from 'constants/bi-names';
import { EventEmitter } from 'events';

import { InGameAudioVideo, Preferences, User } from 'types';

export const EVENTS = {
  UPDATE: 'user:update',
};

const COLLECTION: string = 'users';

function getDefaultUserData(): User {
  return {
    preferences: {
      useMobileCamera: false,
    },
  };
}

export default class UserApi {
  private authUser: firebase.User | null = null;
  private db = firebase.firestore();
  private collectionRef = this.db.collection(COLLECTION);
  private user: User | null = null;
  private emitter: EventEmitter;
  // TODO: this is ugly being passed from the instantiated class
  private track: (name: string, isPII: boolean, data?: {}) => Promise<void> =
    async () => {
      return;
    }; // init with noop

  constructor() {
    this.emitter = new EventEmitter();
  }

  init = async (
    user: firebase.User,
    track: (name: string, isPII: boolean, data?: {}) => Promise<void>
  ): Promise<void> => {
    this.authUser = user;
    this.track = track;

    this.user = null;
    const doc = await this.getUserDoc();
    if (!doc.exists) {
      const userData = getDefaultUserData();
      this.user = userData;
      await doc.ref.set(userData);
    } else {
      this.user = doc.data() as User;
    }
    this.watchUser();
  };

  watchUser = (): Function => {
    let shouldEmit = false;

    return this.db
      .collection(COLLECTION)
      .doc(this.authUser?.uid)
      .onSnapshot((snapshot) => {
        const after = snapshot.data() as User;
        this.user = after;

        if (shouldEmit) {
          // tell all subscribers
          this.emitter.emit(EVENTS.UPDATE, this.user);
        }

        // flip should emit after the first load
        shouldEmit = true;
      });
  };

  // Allow consumers to subscribe to updates.
  subscribe(eventName: string, callback: (...args: any[]) => void): Function {
    this.emitter.on(eventName, callback);
    // return the unsubscribe method
    return () => {
      this.emitter.off(eventName, callback);
    };
  }

  getUserDoc = async (): Promise<firebase.firestore.DocumentSnapshot> => {
    return await this.collectionRef.doc(this.authUser?.uid).get();
  };

  getUserDocRef = async (): Promise<firebase.firestore.DocumentReference> => {
    return await this.collectionRef.doc(this.authUser?.uid);
  };

  getUser = (): User | null => {
    return this.user;
  };

  async updateWotcAccount(accountId: string, personaId: string) {
    await this.update({
      personaId,
      accountId,
    });
  }

  async update(data: Partial<User>): Promise<void> {
    const docRef = await this.getUserDocRef();
    await docRef.update(data);
  }

  toggleBlockuser = async (uid: string): Promise<void> => {
    if (!this.user) {
      return;
    }
    const currentList = this.user.blocklist || [];
    const currentIndex = currentList.indexOf(uid);
    const isBlocking = currentIndex === -1;
    const updatedBlocklist = isBlocking
      ? [...currentList, uid] // add
      : currentList.filter((_, i) => i !== currentIndex); // remove
    this.user = {
      ...this.user,
      blocklist: updatedBlocklist,
    };
    await this.track(isBlocking ? BLOCK_USER : UNBLOCK_USER, false, {
      blockedAccountId: uid,
    });

    const docRef = await this.getUserDocRef();
    await docRef.update({ blocklist: updatedBlocklist });
  };

  setPreferences = async (preferences: Preferences): Promise<void> => {
    if (!this.user) {
      return;
    }

    const { preferences: existingPreferences } = this.user;

    const updatedPreferences = {
      ...existingPreferences,
      ...preferences,
    };

    this.user = {
      ...this.user,
      preferences: updatedPreferences,
    };
    const docRef = await this.getUserDocRef();
    await docRef.update({ preferences: updatedPreferences });
    return;
  };

  setAVPreferences = async (
    gameAudioVideo: InGameAudioVideo
  ): Promise<void> => {
    if (!this.user) {
      return;
    }

    const { preferences: existingPreferences } = this.user;

    const update = {
      ...existingPreferences,
      gameAudioVideo: {
        ...existingPreferences?.gameAudioVideo,
        ...gameAudioVideo,
      },
    };
    this.user.preferences = update;
    const docRef = await this.getUserDocRef();
    await docRef.update({ preferences: update });
    return;
  };

  cleanup(): void {
    this.user = null;
    this.authUser = null;
  }

  async updateName(displayName: string): Promise<void> {
    if (!this.authUser) {
      return;
    }

    await updateProfile(this.authUser, { displayName });
  }

  async updatePhoto(file: File): Promise<string> {
    if (!file) {
      throw new Error('No file provided to update user photo.');
    }

    const { name } = file;
    const chunks = name.split('.');
    const ext = (chunks[chunks.length - 1] || '').toLowerCase();

    if (ext !== 'jpg' && ext !== 'jpeg' && ext !== 'png') {
      throw new Error('Images must be of type jpg or png.');
    }

    const storageRef = firebase.storage().ref();
    const userImageRef = storageRef.child(
      `${this.authUser?.uid}/images/profile.${ext}`
    );

    let newPhotoURL;

    try {
      const snapshot = await userImageRef.put(file);
      newPhotoURL = await snapshot.ref.getDownloadURL();
      await this.authUser?.updateProfile({
        photoURL: newPhotoURL,
      });
    } catch (e) {
      throw new Error('Could not upload profile picture.');
    }

    return newPhotoURL;
  }
}

async function updateProfile(
  user: firebase.User,
  update: Partial<firebase.UserInfo> = {}
): Promise<void> {
  try {
    await user.updateProfile(update);
  } catch (e) {
    console.error(e);
  }
}
