import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

interface MessageBoxProps {
  title: string;
  message: string;
  buttonLabel?: string;
  showRejoin?: boolean;
  onDismiss: () => void;
}

export const MessageBox: FunctionComponent<MessageBoxProps> = (props) => {
  const {
    title,
    message,
    buttonLabel = 'Ok',
    showRejoin = false,
    onDismiss,
  } = props;

  const { t } = useTranslation();

  const onRejoin = (): void => {
    window.location.reload();
  };

  const rejoinButton = showRejoin ? (
    <button
      className="text-sm py-2 px-3 rounded tranision-all ease-in-out duration-200 mx-2 bg-st-purple-light hover:bg-st-purple-normal text-white"
      onClick={onRejoin}
    >
      {t('action__rejoin')}
    </button>
  ) : null;

  return (
    <div className="flex flex-col w-full h-full justify-center items-center">
      <div className="w-500 flex-col justify-center align-middle text-center text-gray-600 rounded-lg shadow-md p-12 text-gray-200 bg-surface-medium">
        <div className="font-thin text-3xl text-white mb-4">{title}</div>
        <div className="mb-6">{message}</div>
        <div className="text-center">
          {rejoinButton}
          <button
            className="text-sm py-2 px-3 rounded tranision-all ease-in-out duration-200 mx-2 bg-st-purple-light hover:bg-st-purple-normal text-white"
            onClick={onDismiss}
          >
            {buttonLabel}
          </button>
        </div>
      </div>
    </div>
  );
};
