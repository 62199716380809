import { useState, useEffect } from 'react';

import Api, { EVENTS } from 'api';

import { User, Preferences } from 'types';

export default function usePreferences(): Preferences {
  const [preferences, setPreferences] = useState<Preferences>({});

  useEffect(() => {
    const onUpdate = (data: User) => {
      setPreferences(data.preferences || {});
    };

    const unsubscribe = Api.user.subscribe(EVENTS.USER.UPDATE, onUpdate);

    // get initial value if we have a user.
    const user = Api.user.getUser();
    if (user) {
      onUpdate(user);
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  return preferences;
}
